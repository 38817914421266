import logo from 'images/servicesLogos/mattermost.svg';
import logoOpenGraph from 'images/servicesLogos/openGraphVersions/mattermost.png';

import growth from 'images/landings/icons/growth.svg';
import startup from 'images/landings/icons/startup.svg';
import shield from 'images/landings/icons/shield.svg';
import responsive from 'images/landings/icons/responsive.svg';
import envelope from 'images/landings/icons/envelope.svg';
import strategy from 'images/landings/icons/strategy.svg';

import checked from 'images/landings/icons/checked.svg';

import imageMattermost from 'images/landings/screenshots/mattermost/mattermost.png';

const backgroundColor = '#3d63b3';
const button = {
  color: '#000',
  backgroundColor: '#ffbc1f'
};

export default ({ t = () => [] }) => ({
  id: 'mattermost',
  name: 'Mattermost',
  nameUrl: 'Mattermost',
  title: t('services/mattermost:title'),
  logo,
  logoStyle: [
    {
      elementId: 'path',
      style: {
        fill: '#fff'
      }
    }
  ],
  logoOpenGraph,
  button,
  backgroundColor,

  header: {
    title: 'Mattermost',
    description: t('services/mattermost:description'),
    images: [ { image: imageMattermost } ]
  },

  benefits: {
    items: [
      {
        icon: growth,
        ...t('services/mattermost:benefits.business')
      },
      {
        icon: startup,
        ...t('services/mattermost:benefits.performant')
      },
      {
        icon: shield,
        ...t('services/mattermost:benefits.secured')
      },
      {
        icon: responsive,
        ...t('services/mattermost:benefits.notifications')
      },
      {
        icon: envelope,
        ...t('services/mattermost:benefits.emails')
      },
      {
        icon: strategy,
        ...t('services/mattermost:benefits.updatable')
      }
    ]
  },

  also: {
    color: button.backgroundColor,
    items: t('services/mattermost:also.items')
      .map(content => ({ icon: checked, ...content }))
  },

  pricing: {
    buttonBackgroundColor: button.backgroundColor,
    buttonColor: button.color,
    sentencesIconColor: button.backgroundColor,
    includes: t('services/mattermost:pricing.includes')
      .map(description => ({ icon: checked, description })),
    fields: {
      usersRecommended: v => [ new Intl.NumberFormat(undefined).format(v), t('services/mattermost:pricing.subLegends.usersRecommended') ],
      hdd: hdd => [ hdd < 1024 * 1024 * 1024 ? `${Math.round(hdd / (1024 * 1024))} ${t('units.MB')}` : `${Math.round(hdd / (1024 * 1024 * 1024))} ${t('units.GB')}`, t('services/mattermost:pricing.subLegends.storage') ]
    }
  },

  recall: {
    description: t('services/mattermost:recall')
  },

  faq: {
    items: t('services/mattermost:faq.items')
  }
});
