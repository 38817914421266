import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { WarningFilled } from '@ant-design/icons';

import Button from './landings/Button';

import { withTranslation } from 'react-i18next';

import styles from './Page404.css';

class Page404 extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired
  };


  render() {
    const { t } = this.props;

    return (
      <>
        <div className={styles.container}>
          <WarningFilled className={styles.icon} />
          <br />

          {t('base.404.message')}
          <br />

          <Button
            href="/"
            label={t('base.404.button')}
            backgroundColor="#1677ff"
            color="#fff"
            size="big"
            newPage={false}
          />
        </div>

        <Helmet>
          <title>{t('base.404.title')}</title>

          {/* For SEO purpose */}
          <meta name="robots" content="noindex" />

          {/* For Rendertron */}
          <meta name="render:status_code" content="404" />
        </Helmet>
      </>
    );
  }
}

export default withTranslation()(Page404);
