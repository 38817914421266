import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as navigationActions from 'redux/actions/navigation';

import InstanceStore from 'components/InstanceStore';

import { Card, Col, Button, Row, Select } from 'antd';

import regionsDetails from 'contents/regions';

class _default extends React.Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    navigationActions: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };

  state = {
    regionId: undefined
  };

  componentDidMount() {
    const { organizationId, serviceStoreId } = this.props.match.params;
    const route = `/dashboard/organizations/${organizationId}/servicesStore/${serviceStoreId}/instancesStore`;
    const { regions} = this.props.api.subscriptions[route].datas;
    this.setState({ regionId: regions[0] });

    this.props.navigationActions.stepSelect(1);
  }

  render() {
    if (this.state.regionId === undefined) {
      return null;
    }

    const { organizationId, serviceStoreId } = this.props.match.params;
    const route = `/dashboard/organizations/${organizationId}/servicesStore/${serviceStoreId}/instancesStore`;
    const { regions, instancesStore} = this.props.api.subscriptions[route].datas;

    const routeOrganization = `/dashboard/organizations/${organizationId}`;
    const { currency } = this.props.api.subscriptions[routeOrganization].datas;

    return (
      <Card
        title="Select an instance"
        headStyle={{ backgroundColor: '#fff' }}
        style={{ backgroundColor: '#fafafa' }}

        // Region selector
        extra={(
          <Select defaultValue={this.state.regionId} onChange={regionId => this.setState({ regionId })}>
            {
              regions
                .map(regionId => (
                  <Select.Option key={regionId} value={regionId}>
                    {regionsDetails[regionId].emoji} {regionsDetails[regionId].label}
                  </Select.Option>
                ))
            }
          </Select>
        )}
      >
        <Row style={{ overflow: 'hidden', paddingBottom: '10px' }}> {/* To avoid a bug on display with hourly billing sentence */}
          {instancesStore
            .sort((a, b) => {
              const aPrice = a.prices.find(price => price.currency === currency);
              const bPrice = b.prices.find(price => price.currency === currency);
              return aPrice.amount - bPrice.amount;
            })
            .map(instanceStore => (
              <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={8} key={instanceStore.id}>
                <InstanceStore
                  instanceStore={instanceStore}

                  // Region details
                  features={[
                    `${regionsDetails[this.state.regionId].emoji} Instance running in ${regionsDetails[this.state.regionId].label}`
                  ]}

                  currency={currency}
                  onClick={() => this.props.history.push({
                    pathname: '../../purchase/',
                    state: {
                      type: 'serviceStore',
                      serviceStoreId,
                      instanceStoreId: instanceStore.id,
                      instanceStoreStatus: instanceStore.status,
                      regionId: this.state.regionId
                    }
                  })}
                />
              </Col>
            ))
          }
        </Row>

        <div style={{ textAlign: 'right', margin: '10px' }}>
          <Link to="../../">
            <Button style={{ marginRight: 8 }}>Previous</Button>
          </Link>
        </div>
      </Card>
    );
  }
}

export default connect(
  ({ api, app }) => ({ api, app }),
  dispatch => ({
    navigationActions: bindActionCreators(navigationActions, dispatch)
  })
)(_default);
