import React from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import * as apiActions from 'redux/actions/api';
import * as appActions from 'redux/actions/app';

import { withTranslation } from 'react-i18next';

import queryString from 'query-string';
import { Modal, Form, Button, Card, Alert } from 'antd';

import FormItems from 'components/FormItems';

class SingleUseTokenLogin extends React.Component {
  static propTypes = {
    apiActions: PropTypes.object.isRequired,
    appActions: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired
  };

  state = {
    error: false,
    form: {},
    formIsValid: false,
    showErrors: false,
    totpVisible: false
  };

  componentDidMount() {
    this._mounted = true;

    const { emailEncoded, token } = queryString.parse(this.props.location.search);
    this.login({ emailEncoded, token });
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  login({ emailEncoded, token, totpToken }) {
    const { t } = this.props;

    // Note: debug to try to find what triggered the issue https://sentry.io/organizations/bacto/issues/2243594779/?environment=production&project=5624643
    try {
      atob(emailEncoded);
    }
    catch (error) {
      console.error(`Error when trying to decode ${emailEncoded}`);
    }

    this.props.apiActions.post(
      {
        route: '/singleUseTokenLogin',
        routeArgs: { email: atob(emailEncoded), token, totpToken: totpToken ? totpToken.replace('-', '') : totpToken },
        handleErrorAutomatically: false
      },
      (error, response) => {
        if (!this._mounted) { return false; }


        if (error === 'totpAuthenticationRequired') {
          this.setState({ totpVisible: true, showErrors: false });
          return;
        }
        else if (error === 'totpAuthenticationFailed') {
          this.setState({ error: t('login.totpAuthenticationFailed') });
          return;
        }
        else if (error) {
          this.props.history.replace('/notLogged/login');
          Modal.error({
            content: 'This link was temporary and is no more available.',
          });
          return;
        }

        this.props.appActions.sessionSetDispatch(response);

        const { next } = queryString.parse(this.props.location.search);
        if (next === 'account') {
          this.props.history.replace('/account');
        }
        else {
          this.props.history.replace('/');
        }
      }
    );
  }

  handleSubmit = () => {
    this.setState({ showErrors: true });
    if (!this.state.formIsValid) {
      return false;
    }

    this.setState({ error: false });

    const { emailEncoded, token } = queryString.parse(this.props.location.search);
    const { totpToken } = this.state.form;
    this.login({ emailEncoded, token, totpToken });
  };

  renderError() {
    if (!this.state.error) {
      return null;
    }

    const { t } = this.props;
    return (
      <Form.Item>
        <Alert
          message={t('login.errorTitle')}
          description={this.state.error}
          type="error"
          showIcon
        />
      </Form.Item>
    );
  }


  entries = () => {
    const { t } = this.props;
    return [
      {
        label: t('login.totp'),
        name: 'totpToken',
        required: true,
        input: {
          type: 'text',
          onChange: value => value.replace(/^([0-9]{3})-?([0-9]+)$/, '$1-$2').replace(/^(.*)-$/, '$1'),
          hasError: (name, value, form) => /^[0-9]{3}-[0-9]{3}$/.test(value) ? '' : true,
          autoComplete: 'one-time-code'
        }
      }
    ];
  };


  render() {
    if (!this.state.totpVisible) {
      return null;
    }

    const { t } = this.props;
    return (
      <Card title={t('login.action')}>
        <Form onFinish={this.handleSubmit} layout="vertical">
          {this.renderError()}

          <FormItems
            sizes={{ xxl: 24, xl: 24, lg: 24, md: 24, sm: 24, xs: 24 }}
            entries={this.entries()}
            onStatusChange={formIsValid => this.setState({ formIsValid })}
            datas={this.state.form}
            onUpdate={form => this.setState({ form })}
            showErrors={this.state.showErrors}
          />

          <Form.Item style={{ marginBottom: 0 }}>
            <Button
              type="primary"
              htmlType="submit"
              style={{ width: '100%' }}
            >
              {t('login.action')}
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}

export default compose(
  connect(
    () => ({}),
    dispatch => ({
      apiActions: bindActionCreators(apiActions, dispatch),
      appActions: bindActionCreators(appActions, dispatch)
    })
  ),
  withTranslation()
)(SingleUseTokenLogin);
