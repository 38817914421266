import React from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as appActions from 'redux/actions/app';
import isEqual from 'lodash.isequal';

import Script from 'react-load-script';

class Intercom extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    appActions: PropTypes.object.isRequired
  };

  state = {
    datas: {},
    visibled: false
  };

  componentDidMount() {
    this._mount = true;
    this.update();

    // Wait for 15 seconds or that the client scroll the page or move its mouse to load Intercom.
    // The goal is to avoid to load Intercom at the beginning to improve lighthouse results.
    setTimeout(
      () => this._mount && this.setState({ visibled: true }),
      15 * 1000
    );

    const setVisible = (() => {
      this._mount && this.setState({ visibled: true });
      document.addEventListener('mousemove', setVisible);
      document.addEventListener('scroll', setVisible);
    }).bind(this);
    document.addEventListener('mousemove', setVisible);
    document.addEventListener('scroll', setVisible);
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps, this.props)) {
      this.update();
    }
  }

  componentWillUnmount() {
    this._mount = false;
  }

  update() {
    const { language } = this.props.app;
    const datas = {
      user_id: this.props.app.sessionAuth && this.props.app.sessionAuth.username ? this.props.app.sessionAuth.username : undefined,
      user_hash: this.props.app.sessionAuth && this.props.app.sessionAuth.intercomAuthHash ? this.props.app.sessionAuth.intercomAuthHash : undefined,
      language_override: language
    };

    this.setState(currentState => {
      if (!isEqual(currentState.datas, datas)) {
        window.Intercom !== undefined ? window.Intercom('update', datas) : null;
        return {
          ...currentState,
          datas
        };
      }
      return currentState;
    });
  }

  // Case with some ads blocker
  onError = (error) => {};

  onLoad = () => {
    if (!window.Intercom) {
      console.error('Tried to load Intercom but it seems to not be usable.');
      return false;
    }

    const { language } = this.props.app;
    window.Intercom(
      'boot',
      {
        app_id: process.env.INTERCOM_KEY,
        language_override: language,
        ...this.state.datas
      }
    );
    this.props.appActions.intercomAvailabilitySet(true);
  };

  render() {
    if (!this.state.visibled) {
      return null;
    }

    return (
      <Script
        url="https://widget.intercom.io/widget/ja4oqok9"
        onError={this.onError}
        onLoad={this.onLoad}
      />
    );
  }
}

export default connect(
  ({ app }) => ({ app }),
  dispatch => ({
    appActions: bindActionCreators(appActions, dispatch)
  })
)(Intercom);