// NOTE: this file is available on WEB and API
// Those two files have to be the same!


// Save https://restcountries.eu/rest/v2/all to countries.json
// const countries = require('./countries.json');

// const countriesFormated = countries
//   .map(({ name, alpha2Code, regionalBlocs }) => ([
//     name,
//     alpha2Code,
//   ]))
//   .sort((a, b) => a[0].localeCompare(b[0]));

// console.log(JSON.stringify(countriesFormated));

module.exports = {
  // See https://gist.github.com/marcinlerka/630cc05d11bb10c5f1904506ff92abcd
  vatNumbersFormats: {
    AT: [ /^(AT)(U\d{8}$)/i, 'ATUxxxxxxxx' ],
    BE: [ /^(BE)(\d{10}$)/i, 'BExxxxxxxxxx' ],
    BG: [ /^(BG)(\d{9,10}$)/i, 'BGxxxxxxxxx' ],
    CY: [ /^(CY)([0-5|9]\d{7}[A-Z]$)/i, 'CYxxxxxxx' ],
    CZ: [ /^(CZ)(\d{8,10})?$/i, 'CZxxxxxxxx' ],
    DE: [ /^(DE)([1-9]\d{8}$)/i, 'DExxxxxxxx' ],
    DK: [ /^(DK)(\d{8}$)/i, 'DKxxxxxxxx' ],
    EE: [ /^(EE)(10\d{7}$)/i, 'EE10xxxxxxx' ],
    // EL: [ /^(EL)(\d{9}$)/i, 'ELxxxxxxxxx' ], // Not member of Europe
    ES: [ /^(ES)([0-9A-Z][0-9]{7}[0-9A-Z]$)/i, 'ESxxxxxxxxx' ],
    // EU: [ /^(EU)(\d{9}$)/i, 'EUxxxxxxxxx' ], // Not member of Europe
    FI: [ /^(FI)(\d{8}$)/i, 'FIxxxxxxxx' ],
    FR: [ /^(FR)([0-9A-Z]{2}[0-9]{9}$)/i, 'FRxxxxxxxxxxx' ],
    // GB: [ /^(GB)((?:[0-9]{12}|[0-9]{9}|(?:GD|HA)[0-9]{3})$)/i, 'GBxxxxxxxxx' ],
    GR: [ /^(EL)(\d{8,9}$)/i, 'ELxxxxxxxx' ],
    HR: [ /^(HR)(\d{11}$)/i, 'HRxxxxxxxxxxx' ],
    HU: [ /^(HU)(\d{8}$)/i, 'HUxxxxxxxx' ],
    IE: [ /^(IE)([0-9A-Z*+]{7}[A-Z]{1,2}$)/i, 'IExxxxxxxx' ],
    IT: [ /^(IT)(\d{11}$)/i, 'ITxxxxxxxxxxx' ],
    LV: [ /^(LV)(\d{11}$)/i, 'LVxxxxxxxxxxx' ],
    LT: [ /^(LT)(\d{9}$|\d{12}$)/i, 'LTxxxxxxxxx' ],
    LU: [ /^(LU)(\d{8}$)/i, 'LUxxxxxxxx' ],
    MT: [ /^(MT)([1-9]\d{7}$)/i, 'MTxxxxxxx' ],
    NL: [ /^(NL)(\d{9}B\d{2}$)/i, 'NLxxxxxxxxxBxx' ],
    // NO: [ /^(NO)(\d{9}$)/i, 'NOxxxxxxxxx' ], // Not member of Europe
    PL: [ /^(PL)(\d{10}$)/i, 'PLxxxxxxxxxx' ],
    PT: [ /^(PT)(\d{9}$)/i, 'PTxxxxxxxxx' ],
    RO: [ /^(RO)([1-9]\d{1,9}$)/i, 'ROxxxxxxxxx' ],
    // RU: [ /^(RU)(\d{10}$|\d{12}$)/i, 'RUxxxxxxxxxx' ], // Not member of Europe
    // RS: [ /^(RS)(\d{9}$)/i, 'RSxxxxxxxxx' ], // Not member of Europe
    SI: [ /^(SI)([1-9]\d{7}$)/i, 'SIxxxxxxx' ],
    SK: [ /^(SK)([1-9]\d[(2-4)|(6-9)]\d{7}$)/i, 'SKxxxxxxx' ],
    SE: [ /^(SE)(\d{10}01$)/i, 'SExxxxxxxxxx01' ]
  },


  // Emojis
  // https://raw.githubusercontent.com/risan/country-flag-emoji-json/master/json/flag-emojis-by-code.pretty.json
  emojisFlags: {
    AC: '🇦🇨',
    AD: '🇦🇩',
    AE: '🇦🇪',
    AF: '🇦🇫',
    AG: '🇦🇬',
    AI: '🇦🇮',
    AL: '🇦🇱',
    AM: '🇦🇲',
    AO: '🇦🇴',
    AQ: '🇦🇶',
    AR: '🇦🇷',
    AS: '🇦🇸',
    AT: '🇦🇹',
    AU: '🇦🇺',
    AW: '🇦🇼',
    AX: '🇦🇽',
    AZ: '🇦🇿',
    BA: '🇧🇦',
    BB: '🇧🇧',
    BD: '🇧🇩',
    BE: '🇧🇪',
    BF: '🇧🇫',
    BG: '🇧🇬',
    BH: '🇧🇭',
    BI: '🇧🇮',
    BJ: '🇧🇯',
    BL: '🇧🇱',
    BM: '🇧🇲',
    BN: '🇧🇳',
    BO: '🇧🇴',
    BQ: '🇧🇶',
    BR: '🇧🇷',
    BS: '🇧🇸',
    BT: '🇧🇹',
    BV: '🇧🇻',
    BW: '🇧🇼',
    BY: '🇧🇾',
    BZ: '🇧🇿',
    CA: '🇨🇦',
    CC: '🇨🇨',
    CD: '🇨🇩',
    CF: '🇨🇫',
    CG: '🇨🇬',
    CH: '🇨🇭',
    CI: '🇨🇮',
    CK: '🇨🇰',
    CL: '🇨🇱',
    CM: '🇨🇲',
    CN: '🇨🇳',
    CO: '🇨🇴',
    CP: '🇨🇵',
    CR: '🇨🇷',
    CU: '🇨🇺',
    CV: '🇨🇻',
    CW: '🇨🇼',
    CX: '🇨🇽',
    CY: '🇨🇾',
    CZ: '🇨🇿',
    DE: '🇩🇪',
    DG: '🇩🇬',
    DJ: '🇩🇯',
    DK: '🇩🇰',
    DM: '🇩🇲',
    DO: '🇩🇴',
    DZ: '🇩🇿',
    EA: '🇪🇦',
    EC: '🇪🇨',
    EE: '🇪🇪',
    EG: '🇪🇬',
    EH: '🇪🇭',
    ER: '🇪🇷',
    ES: '🇪🇸',
    ET: '🇪🇹',
    EU: '🇪🇺',
    FI: '🇫🇮',
    FJ: '🇫🇯',
    FK: '🇫🇰',
    FM: '🇫🇲',
    FO: '🇫🇴',
    FR: '🇫🇷',
    GA: '🇬🇦',
    GB: '🇬🇧',
    GD: '🇬🇩',
    GE: '🇬🇪',
    GF: '🇬🇫',
    GG: '🇬🇬',
    GH: '🇬🇭',
    GI: '🇬🇮',
    GL: '🇬🇱',
    GM: '🇬🇲',
    GN: '🇬🇳',
    GP: '🇬🇵',
    GQ: '🇬🇶',
    GR: '🇬🇷',
    GS: '🇬🇸',
    GT: '🇬🇹',
    GU: '🇬🇺',
    GW: '🇬🇼',
    GY: '🇬🇾',
    HK: '🇭🇰',
    HM: '🇭🇲',
    HN: '🇭🇳',
    HR: '🇭🇷',
    HT: '🇭🇹',
    HU: '🇭🇺',
    IC: '🇮🇨',
    ID: '🇮🇩',
    IE: '🇮🇪',
    IL: '🇮🇱',
    IM: '🇮🇲',
    IN: '🇮🇳',
    IO: '🇮🇴',
    IQ: '🇮🇶',
    IR: '🇮🇷',
    IS: '🇮🇸',
    IT: '🇮🇹',
    JE: '🇯🇪',
    JM: '🇯🇲',
    JO: '🇯🇴',
    JP: '🇯🇵',
    KE: '🇰🇪',
    KG: '🇰🇬',
    KH: '🇰🇭',
    KI: '🇰🇮',
    KM: '🇰🇲',
    KN: '🇰🇳',
    KP: '🇰🇵',
    KR: '🇰🇷',
    KW: '🇰🇼',
    KY: '🇰🇾',
    KZ: '🇰🇿',
    LA: '🇱🇦',
    LB: '🇱🇧',
    LC: '🇱🇨',
    LI: '🇱🇮',
    LK: '🇱🇰',
    LR: '🇱🇷',
    LS: '🇱🇸',
    LT: '🇱🇹',
    LU: '🇱🇺',
    LV: '🇱🇻',
    LY: '🇱🇾',
    MA: '🇲🇦',
    MC: '🇲🇨',
    MD: '🇲🇩',
    ME: '🇲🇪',
    MF: '🇲🇫',
    MG: '🇲🇬',
    MH: '🇲🇭',
    MK: '🇲🇰',
    ML: '🇲🇱',
    MM: '🇲🇲',
    MN: '🇲🇳',
    MO: '🇲🇴',
    MP: '🇲🇵',
    MQ: '🇲🇶',
    MR: '🇲🇷',
    MS: '🇲🇸',
    MT: '🇲🇹',
    MU: '🇲🇺',
    MV: '🇲🇻',
    MW: '🇲🇼',
    MX: '🇲🇽',
    MY: '🇲🇾',
    MZ: '🇲🇿',
    NA: '🇳🇦',
    NC: '🇳🇨',
    NE: '🇳🇪',
    NF: '🇳🇫',
    NG: '🇳🇬',
    NI: '🇳🇮',
    NL: '🇳🇱',
    NO: '🇳🇴',
    NP: '🇳🇵',
    NR: '🇳🇷',
    NU: '🇳🇺',
    NZ: '🇳🇿',
    OM: '🇴🇲',
    PA: '🇵🇦',
    PE: '🇵🇪',
    PF: '🇵🇫',
    PG: '🇵🇬',
    PH: '🇵🇭',
    PK: '🇵🇰',
    PL: '🇵🇱',
    PM: '🇵🇲',
    PN: '🇵🇳',
    PR: '🇵🇷',
    PS: '🇵🇸',
    PT: '🇵🇹',
    PW: '🇵🇼',
    PY: '🇵🇾',
    QA: '🇶🇦',
    RE: '🇷🇪',
    RO: '🇷🇴',
    RS: '🇷🇸',
    RU: '🇷🇺',
    RW: '🇷🇼',
    SA: '🇸🇦',
    SB: '🇸🇧',
    SC: '🇸🇨',
    SD: '🇸🇩',
    SE: '🇸🇪',
    SG: '🇸🇬',
    SH: '🇸🇭',
    SI: '🇸🇮',
    SJ: '🇸🇯',
    SK: '🇸🇰',
    SL: '🇸🇱',
    SM: '🇸🇲',
    SN: '🇸🇳',
    SO: '🇸🇴',
    SR: '🇸🇷',
    SS: '🇸🇸',
    ST: '🇸🇹',
    SV: '🇸🇻',
    SX: '🇸🇽',
    SY: '🇸🇾',
    SZ: '🇸🇿',
    TA: '🇹🇦',
    TC: '🇹🇨',
    TD: '🇹🇩',
    TF: '🇹🇫',
    TG: '🇹🇬',
    TH: '🇹🇭',
    TJ: '🇹🇯',
    TK: '🇹🇰',
    TL: '🇹🇱',
    TM: '🇹🇲',
    TN: '🇹🇳',
    TO: '🇹🇴',
    TR: '🇹🇷',
    TT: '🇹🇹',
    TV: '🇹🇻',
    TW: '🇹🇼',
    TZ: '🇹🇿',
    UA: '🇺🇦',
    UG: '🇺🇬',
    UM: '🇺🇲',
    UN: '🇺🇳',
    US: '🇺🇸',
    UY: '🇺🇾',
    UZ: '🇺🇿',
    VA: '🇻🇦',
    VC: '🇻🇨',
    VE: '🇻🇪',
    VG: '🇻🇬',
    VI: '🇻🇮',
    VN: '🇻🇳',
    VU: '🇻🇺',
    WF: '🇼🇫',
    WS: '🇼🇸',
    XK: '🇽🇰',
    YE: '🇾🇪',
    YT: '🇾🇹',
    ZA: '🇿🇦',
    ZM: '🇿🇲',
    ZW: '🇿🇼'
  },

  languagesEmojiFlags: {
    EN: '🇬🇧',
    FR: '🇫🇷'
  },

  namesAndCodes: [
    // Name, country code (ISO 3166-1 alpha 2)
    [ 'Afghanistan', 'AF' ],
    [ 'Åland Islands', 'AX' ],
    [ 'Albania', 'AL' ],
    [ 'Algeria', 'DZ' ],
    [ 'American Samoa', 'AS' ],
    [ 'Andorra', 'AD' ],
    [ 'Angola', 'AO' ],
    [ 'Anguilla', 'AI' ],
    [ 'Antarctica', 'AQ' ],
    [ 'Antigua and Barbuda', 'AG' ],
    [ 'Argentina', 'AR' ],
    [ 'Armenia', 'AM' ],
    [ 'Aruba', 'AW' ],
    [ 'Australia', 'AU' ],
    [ 'Austria', 'AT' ],
    [ 'Azerbaijan', 'AZ' ],
    [ 'Bahamas', 'BS' ],
    [ 'Bahrain', 'BH' ],
    [ 'Bangladesh', 'BD' ],
    [ 'Barbados', 'BB' ],
    [ 'Belarus', 'BY' ],
    [ 'Belgium', 'BE' ],
    [ 'Belize', 'BZ' ],
    [ 'Benin', 'BJ' ],
    [ 'Bermuda', 'BM' ],
    [ 'Bhutan', 'BT' ],
    [ 'Bolivia (Plurinational State of)', 'BO' ],
    [ 'Bonaire, Sint Eustatius and Saba', 'BQ' ],
    [ 'Bosnia and Herzegovina', 'BA' ],
    [ 'Botswana', 'BW' ],
    [ 'Bouvet Island', 'BV' ],
    [ 'Brazil', 'BR' ],
    [ 'British Indian Ocean Territory', 'IO' ],
    [ 'Brunei Darussalam', 'BN' ],
    [ 'Bulgaria', 'BG' ],
    [ 'Burkina Faso', 'BF' ],
    [ 'Burundi', 'BI' ],
    [ 'Cabo Verde', 'CV' ],
    [ 'Cambodia', 'KH' ],
    [ 'Cameroon', 'CM' ],
    [ 'Canada', 'CA' ],
    [ 'Cayman Islands', 'KY' ],
    [ 'Central African Republic', 'CF' ],
    [ 'Chad', 'TD' ],
    [ 'Chile', 'CL' ],
    [ 'China', 'CN' ],
    [ 'Christmas Island', 'CX' ],
    [ 'Cocos (Keeling) Islands', 'CC' ],
    [ 'Colombia', 'CO' ],
    [ 'Comoros', 'KM' ],
    [ 'Congo', 'CG' ],
    [ 'Congo (Democratic Republic of the)', 'CD' ],
    [ 'Cook Islands', 'CK' ],
    [ 'Costa Rica', 'CR' ],
    [ 'Côte d\'Ivoire', 'CI' ],
    [ 'Croatia', 'HR' ],
    [ 'Cuba', 'CU' ],
    [ 'Curaçao', 'CW' ],
    [ 'Cyprus', 'CY' ],
    [ 'Czech Republic', 'CZ' ],
    [ 'Denmark', 'DK' ],
    [ 'Djibouti', 'DJ' ],
    [ 'Dominica', 'DM' ],
    [ 'Dominican Republic', 'DO' ],
    [ 'Ecuador', 'EC' ],
    [ 'Egypt', 'EG' ],
    [ 'El Salvador', 'SV' ],
    [ 'Equatorial Guinea', 'GQ' ],
    [ 'Eritrea', 'ER' ],
    [ 'Estonia', 'EE' ],
    [ 'Ethiopia', 'ET' ],
    [ 'Falkland Islands (Malvinas)', 'FK' ],
    [ 'Faroe Islands', 'FO' ],
    [ 'Fiji', 'FJ' ],
    [ 'Finland', 'FI' ],
    [ 'France', 'FR' ],
    [ 'French Guiana', 'GF' ],
    [ 'French Polynesia', 'PF' ],
    [ 'French Southern Territories', 'TF' ],
    [ 'Gabon', 'GA' ],
    [ 'Gambia', 'GM' ],
    [ 'Georgia', 'GE' ],
    [ 'Germany', 'DE' ],
    [ 'Ghana', 'GH' ],
    [ 'Gibraltar', 'GI' ],
    [ 'Greece', 'GR' ],
    [ 'Greenland', 'GL' ],
    [ 'Grenada', 'GD' ],
    [ 'Guadeloupe', 'GP' ],
    [ 'Guam', 'GU' ],
    [ 'Guatemala', 'GT' ],
    [ 'Guernsey', 'GG' ],
    [ 'Guinea', 'GN' ],
    [ 'Guinea-Bissau', 'GW' ],
    [ 'Guyana', 'GY' ],
    [ 'Haiti', 'HT' ],
    [ 'Heard Island and McDonald Islands', 'HM' ],
    [ 'Holy See', 'VA' ],
    [ 'Honduras', 'HN' ],
    [ 'Hong Kong', 'HK' ],
    [ 'Hungary', 'HU' ],
    [ 'Iceland', 'IS' ],
    [ 'India', 'IN' ],
    [ 'Indonesia', 'ID' ],
    [ 'Iran (Islamic Republic of)', 'IR' ],
    [ 'Iraq', 'IQ' ],
    [ 'Ireland', 'IE' ],
    [ 'Isle of Man', 'IM' ],
    [ 'Israel', 'IL' ],
    [ 'Italy', 'IT' ],
    [ 'Jamaica', 'JM' ],
    [ 'Japan', 'JP' ],
    [ 'Jersey', 'JE' ],
    [ 'Jordan', 'JO' ],
    [ 'Kazakhstan', 'KZ' ],
    [ 'Kenya', 'KE' ],
    [ 'Kiribati', 'KI' ],
    [ 'Korea (Democratic People\'s Republic of)', 'KP' ],
    [ 'Korea (Republic of)', 'KR' ],
    [ 'Kuwait', 'KW' ],
    [ 'Kyrgyzstan', 'KG' ],
    [ 'Lao People\'s Democratic Republic', 'LA' ],
    [ 'Latvia', 'LV' ],
    [ 'Lebanon', 'LB' ],
    [ 'Lesotho', 'LS' ],
    [ 'Liberia', 'LR' ],
    [ 'Libya', 'LY' ],
    [ 'Liechtenstein', 'LI' ],
    [ 'Lithuania', 'LT' ],
    [ 'Luxembourg', 'LU' ],
    [ 'Macao', 'MO' ],
    [ 'Macedonia (the former Yugoslav Republic of)', 'MK' ],
    [ 'Madagascar', 'MG' ],
    [ 'Malawi', 'MW' ],
    [ 'Malaysia', 'MY' ],
    [ 'Maldives', 'MV' ],
    [ 'Mali', 'ML' ],
    [ 'Malta', 'MT' ],
    [ 'Marshall Islands', 'MH' ],
    [ 'Martinique', 'MQ' ],
    [ 'Mauritania', 'MR' ],
    [ 'Mauritius', 'MU' ],
    [ 'Mayotte', 'YT' ],
    [ 'Mexico', 'MX' ],
    [ 'Micronesia (Federated States of)', 'FM' ],
    [ 'Moldova (Republic of)', 'MD' ],
    [ 'Monaco', 'MC' ],
    [ 'Mongolia', 'MN' ],
    [ 'Montenegro', 'ME' ],
    [ 'Montserrat', 'MS' ],
    [ 'Morocco', 'MA' ],
    [ 'Mozambique', 'MZ' ],
    [ 'Myanmar', 'MM' ],
    [ 'Namibia', 'NA' ],
    [ 'Nauru', 'NR' ],
    [ 'Nepal', 'NP' ],
    [ 'Netherlands', 'NL' ],
    [ 'New Caledonia', 'NC' ],
    [ 'New Zealand', 'NZ' ],
    [ 'Nicaragua', 'NI' ],
    [ 'Niger', 'NE' ],
    [ 'Nigeria', 'NG' ],
    [ 'Niue', 'NU' ],
    [ 'Norfolk Island', 'NF' ],
    [ 'Northern Mariana Islands', 'MP' ],
    [ 'Norway', 'NO' ],
    [ 'Oman', 'OM' ],
    [ 'Pakistan', 'PK' ],
    [ 'Palau', 'PW' ],
    [ 'Palestine, State of', 'PS' ],
    [ 'Panama', 'PA' ],
    [ 'Papua New Guinea', 'PG' ],
    [ 'Paraguay', 'PY' ],
    [ 'Peru', 'PE' ],
    [ 'Philippines', 'PH' ],
    [ 'Pitcairn', 'PN' ],
    [ 'Poland', 'PL' ],
    [ 'Portugal', 'PT' ],
    [ 'Puerto Rico', 'PR' ],
    [ 'Qatar', 'QA' ],
    [ 'Republic of Kosovo', 'XK' ],
    [ 'Réunion', 'RE' ],
    [ 'Romania', 'RO' ],
    [ 'Russian Federation', 'RU' ],
    [ 'Rwanda', 'RW' ],
    [ 'Saint Barthélemy', 'BL' ],
    [ 'Saint Helena, Ascension and Tristan da Cunha', 'SH' ],
    [ 'Saint Kitts and Nevis', 'KN' ],
    [ 'Saint Lucia', 'LC' ],
    [ 'Saint Martin (French part)', 'MF' ],
    [ 'Saint Pierre and Miquelon', 'PM' ],
    [ 'Saint Vincent and the Grenadines', 'VC' ],
    [ 'Samoa', 'WS' ],
    [ 'San Marino', 'SM' ],
    [ 'Sao Tome and Principe', 'ST' ],
    [ 'Saudi Arabia', 'SA' ],
    [ 'Senegal', 'SN' ],
    [ 'Serbia', 'RS' ],
    [ 'Seychelles', 'SC' ],
    [ 'Sierra Leone', 'SL' ],
    [ 'Singapore', 'SG' ],
    [ 'Sint Maarten (Dutch part)', 'SX' ],
    [ 'Slovakia', 'SK' ],
    [ 'Slovenia', 'SI' ],
    [ 'Solomon Islands', 'SB' ],
    [ 'Somalia', 'SO' ],
    [ 'South Africa', 'ZA' ],
    [ 'South Georgia and the South Sandwich Islands', 'GS' ],
    [ 'South Sudan', 'SS' ],
    [ 'Spain', 'ES' ],
    [ 'Sri Lanka', 'LK' ],
    [ 'Sudan', 'SD' ],
    [ 'Suriname', 'SR' ],
    [ 'Svalbard and Jan Mayen', 'SJ' ],
    [ 'Swaziland', 'SZ' ],
    [ 'Sweden', 'SE' ],
    [ 'Switzerland', 'CH' ],
    [ 'Syrian Arab Republic', 'SY' ],
    [ 'Taiwan', 'TW' ],
    [ 'Tajikistan', 'TJ' ],
    [ 'Tanzania, United Republic of', 'TZ' ],
    [ 'Thailand', 'TH' ],
    [ 'Timor-Leste', 'TL' ],
    [ 'Togo', 'TG' ],
    [ 'Tokelau', 'TK' ],
    [ 'Tonga', 'TO' ],
    [ 'Trinidad and Tobago', 'TT' ],
    [ 'Tunisia', 'TN' ],
    [ 'Turkey', 'TR' ],
    [ 'Turkmenistan', 'TM' ],
    [ 'Turks and Caicos Islands', 'TC' ],
    [ 'Tuvalu', 'TV' ],
    [ 'Uganda', 'UG' ],
    [ 'Ukraine', 'UA' ],
    [ 'United Arab Emirates', 'AE' ],
    [ 'United Kingdom of Great Britain and Northern Ireland', 'GB' ],
    [ 'United States Minor Outlying Islands', 'UM' ],
    [ 'United States of America', 'US' ],
    [ 'Uruguay', 'UY' ],
    [ 'Uzbekistan', 'UZ' ],
    [ 'Vanuatu', 'VU' ],
    [ 'Venezuela (Bolivarian Republic of)', 'VE' ],
    [ 'Viet Nam', 'VN' ],
    [ 'Virgin Islands (British)', 'VG' ],
    [ 'Virgin Islands (U.S.)', 'VI' ],
    [ 'Wallis and Futuna', 'WF' ],
    [ 'Western Sahara', 'EH' ],
    [ 'Yemen', 'YE' ],
    [ 'Zambia', 'ZM' ],
    [ 'Zimbabwe', 'ZW' ]
  ],

  // ISO 3166-1 alpha 2
  regionsSupported: {
    FR: [
      'FR',

      // -----[ Outermost Regions (OMR) ]------
      'GF', // French Guiana
      'GP', // Guadeloupe
      'MQ', // Martinique
      'YT', // Mayotte
      'RE', // Réunion
      'MF', // Saint Martin
    ]
  }
};
