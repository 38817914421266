import logo from 'images/servicesLogos/opensearch.svg';
import logoOpenGraph from 'images/servicesLogos/openGraphVersions/opensearch.png';

import growth from 'images/landings/icons/growth.svg';
import startup from 'images/landings/icons/startup.svg';
import shield from 'images/landings/icons/shield.svg';
import strategy from 'images/landings/icons/strategy.svg';

import checked from 'images/landings/icons/checked.svg';

import dashboardsEcommerce from 'images/landings/screenshots/opensearch/dashboardsEcommerce.png';
import dashboardsFlights from 'images/landings/screenshots/opensearch/dashboardsFlights.png';
import dashboardsWeb from 'images/landings/screenshots/opensearch/dashboardsWeb.png';

const backgroundColor = '#003B5C';
const button = {
  color: '#FFF',
  backgroundColor: '#00A3E0'
};


export default ({ t = () => [] }) => ({
  id: 'opensearch',
  name: 'OpenSearch',
  nameUrl: 'OpenSearch',
  title: t('services/opensearch:title'),
  logo,
  logoStyle: [
    {
      elementId: 'color1',
      style: {
        fill: '#00A3E0'
      }
    },
    {
      elementId: 'color2',
      style: {
        fill: '#00A3E0'
      }
    },
    {
      elementId: 'color3',
      style: {
        fill: '#00A3E0'
      }
    }
  ],
  logoOpenGraph,
  button,
  backgroundColor,

  header: {
    title: 'OpenSearch',
    description: t('services/opensearch:description'),
    images: [
      { image: dashboardsEcommerce },
      { image: dashboardsFlights },
      { image: dashboardsWeb }
    ]
  },

  explanation: {
    title: t('services/opensearch:explanation.title'),
    content: t('services/opensearch:explanation.content')
  },

  benefits: {
    items: [
      {
        icon: growth,
        ...t('services/opensearch:benefits.business')
      },
      {
        icon: startup,
        ...t('services/opensearch:benefits.performant')
      },
      {
        icon: shield,
        ...t('services/opensearch:benefits.secured')
      },
      {
        icon: strategy,
        ...t('services/opensearch:benefits.updatable')
      }
    ]
  },

  also: {
    color: button.backgroundColor,
    items: t('services/opensearch:also.items')
      .map(content => ({ icon: checked, ...content }))
  },

  pricing: {
    buttonBackgroundColor: button.backgroundColor,
    buttonColor: button.color,
    sentencesIconColor: button.backgroundColor,
    includes: t('services/opensearch:pricing.includes')
      .map(description => ({ icon: checked, description })),
  },

  recall: {
    description: t('services/opensearch:recall')
  },

  faq: {
    items: t('services/opensearch:faq.items')
  }
});
