import logo from 'images/servicesLogos/nodejs-current.svg';
import logoOpenGraph from 'images/servicesLogos/openGraphVersions/nodejs-current.png';

import growth from 'images/landings/icons/growth.svg';
import startup from 'images/landings/icons/startup.svg';
import shield from 'images/landings/icons/shield.svg';
import packageSvg from 'images/landings/icons/package.svg';
import target from 'images/landings/icons/target.svg';
import strategy from 'images/landings/icons/strategy.svg';

import checked from 'images/landings/icons/checked.svg';

import imageNodejs from 'images/landings/screenshots/nodejs/nodejs.png';

const backgroundColor = '#333';
const button = {
  color: '#000',
  backgroundColor: '#8CC84B'
};

export default ({ t = () => [], region }) => ({
  id: 'nodejs-current',
  name: 'Node.js',
  nameUrl: 'Node-js',
  title: t('services/node-js:title'),
  logo,
  logoOpenGraph,
  button,
  backgroundColor,

  header: {
    title: 'Node.js',
    h1: t('services/node-js:h1'),
    description: t('services/node-js:description'),

    images: [
      { image: imageNodejs }
    ]
  },

  benefits: {
    items: [
      {
        icon: growth,
        ...t('services/node-js:benefits.business')
      },
      {
        icon: startup,
        ...t('services/node-js:benefits.performant')
      },
      {
        icon: shield,
        ...t('services/node-js:benefits.secured')
      },
      {
        icon: packageSvg,
        ...t('services/node-js:benefits.deploy')
      },
      {
        icon: target,
        ...t('services/node-js:benefits.configurable')
      },
      {
        icon: strategy,
        ...t('services/node-js:benefits.updatable')
      }
    ]
  },

  also: {
    color: button.backgroundColor,
    items: t('services/node-js:also.items')
      .map(content => ({ icon: checked, ...content }))
  },

  pricing: {
    buttonBackgroundColor: button.backgroundColor,
    buttonColor: button.color,
    sentencesIconColor: button.backgroundColor,
    includes: t('services/node-js:pricing.includes')
      .map(description => ({ icon: checked, description }))
  },

  recall: {
    description: t('services/node-js:recall')
  },

  faq: {
    items: t('services/node-js:faq.items')
  }
});
