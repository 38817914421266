import logo from 'images/servicesLogos/mercure-hub.svg';
import logoOpenGraph from 'images/servicesLogos/openGraphVersions/mercure-hub.png';

import growth from 'images/landings/icons/growth.svg';
import startup from 'images/landings/icons/startup.svg';
import shield from 'images/landings/icons/shield.svg';
import strategy from 'images/landings/icons/strategy.svg';

import checked from 'images/landings/icons/checked.svg';

import imageSubscription from 'images/landings/screenshots/mercure/subscriptions.png';

const backgroundColor = '#15aed7';
const button = {
  color: '#000',
  backgroundColor: '#fadb33'
};

const serviceName = 'Mercure-Hub';
export default ({ t = () => [] }) => ({
  id: 'mercure-hub',
  name: 'Mercure-Hub',
  nameUrl: 'Mercure-Hub',
  title: t('services/mercure-hub:title', { serviceName }),
  logo,
  logoOpenGraph,
  button,
  backgroundColor,

  header: {
    title: 'Mercure-Hub',
    description: t('services/mercure-hub:description', { serviceName }) + '\n\n' + t('services/mercure-hub:callToAction', { serviceName }),
    images: [
      { image: imageSubscription }
    ]
  },

  benefits: {
    items: [
      {
        icon: growth,
        ...t('services/mercure-hub:benefits.business', { serviceName })
      },
      {
        icon: startup,
        ...t('services/mercure-hub:benefits.performant', { serviceName })
      },
      {
        icon: shield,
        ...t('services/mercure-hub:benefits.secured', { serviceName })
      },
      {
        icon: strategy,
        ...t('services/mercure-hub:benefits.updatable', { serviceName })
      }
    ]
  },

  also: {
    color: button.backgroundColor,
    items: t('services/mercure-hub:also.items', { serviceName })
      .map(content => ({ icon: checked, ...content }))
  },

  pricing: {
    buttonBackgroundColor: button.backgroundColor,
    buttonColor: button.color,
    sentencesIconColor: button.backgroundColor,
    includes: t('services/mercure-hub:pricing.includes')
      .map(description => ({ icon: checked, description })),
    fields: {
      maxConcurrentConnections: v => [ new Intl.NumberFormat(undefined).format(v), t('services/mercure-hub:pricing.subLegends.maxConcurrentConnections') ],
      messagesPerSecond: v => [
        v > 0
          ? new Intl.NumberFormat(undefined).format(v)
          : t('mainPage.pricing.unlimited'),
        t('services/mercure-hub:pricing.subLegends.messagesPerSecond')
      ],
      postsPerSecond: v => [
        v > 0
          ? new Intl.NumberFormat(undefined).format(v)
          : t('mainPage.pricing.unlimited'),
        t('services/mercure-hub:pricing.subLegends.postsPerSecond')
      ],
      maxMessageSize: v => [
        v > 0
          ? new Intl.NumberFormat(undefined).format(v)
          : t('mainPage.pricing.unlimited'),
        t('services/mercure-hub:pricing.subLegends.maxMessageSize')
      ],
      hdd: hdd => [ hdd < 1024 ? `${Math.round(hdd / (1024 * 1024))} ${t('units.MB')}` : `${Math.round(hdd / (1024 * 1024 * 1024))} ${t('units.GB')}`, t('services/mercure-hub:pricing.subLegends.storage') ]
    }
  },

  recall: {
    description: t('services/mercure-hub:callToAction', { serviceName })
  },

  faq: {
    items: t('services/mercure-hub:faq.items', { serviceName })
  }
});