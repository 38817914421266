import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

class _default extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  componentDidMount() {
    // Go to automatically to organization page
    const { sessionAuth } = this.props.app;

    // If the user is logged
    if (sessionAuth.username) {
      this.props.history.replace('/account/organizations/');
    }
    else {
      this.props.history.push('/notLogged/Login');
    }
  }

  render() {
    return null;
  }
}

export default connect(
  ({ app }) => ({ app })
)(_default);
