import logo from 'images/servicesLogos/mariadb.svg';
import logoOpenGraph from 'images/servicesLogos/openGraphVersions/mariadb.png';

import growth from 'images/landings/icons/growth.svg';
import startup from 'images/landings/icons/startup.svg';
import shield from 'images/landings/icons/shield.svg';
import website from 'images/landings/icons/website.svg';
import strategy from 'images/landings/icons/strategy.svg';
import medal from 'images/landings/icons/medal.svg';

import checked from 'images/landings/icons/checked.svg';

import imageServicesMariadbRedisMinioPostfix from 'images/landings/screenshots/servicesMariadbRedisMinioPostfix.png';
import imagePhpmyadmin from 'images/landings/screenshots/mariadb/phpmyadmin.png';
import imagePhpmyadminTable from 'images/landings/screenshots/mariadb/phpmyadminTable.png';

const backgroundColor = '#4e629a';
const button = {
  color: '#fff',
  backgroundColor: '#d24d4d'
};



export default ({ t = () => [] }) => ({
  id: 'mariadb',
  name: 'MariaDB',
  nameUrl: 'MariaDB',
  title: t('services/mariadb:title'),
  logo,
  logoOpenGraph,
  button,
  backgroundColor,

  header: {
    title: 'MariaDB',
    description: t('services/mariadb:description'),
    images: [
      { image: imageServicesMariadbRedisMinioPostfix },
      { image: imagePhpmyadmin },
      { image: imagePhpmyadminTable }
    ]
  },

  benefits: {
    items: [
      {
        icon: growth,
        ...t('services/mariadb:benefits.business')
      },
      {
        icon: startup,
        ...t('services/mariadb:benefits.performant')
      },
      {
        icon: shield,
        ...t('services/mariadb:benefits.secured')
      },
      {
        icon: website,
        ...t('services/mariadb:benefits.simple')
      },
      {
        icon: medal,
        ...t('services/mariadb:benefits.unlimited')
      },
      {
        icon: strategy,
        ...t('services/mariadb:benefits.updatable')
      }
    ]
  },

  also: {
    color: button.backgroundColor,
    items: t('services/mariadb:also.items')
      .map(content => ({ icon: checked, ...content }))
  },

  pricing: {
    buttonBackgroundColor: button.backgroundColor,
    buttonColor: button.color,
    sentencesIconColor: button.backgroundColor,
  },

  recall: {
    description: t('services/mariadb:recall')
  },

  faq: {
    items: t('services/mariadb:faq.items')
  }
});
