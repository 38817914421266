import { combineReducers } from 'redux';
import app from './app';
import api from './api';
import navigation from './navigation';

const reducers = combineReducers({
  app,
  api,
  navigation
});

export default reducers;
