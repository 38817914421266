import logo from 'images/servicesLogos/mysql.svg';
import logoOpenGraph from 'images/servicesLogos/openGraphVersions/mysql.png';

import growth from 'images/landings/icons/growth.svg';
import startup from 'images/landings/icons/startup.svg';
import shield from 'images/landings/icons/shield.svg';
import website from 'images/landings/icons/website.svg';
import strategy from 'images/landings/icons/strategy.svg';
import medal from 'images/landings/icons/medal.svg';

import checked from 'images/landings/icons/checked.svg';

import imageServicesMariadbRedisMinioPostfix from 'images/landings/screenshots/servicesMariadbRedisMinioPostfix.png';
import imagePhpmyadmin from 'images/landings/screenshots/mariadb/phpmyadmin.png';
import imagePhpmyadminTable from 'images/landings/screenshots/mariadb/phpmyadminTable.png';

const backgroundColor = '#4479a1';
const button = {
  color: '#fff',
  backgroundColor: '#e97b00'
};

export default ({ t = () => [] }) => ({
  id: 'mysql',
  name: 'MySQL',
  nameUrl: 'MySQL',
  title: t('services/mysql:title'),
  logo,
  logoOpenGraph,
  button,
  backgroundColor,

  header: {
    title: 'MySQL',
    description: t('services/mysql:description'),
    images: [
      { image: imageServicesMariadbRedisMinioPostfix },
      { image: imagePhpmyadmin },
      { image: imagePhpmyadminTable }
    ]
  },

  benefits: {
    items: [
      {
        icon: growth,
        ...t('services/mysql:benefits.business')
      },
      {
        icon: startup,
        ...t('services/mysql:benefits.performant')
      },
      {
        icon: shield,
        ...t('services/mysql:benefits.secured')
      },
      {
        icon: website,
        ...t('services/mysql:benefits.simple')
      },
      {
        icon: medal,
        ...t('services/mysql:benefits.unlimited')
      },
      {
        icon: strategy,
        ...t('services/mysql:benefits.updatable')
      }
    ]
  },

  also: {
    color: button.backgroundColor,
    items: t('services/mysql:also.items')
      .map(content => ({ icon: checked, ...content }))
  },

  pricing: {
    buttonBackgroundColor: button.backgroundColor,
    buttonColor: button.color,
    sentencesIconColor: button.backgroundColor,
  },

  recall: {
    description: t('services/mysql:recall')
  },

  faq: {
    items: t('services/mysql:faq.items')
  }
});
