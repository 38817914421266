import logo from 'images/servicesLogos/code-hero.svg';
import logoOpenGraph from 'images/servicesLogos/openGraphVersions/code-hero.png';

import growth from 'images/landings/icons/growth.svg';
import startup from 'images/landings/icons/startup.svg';
import shield from 'images/landings/icons/shield.svg';
import strategy from 'images/landings/icons/strategy.svg';
import checked from 'images/landings/icons/checked.svg';

import imageCodeHero from 'images/landings/screenshots/code-hero/code-hero.png';

const backgroundColor = '#453d83';
const button = {
  color: '#fff',
  backgroundColor: '#007EE4'
};

const serviceName = 'Code-Hero';
export default ({ t = () => [] }) => ({
  id: 'code-hero',
  name: serviceName,
  nameUrl: serviceName,
  title: t('services/code-hero:title', { serviceName }),
  logo,
  logoOpenGraph,
  button,
  backgroundColor,

  header: {
    title: 'Code-Hero',
    h1: t('services/code-hero:h1', { serviceName }),
    description: t('services/code-hero:description', { serviceName }) + '\n\n' + t('services/code-hero:callToAction', { serviceName }),
    images: [
      { image: imageCodeHero }
    ]
  },

  explanation: {
    title: t('services/code-hero:explanation.title', { serviceName }),
    content: t('services/code-hero:explanation.content', { serviceName })
  },

  benefits: {
    items: [ growth, strategy, startup, shield ].map((icon, index) => ({
      icon,
      ...t('services/code-hero:benefits', { serviceName })[index]
    }))
  },

  also: {
    color: button.backgroundColor,
    items: t('services/code-hero:also.items', { serviceName })
      .map(content => ({ icon: checked, ...content }))
  },

  recall: {
    description: t('services/code-hero:callToAction', { serviceName })
  },

  pricing: {
    h1: t('services/code-hero:pricing.h1', { serviceName }),
    buttonBackgroundColor: button.backgroundColor,
    buttonColor: button.color,
    sentencesIconColor: button.backgroundColor,
    includes: t('services/code-hero:pricing.includes', { serviceName })
      .map(description => ({ icon: checked, description }))
  },

  faq: {
    items: t('services/code-hero:faq.items', { serviceName })
  }
});