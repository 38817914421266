import React from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as apiActions from 'redux/actions/api';
import { Result, Button, Spin } from 'antd';

import queryString from 'query-string';

class EmailsInformationalUnsubscribe extends React.Component {

  static propTypes = {
    apiActions: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
  };

  state = {
    loading: false,
    confirmed: false
  };

  componentDidMount() {
    this._mounted = true;
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  unsubscribe() {
    this.setState({ confirmed: true, loading: true });
    const { emailEncoded, emailKey } = queryString.parse(this.props.location.search);

    this.props.apiActions.post(
      {
        route: '/emailsInformationalUnsubscribe',
        routeArgs: { email: atob(emailEncoded), key: emailKey }
      },
      (error, response) => {
        if (!this._mounted) { return false; }
        this.setState({ loading: false });
      }
    );
  }

  render() {
    if (!this.state.confirmed) {
      return (
        <Spin spinning={this.state.loading}>
          <Result
            status="warning"
            title="Are you sure you don't want to receive free coupons or news anymore?"
            subTitle="Promise, we send very few emails and only ones that can really interest you!"
            extra={[
              <a key="cancel" href="/">
                <Button type="primary">
                  Cancel
                </Button>
              </a>,
              <Button key="confirm" danger onClick={() => this.unsubscribe()}>Confirm</Button>,
            ]}
          />
        </Spin>
      );
    }
    else {
      return (
        <Result
          status="success"
          title="You will not received this kind of emails in the future"
          subTitle="Sorry if we bothered you!"
          extra={(
            <a href="/">
              <Button type="primary">
                Continue
              </Button>
            </a>
          )}
        />
      );
    }
  }
}

export default connect(
  () => ({}),
  dispatch => ({
    apiActions: bindActionCreators(apiActions, dispatch)
  })
)(EmailsInformationalUnsubscribe);
