import React from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as apiActions from 'redux/actions/api';

import { Timeline, Alert, Empty, Spin } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';

import ServiceBackupsRollback from './ServiceBackupsRollback';

class ServiceBackups extends React.Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    apiActions: PropTypes.object.isRequired,
    serviceId: PropTypes.string.isRequired,
    onRollback: PropTypes.func
  };


  componentDidMount() {
    const route = `/dashboard/services/${this.props.serviceId}/backups`;
    this.props.apiActions.subscribe({
      route,
      routeArgs: {}
    });
  }

  componentWillUnmount() {
    const route = `/dashboard/services/${this.props.serviceId}/backups`;
    this.props.apiActions.unsubscribe({ route });
  }

  formatDate(date) {
    const dateOption = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
    return new Intl.DateTimeFormat(undefined, dateOption).format(new Date(date));
  }


  render() {
    const route = `/dashboard/services/${this.props.serviceId}/backups`;
    const subscription = this.props.api.subscriptions[route];
    const loading = !(subscription && subscription.status !== 'initialize');
    const backups = !loading ? subscription.datas : [];

    return (
      <Spin spinning={loading}>
        <Alert
          type="info"
          showIcon
          message="How backups work"
          description={(
            <>
              Our system creates backups every 24 hours, automatically. You have nothing to do.
              <br />
              These backups are atomic, meaning that your entire instance is backuped and all data are consistent.
              <br />
              <br />
              Each backup is uploaded to a secured vault server where they are verified, certified and archived.
            </>
          )}
          style={{ marginBottom: 16 }}
        />

        {backups.length === 0
          ? ( <Empty description="Your secured vault is currently empty. You have to wait 24 hours to see the first backup appear here." /> )
          : backups.length === 1
            ? 'Your secured vault contains 1 backup:'
            : `Your secured vault contains ${backups.length} backups:`
        }
        <Timeline
          style={{ marginTop: 20, marginLeft: 20 }}
          items={backups.map(
            (backup, index) => backup.status === 'archived'
              ? {
                color: 'green',
                children: (
                  <>
                    {this.formatDate(backup.date)}: Backup archived into the vault
                    <ServiceBackupsRollback serviceId={this.props.serviceId} backup={backup} onRollback={this.props.onRollback} />
                  </>
                )
              }
              : {
                color: 'blue',
                dot: ( <ClockCircleOutlined style={{ fontSize: 16 }} /> ),
                children: `${this.formatDate(backup.date)}: Backup waiting to be archived to the vault...`
              }
          )}
        />
      </Spin>
    );
  }
}

export default connect(
  ({ api }) => ({ api }),
  dispatch => ({
    apiActions: bindActionCreators(apiActions, dispatch)
  })
)(ServiceBackups);
