import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import isEqual from 'lodash.isequal';

import Routes from 'components/Routes';

const routesLoad = (routes, routeParent) => {
  let routeToIgnore = null;

  return routes.map(({ route, component }, index) => {
    const routeName = route.replace(/\{([^}]*)\}/g, ':$1'); // Replace {abc} per :abc

    const reg = new RegExp('^' + routeParent);
    if (!reg.test(routeName)) {
      return null;
    }

    const reg2 = new RegExp('^' + routeToIgnore);
    if (reg2.test(routeName)) {
      return null;
    }

    if (/_container$/.test(routeName)) {
      const path = routeName.replace(/_container$/, '');
      routeToIgnore = path;
      return {
        path,
        exact: false,
        component,
        routes: routesLoad(routes.slice(index + 1), path),
        sortWeight: routeName.split(':').length
      };
    }

    return {
      path: routeName.replace(/\/_default$/, '/'),
      exact: /\/_default$/.test(routeName),
      component,
      sortWeight: routeName.split(':').length
    };
  })
  .filter(route => route !== null)
  .sort((a, b) => a.sortWeight - b.sortWeight); // Sort to avoid '/:id' beeing read before '/add'
};

class Router extends React.Component {
  static propTypes = {
    rootPath: PropTypes.string.isRequired,
    routes: PropTypes.array.isRequired
  };

  state = {
    routes: []
  };

  componentDidMount() {
    this.routesUpdate();
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.routes, this.props.routes)) {
      this.routesUpdate();
    }
  }

  routesUpdate() {
    const routes = routesLoad(this.props.routes, '/');
    this.setState({ routes });
  }

  renderRoutes(routes = []) {
    if (!routes.length) {
      return null;
    }

    return ( <Routes routes={routes} renderRoutes={this.renderRoutes.bind(this)} rootPath={this.props.rootPath} /> );
  }


  render() {
    return this.renderRoutes(this.state.routes);
  }
}

export default withRouter(Router);
