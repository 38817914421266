import logo from 'images/servicesLogos/nextcloud.svg';
import logoOpenGraph from 'images/servicesLogos/openGraphVersions/nextcloud.png';

import packageIcon from 'images/landings/icons/package.svg';
import startup from 'images/landings/icons/startup.svg';
import shield from 'images/landings/icons/shield.svg';
import onlineShop from 'images/landings/icons/online-shop.svg';
import support from 'images/landings/icons/support.svg';
import strategy from 'images/landings/icons/strategy.svg';

import checked from 'images/landings/icons/checked.svg';

import imageFirstRunWizard from 'images/landings/screenshots/nextcloud/firstRunWizard.jpg';
import imageFiles from 'images/landings/screenshots/nextcloud/files.png';
import imagePdf from 'images/landings/screenshots/nextcloud/pdf.jpg';

const backgroundColor = '#1cafff';
const button = {
  color: '#fff',
  backgroundColor: '#FF8B00'
};

export default ({ t = () => [] }) => ({
  id: 'nextcloud',
  name: 'Nextcloud',
  nameUrl: 'Nextcloud',
  title: t('services/nextcloud:title'),
  logo,
  logoStyle: [
    {
      elementId: 'path',
      style: {
        fill: '#fff'
      }
    }
  ],
  logoOpenGraph,
  button,
  backgroundColor,

  header: {
    title: 'Nextcloud',
    description: t('services/nextcloud:description'),
    images: [
      { image: imageFirstRunWizard },
      { image: imageFiles },
      { image: imagePdf }
    ]
  },

  benefits: {
    items: [ packageIcon, support, shield, onlineShop, startup, strategy ].map((icon, index) => ({
      icon,
      ...t('services/nextcloud:benefits')[index]
    }))
  },

  also: {
    color: button.backgroundColor,
    items: t('services/nextcloud:also.items')
      .map(content => ({ icon: checked, ...content }))
  },

  recall: {
    description: t('services/nextcloud:recall')
  },

  pricing: {
    buttonBackgroundColor: button.backgroundColor,
    buttonColor: button.color,
    sentencesIconColor: button.backgroundColor,
    includes: t('services/nextcloud:pricing.includes')
      .map(description => ({ icon: checked, description })),
    fields: {
      vCpus: vCpus => [ vCpus, vCpus === 1 ? t('mainPage.pricing.subLegends.vCpu') : t('mainPage.pricing.subLegends.vCpus') ],
      ram: ram => [ ram < 1024 * 1024 * 1024 ? `${Math.round(ram / (1024 * 1024))} ${t('units.MB')}` : `${Math.round(ram / (1024 * 1024 * 1024))} ${t('units.GB')}`, t('mainPage.pricing.subLegends.ram') ],
      hdd: hdd => [ hdd < 1024 * 1024 * 1024 ? `${Math.round(hdd / (1024 * 1024))} ${t('units.MB')}` : `${Math.round(hdd / (1024 * 1024 * 1024))} ${t('units.GB')}`, t('mainPage.pricing.subLegends.disk') ],
      users: users => [
        users > 0
          ? new Intl.NumberFormat(undefined).format(users)
          : t('mainPage.pricing.unlimited'),
        t('services/nextcloud:pricing.subLegends.users')
      ],
      antivirus: antivirus => [
        antivirus
          ? '✅'
          : '❌',
        t('services/nextcloud:pricing.subLegends.antivirus')
      ]
    }
  },

  faq: {
    items: t('services/nextcloud:faq.items')
  }
});
