import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

class _default extends React.Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };

  componentDidMount() {
    const { organizationId } = this.props.match.params;
    const organization = this.props.api.subscriptions[`/dashboard/organizations/${organizationId}`].datas;
    if (!organization.name) {
      this.props.history.replace('organization', this.props.location.state);
    }
    else if (this.props.location.state.instanceStoreStatus !== 'available') {
      this.props.history.replace('verifications', this.props.location.state);
    }
    else {
      this.props.history.replace('terms', this.props.location.state);
    }
  }

  render() {
    return null;
  }
}

export default connect(
  ({ api }) => ({ api })
)(_default);
