import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as navigationActions from 'redux/actions/navigation';

import { Card, Button } from 'antd';

import ServicePackCreate from 'components/ServicePackCreate';

class _default extends React.Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    navigationActions: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };

  state = {
    instancesConfiguration: {}
  };

  componentDidMount() {
    const { serviceStoreId, organizationId } = this.props.match.params;
    this._route = `/dashboard/organizations/${organizationId}/servicesStore/${serviceStoreId}/instancesStore`;
    this._routeOrganization = `/dashboard/organizations/${organizationId}`;
    this._routeServicesStore = `/dashboard/organizations/${organizationId}/servicesStore`;
    this.props.navigationActions.stepSelect(1);
  }


  render() {
    const { servicePackStoreId } = this.props.match.params;
    const servicesPacksStore = this.props.api.subscriptions[this._routeServicesStore].datas;
    const servicePackStore = servicesPacksStore.find(i => i.id === servicePackStoreId);
    const { instancesStore } = this.props.api.subscriptions[this._route].datas;
    const { currency } = this.props.api.subscriptions[this._routeOrganization].datas;

    return (
      <Card
        title="Select instances"
        style={{ backgroundColor: '#fafafa' }}
      >
        <div style={{ overflow: 'hidden' }}> {/* To avoid a bug on display with hourly billing sentence */}
          {servicePackStore.services.map((service, index) =>
            <ServicePackCreate
              key={index}
              service={service}
              instancesStore={instancesStore}
              currency={currency}
              onChange={configuration =>
                this.setState(({ instancesConfiguration }) =>
                  ({ instancesConfiguration: { ...instancesConfiguration, [service.id]: configuration } }))
              }
            />
          )}
        </div>

        <div style={{ margin: '10px' }}>
          Hourly billings. Each started hour is due. Minimum of {new Intl.NumberFormat(undefined, { style: 'currency', currency }).format(1)}/month per instance started.
        </div>

        <div style={{ textAlign: 'right', margin: '10px' }}>
          <Link to="../../">
            <Button style={{ marginRight: 8 }}>Previous</Button>
          </Link>
          <Link
            to={{
              pathname: '../../purchase/',
              state: {
                type: 'servicePackStore',
                servicePackStoreId,
                instancesConfiguration: this.state.instancesConfiguration
              }
            }}
          >
            <Button type="primary">Continue</Button>
          </Link>
        </div>
      </Card>
    );
  }
}

export default connect(
  ({ api, app }) => ({ api, app }),
  dispatch => ({
    navigationActions: bindActionCreators(navigationActions, dispatch)
  })
)(_default);
