import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash.isequal';

import ConfiguratorItems from './ConfiguratorItems';

import { Form, Button, message } from 'antd';

import styles from './Configurator.css';

class Configurator extends React.Component {
  static propTypes = {
    configuration: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    schema: PropTypes.func.isRequired,
    service: PropTypes.object.isRequired
  };

  state = {
    configuration: {},
    configurationOriginal: {}
  };


  UNSAFE_componentWillMount() {
    this.setState({
      configuration: this.props.configuration,
      configurationOriginal: this.props.configuration
    });
  }


  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps.configuration, this.props.configuration)) {
      this.setState({ configuration: nextProps.configuration });
    }
  }


  onSubmit() {
    const { isValid, configurationUpdated } = this.configurationCheckAndSanitize(this.props.schema, this.state.configuration, this.state.configurationOriginal);

    if (!isValid) {
      // If we have an error, update configuration, replacing undefined per '', which will show empty required fields in red
      this.setState({ configuration: configurationUpdated });
      message.error('Your configuration has some errors');
      return false;
    }

    this.props.onSubmit(configurationUpdated);
    return false;
  }


  configurationCheckAndSanitize(schema, configuration, configurationOriginal) {
    schema = schema({}, this.props.service, configuration, configurationOriginal, []);

    let isValid = true;
    const configurationUpdated = Object.keys(schema)
      .map(
        key => {
          const input = schema[key];

          if (input.hidden) {
            return null;
          }

          const value = configuration[key] === undefined ? '' : configuration[key];
          // Check input.isValid function if it is defined and value is not empty while it is not required
          if (input.isValid && !(value === '' && input.required === false)) {
            isValid = input.isValid(value, input) === true ? isValid : false;
          }

          if (schema[key].type === 'object') {
            const result = this.configurationCheckAndSanitize(schema[key].schema, configuration[key], configurationOriginal[key] || {});
            isValid = result.isValid === true ? isValid : false;
            return { [key]: result.configurationUpdated };
          }
          else if (schema[key].type === 'array') {
            const result = configuration[key]
              .map(entry => {
                const result = this.configurationCheckAndSanitize(schema[key].schema, entry, {});
                isValid = result.isValid === true ? isValid : false;
                return result.configurationUpdated;
              });

            isValid = schema[key].min !== undefined && configuration[key].length >= schema[key].min ? isValid : false;
            isValid = schema[key].max !== undefined && configuration[key].length <= schema[key].max ? isValid : false;
            return { [key]: result };
          }
          else {
            return { [key]: configuration[key] === undefined ? '' : configuration[key] };
          }
        }
      )
      .filter(v => v !== null)
      .reduce((a, b) => ({...a, ...b}), {});

    return { isValid, configurationUpdated };
  }

  render() {
    return (
      <Form layout="horizontal" onFinish={this.onSubmit.bind(this)} className={styles.configurator}>
        <ConfiguratorItems
          service={this.props.service}
          schema={this.props.schema}
          configuration={this.state.configuration}
          configurationOriginal={this.state.configurationOriginal}
          onChange={(newConfiguration, cb) =>
            this.setState(
              prevState => {
                const configuration = { ...prevState.configuration, ...newConfiguration };
                if (!isEqual(configuration, prevState.configuration)) {
                  return { configuration };
                }
              },
              cb
            )
          }
        />

        <div style={{ textAlign: 'right' }}>
          <Button onClick={this.props.onCancel} style={{ marginRight: 8 }}>Cancel</Button>
          <Button type="primary" htmlType="submit">Validate</Button>
        </div>
      </Form>
    );
  }
}

export default Configurator;
