import logo from 'images/servicesLogos/matomo.svg';
import logoOpenGraph from 'images/servicesLogos/openGraphVersions/matomo.png';

import idea from 'images/landings/icons/idea.svg';
import shield from 'images/landings/icons/shield.svg';
import envelope from 'images/landings/icons/envelope.svg';
import strategy from 'images/landings/icons/strategy.svg';
import startup from 'images/landings/icons/startup.svg';
import target from 'images/landings/icons/target.svg';

import checked from 'images/landings/icons/checked.svg';

import imageDashboard from 'images/landings/screenshots/matomo/dashboard.png';
import imageLocations from 'images/landings/screenshots/matomo/locations.png';
import imageRealtimeMap from 'images/landings/screenshots/matomo/realtimeMap.png';

const backgroundColor = '#3450A3';
const button = {
  color: '#fff',
  backgroundColor: '#6db81c'
};

export default ({ t = () => [] }) => ({
  id: 'matomo',
  name: 'Matomo',
  nameUrl: 'Matomo',
  title: t('services/matomo:title'),
  logo,
  logoOpenGraph,
  button,
  backgroundColor,

  header: {
    title: 'Matomo',
    description: t('services/matomo:description'),
    images: [
      { image: imageLocations },
      { image: imageRealtimeMap },
      { image: imageDashboard },
    ]
  },

  benefits: {
    items: [
      {
        icon: startup,
        ...t('services/matomo:benefits.performant')
      },
      {
        icon: target,
        ...t('services/matomo:benefits.geolocation')
      },
      {
        icon: idea,
        ...t('services/matomo:benefits.domain')
      },
      {
        icon: envelope,
        ...t('services/matomo:benefits.emails')
      },
      {
        icon: shield,
        ...t('services/matomo:benefits.secured')
      },
      {
        icon: strategy,
        ...t('services/matomo:benefits.updatable')
      }
    ]
  },

  also: {
    color: button.backgroundColor,
    items: t('services/matomo:also.items')
      .map(content => ({ icon: checked, ...content }))
  },

  pricing: {
    buttonBackgroundColor: button.backgroundColor,
    buttonColor: button.color,
    sentencesIconColor: button.backgroundColor,
    includes: t('services/matomo:pricing.includes')
      .map(description => ({ icon: checked, description })),
    fields: {
      vCpus: vCpus => [ vCpus, vCpus === 1 ? t('services/matomo:pricing.subLegends.vCpu') : t('services/matomo:pricing.subLegends.vCpus') ],
      ram: ram => [ ram < 1024 * 1024 * 1024 ? `${Math.round(ram / (1024 * 1024))} ${t('units.MB')}` : `${Math.round(ram / (1024 * 1024 * 1024))} ${t('units.GB')}`, t('services/matomo:pricing.subLegends.ram') ],
      hdd: hdd => [ hdd < 1024 * 1024 * 1024 ? `${Math.round(hdd / (1024 * 1024))} ${t('units.MB')}` : `${Math.round(hdd / (1024 * 1024 * 1024))} ${t('units.GB')}`, t('services/matomo:pricing.subLegends.storage') ],
      maxConcurrentConnections: v => [ new Intl.NumberFormat(undefined).format(v), t('services/matomo:pricing.subLegends.maxConcurrentConnections') ],
      pageViewsRecommended: v => [ new Intl.NumberFormat(undefined).format(v), t('services/matomo:pricing.subLegends.pageViewsRecommended') ]
    }
  },

  recall: {
    description: t('services/matomo:recall')
  },

  faq: {
    items: t('services/matomo:faq.items')
  }
});
