import React from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as apiActions from 'redux/actions/api';

import { Spin, Alert, Form, Button, message } from 'antd';
import FormItems from './FormItems';
import Link from 'components/Link';

class ServiceLogsForwarder extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    apiActions: PropTypes.object.isRequired,
    serviceId: PropTypes.string.isRequired,
    onUpdate: PropTypes.func.isRequired
  };

  state = {
    loading: true,
    showErrors: false,
    formIsValid: false,
    form: {}
  };

  componentDidMount() {
    this._mounted = true;
    this.logsServerGet();
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  logsServerGet() {
    this.props.apiActions.get(
      {
        route: `/dashboard/services/${this.props.serviceId}/logsServer`,
        routeArgs: {},
        spinner: false
      },
      (error, form) => {
        if (error || !this._mounted) {
          return;
        }
        this.setState(
          form
            ? { form, loading: false }
            : { loading: false }
        );
      }
    );
  }

  form = () => [
    {
      label: 'Graylog domain',
      name: 'host',
      input: {
        type: 'text',
        placeholder: 'XXXXXX.stackhero-network.com',
        hasError: (name, value, form) => form.port && !/^([a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,24}$/.test(value)
      }
    },

    {
      label: 'Graylog port',
      name: 'port',
      extra: 'Your GELF HTTP input port with TLS. Default is 12201.',
      input: {
        type: 'text',
        placeholder: 12201,
        default: 12201,
        hasError: (name, value, form) => form.host && !(typeof(value) === 'number' && value > 0 && value <= 65535),
        onChange: value => isNaN(parseInt(value)) ? value : parseInt(value.replace(/[^0-9]*/g, ''))
      }
    }
  ];

  onValidate = () => {
    if (!this.state.formIsValid) {
      this.setState({ showErrors: true });
      return false;
    }

    this.props.apiActions[this.state.form.host && this.state.form.port ? 'put' : 'del'](
      {
        route: `/dashboard/services/${this.props.serviceId}/logsServer`,
        routeArgs: this.state.form.host ? this.state.form : {}
      },
      (error, form) => {
        if (error || !this._mounted) {
          return;
        }

        // go to actions
        message.success('Logs forwarder configuration has been saved');
        this.props.onUpdate();
      }
    );
  };

  render() {
    return (
      <Spin spinning={this.state.loading}>
        <Alert
          type="info"
          showIcon
          message="Logs forwarder configuration"
          description={(
            <>
              With "logs forwarder", You can forward logs from this instance to any Graylog server.
              <br />
              <br />
              First, add a "GELF HTTP" input to your Graylog.
              <br />
              Then activate TLS encryption on your GELF HTTP port (12201 per default) in your Graylog's configuration (in Stackhero dashboard).
              <br />
              <br />
              You can find more information in <Link url={`${process.env.HTTP_SCHEME}://${process.env.SHOWCASE_HOST}/${this.props.app.languageAndRegion}/stackhero/documentations/Forward-logs-to-Graylog`}>Stackhero documentation</Link>.
            </>
          )}
          style={{ marginBottom: 16 }}
        />


        <Form onFinish={this.onValidate}>
          <FormItems
            entries={this.form()}
            onStatusChange={formIsValid => this.setState({ formIsValid })}
            datas={this.state.form}
            onUpdate={form => this.setState({ form })}
            sizes={{ xs: 24, sm: 6 }}
            showErrors={this.state.showErrors}
          />

          <div style={{ textAlign: 'right' }}>
            <Button type="primary" htmlType="submit">Validate</Button>
          </div>
        </Form>
      </Spin>
    );
  }
}

export default connect(
  ({ app }) => ({ app }),
  dispatch => ({
    apiActions: bindActionCreators(apiActions, dispatch)
  })
)(ServiceLogsForwarder);
