import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash.isequal';

import { Button } from 'antd';
import { DeleteOutlined, UserAddOutlined } from '@ant-design/icons';

import ConfiguratorItems from './ConfiguratorItems';
import Markdown from 'components/Markdown';

import styles from './ConfiguratorArray.css';

class ConfiguratorArray extends React.Component {

  static propTypes = {
    entry: PropTypes.object.isRequired,
    help: PropTypes.string,
    configuration: PropTypes.array,
    configurationOriginal: PropTypes.array,
    schema: PropTypes.func.isRequired,
    service: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired
  };

  state = {
    configuration: []
  };

  UNSAFE_componentWillMount() {
    this.setState({ configuration: this.configurationInit(this.props.configuration) });
  }


  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps.configuration, this.props.configuration)) {
      this.setState({ configuration: this.configurationInit(nextProps.configuration) });
    }
  }

  icons = {
    'user-add': <UserAddOutlined />
  };

  configurationInit(configuration) {
    if (!configuration) {
      configuration = [];
    }
    if (configuration.length < this.props.entry.min) {
      const i = this.props.entry.min - configuration.length;
      for (let n = 0; n < i ; n++) {
        this.itemAdd();
      }
    }
    return configuration.map(data => data._id ? data : { ...data, _id: Math.random() });
  }


  itemRemove(index) {
    this.props.onChange([ ...this.state.configuration.slice(0, index), ...this.state.configuration.slice(index + 1) ]);
  }


  renderArrayButtonRemove(index) {
    if (this.state.configuration.length <= this.props.entry.min) {
      return null;
    }

    return (
      <div style={{ textAlign: 'right' }}>
        <Button danger icon={<DeleteOutlined />} size="small" onClick={this.itemRemove.bind(this, index)}>
          Remove
        </Button>
      </div>
    );
  }


  itemAdd() {
    this.props.onChange([ ...this.state.configuration, { _id: Math.random() } ]);
  }


  renderArrayButtonAdd() {
    if (this.state.configuration.length >= this.props.entry.max) {
      return null;
    }

    const { icon, label } = this.props.entry.addButton;
    return (
      <div style={{ textAlign: 'right' }}>
        <Button icon={this.icons[icon]} onClick={this.itemAdd.bind(this)} size="small">
          {label}
        </Button>
      </div>
    );
  }


  onChange(index, value) {
    this.props.onChange([
      ...this.state.configuration.slice(0, index),
      value,
      ...this.state.configuration.slice(index + 1),
    ]);
  }


  renderGroupOfItems(index, configuration) {
    if (!configuration._id) {
      throw Error('_id has to be set!');
    }
    return (
      <div className={styles.item} key={configuration._id}>
        <ConfiguratorItems
          service={this.props.service}
          schema={this.props.schema}
          configuration={configuration}
          configurationOriginal={this.props.configurationOriginal || {}}
          onChange={this.onChange.bind(this, index)}
        />
        {this.renderArrayButtonRemove(index)}
      </div>
    );
  }


  render() {
    return (
      <div>
        {this.props.help ? ( <Markdown content={this.props.help} /> ) : null}

        <div className={styles.items}>
          {this.state.configuration.map((data, index) => this.renderGroupOfItems(index, data))}
        </div>

        {this.renderArrayButtonAdd()}
      </div>
    );
  }
}

export default ConfiguratorArray;
