import logo from 'images/servicesLogos/rabbitmq.svg';
import logoOpenGraph from 'images/servicesLogos/openGraphVersions/rabbitmq.png';

import growth from 'images/landings/icons/growth.svg';
import startup from 'images/landings/icons/startup.svg';
import shield from 'images/landings/icons/shield.svg';
import strategy from 'images/landings/icons/strategy.svg';
import packageSvg from 'images/landings/icons/package.svg';
import clipboard from 'images/landings/icons/clipboard.svg';

import checked from 'images/landings/icons/checked.svg';

import imageDashboard from 'images/landings/screenshots/rabbitmq/dashboard.png';

// #866e8b is the original RabbitMQ website color, but it is too dull.
const backgroundColor = '#5e3c64';
const button = {
  color: '#fff',
  backgroundColor: '#ea5800'
};

const serviceName = 'RabbitMQ';
export default ({ t = () => [] }) => ({
  id: 'rabbitmq',
  name: 'RabbitMQ',
  nameUrl: 'RabbitMQ',
  title: t('services/rabbitmq:title', { serviceName }),
  logo,
  logoOpenGraph,
  button,
  backgroundColor,

  header: {
    title: 'RabbitMQ',
    description: t('services/rabbitmq:description', { serviceName }) + '\n\n' + t('services/rabbitmq:callToAction', { serviceName }),
    images: [
      { image: imageDashboard }
    ]
  },

  benefits: {
    items: [
      {
        icon: growth,
        ...t('services/rabbitmq:benefits.business', { serviceName })
      },
      {
        icon: startup,
        ...t('services/rabbitmq:benefits.performant', { serviceName })
      },
      {
        icon: shield,
        ...t('services/rabbitmq:benefits.secured', { serviceName })
      },
      {
        icon: clipboard,
        ...t('services/rabbitmq:benefits.manageable', { serviceName })
      },
      {
        icon: packageSvg,
        ...t('services/rabbitmq:benefits.complete', { serviceName })
      },
      {
        icon: strategy,
        ...t('services/rabbitmq:benefits.updatable', { serviceName })
      }
    ]
  },

  also: {
    color: button.backgroundColor,
    items: t('services/rabbitmq:also.items', { serviceName })
      .map(content => ({ icon: checked, ...content }))
  },

  pricing: {
    ...t('services/rabbitmq:pricing'),
    buttonBackgroundColor: button.backgroundColor,
    buttonColor: button.color,
    sentencesIconColor: button.backgroundColor,
    includes: t('services/rabbitmq:pricing.includes')
      .map(description => ({ icon: checked, description })),
    fields: {
      maxMessagesPerSecond: v => [
        v > 0
          ? new Intl.NumberFormat(undefined).format(v)
          : t('mainPage.pricing.unlimited'),
        t('services/rabbitmq:pricing.subLegends.maxMessagesPerSecond')
      ],
      maxConcurrentConnections: v => [ new Intl.NumberFormat(undefined).format(v), t('services/rabbitmq:pricing.subLegends.maxConcurrentConnections') ],
      hdd: hdd => [ hdd < 1024 * 1024 * 1024 ? `${Math.round(hdd / (1024 * 1024))} ${t('units.MB')}` : `${Math.round(hdd / (1024 * 1024 * 1024))} ${t('units.GB')}`, t('services/rabbitmq:pricing.subLegends.storage') ]
    }
  },

  recall: {
    description: t('services/rabbitmq:callToAction', { serviceName })
  },

  faq: {
    items: t('services/rabbitmq:faq.items', { serviceName })
  }
});
