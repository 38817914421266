import React from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as apiActions from 'redux/actions/api';

import { Card, Form, Button, Modal } from 'antd';

import FormItems from 'components/FormItems';

class _default extends React.Component {
  static propTypes = {
    apiActions: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };

  state = {
    form: {},
    formIsValid: false,
    showErrors: false
  };

  componentDidMount() {
    this._mounted = true;
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  handleSubmit = () => {
    this.setState({ showErrors: true });

    if (!this.state.formIsValid) {
      return false;
    }

    const { organizationId } = this.props.match.params;
    this.props.apiActions.post(
      {
        route: `/dashboard/organizations/${organizationId}/stacks`,
        routeArgs: this.state.form,
        handleErrorAutomatically: false
      },
      (error, result) => {
        if (!this._mounted) { return; }
        if (error && error.response.status === 403 && error.response.data.message === 'emptyStacks') {
          Modal.error({
            title: 'You cannot create a new stack',
            content: ( <span>You have some stacks without any services.<br />You can use them before creating a new one.</span> )
          });
        }
        else if (error) {
          throw error;
        }
        else {
          this.props.history.push(`../${result.id}/`);
        }
      }
    );
  };


  entries = [
    {
      label: 'Name',
      name: 'name',
      required: true,
      input: {
        type: 'text',
        placeholder: 'My awesome project',
        hasError: (name, value, form) => value.length < 3 || value.length > 64 ? 'Should be between 3 and 64 characters' : ''
      }
    }
  ];


  render() {
    return (
      <Card title="Create a new stack">

        <Form onFinish={this.handleSubmit}>
          <FormItems
            entries={this.entries}
            onStatusChange={formIsValid => this.setState({ formIsValid })}
            datas={this.state.form}
            onUpdate={form => this.setState({ form })}
            showErrors={this.state.showErrors}
          />

          <div style={{ textAlign: 'right' }}>
            Note: you should create a stack per project to have a perfect isolation
          </div>
          <br />

          <Form.Item style={{ textAlign: 'right', marginBottom: 0 }}>
            <Button type="primary" htmlType="submit">Create</Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }

}

export default connect(
  () => ({}),
  dispatch => ({
    apiActions: bindActionCreators(apiActions, dispatch)
  })
)(_default);
