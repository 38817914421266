import React from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as apiActions from 'redux/actions/api';

import { Card, Form, Button } from 'antd';

import FormItems from 'components/FormItems';
import countries from 'contents/countries';

class _default extends React.Component {
  static propTypes = {
    apiActions: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  state = {
    form: {},
    formIsValid: false,
    showErrors: false
  };

  componentDidMount() {
    this._mounted = true;
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  handleSubmit = () => {
    this.setState({ showErrors: true });

    if (!this.state.formIsValid) {
      return false;
    }

    this.props.apiActions.post(
      {
        route: '/dashboard/organizations',
        routeArgs: this.state.form,
        handleErrorAutomatically: false
      },
      (error, result) => {
        if (!this._mounted) { return; }
        if (error) {
          throw error;
        }
        this.props.history.push(`../${result.id}/`);
      }
    );
  };


  render() {
    const entries = [
      {
        label: 'Name',
        name: 'name',
        required: true,
        input: {
          type: 'text',
          placeholder: 'My company',
          hasError: (name, value, form) => value.length < 1 || value.length > 64 ? 'Should be between 1 and 64 characters' : '',
          autoComplete: 'organization'
        }
      },
      {
        label: 'Address',
        name: 'address',
        required: true,
        input: {
          type: 'textarea',
          hasError: (name, value, form) => value.length < 1 || value.length > 128 ? 'Should be between 1 and 128 characters' : '',
          autoComplete: 'street-address'
        }
      },
      {
        label: 'Postal code',
        name: 'postalCode',
        required: true,
        input: {
          type: 'text',
          hasError: (name, value, form) => value.length < 2 || value.length > 16 ? 'Should be between 2 and 16 characters' : '',
          autoComplete: 'postal-code'
        }
      },
      {
        label: 'City',
        name: 'city',
        required: true,
        input: {
          type: 'text',
          hasError: (name, value, form) => value.length < 1 || value.length > 32 ? 'Should be between 1 and 32 characters' : ''
        }
      },
      {
        label: 'Country',
        name: 'countryCode',
        required: true,
        input: {
          type: 'select',
          placeholder: 'Select your country',
          entries: countries.namesAndCodes.map(([ label, id ]) => ({ id, label })),
          hasError: (name, value, form) => countries.namesAndCodes.find(([ /* null */, id ]) => id === value) ? '' : 'You should select your country'
        }
      },

      this.state.form.countryCode
        && countries.vatNumbersFormats[this.state.form.countryCode]
        ? {
          label: 'VAT number',
          name: 'vatNumber',
          required: false,
          input: {
            type: 'text',
            placeholder: countries.vatNumbersFormats[this.state.form.countryCode][1],
            hasError: (name, value, form) => !value || countries.vatNumbersFormats[this.state.form.countryCode][0].test(value) ? '' : 'Format should be ' + countries.vatNumbersFormats[this.state.form.countryCode][1]
          }
        }
        : null
    ].filter(v => v !== null);

    return (
      <Card title="Create a new organization">
        <Form onFinish={this.handleSubmit}>
          <FormItems
            entries={entries}
            onStatusChange={formIsValid => this.setState({ formIsValid })}
            datas={this.state.form}
            onUpdate={form => this.setState({ form })}
            showErrors={this.state.showErrors}
          />

          <Form.Item style={{ textAlign: 'right', marginBottom: 0 }}>
            <Button type="primary" htmlType="submit">Create</Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }

}

export default connect(
  () => ({}),
  dispatch => ({
    apiActions: bindActionCreators(apiActions, dispatch)
  })
)(_default);
