import React from 'react';
import PropTypes from 'prop-types';
import LinkLayout from 'components/Link';

import { Timeline } from 'antd';

class Summary extends React.Component {
  static propTypes = {
    entries: PropTypes.array.isRequired,
    baseUrl: PropTypes.string.isRequired
  };

  render() {
    return (
      <Timeline
        items={this.props.entries.map(entry => ({
          color: 'grey',
          children: (
            <>
              <LinkLayout url={this.props.baseUrl + '/' + entry.url}>
                {entry.title}
              </LinkLayout>
              <br />
              {entry.description}
            </>
          )
        }))}
      />
    );
  }
}

export default Summary;
