import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import createStore from 'redux/createStore';

import Router from 'components/Router';

import 'antd/dist/reset.css';
import './entryPoint.css';

const store = createStore();

const routes = require('./routes.js');
const renderToDom = (routes) => {
  render(
    <Provider store={store}>
      <BrowserRouter>
        <Router rootPath="" routes={routes} />
      </BrowserRouter>
    </Provider>,
    document.getElementById('mainContainer')
  );
};

renderToDom(routes(new Date()));

// Support hot reload on development
if (module.hot) {
  module.hot.accept('../../components/Router', () => renderToDom(routes()));
  module.hot.accept('./routes.js', () => { const routes = require('./routes.js'); renderToDom(routes(new Date())); });
}
