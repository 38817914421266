import React from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as apiActions from 'redux/actions/api';

import queryString from 'query-string';

class EmailValidation extends React.Component {

  static propTypes = {
    apiActions: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
  };

  componentDidMount() {
    this._mounted = true;

    const { emailEncoded, key } = queryString.parse(this.props.location.search);

    // Note: debug to try to find what triggered the issue https://sentry.io/organizations/bacto/issues/1994938073/?project=1190481&query=is%3Aunresolved
    try {
      atob(emailEncoded);
    }
    catch (error) {
      console.error(`Error when trying to decode ${emailEncoded} key ${key}`);
    }

    this.props.apiActions.post(
      {
        route: '/emailValidation',
        routeArgs: { email: atob(emailEncoded), key },
        handleErrorAutomatically: false
      },
      (error, response) => {
        if (!this._mounted) { return false; }
        this.props.history.replace('/');
      }
    );
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  render() {
    return null;
  }
}

export default connect(
  () => ({}),
  dispatch => ({
    apiActions: bindActionCreators(apiActions, dispatch)
  })
)(EmailValidation);
