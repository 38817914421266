import React from 'react';
import PropTypes from 'prop-types';

class _default extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired
  };

  componentDidMount() {
    // Go to automatically to stacks page
    this.props.history.replace('stacks/');
  }

  render() {
    return null;
  }
}

export default _default;
