import React from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as apiActions from 'redux/actions/api';

import { Link } from 'react-router-dom';

import { Card, Table, Tag, Button } from 'antd';
import { EditOutlined, DownloadOutlined } from '@ant-design/icons';

class _default extends React.Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    apiActions: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };

  componentDidMount() {
    this._mounted = true;
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  invoiceDownload(invoice) {
    const { organizationId } = this.props.match.params;
    this.props.apiActions.get(
      {
        route: `/dashboard/organizations/${organizationId}/billing/invoices/${invoice.id}`,
        routeArgs: {},
        options: { responseType: 'blob' },
      },
      (error, result) => {
        if (error || !this._mounted) {
          return;
        }

        const period = new Date(invoice.creationDate).toLocaleDateString('fr-CA');
        const statusFormated = invoice.status.charAt(0).toUpperCase() + invoice.status.slice(1);
        const filename = `${period} - Stackhero - Invoice - ${invoice.id} - ${statusFormated}.pdf`;

        let a = document.createElement('a');
        document.body.appendChild(a);
        a.href = window.URL.createObjectURL(result);
        a.download = filename;
        a.click();

        setTimeout(() => document.body.removeChild(a), 100);
      }
    );
  }

  renderInvoices() {
    const { organizationId } = this.props.match.params;
    const route = `/dashboard/organizations/${organizationId}/billing/invoices`;
    const invoices = this.props.api.subscriptions[route].datas;

    if (!invoices.length) {
      return ( <span>You don't have any invoice yet</span> );
    }

    return (
      <Table dataSource={invoices} rowKey="id">
        <Table.Column
          title="Date"
          key="date"
          render={text => (new Intl.DateTimeFormat(undefined).format(new Date(text.creationDate)))}
        />

        <Table.Column
          title="Period"
          key="period"
          render={text => new Intl.DateTimeFormat(undefined, { timeZone: 'UTC', month: 'long', year: 'numeric' }).format(new Date(text.periodDate)) }
        />

        <Table.Column
          title="Reference"
          dataIndex="id"
        />

        <Table.Column
          title="Amount"
          key="totalWithoutVatX100"
          align="right"
          render={({ currency, currencyHasCents, totalWithoutVatX100 }) => (new Intl.NumberFormat(undefined, { style: 'currency', currency, minimumFractionDigits: currencyHasCents ? 2 : 0 }).format(totalWithoutVatX100 / 100))}
        />

        <Table.Column
          title="Tax"
          key="totalVatX100"
          align="right"
          render={({ currency, currencyHasCents, totalVatX100 }) => (new Intl.NumberFormat(undefined, { style: 'currency', currency, minimumFractionDigits: currencyHasCents ? 2 : 0 }).format(totalVatX100 / 100))}
        />

        <Table.Column
          title="Total"
          key="totalWithVatX100"
          align="right"
          render={({ currency, currencyHasCents, totalWithVatX100 }) => (new Intl.NumberFormat(undefined, { style: 'currency', currency, minimumFractionDigits: currencyHasCents ? 2 : 0 }).format(totalWithVatX100 / 100))}
        />

        <Table.Column
          title="Status"
          key="status"
          render={(text, record) => {
            if (text.status === 'due') {
              return ( <Tag color="blue">Ongoing</Tag> );
            }
            else if (text.status === 'draft') {
              return ( <Tag color="orange">Draft</Tag> );
            }
            else if (text.status === 'paid') {
              return ( <Tag color="green">Paid</Tag> );
            }
            else if (text.status === 'unpaid') {
              return ( <Tag color="red">Unpaid</Tag> );
            }
            else {
              throw Error(`Unknown value ${JSON.stringify(text)}`);
            }
          }}
        />

        <Table.Column
          key="download"
          render={(text, record) => {
            if (text.status === 'paid' || text.status === 'draft' || text.status === 'unpaid') {
              return ( <Button onClick={this.invoiceDownload.bind(this, text)} icon={<DownloadOutlined />} /> );
            }
          }}
        />
      </Table>
    );
  }

  render() {
    const { organizationId } = this.props.match.params;
    const { invoicesRecipients } = this.props.api.subscriptions[`/dashboard/organizations/${organizationId}`].datas;

    return (
      <>
        <Card style={{ marginBottom: 16 }}>
          New invoices are automatically sent by email to <span style={{ fontWeight: 'bold' }}>{invoicesRecipients.join()}</span> <Link to={'../../edit'}><EditOutlined /></Link>
        </Card>

        {/* overflow for mobile */}
        <Card title="Your invoices" styles={{ body: { overflow: 'scroll' }}}>
          {this.renderInvoices()}
        </Card>
      </>
    );
  }
}

export default connect(
  ({ api }) => ({ api }),
  dispatch => ({
    apiActions: bindActionCreators(apiActions, dispatch)
  })
)(_default);
