import React from 'react';
import PropTypes from 'prop-types';

import { Progress } from 'antd';

class Gauge extends React.Component {
  static propTypes = {
    percent: PropTypes.number,
    color: PropTypes.string,
    limits: PropTypes.array
  };

  render() {
    const { percent, color, limits } = this.props;

    const limitsArray = !limits
      ? [ 70, 90 ]
      : limits;

    const strokeColor = color
      ? color
      : percent >= limitsArray[1]
        ? '#f5222d'
        : percent >= limitsArray[0]
          ? '#fa8c16'
          : '#52c41a';

    return (
      <Progress
        type="dashboard"
        percent={percent}
        strokeColor={strokeColor}
        size={80}
        status="normal"
        format={(percentCapped, successPercent) => parseFloat(percent) !== percent ? '...' : `${percent}%`
        }
      />
    );
  }
}

export default Gauge;
