import logo from 'images/servicesLogos/grafana.svg';
import logoOpenGraph from 'images/servicesLogos/openGraphVersions/grafana.png';

import growth from 'images/landings/icons/growth.svg';
import startup from 'images/landings/icons/startup.svg';
import shield from 'images/landings/icons/shield.svg';
import packageSvg from 'images/landings/icons/package.svg';
import strategy from 'images/landings/icons/strategy.svg';
import checked from 'images/landings/icons/checked.svg';
import key from 'images/landings/icons/key.svg';
import onlineShop2 from 'images/landings/icons/online-shop-2.svg';
import idea from 'images/landings/icons/idea.svg';

import imageDashboard from 'images/landings/screenshots/grafana/dashboard.png';

const backgroundColor = '#333335';
const button = {
  color: '#fff',
  backgroundColor: '#f46800'
};

export default ({ t = () => [] }) => ({
  id: 'grafana',
  name: 'Grafana',
  nameUrl: 'Grafana',
  title: t('services/grafana:title', { serviceName: 'Grafana' }),
  logo,
  logoOpenGraph,
  button,
  backgroundColor,

  header: {
    title: 'Grafana',
    description: t('services/grafana:description', { serviceName: 'Grafana' }),
    images: [
      { image: imageDashboard }
    ]
  },

  explanation: {
    title: t('services/grafana:explanation.title', { serviceName: 'Grafana' }),
    content: t('services/grafana:explanation.content', { serviceName: 'Grafana' })
  },

  benefits: {
    items: [ growth, strategy, onlineShop2, packageSvg, idea, key, startup, shield ].map((icon, index) => ({
      icon,
      ...t('services/grafana:benefits', { serviceName: 'Grafana' })[index]
    }))
  },

  also: {
    color: button.backgroundColor,
    items: t('services/grafana:also.items', { serviceName: 'Grafana' })
      .map(content => ({ icon: checked, ...content }))
  },

  recall: {
    description: t('services/grafana:recall', { serviceName: 'Grafana' })
  },

  pricing: {
    buttonBackgroundColor: button.backgroundColor,
    buttonColor: button.color,
    sentencesIconColor: button.backgroundColor,
    includes: t('services/grafana:pricing.includes', { serviceName: 'Grafana' })
      .map(description => ({ icon: checked, description })),
    fields: {
      vCpus: vCpus => [ vCpus, vCpus === 1 ? t('mainPage.pricing.subLegends.vCpu') : t('mainPage.pricing.subLegends.vCpus') ],
      ram: ram => [ ram < 1024 * 1024 * 1024 ? `${Math.round(ram / (1024 * 1024))} ${t('units.MB')}` : `${Math.round(ram / (1024 * 1024 * 1024))} ${t('units.GB')}`, t('mainPage.pricing.subLegends.ram') ],
      hdd: hdd => [ hdd < 1024 * 1024 * 1024 ? `${Math.round(hdd / (1024 * 1024))} ${t('units.MB')}` : `${Math.round(hdd / (1024 * 1024 * 1024))} ${t('units.GB')}`, t('mainPage.pricing.subLegends.disk') ],
      pluginsCountMax: pluginsCountMax => [
        new Intl.NumberFormat(undefined).format(pluginsCountMax),
        t('services/grafana:pricing.subLegends.pluginsCountMax')
      ]
    }
  },

  faq: {
    items: t('services/grafana:faq.items', { serviceName: 'Grafana' })
  }
});