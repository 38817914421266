import React from 'react';
import PropTypes from 'prop-types';

import styles from './MarkdownSimple.css';

class MarkdownSimple extends React.Component {
  static propTypes = {
    content: PropTypes.string
  };

  render() {
    return this.props.content.split('\n')
      .map((item, key) => (
        <React.Fragment key={key}>
          {/* Handle bold (**), italic (*), backquotes, Markdown text */}
          {item
            .split(/(\*\*.+?\*\*|\*.+?\*)/g)
            .map((item, key) =>
              [
                /^\*\*.+?\*\*$/.test(item) && ( <strong key={key}>{item.replace(/^\*\*(.+?)\*\*$/, '$1')}</strong> ),
                /^\*.+?\*$/.test(item) && ( <em key={key}>{item.replace(/^\*(.+?)\*$/, '$1')}</em> ),
                /^`.+?`$/.test(item) && ( <code key={key} className={styles.code}>{item.replace(/^`(.+?)`$/, '$1')}</code> ),
                ( <React.Fragment key={key}>{item}</React.Fragment> )
              ]
              .filter(v => v)
              .shift()
            )
          }
          <br/>
        </React.Fragment>
      ));
  }
}

export default MarkdownSimple;