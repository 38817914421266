import React from 'react';
import PropTypes from 'prop-types';
import Link from 'components/Link';
import Dropdown, { DropdownElement } from 'components/Dropdown';
import { CaretDownOutlined } from '@ant-design/icons';

import { compose } from 'redux';
import { connect } from 'react-redux';

import { withTranslation } from 'react-i18next';

import styles from './MenuServices.css';

class MenuServices extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    services: PropTypes.array.isRequired,
    children: PropTypes.node
  };

  renderMenu = () => this.props.services
    .filter(serviceFunc => serviceFunc({}).hidden !== true)
    .map(serviceFunc => {
      const { name, nameUrl, logo } = serviceFunc({});
      return (
        <DropdownElement key={name}>
          <Link
            className={styles.link}
            url={`${process.env.HTTP_SCHEME}://${process.env.SHOWCASE_HOST}/${this.props.app.languageAndRegion}/services/${nameUrl}/benefits`}
          >
            <div style={{ backgroundImage: `url(${logo})` }} />
            <span>{name}</span>
          </Link>
        </DropdownElement>
      );
    });

  render() {
    const { t } = this.props;
    return (
      <Dropdown elements={this.renderMenu()} triggeredOnMouseOver placement="right">
        {this.props.children || t('mainPage.menu.services')} <CaretDownOutlined />
      </Dropdown>
    );
  }
}

export default compose(
  connect(
    ({ app }) => ({ app })
  ),
  withTranslation()
)(MenuServices);