import React from 'react';
import PropTypes from 'prop-types';

import { Badge } from 'antd';

class ServiceStatus extends React.Component {
  static propTypes = {
    status: PropTypes.object.isRequired,
    badgeOnly: PropTypes.bool,
    legendOnly: PropTypes.bool
  };

  statuses = {
    ok: 'success',
    error: 'error',
    suspended: 'warning',
    deleted: 'default'
  };

  legends = {
    running: 'running',
    serviceCreation: 'creation',
    serviceConfiguration: 'configuration',
    serviceDestruction: 'deleting',
    serviceUpdate: 'updating',
    hostMigration: 'host migration',
    gatewayMigration: 'gateway migration',
    serviceSuspend: 'suspending',
    serviceUnsuspend: 'unsuspending',
    serviceBackup: 'backuping',
    deleted: 'deleted',
    instanceUpgrade: 'upgrading instance',
    instanceRestart: 'restarting instance',
    icmp: 'not replying',
    host: 'host not replying',
    gateway: 'gateway not replying',
    suspended: 'suspended',
    instanceFirewallUpdate: 'updating firewall',
    backupRollback: 'rolling back to a backup',
    instanceLogsServerUpdate: 'configuring logs forwader'
  };

  renderLegend() {
    if (!this.legends[this.props.status.type]) {
      console.error(`Legend ${this.props.status.type} unknown`);
    }
    return ( <span>{this.legends[this.props.status.type] || this.props.status.type}</span> );
  }

  render() {
    if (this.props.badgeOnly) {
      return ( <Badge status={this.statuses[this.props.status.state] || 'processing'} /> );
    }
    else if (this.props.legendOnly) {
      return this.renderLegend();
    }
    else {
      return ( <Badge status={this.statuses[this.props.status.state] || 'processing'} text={this.renderLegend()} /> );
    }
  }
}

export default ServiceStatus;
