import React from 'react';
import PropTypes from 'prop-types';

import ServiceStatus from 'components/ServiceStatus';
import { Descriptions } from 'antd';
import regionsDetails from 'contents/regions';

class ServiceDetails extends React.Component {
  static propTypes = {
    service: PropTypes.object.isRequired,
  };

  detailsGet() {
    const { service } = this.props;
    if (!service.isConfigured) {
      return [];
    }

    return service.serviceStore.serviceDetails
      .filter(v => v.length > 0)
      .map((detail, index) => ({
        label: detail[0],
        content: /^https:/.test(detail[1])
          ? ( <a href={detail[1]} target="_blank" rel="noopener noreferrer">{detail[1]}</a> )
          : detail[1]
      }));
  }


  render() {
    const { service } = this.props;

    const entries = [
      {
        label: 'Service ID',
        content: service.id
      },
      {
        label: 'Status',
        content: ( <ServiceStatus status={service.status}/> )
      },
      {
        label: 'Service',
        content: service.serviceStore.title
      },
      {
        label: 'Version',
        content: service.serviceStore.version
      },
      {
        label: 'Instance type',
        content: 'Dedicated & isolated'
      },
      service.region
        ? {
          label: 'Instance region',
          content: `${regionsDetails[service.region].emoji} ${regionsDetails[service.region].label}`
        }
        : null,
      service.dedicatedIp
        ? {
          label: 'Dedicated IPv4',
          content: service.dedicatedIp
        }
        : null,

      ...this.detailsGet()
    ].filter(v => v);

    return (
      <Descriptions
        size="small"
        bordered
        column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
        items={entries.map(({ label, content }) => ({
          key: label,
          label,
          children: content
        }))}
      />
    );
  }
}

export default ServiceDetails;
