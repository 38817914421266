import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { Breadcrumb as AntdBreadcrumb } from 'antd';

import styles from './Breadcrumb.css';

class Breadcrumb extends React.Component {
  static propTypes = {
    navigation: PropTypes.object.isRequired,
    className: PropTypes.string
  };

  render() {
    if (!this.props.navigation.breadcrumbs.length) {
      return null;
    }

    const className = this.props.className
      ? [ styles.breadcrumb, ...this.props.className.split(' ') ].join(' ')
      : styles.breadcrumb;

    return (
      <AntdBreadcrumb
        className={className}
        items={
          this.props.navigation.breadcrumbs
            .map((entry, index, entries) => ({
              title: entry.url && index + 1 < entries.length
                ? (
                  <Link to={entry.url}>
                    {entry.name}
                  </Link>
                )
                : entry.name
            }))
        }
      />
    );
  }
}

export default connect(
  state => ({ navigation: state.navigation })
)(Breadcrumb);
