import React from 'react';
import PropTypes from 'prop-types';

class _container extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    children: PropTypes.node,
  };

  componentDidMount() {
    if (!this.props.location.state) {
      this.props.history.replace('../');
    }
  }

  render() {
    return this.props.children;
  }
}

export default _container;