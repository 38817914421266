const initialState = {
	steps: [],
	stepSelected: 0,
	breadcrumbs: []
};

const navigation = (state = initialState, action) => {
	switch (action.type) {

	case 'STEPS_SET':
		return { ...state, steps: action.steps, stepSelected: 0 };

	case 'STEPS_UNSET':
		return { ...state, steps: [], stepSelected: 0 };

  case 'STEP_SELECT':
    return { ...state, stepSelected: action.index };

  case 'STEP_SELECT_LABEL':
    return { ...state, stepSelected: state.steps.findIndex(({ label }) => label === action.label) };

	case 'BREADCRUMB_ADD':
		return { ...state, breadcrumbs: [ ...state.breadcrumbs, { name: action.name, url: action.url, key: action.key } ] };

	case 'BREADCRUMB_UPDATE':
		return { ...state, breadcrumbs: state.breadcrumbs.map((b) => {
			if (b.key !== action.key) {
				return b;
			}
			return { name: action.name, url: action.url, key: action.key };
		}) };

	case 'BREADCRUMB_REMOVE':
		return { ...state, breadcrumbs: state.breadcrumbs.filter(b => b.key !== action.key) };

	default:
		return state;
	}
};


export default navigation;
