import React from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as apiActions from 'redux/actions/api';
import * as navigationActions from 'redux/actions/navigation';

import { Card, Alert } from 'antd';

import CreditCardFormParent from 'components/CreditCardFormParent';

class Verifications extends React.Component {
  static propTypes = {
    apiActions: PropTypes.object.isRequired,
    navigationActions: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };

  componentDidMount() {
    this._mounted = true;
    this.props.navigationActions.stepSelectByLabel('verifications');

    if (this.props.location.state.instanceStoreStatus === 'available') {
      this.props.history.replace('../purchase/terms', this.props.location.state);
    }
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  render() {
    return (
      <Card title="Payment method">
        <div style={{ maxWidth: 540, marginLeft: 'auto', marginRight: 'auto' }}>
        <Alert
          message="To avoid frauds, we need your payment card"
          description={
            <>
              Billing will be done at the beginning of each month for the services consumed and only if you do not have a free coupon.
              <br />
              You can check your current consumption at any time in "Billing"/"Invoices".
              <br />
              <span style={{ fontWeight: 'bold' }}>
                {this.props.location.state.instanceStoreStatus === 'paymentMethodVerificationRequired'
                  ? (<>To validate your card, a small amount will be captured and released immediately: you may get a notification from your bank but we won't really charge you.</>)
                  : (<>You will not be charged at this time.</>)
                }
              </span>
            </>
          }
          type="success"
          showIcon
          style={{ marginBottom: 16 }}
        />
          <CreditCardFormParent
            type="add"
            onSuccess={(paymentMethodId, cb) => {
              // Send token to API
              const { organizationId } = this.props.match.params;
              this.props.apiActions.post(
                {
                  route: `/dashboard/organizations/${organizationId}/billing/creditCard`,
                  routeArgs: {
                    paymentMethodId,
                    withVerification: this.props.location.state.instanceStoreStatus === 'paymentMethodVerificationRequired'
                  }
                },
                (error, result) => {
                  if (error) {
                    cb(error, null);
                    return;
                  }
                  if (!this._mounted) {
                    return;
                  }
                  cb(null, null);
                  this.props.history.push('../purchase/terms', this.props.location.state);
                }
              );
            }}
          />
        </div>
      </Card>
    );
  }
}

export default connect(
  () => ({}),
  dispatch => ({
    apiActions: bindActionCreators(apiActions, dispatch),
    navigationActions: bindActionCreators(navigationActions, dispatch)
  })
)(Verifications);
