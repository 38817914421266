import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as apiActions from 'redux/actions/api';

import { Card, Button, Modal, message, Input, Form, Tooltip, Result, Badge } from 'antd';
import { EditOutlined, DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';

class _default extends React.Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    apiActions: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };


  state = {
    stackEdition: {}
  };

  componentDidMount() {
    this._mounted = true;

    const { organizationId } = this.props.match.params;
    const route = `/dashboard/organizations/${organizationId}/stacks`;
    const stacks = this.props.api.subscriptions[route].datas;
    if (!stacks.length) {
      // If no stack has been created
      this.props.history.replace('create/');
    }
  }

  componentDidUpdate() {
    const { organizationId } = this.props.match.params;
    const route = `/dashboard/organizations/${organizationId}/stacks`;
    const stacks = this.props.api.subscriptions[route].datas;
    if (!stacks.length) {
      this.props.history.replace('create/');
    }
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  deleteStack(stack) {
    this.props.apiActions.del(
      {
        route: `/dashboard/stacks/${stack.id}`,
        handleErrorAutomatically: false
      },
      (error, res) => {
        if (!this._mounted) { return; }

        if (error && error.response.status === 403 && error.response.data.message === 'notEmptyStack') {
          Modal.error({
            title: 'You cannot delete this stack',
            content: 'If you really want to delete this stack, you have to delete its services first.'
          });
        }
        else if (error) {
          throw error;
        }
        else {
          message.success(`The stack "${stack.name}" has been deleted`);
        }
      }
    );
  }


  editStack(stack) {
    this.setState(
      state => ({
        stackEdition: state.stackEdition.id === stack.id ? {} : { id: stack.id, name: stack.name }
      }),
    );
  }

  stackNameValid(name) {
    return name.length >= 3 && name.length <= 64;
  }

  stackRename(stack, newName) {
    if (!this.stackNameValid(newName) || stack.name === newName) {
      this.setState({ stackEdition: {} });
      return;
    }

    this.props.apiActions.patch(
      {
        route: `/dashboard/stacks/${stack.id}`,
        routeArgs: { newName }
      },
      () => {
        if (!this._mounted) { return; }
        message.success('This stack has been renamed');
        this.setState({ stackEdition: {} });
      }
    );
  }


  renderEmpty() {
    return (
      <Card>
        <Result
          title="You don't have any stack created yet"
          extra={this.renderCreateButton()}
        />
      </Card>
    );
  }


  renderExtra(stack) {
    const deleteVisibled = stack.userPermissions.indexOf('stackDelete') !== -1;
    const editVisibled = stack.userPermissions.indexOf('stackConfigure') !== -1;

    return [
      deleteVisibled
        ? (
          <Tooltip key="edit" title="Edit this stack name">
            <Button
              type="text"
              icon={ <EditOutlined /> }
              onClick={e => { e.preventDefault(); e.stopPropagation(); this.editStack(stack); }}
              block
            />
          </Tooltip>
        )
        : null,

      editVisibled
        ? (
          <Tooltip
            key="delete"
            title={stack.servicesCount === 0 ? 'Delete this stack' : 'To delete this stack you have to first delete all services in it'}
          >
            <Button
              type="text"
              icon={ <DeleteOutlined /> }
              onClick={e => { e.preventDefault(); e.stopPropagation(); this.deleteStack(stack); }}
              disabled={stack.servicesCount !== 0}
              block
            />
          </Tooltip>
        )
        : null
    ].filter(v => v);
  }


  renderDescription(stack) {
    if (!stack.servicesCount) {
      return ('This stack doesn\'t contain any services yet');
    }
    else if (stack.servicesCount === 1) {
      return ('This stack contains 1 service');
    }
    else {
      return (`This stack contains ${stack.servicesCount} services`);
    }
  }


  renderTitle(stack) {
    if (this.state.stackEdition.id !== stack.id) {
      return stack.name;
    }
    return (
      <Form.Item
        validateStatus={this.stackNameValid(this.state.stackEdition.name) ? 'success' : 'error'}
        style={{ margin: 0 }}
      >
        <Input
          autoFocus
          value={this.state.stackEdition.name}
          onChange={e => {
            const name = e.target.value;
            this.setState(state => ({ stackEdition: { ...state.stackEdition, name } }));
          }}
          onBlur={() => this.stackRename(stack, this.state.stackEdition.name)}
          onPressEnter={() => this.stackRename(stack, this.state.stackEdition.name)}
        />
      </Form.Item>
    );
  }

  renderStack(stack) {
    const card = (
      <Card
        title={this.renderTitle(stack)}
        style={{ marginBottom: 16 }}
        hoverable
        actions={this.renderExtra(stack)}
      >
        {this.renderDescription(stack)}
      </Card>
    );

    const { servicesUpdatesAvailableCount } = stack;
    const updatesContainer = servicesUpdatesAvailableCount
      ? (
        <Badge.Ribbon text={servicesUpdatesAvailableCount === 1 ? 'One update is available' : `${servicesUpdatesAvailableCount} updates are available`}>
          {card}
        </Badge.Ribbon>
      )
      : card;

    return (
      <Link to={`${stack.id}/`}>
        {updatesContainer}
      </Link>
    );
  }


  renderStacks() {
    const { organizationId } = this.props.match.params;
    const route = `/dashboard/organizations/${organizationId}/stacks`;
    const stacks = this.props.api.subscriptions[route].datas;
    return (
      <>
        {stacks.map((stack, index) => (<React.Fragment key={stack.id}>{this.renderStack(stack)}</React.Fragment>))}
        <br />
        {this.renderCreateButton()}
      </>
    );
  }

  renderCreateButton() {
    // Hide if no permission to do this
    const { organizationId } = this.props.match.params;
    const { userPermissions } = this.props.api.subscriptions[`/dashboard/organizations/${organizationId}`].datas;
    if (userPermissions.indexOf('stacksCreate') === -1) {
      return null;
    }

    return (
      <div style={{ width: '100%', textAlign: 'center' }}>
        <Button
          type="primary"
          size="large"
          icon={ <PlusCircleOutlined /> }
          onClick={() => this.props.history.push('create/')}
        >
          Create a new stack
        </Button>
      </div>
    );
  }


  render() {
    const { organizationId } = this.props.match.params;
    const route = `/dashboard/organizations/${organizationId}/stacks`;
    const stacks = this.props.api.subscriptions[route].datas;
    return stacks.length
      ? this.renderStacks()
      : this.renderEmpty();
  }
}

export default connect(
  ({ api }) => ({ api }),
  dispatch => ({
    apiActions: bindActionCreators(apiActions, dispatch)
  })
)(_default);
