import React from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as apiActions from 'redux/actions/api';
import * as navigationActions from 'redux/actions/navigation';

import { Card, Button, Form } from 'antd';
import FormItems from 'components/FormItems';
import countries from 'contents/countries';

class Organization extends React.Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    apiActions: PropTypes.object.isRequired,
    navigationActions: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };

  state = {
    formIsValid: false,
    form: {},
    showErrors: false
  };

  componentDidMount() {
    this._mounted = true;
    this.props.navigationActions.stepSelectByLabel('organization');

    const { organizationId } = this.props.match.params;
    const organization = this.props.api.subscriptions[`/dashboard/organizations/${organizationId}`].datas;
    if (organization.name) {
      this.props.history.replace('../purchase/Verifications', this.props.location.state);
    }
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  handleSubmit = () => {
    this.setState({ showErrors: true });
    if (!this.state.formIsValid) {
      return false;
    }

    const { organizationId } = this.props.match.params;
    const { name, address, city, countryCode, vatNumber, postalCode } = this.state.form;
    this.props.apiActions.patch(
      {
        route: `/dashboard/organizations/${organizationId}`,
        routeArgs: { name, address, city, countryCode, vatNumber, postalCode }
      },
      (error, result) => {
        if (error || !this._mounted) {
          return;
        }
        this.props.history.push('../purchase/Verifications', this.props.location.state);
      }
    );
  };

  render() {
    const entries = [
      {
        label: 'Name',
        name: 'name',
        required: true,
        input: {
          type: 'text',
          placeholder: 'Your organization name',
          hasError: (name, value, form) => value.length < 1 || value.length > 32 ? 'Should be between 1 and 32 characters' : '',
          autoComplete: 'organization'
        }
      },
      {
        label: 'Address',
        name: 'address',
        required: true,
        input: {
          type: 'textarea',
          placeholder: 'Organization address',
          hasError: (name, value, form) => value.length < 1 || value.length > 128 ? 'Should be between 1 and 128 characters' : '',
          autoComplete: 'street-address'
        }
      },
      {
        label: 'Postal code',
        name: 'postalCode',
        required: true,
        input: {
          type: 'text',
          placeholder: 'Organization postal code',
          hasError: (name, value, form) => value.length < 2 || value.length > 16 ? 'Should be between 2 and 16 characters' : '',
          autoComplete: 'postal-code'
        }
      },
      {
        label: 'City',
        name: 'city',
        required: true,
        input: {
          type: 'text',
          placeholder: 'Organization city',
          hasError: (name, value, form) => value.length < 1 || value.length > 32 ? 'Should be between 1 and 32 characters' : ''
        }
      },
      {
        label: 'Country',
        name: 'countryCode',
        required: true,
        input: {
          type: 'select',
          placeholder: 'Select your country',
          entries: countries.namesAndCodes.map(([ label, id ]) => ({ id, label })),
          hasError: (name, value, form) => countries.namesAndCodes.find(([ /* null */, id ]) => id === value) ? '' : 'You should select your country'
        }
      },

      this.state.form.countryCode
        && countries.vatNumbersFormats[this.state.form.countryCode]
        ? {
          label: 'VAT number',
          name: 'vatNumber',
          required: false,
          input: {
            type: 'text',
            placeholder: countries.vatNumbersFormats[this.state.form.countryCode][1],
            hasError: (name, value, form) => !value || countries.vatNumbersFormats[this.state.form.countryCode][0].test(value) ? '' : 'Format should be ' + countries.vatNumbersFormats[this.state.form.countryCode][1]
          }
        }
        : null,
    ].filter(v => v !== null);

    return (
      <Card title="Your organization">
        <Form onFinish={this.handleSubmit}>
          <FormItems
            entries={entries}
            onStatusChange={formIsValid => this.setState({ formIsValid })}
            datas={this.state.form}
            onUpdate={form => this.setState({ form })}
            showErrors={this.state.showErrors}
          />

          <Form.Item style={{ textAlign: 'right', marginBottom: 0 }}>
            <Button type="primary" htmlType="submit">Continue</Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}

export default connect(
  ({ api }) => ({ api }),
  dispatch => ({
    apiActions: bindActionCreators(apiActions, dispatch),
    navigationActions: bindActionCreators(navigationActions, dispatch)
  })
)(Organization);
