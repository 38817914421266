import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Markdown from 'components/Markdown';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as apiActions from 'redux/actions/api';
import * as navigationActions from 'redux/actions/navigation';

import { Card, Badge, Spin, Button } from 'antd';
import { FileTextOutlined } from '@ant-design/icons';

import LinkLayout from 'components/Link';

const images = require.context('images/servicesLogos/', true);

News.propTypes = {
  api: PropTypes.object.isRequired,
  app: PropTypes.object.isRequired,
  apiActions: PropTypes.object.isRequired,
  navigationActions: PropTypes.object.isRequired
};

function News(props) {
  const route = '/dashboard/account/news';

  useEffect(
    () => {
      props.navigationActions.breadcrumbAdd(renderBreadcrumb(), null, 'news');

      props.apiActions.subscribe({
        route,
        routeArgs: {},
        refreshInterval: 20 * 1000,
        spinner: false,
        handleErrorAutomatically: false
      });

      // Cleanup code to run on component unmount
      return () => {
        props.apiActions.unsubscribe({
          route
        });

        props.navigationActions.breadcrumbRemove('news');
      };
    },
    []
  );

  const loaded = props.api.subscriptions[route] && props.api.subscriptions[route].status !== 'initialize';
  const newsContent = props.api.subscriptions[route]?.datas || [];

  function renderBreadcrumb() {
    return (
      <>
        <FileTextOutlined />
        &nbsp;
        Stackhero's Latest News
      </>
    );
  }

  function renderNewsCard(news) {
    return (
      <Card
        title={(
          <>
            {<img
              src={images(`./${news.service?.toLowerCase() || 'stackhero'}.svg`, true).default}
              alt={news.service || 'Stackhero'}
              style={{ height: 18, maxWidth: 18, marginRight: 8 }}
            />}
            {news.title}
          </>
        )}
        style={{ marginBottom: '24px' }}
        actions={news.service
          ? [(
            <div key={0} style={{ textAlign: 'right' }}>
              <LinkLayout url={`${process.env.HTTP_SCHEME}://${process.env.SHOWCASE_HOST}/${props.app.languageAndRegion}/services/${news.service}/benefits`}>
                <Button type="link" size="default">More about {news.service}</Button>
              </LinkLayout>
            </div>
          )]
          : []
        }
      >
        <Markdown
          content={news.description}
          style={{ fontSize: '1.15em' }}
        />
      </Card>
    );
  }

  function renderNews(news) {
    if (news.unread) {
      return (
        <Badge.Ribbon
          text="New"
          color="green"
        >
          {renderNewsCard(news)}
        </Badge.Ribbon>
      );
    }
    else {
      return renderNewsCard(news);
    }
  }

  return (
    <Spin spinning={!loaded}>
      {
        newsContent.map((news, index) => (
          <React.Fragment key={index}>
            {renderNews(news)}
          </React.Fragment>
        ))
      }
    </Spin>
  );
}

export default connect(
  ({ api, app }) => ({ api, app }),
  dispatch => ({
    apiActions: bindActionCreators(apiActions, dispatch),
    navigationActions: bindActionCreators(navigationActions, dispatch)
  })
)(News);