import React from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as apiActions from 'redux/actions/api';

import sha512 from 'hash.js/lib/hash/sha/512';
import Qrcode from 'qrcode.react';

import { Form, Button, Modal, Alert, message } from 'antd';

import FormItems from 'components/FormItems';

// Taken from https://github.com/agnoster/base32-js/blob/master/lib/base32.js
const base32Encode = data => {
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ234567';

  let bits = 0;
  let value = 0;
  let output = '';

  for (let i = 0; i < data.byteLength; i++) {
    value = (value << 8) | data[i];
    bits += 8;

    while (bits >= 5) {
      output += alphabet[(value >>> (bits - 5)) & 31];
      bits -= 5;
    }
  }

  if (bits > 0) {
    output += alphabet[(value << (5 - bits)) & 31];
  }

  // Padding
  while ((output.length % 8) !== 0) {
    output += '=';
  }

  return output;
};

class ModalTotp extends React.Component {
  static propTypes = {
    apiActions: PropTypes.object.isRequired,
    email: PropTypes.string.isRequired,
    emailValidated: PropTypes.bool.isRequired,
    totpEnabled: PropTypes.bool.isRequired
  };

  state = this.generateState();

  UNSAFE_componentWillMount() {
    this._mounted = true;
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  generateState() {
    return {
      modalVisible: false,
      formIsValid: false,
      form: {},
      error: null,
      showErrors: false,
      // Note: secretKey has to be in base32 (A-Z and 2-7).
      // Google Authenticator is stupid (it seems to be the same for 1Password). See https://github.com/yeojz/otplib#google-authenticator
      // This is why we use SHA1 and a secret with 20 bytes.
      secretKey: base32Encode(window.crypto.getRandomValues(new Uint8Array(20)))
    };
  }

  show = () => {
    this.setState({
      modalVisible: true,
      formIsValid: false,
      form: {},
      error: null
    });
  };


  handleOk = () => {
    this.setState({ showErrors: true });
    if (!this.state.formIsValid) {
      return false;
    }

    this.setState({ error: null });

    const { confirmationCode, password } = this.state.form;

    const passwordPreHashed = sha512().update(password + 'stackhero' + this.props.email).digest('hex');

    if (this.props.totpEnabled) {
      this.props.apiActions.del(
        {
          route: '/dashboard/account/totp',
          routeArgs: { passwordPreHashed },
          handleErrorAutomatically: false
        },
        (error, result) => {
          if (!this._mounted) { return false; }

          if (error) {
            const errors = {
              authenticationFailed: 'Your password is not the good one'
            };

            this.setState({ error: errors[error] });
            return;
          }

          this.setState(this.generateState());
        }
      );
    }
    else {
      this.props.apiActions.post(
        {
          route: '/dashboard/account/totp',
          routeArgs: { secretKey: this.state.secretKey, confirmationCode: confirmationCode.replace('-', ''), passwordPreHashed },
          handleErrorAutomatically: false
        },
        (error, result) => {
          if (!this._mounted) { return false; }

          if (error) {
            const errors = {
              authenticationFailed: 'Your password is not the good one',
              confirmationCodeWrong: 'The 6 digits code is not correct'
            };

            if (!errors[error]) {
              throw error;
            }
            this.setState({ error: errors[error] });
            return;
          }

          this.setState(this.generateState());
        }
      );
    }
  };


  handleCancel = () => {
    this.setState(this.generateState());
  };


  handleSubmit = (e) => {
    this.handleOk();
  };


  renderError() {
    if (!this.state.error) {
      return null;
    }

    return (
      <Form.Item>
        <Alert
          message="Error"
          description={this.state.error}
          type="error"
          showIcon
        />
      </Form.Item>
    );
  }


  renderFooter = () => {
    return [
      ( <Button key="cancel" onClick={this.handleCancel}>Cancel</Button> ),
      (
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          onClick={this.handleOk}
        >
          {this.props.totpEnabled ? 'Disable two-factor authentication' : 'Enable two-factor authentication'}
        </Button>
      )
    ];
  };


  render() {
    const entries = this.props.totpEnabled
      ? [
          {
            label: 'Enter your account password',
            name: 'password',
            required: true,
            input: {
              type: 'password',
              hasError: (name, value, form) => value.length < 8 || value.length > 72 ? 'Should be between 8 and 72 characters' : ''
            }
          }
        ]
      : [
          {
            label: '1. Scan this QR code using your favorite app',
            name: 'qrCode',
            input: {
              type: 'element',
              entry: (
                <div style={{ display: 'block', margin: '16px auto', textAlign: 'center' }}>
                  <Qrcode
                    value={`otpauth://totp/Stackhero:${this.props.email}?secret=${this.state.secretKey}&issuer=Stackhero&algorithm=SHA1&digits=6&period=30`}
                    size={300}
                    bgColor="#fff"
                    fgColor="#333"
                    level="M"
                    includeMargin={false}
                    renderAs="svg"
                  />
                  <br />
                  <span
                    title="Click to copy"
                    onMouseDown={() => {
                      const el = document.createElement('textarea');
                      el.value = this.state.secretKey;
                      el.style = { visible: false };
                      document.body.appendChild(el);
                      el.select();
                      document.execCommand('copy');
                      document.body.removeChild(el);
                      message.info('The 2FA code has been copied to your clipboard');
                    }}
                    role="button"
                    tabIndex={0}
                    style={{ cursor: 'copy' }}
                  >
                  Or click here to copy the code to your clipboard.
                  </span>
                </div>
              )
            }
          },
          {
            label: '2. Enter the 6 digits code generated by your app',
            name: 'confirmationCode',
            required: true,
            input: {
              type: 'text',
              onChange: value => value.replace(/^([0-9]{3})-?([0-9]+)$/, '$1-$2').replace(/^(.*)-$/, '$1'),
              hasError: (name, value, form) => /^[0-9]{3}-[0-9]{3}$/.test(value)
                ? ''
                : 'Should be between 6 digits',
              autoComplete: 'one-time-code'
            }
          },
          {
            label: '3. Enter your account password',
            name: 'password',
            required: true,
            input: {
              type: 'password',
              hasError: (name, value, form) => value.length < 8 || value.length > 72 ? 'Should be between 8 and 72 characters' : '',
              autoComplete: 'current-password'
            }
          }
        ];
    return [
      (
        <Modal
          key="modal"
          title={this.props.totpEnabled ? 'Disable two-factor authentication' : 'Enable two-factor authentication'}
          open={this.state.modalVisible}
          onCancel={this.handleCancel}
          footer={this.renderFooter()}
        >
          <Form onFinish={this.handleSubmit} layout="vertical">
            {this.renderError()}
            <FormItems
              sizes={{ xxl: 24, xl: 24, lg: 24, md: 24, sm: 24, xs: 24 }}
              entries={entries}
              onStatusChange={formIsValid => this.setState({ formIsValid })}
              datas={this.state.form}
              onUpdate={form => this.setState({ form })}
              showErrors={this.state.showErrors}
            />

            {/* To handle the submission on enter key */}
            <Button htmlType="submit" style={{ display: 'none' }} />
          </Form>
        </Modal>
      )
      ,
      (
        <Button
          key="button"
          size="small"
          onClick={() => this.show() }
          disabled={!this.props.emailValidated}
          title={this.props.emailValidated ? '' : 'You need to validate your email address before activating 2FA'}
        >
          {this.props.totpEnabled ? 'Disable 2FA' : 'Enable 2FA'}
        </Button>
      )
    ];
  }
}

export default connect(
  () => ({}),
  dispatch => ({
    apiActions: bindActionCreators(apiActions, dispatch)
  })
)(ModalTotp);
