import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import jsMd5 from 'js-md5';

import { UserOutlined, LogoutOutlined } from '@ant-design/icons';
import Dropdown, { DropdownElement } from 'components/Dropdown';

import styles from './MenuAvatar.css';

class MenuAvatar extends React.Component {
  static propTypes = {
    user: PropTypes.object
  };

  state = {
    gravatarUrl: null
  };

  componentDidMount() {
    this.gravatarUrlUpdate(this.props.user.email);
  }


  componentDidUpdate(prevProps) {
    if (prevProps.user.email !== this.props.user.email) {
      this.gravatarUrlUpdate(this.props.user.email);
    }
  }


  gravatarUrlUpdate(email) {
    const emailFormated = email.replace(/\+.*@/, '@'); // From user+service@domain.com to user@domain.com
    const gravatarHash = jsMd5.create().update(emailFormated).hex();
    const gravatarUrl = `https://www.gravatar.com/avatar/${gravatarHash}?d=blank&s=120`;
    this.setState({ gravatarUrl });
  }


  userDropdown = () => {
    const { permissions } = this.props.user;
    const accountVisibled = permissions.indexOf('userUpdate') !== -1;

    return [
      accountVisibled
        ? (
          <DropdownElement key="account">
            <Link to={'/account/'}>
              <UserOutlined />&nbsp;
              Your account
            </Link>
          </DropdownElement>
        )
        : null,
      (
        <DropdownElement key="logout">
          <Link to={'/account/logout/'}>
            <LogoutOutlined style={{ color: '#f04134' }} />&nbsp;
            Logout
          </Link>
        </DropdownElement>
      )
    ];
  };


  render() {
    return (
      <Dropdown elements={this.userDropdown()} placement="right" columns={1} triggeredOnMouseOver>
        <div className={styles.avatarContainer}>
          <div className={styles.avatar}>
            <UserOutlined />
            {this.state.gravatarUrl && <div style={{ backgroundImage: `url(${this.state.gravatarUrl})` }} /> }
          </div>
        </div>
      </Dropdown>
    );
  }
}

export default MenuAvatar;