import React from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Card, Alert } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import * as apiActions from 'redux/actions/api';

import { withTranslation, Trans } from 'react-i18next';

import Utils from 'modules/Utils';
const utils = new Utils();

import FormItems from 'components/FormItems';

class PasswordReset extends React.Component {

  static propTypes = {
    app: PropTypes.object.isRequired,
    apiActions: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired
  };

  state = {
    state: 'todo',
    form: {},
    formIsValid: false,
    showErrors: false,
    error: false
  };


  componentDidMount() {
    this._mounted = true;
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  handleSubmit = () => {
    this.setState({ showErrors: true });
    if (!this.state.formIsValid) {
      return false;
    }

    const { email } = this.state.form;
    const { language } = this.props.app;

    this.props.apiActions.post(
      {
        route: '/passwordResetRequest',
        routeArgs: { email, language }
      },
      (error, response) => {
        if (!this._mounted) { return false; }
        this.setState({ state: 'done' });
      }
    );
  };


  entries = () => {
    const { t } = this.props;
    return [
      {
        name: 'email',
        required: true,
        input: {
          type: 'text',
          onChange: value => value.replace(/ /g, ''),
          prefix: ( <MailOutlined style={{ fontSize: 13 }} /> ),
          placeholder: t('passwordForgotten.email'),
          hasError: (name, value, form) => !utils.emailCheck(value) ? true : '',
          autoComplete: 'email'
        }
      }
    ];
  };

  renderError() {
    if (!this.state.error) {
      return null;
    }

    const { t } = this.props;
    return (
      <Form.Item>
        <Alert
          message={t('passwordForgotten.errorTitle')}
          description={this.state.error}
          type="error"
          showIcon
        />
      </Form.Item>
    );
  }


  renderForm() {
    const { t } = this.props;
    return (
      <Form onFinish={this.handleSubmit}>
        {this.renderError()}

        <FormItems
          sizes={{ xs: 24, sm: 24 }}
          entries={this.entries()}
          onStatusChange={formIsValid => this.setState({ formIsValid })}
          datas={this.state.form}
          onUpdate={form => this.setState({ form })}
          showErrors={this.state.showErrors}
        />

        <Form.Item style={{ marginBottom: 0 }}>
          <Button
            type="primary"
            htmlType="submit"
            style={{ width: '100%' }}
          >
            {t('passwordForgotten.action')}
          </Button>
        </Form.Item>
      </Form>
    );
  }


  renderConfirmation() {
    const { t } = this.props;
    return (
      <span>{t('passwordForgotten.confirmation')}</span>
    );
  }

  render() {
    const { t } = this.props;

    if (this.state.state !== 'done') {
      return (
        <>
          <Card title={t('passwordForgotten.title')} style={{ marginBottom: '30px' }}>
            {this.renderForm()}
          </Card>

          <div style={{ textAlign: 'center' }}>
            <Trans i18nKey="login.noAccount">
              Don't have an account yet? <Link to={'/notLogged/signup'}>Sign Up</Link>
            </Trans>
          </div>
        </>
      );
    }
    else {
      return (
        <Card title={t('passwordForgotten.title')}>
          {this.renderConfirmation()}
        </Card>
      );
    }
  }
}

export default compose(
  connect(
    ({ app }) => ({ app }),
    dispatch => ({
      apiActions: bindActionCreators(apiActions, dispatch)
    })
  ),
  withTranslation()
)(PasswordReset);