import React from 'react';
import PropTypes from 'prop-types';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as appActions from 'redux/actions/app';

import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';

import styles from './Footer.css';

import Dropdown, { DropdownElement } from 'components/Dropdown';

import { GlobalOutlined, EnvironmentOutlined, TwitterOutlined, LinkedinOutlined } from '@ant-design/icons';
import services from 'contents/services';
import ButtonLink from 'components/landings/ButtonLink';
import Link from 'components/Link';
import Markdown from 'components/Markdown';

import countries from 'contents/countries';

class Footer extends React.Component {
  static propTypes = {
    backgroundColor: PropTypes.string.isRequired,
    app: PropTypes.object.isRequired,
    appActions: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    legals: PropTypes.array
  };

  renderServices() {
    if (!services.length) {
      return null;
    }

    const { t } = this.props;
    return [
      ( <h3 key="title">{t('footer.servicesTitle')}</h3> ),
      (
        <div key="content" className={styles.services}>
          {services
            .filter(serviceFunc => serviceFunc({}).hidden !== true)
            .map(serviceFunc => {
              const { name, nameUrl, logo } = serviceFunc({});
              return (
                <Link
                  key={name}
                  url={`${process.env.HTTP_SCHEME}://${process.env.SHOWCASE_HOST}/${this.props.app.languageAndRegion}/services/${nameUrl}/benefits`}
                >
                  <div style={{ backgroundImage: `url(${logo})` }} />
                  <span>{name}</span>
                </Link>
              );
            })
          }
        </div>
      )
    ];
  }

  renderOther() {
    const { t } = this.props;
    const { language, languageAndRegion, region } = this.props.app;

    const regionName = region
      ? countries.namesAndCodes.find(nameAndCode => nameAndCode[1].toLowerCase() === region)[0]
      : t('footer.global');
    return [
      ( <h3 key="title">&nbsp;</h3> ),
      (
        <ul key="content" className={styles.otherContainer}>
          {/* About */}
          <li>
            <Link url={`${process.env.HTTP_SCHEME}://${process.env.SHOWCASE_HOST}/${languageAndRegion}/stackhero/about`}>
              {t('footer.about')}
            </Link>
          </li>

          {/* Terms of service */}
          <li>
            <Link url={`${process.env.HTTP_SCHEME}://${process.env.SHOWCASE_HOST}/${languageAndRegion}/stackhero/terms`}>
              {t('footer.terms')}
            </Link>
          </li>

          {/* Privacy */}
          <li>
            <Link url={`${process.env.HTTP_SCHEME}://${process.env.SHOWCASE_HOST}/${languageAndRegion}/stackhero/privacy`}>
              {t('footer.privacy')}
            </Link>
          </li>

          {/* Certifications */}
          <li>
            <Link url={`${process.env.HTTP_SCHEME}://${process.env.SHOWCASE_HOST}/${languageAndRegion}/stackhero/certifications`}>
              {t('footer.certifications')}
            </Link>
          </li>

          {/* Documentations */}
          <li>
            <Link url={`${process.env.HTTP_SCHEME}://${process.env.SHOWCASE_HOST}/${languageAndRegion}/stackhero/documentations`}>
              {t('footer.documentations')}
            </Link>
          </li>

          {/* Support */}
          <li>
            {this.props.app.intercomAvailable
              ? (
                <ButtonLink
                  onClick={() => window.Intercom('showNewMessage')}
                  colored={false}
                >
                    {t('footer.support')}
                </ButtonLink>
              )
              : (
                <a href="mailto:support@stackhero.io">
                  {t('footer.support')}
                </a>
              )
            }
          </li>

          {/* Status */}
          <li>
            <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/stackheroStatus">Status</a>
          </li>

          {/* Languages */}
          <li className={styles.languagesAndRegions}>
            <Dropdown elements={this.renderLanguages(t)} columns={1}>
              <div className={styles.languagesAndRegionsButton}>
                <GlobalOutlined /> {t('footer.languages')[language]}
              </div>
            </Dropdown>
          </li>

          {/* Regions */}
          <li className={styles.languagesAndRegions}>
            <Dropdown elements={this.renderRegions(t)} columns={1}>
              <div className={styles.languagesAndRegionsButton}>
                <EnvironmentOutlined /> {regionName}
              </div>
            </Dropdown>
          </li>

          {/* Social networks */}
          <li className={styles.socials}>
            <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/stackhero_io"><TwitterOutlined /></a>
            <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/stackhero/"><LinkedinOutlined /></a>
          </li>
        </ul>
      )
    ];
  }

  renderLanguages = t => process.env.SUPPORTED_LANGUAGE
    .map(language => {
      const href = window.location.href
        .replace(RegExp(`^${window.location.origin}`), '')
        .replace(
          RegExp(`^/${this.props.app.languageAndRegion}/`),
          this.props.app.region ? `/${language}-${this.props.app.region}/` : `/${language}/`
        );
      return (
        <DropdownElement
          key={language}
        >
          {/* We put an anchor tag here because because it could improve SEO as we link to multiple languages pages */}
          <a
            href={href}
            onClick={event => {
              event.preventDefault();
              this.props.appActions.languageInit({ language, forced: true });
            }}
          >
            {t('footer.emojiAdd', { emoji: countries.languagesEmojiFlags[language.toUpperCase()], content: t('footer.languages')[language] })}
          </a>
        </DropdownElement>
      );
    });

  renderRegions = (t) => Object.keys(countries.regionsSupported)
    .concat('') // Add empty region, with is global
    .map(code => countries.namesAndCodes.find(nameAndCode => nameAndCode[1] === code) || [ '', '' ])
    .sort((a, b) => a[0].localeCompare(b[0]))
    .map(([ name, code ]) => {
      const href = window.location.href
        .replace(RegExp(`^${window.location.origin}`), '')
        .replace(
          RegExp(`^/${this.props.app.languageAndRegion}/`),
          code ? `/${this.props.app.language}-${code.toLowerCase()}/` : `/${this.props.app.language}/`
        );
      return (
        <DropdownElement key={name}>
          {/* We put an anchor tag here because the goal is for search engines to find other regions' pages. It seems that sitemap file is not followed if we don't have internal links. */}
          <a
            href={href}
            onClick={event => {
              event.preventDefault();
              this.props.appActions.regionInit({ region: code.toLowerCase(), forced: true });
            }}
          >
            {t('footer.emojiAdd', { emoji: countries.emojisFlags[code] || '🌍', content: name || t('footer.global') })}
          </a>
        </DropdownElement>
      );
    });

  render() {
    const { t } = this.props;

    return (
      <footer className={styles.container} style={{ backgroundColor: this.props.backgroundColor }}>
        <div className={styles.columns}>

          {/* Services */}
          <div className={styles.column}>
            {this.renderServices()}
          </div>

          {/* Other */}
          <div className={styles.column}>
            {this.renderOther()}
          </div>
        </div>

        {/* Stackhero is... */}
        <div className={styles.stackheroIs}>
          {t('footer.stackheroIs')}
        </div>

        {/* Legals */}
        <div className={styles.legal}>

          {/* Legals defined dynamically (e.g. for free coupon conditions) */}
          {
            this.props.legals
              ? this.props.legals.map((legal, index) => ( <React.Fragment key={index}>{legal}<br /></React.Fragment> ))
              : null
          }
          <br />

          {/* Legals defined in translation file (services etc...) */}
          {
            <Markdown
              content={
                t('footer.legals', { services: services.map(serviceFunc => serviceFunc({}).name).join(', ') }).join('\n')
              }
            />
          }
        </div>
      </footer>
    );
  }
}

export default compose(
  connect(
    ({ app }) => ({ app }),
    dispatch => ({
      appActions: bindActionCreators(appActions, dispatch)
    })
  ),
  withTranslation(),
  withRouter
)(Footer);
