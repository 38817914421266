import logo from 'images/servicesLogos/redis.svg';
import logoOpenGraph from 'images/servicesLogos/openGraphVersions/redis.png';

import growth from 'images/landings/icons/growth.svg';
import startup from 'images/landings/icons/startup.svg';
import shield from 'images/landings/icons/shield.svg';
import packageSvg from 'images/landings/icons/package.svg';
import website from 'images/landings/icons/website.svg';
import strategy from 'images/landings/icons/strategy.svg';

import checked from 'images/landings/icons/checked.svg';

import imageRedisCommander from 'images/landings/screenshots/redis/redisCommander.png';
import imageServicesNodejsRedisPostgresqlMinio from 'images/landings/screenshots/servicesNodejsRedisPostgresqlMinio.png';

const backgroundColor = '#354253';
const button = {
  color: '#fff',
  backgroundColor: '#b62411'
};

export default ({ t = () => [] }) => ({
  id: 'redis',
  name: 'Redis®*',
  nameUrl: 'Redis',
  title: t('services/redis:title'),
  logo,
  logoOpenGraph,
  button,
  backgroundColor,

  header: {
    title: 'Redis',
    h1: t('services/redis:h1'),
    description: t('services/redis:description'),
    images: [
      { image: imageRedisCommander },
      { image: imageServicesNodejsRedisPostgresqlMinio }
    ]
  },

  explanation: {
    title: t('services/redis:explanation.title'),
    content: t('services/redis:explanation.content')
  },

  benefits: {
    items: [
      {
        icon: growth,
        ...t('services/redis:benefits.business')
      },
      {
        icon: startup,
        ...t('services/redis:benefits.performant')
      },
      {
        icon: shield,
        ...t('services/redis:benefits.secured')
      },
      {
        icon: packageSvg,
        ...t('services/redis:benefits.extensible')
      },
      {
        icon: website,
        ...t('services/redis:benefits.simple')
      },
      {
        icon: strategy,
        ...t('services/redis:benefits.updatable')
      }
    ]
  },

  also: {
    color: button.backgroundColor,
    items: t('services/redis:also.items')
      .map(content => ({ icon: checked, ...content }))
  },

  pricing: {
    buttonBackgroundColor: button.backgroundColor,
    buttonColor: button.color,
    sentencesIconColor: button.backgroundColor,
    includes: t('services/redis:pricing.includes')
      .map(description => ({ icon: checked, description })),
    fields: {
      size: v => [v < 1024 * 1024 * 1024 ? `${v / 1024 / 1024} ${t('units.MB')}` : `${Math.round(v / 1024 / 1024 / 1024)} ${t('units.GB')}`, t('services/redis:pricing.subLegends.size') ],
      maxConcurrentConnections: v => [ new Intl.NumberFormat(undefined).format(v), t('services/redis:pricing.subLegends.maxConcurrentConnections') ],
      maxDatabases: v => [ new Intl.NumberFormat(undefined).format(v), t('services/redis:pricing.subLegends.maxDatabases') ],
      persistent: v => [ v ? '✅' : '❌', t('services/redis:pricing.subLegends.persistent') ]
    }
  },

  recall: {
    description: t('services/redis:recall')
  },

  faq: {
    items: t('services/redis:faq.items')
  }
});
