import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as navigationActions from 'redux/actions/navigation';

import { Card, List, Row, Col, Alert, Button, Modal } from 'antd';

import ServiceTitle from 'components/ServiceTitle';
import MarkdownSimple from 'components/MarkdownSimple';

class _default extends React.Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    navigationActions: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };

  componentDidMount() {
    this.props.navigationActions.stepSelect(0);
  }


  // renderServicePack(servicePackStore, index) {
  //   const alerts = {
  //     experimental: <Alert type="warning" message={'Warning: this service pack is experimental and may not be stable!'} style={{ marginBottom: 20 }} />,
  //     wip: <Alert type="error" message={'This service pack is a WIP and should not be used yet!'} style={{ marginBottom: 20 }} />
  //   };

  //   const alert = alerts[servicePackStore.status] ? alerts[servicePackStore.status] : null;
  //   return (
  //     <List.Item>
  //       <Card
  //         title={( <ServiceTitle title={servicePackStore.informations.title} name={servicePackStore.name} /> )}
  //         extra={`Version ${servicePackStore.version}`}
  //       >
  //         {alert}
  //         <Row>
  //           <Col>
  //             <p>{servicePackStore.informations.description}</p>
  //             <h4>Main features:</h4>
  //             <ul style={{ margin: 0, padding: '0 0 0 22px' }}>
  //               {servicePackStore.informations.features.map((d, i) => ( <li key={i} style={{ marginTop: 2 }}>{d}</li> ))}
  //             </ul>
  //           </Col>
  //         </Row>

  //         <div style={{ marginTop: 10, textAlign: 'center' }}>
  //           <Link to={`servicePackStore/${servicePackStore.id}/`}>
  //             <Button type="primary" style={{ minWidth: 200, whiteSpace: 'normal', height: 'auto', padding: 5 }}>
  //               <>Start {servicePackStore.informations.title}</>
  //             </Button>
  //           </Link>
  //         </div>
  //       </Card>
  //     </List.Item>
  //   );
  // }


  renderServiceButtonUnpaidInvoices(serviceStore) {
    return (
      <Button
        type="dashed"
        style={{ minWidth: 200, whiteSpace: 'normal', height: 'auto', padding: 5 }}
        onClick={() => Modal.error({
          title: 'Unpaid invoices',
          content: 'You can\'t start a new service while having unpaid invoices. Please resolve this issue before continuing.'
        })}
      >
        <>Start {serviceStore.informations.title}</>
      </Button>
    );
  }


  renderServiceButtonSuspended(serviceStore) {
    return (
      <Button
        type="dashed"
        style={{ minWidth: 200, whiteSpace: 'normal', height: 'auto', padding: 5 }}
        onClick={() => Modal.error({
          title: 'Suspended organization',
          content: 'You can\'t start a new service while being suspended. Please resolve this issue before continuing.'
        })}
      >
        <>Start {serviceStore.informations.title}</>
      </Button>
    );
  }


  renderServiceButton(serviceStore) {
    return (
      <Link to={`serviceStore/${serviceStore.id}/`}>
        <Button type="primary" style={{ minWidth: 200, whiteSpace: 'normal', height: 'auto', padding: 5 }}>
          <>Start {serviceStore.informations.title}</>
        </Button>
      </Link>
    );
  }


  renderService(serviceStore) {
    const alerts = {
      experimental: <Alert type="warning" message={'Warning: this service is experimental and may not be stable!'} style={{ marginBottom: 20 }} />,
      wip: <Alert type="error" message={'This service is a WIP and should not be used yet!'} style={{ marginBottom: 20 }} />
    };
    const alert = alerts[serviceStore.status] ? alerts[serviceStore.status] : null;

    const { organizationId } = this.props.match.params;
    const { hasUnpaidInvoices, isSuspended } = this.props.api.subscriptions[`/dashboard/organizations/${organizationId}`].datas;



    const button = hasUnpaidInvoices
      ? this.renderServiceButtonUnpaidInvoices(serviceStore)
      : isSuspended
        ? this.renderServiceButtonSuspended(serviceStore)
        : this.renderServiceButton(serviceStore);

    return (
      <List.Item>
        <Card
          title={( <ServiceTitle title={serviceStore.informations.title} name={serviceStore.name} /> )}
          extra={`Version ${serviceStore.version}`}
        >
          {alert}
          <Row>
            <Col>
              <p>{serviceStore.informations.description}</p>
              <h4 style={{ fontWeight: 'bold' }}>Main features:</h4>
              <ul style={{ margin: 0, padding: '0 0 0 22px' }}>
                {serviceStore.informations.features.map((feature, index) => (
                  <li key={index} style={{ marginTop: 2 }}>
                    <MarkdownSimple content={feature} />
                  </li>
                ))}
              </ul>
            </Col>
          </Row>

          <div style={{ marginTop: 10, textAlign: 'center' }}>
            {button}
          </div>
        </Card>
      </List.Item>
    );
  }


  render() {
    const { organizationId } = this.props.match.params;
    const routeServicesStore = `/dashboard/organizations/${organizationId}/servicesStore`;
    const servicesStore = this.props.api.subscriptions[routeServicesStore].datas;

    // const routeServicesPacksStore = `/dashboard/organizations/${organizationId}/servicesPacksStore`;
    // const servicesPacksStore = this.props.api.subscriptions[routeServicesPacksStore].datas;

    const toRender = [];

    // if (servicesPacksStore && servicesPacksStore.length) {
    //   toRender.push(
    //     <Card key="servicesPacksStore" title="Services packs" style={{ backgroundColor: '#fafafa', marginBottom: 16 }}>
    //       <List
    //         grid={{ gutter: 16, column: 1 }}
    //         dataSource={servicesPacksStore}
    //         renderItem={(servicesPacksStore, index) => this.renderServicePack(servicesPacksStore, index)}
    //       />
    //     </Card>
    //   );
    // }

    if (servicesStore && servicesStore.length) {
      toRender.push(
        <Card key="servicesStore" title="Services" style={{ backgroundColor: '#fafafa' }}>
          <List
            grid={{ gutter: 16, column: 1 }}
            dataSource={servicesStore}
            renderItem={(serviceStore, index) => this.renderService(serviceStore, index)}
          />
        </Card>
      );
    }

    return toRender;
  }
}

export default connect(
  ({ api }) => ({ api }),
  dispatch => ({
    navigationActions: bindActionCreators(navigationActions, dispatch)
  })
)(_default);
