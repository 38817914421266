import React from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Card, Alert } from 'antd';
import { MailOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import sha512 from 'hash.js/lib/hash/sha/512';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import * as apiActions from 'redux/actions/api';
import * as appActions from 'redux/actions/app';

import { withTranslation, Trans } from 'react-i18next';

import Utils from 'modules/Utils';
const utils = new Utils();

import FormItems from 'components/FormItems';

class SignUp extends React.Component {

  static propTypes = {
    app: PropTypes.object.isRequired,
    apiActions: PropTypes.object.isRequired,
    appActions: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired
  };

  state = {
    error: false,
    form: {},
    formIsValid: false,
    showErrors: false,
    captcha: null,
    loaded: false
  };


  componentDidMount() {
    this._mounted = true;
    this.captchaGet();
  }


  componentWillUnmount() {
    this._mounted = false;
  }


  captchaGet = () => {
    this.props.apiActions.get(
      {
        route: '/captcha',
        routeArgs: {}
      },
      (error, captcha) => {
        if (!this._mounted) { return; }
        this.setState({ captcha, loaded: true });
      }
    );
  };


  handleSubmit = () => {
    this.setState({ showErrors: true });
    if (!this.state.formIsValid) {
      return false;
    }

    const { name, email, password, captchaCode } = this.state.form;
    const { currency, language, region } = this.props.app;
    const { id: captchaId } = this.state.captcha;
    const passwordPreHashed = sha512().update(password + 'stackhero' + email).digest('hex');
    const locale = region ? `${language}-${region.toUpperCase()}` : language;

    this.props.apiActions.post(
      {
        route: '',
        routeArgs: { name, email, passwordPreHashed, currency, locale, language, captchaCode, captchaId },
        handleErrorAutomatically: false
      },
      (error, result) => {
        if (!this._mounted) { return; }

        if (error === 'userExistsYet') {
          // If user exists yet, we redirect to login page and add credentials to auto log the user
          this.props.history.replace('/notLogged/login', { email, password });
          return;
        }
        else if (error === 'captchaInvalid') {
          const { t } = this.props;
          this.setState(
            {
              error: t('signup.errorCaptchaInvalid'),
              loaded: false
            },
            () => this.captchaGet()
          );
          return;
        }
        else if (error) {
          throw Error(error);
        }
        else {
          const { username, password, intercomAuthHash, organizationId, stackId, cookieMaxAge } = result;
          this.props.appActions.sessionSetDispatch({
            username,
            password,
            intercomAuthHash,
            cookieMaxAge
          });
          this.props.history.replace(`/account/organizations/${organizationId}/stacks/${stackId}/services/create/`);
        }
      }
    );
  };


  renderError() {
    const { t } = this.props;
    return (
      <Form.Item>
        <Alert
          message={t('signup.errorTitle')}
          description={this.state.error}
          type="error"
          showIcon
        />
      </Form.Item>
    );
  }


  entries = () => {
    const { t } = this.props;
    return [
      {
        name: 'name',
        required: true,
        input: {
          type: 'text',
          prefix: ( <UserOutlined /> ),
          placeholder: t('signup.name.placeholder'),
          hasError: (name, value, form) => value.length < 1 || value.length > 128 ? t('signup.name.error') : '',
          autoComplete: 'name'
        }
      },
      {
        name: 'email',
        required: true,
        input: {
          type: 'text',
          onChange: value => value.replace(/ /g, ''),
          prefix: ( <MailOutlined /> ),
          placeholder: t('signup.email.placeholder'),
          hasError: (name, value, form) => !utils.emailCheck(value) ? t('signup.email.error') : '',
          autoComplete: 'email'
        }
      },
      {
        name: 'password',
        required: true,
        input: {
          type: 'password',
          prefix: ( <LockOutlined /> ),
          placeholder: t('signup.password.placeholder'),
          hasError: (name, value, form) => value.length < 8 || value.length > 72 ? t('signup.password.error') : '',
          autoComplete: 'new-password'
        }
      },
      {
        name: 'captchaCode',
        required: true,
        input: {
          type: 'text',
          prefix: ( <img src={this.state.captcha.imageBase64} alt={t('signup.captcha.placeholder')} /> ),
          placeholder: t('signup.captcha.placeholder'),
          onChange: value => value.toUpperCase(),
          hasError: (name, value, form) => value.length !== 6 ? t('signup.captcha.error') : ''
        }
      }
    ];
  };


  render() {
    if (!this.state.loaded) {
      return null;
    }

    const { t } = this.props;
    return (
      <>
        <Card title={t('signup.createAccount')} style={{ marginBottom: '30px' }}>
          <Form onFinish={this.handleSubmit} layout="vertical">

            {this.state.error
              ? this.renderError()
              : (
                <Form.Item>
                  <Alert
                    message={t('signup.welcomeMessage.title')}
                    description={t('signup.welcomeMessage.content')}
                    type="info"
                    showIcon
                  />
                </Form.Item>
              )
            }

            <FormItems
              sizes={{ xxl: 24, xl: 24, lg: 24, md: 24, sm: 24, xs: 24 }}
              entries={this.entries()}
              onStatusChange={formIsValid => this.setState({ formIsValid })}
              datas={this.state.form}
              onUpdate={form => this.setState({ form })}
              showErrors={this.state.showErrors}
            />

            <Form.Item style={{ marginBottom: 0 }}>
              <Button
                type="primary"
                htmlType="submit"
                block
              >
                {t('signup.action')}
              </Button>
            </Form.Item>
          </Form>
        </Card>

        <div style={{ textAlign: 'center' }}>
          <Trans i18nKey="signup.haveAnAccount">
            Have an account yet? <Link to={'/notLogged/login'}>Log In</Link>
          </Trans>
        </div>
      </>
    );
  }
}

export default compose(
  connect(
    ({ app }) => ({ app }),
    dispatch => ({
      apiActions: bindActionCreators(apiActions, dispatch),
      appActions: bindActionCreators(appActions, dispatch)
    })
  ),
  withTranslation()
)(SignUp);
