import React from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as apiActions from 'redux/actions/api';
import * as appActions from 'redux/actions/app';

import { ConfigProvider, Modal, Spin, Alert, Button } from 'antd';

const localesModules = {
  'en': require('antd/locale/en_US'),
  'fr': require('antd/locale/fr_FR')
};

import Spinner from 'components/Spinner';

import i18n from 'i18next';
import XHR from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

import Intercom from 'components/Intercom';

import { Helmet } from 'react-helmet';

class _container extends React.Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    apiActions: PropTypes.object.isRequired,
    app: PropTypes.object.isRequired,
    appActions: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired
  };

  state = {
    isReady: false
  };

  componentDidMount() {
    this._mounted = true;
    // Redirect if the domain is not the good one
    if (document.location.host !== process.env.DASHBOARD_HOST) {
      document.location.href = document.location.href
        .replace(document.location.origin, `${process.env.HTTP_SCHEME}://${process.env.DASHBOARD_HOST}`);
      return;
    }

    this.props.appActions.initialize({ withSession: true });
    this.props.appActions.regionInit({ region: '' });
    this.loadLanguage(this.props.app.language);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.app.language !== this.props.app.language) {
      this.loadLanguage(this.props.app.language);
    }
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  loadLanguage(language) {
    if (!language) {
      return;
    }

    i18n
      .use(XHR)
      .use(initReactI18next)

      // Remove emoji flags for Windows users (as it doesn't support such emojis) except for Firefox users (as it includes an emoji font that supports flags).
      .use({
        type: 'postProcessor',
        name: 'emojiFlagsRemover',
        process: (value, key, options, translator) =>
          /Windows/.test(navigator.userAgent) && !/Firefox/.test(navigator.userAgent)
            ? value.replace(/[\uD83C][\uDDE6-\uDDFF][\uD83C][\uDDE6-\uDDFF]/g, '')
            : value
      })

      .init(
        {
          postProcess: [ 'emojiFlagsRemover' ],

          // XHR
          backend: {
            loadPath: (languages, namespaces) => `/locales/{{ns}}.{{lng}}.json?${process.env.VERSION}`
          },


          interpolation: {
            escapeValue: false // react already safes from xss
          },
          initImmediate: false,

          lng: language,
          fallbackLng: false, // Do not put "en" else it will load "en" language on start

          // Allow to store objects or arrays in translations
          returnObjects: true,

          // Send a log to console if a key is missing
          saveMissing: true,
          missingKeyHandler: (lng, ns, key, fallbackValue) => console.warn(`Missing translation ${lng}/${ns}:${key}`),

          react: {
            useSuspense: true,
            bindI18n: 'languageChanged loaded',
            bindStore: 'added removed',
            nsMode: 'default'
          }
        },
        () => this._mounted && this.setState({ isReady: true })
      );
  }


  renderLoading() {
    if (this.props.api.loadingCount === 0) {
      return null;
    }
    return ( <Spinner /> );
  }

  renderError() {
    if (!this.props.api.error.type) {
      return null;
    }

    const errorsGeneric = {
      hasUnpaidInvoices: {
        title: 'Can\'t execute this action',
        content: 'You can\'t execute this action while having unpaid invoices.'
      },
      isSuspended: {
        title: 'Can\'t execute this action',
        content: 'You can\'t execute this action while being suspended.'
      },
      Forbidden: {
        title: 'Can\'t execute this action',
        content: 'You don\'t have the permission to execute this action.'
      }
    };


    if (this.props.api.error.type === 'network') {
      return (
        <Modal
          title="It seems that we can't connect to our servers :-/"
          open
          footer={null}
          closable={false}
        >
          <Spin />&nbsp;&nbsp;&nbsp;Trying to reconnect...
        </Modal>
      );
    }
    else if (this.props.api.error.type === 'maintenance') {
      return (
        <Modal
          title="A maintenance is in progress..."
          open
          footer={null}
          closable={false}
        >
          <Spin />&nbsp;&nbsp;&nbsp;Please hold on, this page will refresh automatically as soon as the maintenance is completed.
        </Modal>
      );
    }
    else if (this.props.api.error.data && errorsGeneric[this.props.api.error.data.message]) {
      const { title, content } = errorsGeneric[this.props.api.error.data.message];
      return (
        <Modal
          title={title}
          open
          footer={ <Button onClick={this.props.apiActions.errorSet.bind(this, {})}>Close</Button> }
          onCancel={this.props.apiActions.errorSet.bind(this, {})}
        >
          <Alert message={content} type="error" />
        </Modal>
      );
    }
    else if (this.props.api.error.type === 'api') {
      const { reason } = this.props.api.error;
      const reasonText = reason.message ? reason.message : reason;
      const message = this.props.api.error.data && this.props.api.error.data.data
        ? this.props.api.error.data.data
        : reasonText
          .split('\n')
          .map((item, index) => ( <span key={index}>{item}<br /></span> ));
      return (
        <Modal
          title="An error occured"
          open
          footer={ <Button onClick={this.props.apiActions.errorSet.bind(this, {})}>Close</Button> }
          onCancel={this.props.apiActions.errorSet.bind(this, {})}
        >
          <Alert message={(<span>{message}</span>)} type="warning" />
        </Modal>
      );
    }
    else {
      const message = this.props.api.error.reason
        .split('\n')
        .map((item, index) => ( <span key={index}>{item}<br /></span> ));

      return (
        <Modal
          title="An unknown error occured"
          open
          footer={ <Button type="primary" onClick={() => document.location.reload()}>Reload page</Button> }
          onCancel={ () => document.location.reload() }
        >
          <Alert message={message} type="error" />
          <br />
          We apologise for this inconvenient.
          <br />
          If you still encounter this error, please send a message to the support and we'll resolve this issue.
        </Modal>
      );
    }
  }

  render() {
    if (!this.state.isReady || !this.props.app.initialized) {
      if (this.props.api && this.props.api.error && this.props.api.error.type) {
        return this.renderError();
      }

      return ( <Spinner /> );
    }

    const baseUrl = window.location.origin;
    const { language, region } = this.props.app;

    return (
      <ConfigProvider
        locale={localesModules[language]}
        theme={{
          token: {
            colorTextBase: '#333'
          }
        }}
      >
        <>
          {/* Note: alway before "{this.props.children}" to let children the ability to overwrite those data */}
          <Helmet
            htmlAttributes={{ lang: region ? `${language}-${region}` : language }}
          >
            <title>{i18n.t('header.title')}</title>

            <meta name="description" content={i18n.t('header.description').replace(/\*\*/g, '')} />

            <meta property="og:site_name" content="Stackhero" />

            <meta property="og:title" content={i18n.t('header.opengraph.title')} />
            <meta property="og:description" content={i18n.t('header.description').replace(/\*\*/g, '')} />

            <meta property="og:image" content={`${baseUrl}/images/screenshot.png`} />
            <meta property="og:type" content="website" />
            <meta property="og:locale" content={language} />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@stackhero_io" />
            <meta name="twitter:creator" content="@stackhero_io" />

            {/* Do not index this pages. See https://support.google.com/webmasters/answer/7440203#indexed_though_blocked_by_robots_txt */}
            <meta name="robots" content="noindex" />
          </Helmet>

          {this.props.children}

          <Intercom />

          {this.renderLoading()}
          {this.renderError()}
        </>
      </ConfigProvider>
    );
  }
}

export default connect(
  ({ api, app }) => ({ api, app }),
  dispatch => ({
    apiActions: bindActionCreators(apiActions, dispatch),
    appActions: bindActionCreators(appActions, dispatch)
  })
)(_container);
