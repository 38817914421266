/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';

import { Form, Button, Alert, Input } from 'antd';
import { CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';

import styles from './CreditCardForm.css';

const stripeStyle = {
  base: {
    color: '#555',
    lineHeight: '32px',
    fontSize: '14px',

    '::placeholder': {
      color: '#bfbfbf'
    }
  },
  invalid: {
    color: '#f5222d'
  }
};

class CreditCardForm extends React.Component {

  static propTypes = {
    stripe: PropTypes.object,
    stripePaymentIntent: PropTypes.object,
    elements: PropTypes.object,
    type: PropTypes.string.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    loading: PropTypes.func.isRequired
  };

  state = {
    error: null,
    loading: false,
    postalCode: null
  };

  loadingSet(loading) {
    this.props.loading(loading);
    this.setState({ loading });
  }

  handleSubmit(e) {
    e.preventDefault();

    if (this.state.loading || !this.props.elements) {
      return false;
    }

    this.loadingSet(true);
    this.setState({
      error: null
    });

    this.props.stripe
      .confirmCardSetup(
        this.props.stripePaymentIntent.clientSecret,
        {
          payment_method: {
            type: 'card',
            card: this.props.elements.getElement(CardNumberElement),
            billing_details: {
              address: {
                postal_code: this.state.postalCode
              }
            }
          }
        }
      )
      .then(result => {
        if (result.error) {
          throw result.error;
        }
        this.loadingSet(false);
        this.props.onSuccess(result.setupIntent.payment_method);
      })
      .catch(error => {
        if (error.code !== 'card_declined' && error.code !== 'incomplete_number') {
          console.error(JSON.stringify(error)); // Will send error to Sentry
        }
        else {
          console.warn(JSON.stringify(error));
        }
        this.setState({
          error: error.message || 'An unknown error occured'
        });
        this.loadingSet(false);
      });
  }

  renderError() {
    if (!this.state.error) {
      return null;
    }

    return (
      <Alert
        message="Error"
        description={this.state.error}
        type="error"
        showIcon
      />
    );
  }


  renderButton() {
    return (
      <Form.Item style={{ textAlign: 'right', marginBottom: 0 }}>
        <Button type="primary" htmlType="submit" disabled={this.state.loading}>
          {this.props.type === 'add' ? 'Add' : 'Change'}
        </Button>

        {this.props.type === 'add'
          ? null
          : (
            <Button type="default" style={{ marginLeft: 8 }} onClick={this.props.onCancel}>
              Cancel
            </Button>
          )
        }
      </Form.Item>
    );
  }


  render() {
    return (
      <form className={styles.form} onSubmit={this.handleSubmit.bind(this)}>
        {this.renderError()}
        <label>
          Card number
          <CardNumberElement
            className={styles.cardInput}
            options={{ style: stripeStyle }}
            placeholder="1234 1234 1234 1234"
          />
        </label>

        <label>
          Expiry date
          <CardExpiryElement
            placeholder="MM / YY"
            className={styles.cardInput}
            options={{ style: stripeStyle }}
          />
        </label>

        <label>
          CVC
          <CardCvcElement
            placeholder="123"
            className={styles.cardInput}
            options={{ style: stripeStyle }}
          />
        </label>


        <label>
          Postal Code
          <Input
            type="text"
            placeholder="(optional)"
            value={this.state.postalCode}
            onChange={e => this.setState({ postalCode: e.target.value })}
          />
        </label>

        {this.renderButton()}
      </form>
    );
  }
}

export default CreditCardForm;