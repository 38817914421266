import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash.isequal';

import { Modal, Form, Tag, Tooltip } from 'antd';

import FormItems from './FormItems';

const stateDefault = {
  showErrors: false,
  formIsValid: false,
  form: {
    source: '0.0.0.0/0',
    portsIds: []
  },
  visible: true
};

class ServiceFirewallModal extends React.Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    onValidate: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    valuesToEdit: PropTypes.object,
    ports: PropTypes.array.isRequired
  };

  state = stateDefault;

  componentDidMount() {
    this.setState({
      form: this.props.valuesToEdit || stateDefault.form
    });
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.valuesToEdit, this.props.valuesToEdit)) {
      this.setState({
        form: this.props.valuesToEdit || stateDefault.form
      });
    }

    if (!this.state.visible && this.props.visible === true) {
      this.setState({ visible: true });
    }
  }

  form = () => [
    {
      label: 'Source',
      name: 'source',
      required: true,
      extra: ( <> 0.0.0.0/0 means all IPs.<br />x.x.x.x/32 means only IP x.x.x.x. </> ),
      input: {
        type: 'text',
        placeholder: '0.0.0.0/0',
        hasError: (name, value, form) => {
          if (value.split('/').length !== 2) {
            return 'The source doesn\'t seem correct';
          }

          const [ ip, prefix ] = value.split('/');

          const [ ipA, ipB, ipC, ipD ] = ip.split('.');
          if (!(parseInt(ipA) >= 0 && parseInt(ipA) <= 255
            && parseInt(ipB) >= 0 && parseInt(ipB) <= 255
            && parseInt(ipC) >= 0 && parseInt(ipC) <= 255
            && parseInt(ipD) >= 0 && parseInt(ipD) <= 255)

            // Avoid IPs with spaces like "123.123.123 .123"
            || parseInt(ipA).toString() !== ipA
            || parseInt(ipB).toString() !== ipB
            || parseInt(ipC).toString() !== ipC
            || parseInt(ipD).toString() !== ipD

            || ip.split('.').length !== 4) {
            return 'The IP doesn\'t seem correct';
          }

          if (!(parseInt(prefix) >= 0 && parseInt(prefix) <= 32) || parseInt(prefix).toString() !== prefix) {
            return 'The prefix should be defined between 0 and 32.';
          }

          if (parseInt(prefix) === 0 && ip !== '0.0.0.0') {
            return ( <>
              You have set an IP with a prefix "/0", meaning that you are allowing all IPs. This is probably not what you want to do.
              <br />
              If you want to allow only IP {ip}, you should set the prefix "/32".
            </>);
          }

          return '';
        }
      }
    },

    {
      label: 'Ports',
      name: 'portsIds',
      required: true,
      input: {
        type: 'tag',
        entries: this.props.ports.map(({ id, ports, legend, label }) => {
          const portsFormated = ports.map(({ port, protocol }) => `${port}/${protocol}`);
          return {
            value: id,
            label: ( <Tooltip title={legend}>{label ? `${label}: ${portsFormated.join(', ')}` : portsFormated.join(', ')}</Tooltip> )
          };
        }),
        hasError: (name, value, form) => !value.length ? 'You have to choose at least a port' : ''
      }
    },

    {
      label: 'Action',
      name: 'action',
      required: true,
      input: {
        type: 'radio',
        vertical: true,
        entries: [
          {
            value: 'accept',
            label: <Tag color="green">ACCEPT</Tag>,
            legend: 'The packets will be accepted.'
          },
          {
            value: 'reject',
            label: <Tag color="orange">REJECT</Tag>,
            legend: 'The packets will be refused. A reply will be sent to the client telling him that the packet is rejected.'
          },
          {
            value: 'drop',
            label: <Tag color="red">DROP</Tag>,
            legend: 'The packets will be ignored. No reply will be sent to the client informing him that the packet isn\'t accepted.'
          }
        ],
        hasError: (name, value, form) => !value ? 'The action is required' : ''
      }
    },

    {
      label: 'Description',
      name: 'description',
      required: false,
      extra: 'Just a memo for you to remember what is the goal of this rule.',
      input: {
        type: 'text',
        placeholder: 'x.x.x.x/y is the server zzz',
        hasError: (name, value, form) => value.length >= 0 && value.length <= 64 ? '' : 'The maximum length is 64 characters.'
      }
    },
  ];

  onValidate = () => {
    if (!this.state.formIsValid) {
      this.setState({ showErrors: true });
      return false;
    }

    this.props.onValidate(this.state.form);
    this.setState(stateDefault);
  };

  render = () => {
    if (!this.state.visible && !this.props.visible) {
      return null;
    }
    return (
      <Modal
        title={this.props.valuesToEdit ? 'Edit a rule' : 'Add a rule'}
        open={this.props.visible}
        onOk={this.onValidate}
        okText="Save rule"
        onCancel={this.props.onCancel}
        cancelText="Cancel"
        afterClose={() => this.setState({ visible: false })} // The goal is to not render the modal when not used while keeping the close animation.
      >
        <Form onFinish={this.onValidate}>
          <FormItems
            entries={this.form()}
            onStatusChange={formIsValid => this.setState({ formIsValid })}
            datas={this.state.form}
            onUpdate={form => this.setState({ form })}
            sizes={{ xs: 24, sm: 6 }}
            showErrors={this.state.showErrors}
          />
        </Form>
      </Modal>
    );
  };
}

export default ServiceFirewallModal;
