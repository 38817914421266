import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import LinkLayout from 'components/Link';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as apiActions from 'redux/actions/api';

import { Card, Row, Col, Button, Alert, Tabs, Badge, Form, Input, message, Tooltip, Space } from 'antd';
import { ReadOutlined, EditOutlined, ProfileOutlined, BranchesOutlined, HddOutlined, SafetyCertificateOutlined, SecurityScanOutlined, SettingOutlined, CloudUploadOutlined, FullscreenOutlined } from '@ant-design/icons';

import ServiceTitle from 'components/ServiceTitle';
import ServiceDetails from 'components/ServiceDetails';
import ServiceInstanceUpgrade from 'components/ServiceInstanceUpgrade';
import ServiceInstanceRestart from 'components/ServiceInstanceRestart';
import ServiceDelete from 'components/ServiceDelete';
import ServiceGettingStarted from 'components/ServiceGettingStarted';
import ServiceTasks from 'components/ServiceTasks';
import ServiceBackups from 'components/ServiceBackups';
import ServiceUpdates from 'components/ServiceUpdates';
import ServiceFirewall from 'components/ServiceFirewall';
import ServiceLogs from 'components/ServiceLogs';
import ServiceLogsForwarder from 'components/ServiceLogsForwarder';
import ServiceMetricsOverview from 'components/ServiceMetricsOverview';

import styles from './_default.css';

class _default extends React.Component {
  static propTypes = {
    apiActions: PropTypes.object.isRequired,
    api: PropTypes.object.isRequired,
    app: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  state = {
    serviceUpgradeDrawerVisible: false,
    activeTab: undefined,
    serviceEdition: {}
  };

  componentDidMount() {
    this._mounted = true;
  }

  componentWillUnmount() {
    this._mounted = false;
  }


  serviceNameEdit(service) {
    this.setState(
      state => ({
        serviceEdition: state.serviceEdition.id === service.id ? {} : { id: service.id, name: service.name }
      }),
    );
  }


  serviceNameValid(name) {
    return name.length >= 3 && name.length <= 64;
  }


  serviceRename(service, newName) {
    if (!this.serviceNameValid(newName) || service.name === newName) {
      this.setState({ serviceEdition: {} });
      return;
    }

    this.props.apiActions.patch(
      {
        route: `/dashboard/services/${service.id}`,
        routeArgs: { newName }
      },
      () => {
        if (!this._mounted) { return; }
        message.success('This service has been renamed');
        this.setState({ serviceEdition: {} });
      }
    );
  }


  renderActionConfigure() {
    const { serviceId } = this.props.match.params;
    const route = `/dashboard/services/${serviceId}`;
    const service = this.props.api.subscriptions[route].datas;
    const disabled = !service.canBeConfigured || [ 'running', 'serviceCreation', 'serviceBackup' ].indexOf(service.status.type) === -1;

    return (
      <Link to="configure/">
        <Button icon={<SettingOutlined />} block disabled={disabled}>Configure</Button>
      </Link>
    );
  }


  renderServiceInstanceRestart() {
    const { serviceId } = this.props.match.params;
    const route = `/dashboard/services/${serviceId}`;
    const service = this.props.api.subscriptions[route].datas;
    const typesOk = [ 'serviceBackup', 'running', 'icmp' ];
    const disabled = !service.isConfigured
      || typesOk.indexOf(service.status.type) === -1
      || (service.status.state !== 'ok' && service.status.type !== 'icmp');

      return (
      <ServiceInstanceRestart
        service={service}
        disabled={disabled}
        onRestart={() => {
          const { serviceId } = this.props.match.params;
          this.props.apiActions.post(
            { route: `/dashboard/services/${serviceId}/restart` },
            error => {
              if (error || !this._mounted) {
                return;
              }
            }
          );
        }}
      />
    );
  }


  renderActionDelete() {
    const { serviceId } = this.props.match.params;
    const route = `/dashboard/services/${serviceId}`;
    const service = this.props.api.subscriptions[route].datas;

    const { stackId } = this.props.match.params;
    const stack = this.props.api.subscriptions[`/dashboard/stacks/${stackId}`].datas;
    const disabled = [ 'running', 'ok', 'suspended' ].indexOf(service.status.state) === -1;

    return (
      <ServiceDelete
        service={service}
        stack={stack}
        disabled={disabled}
        onDelete={() => {
          const { serviceId } = this.props.match.params;
          this.props.apiActions.del(
            { route: `/dashboard/services/${serviceId}` },
            error => {
              if (error || !this._mounted) {
                return;
              }
              this.props.history.push('../');
            }
          );
        }}
      />
    );
  }


  renderServiceAlert() {
    const { serviceId } = this.props.match.params;
    const route = `/dashboard/services/${serviceId}`;
    const service = this.props.api.subscriptions[route].datas;

    if (service.status && service.status.type === 'icmp' && service.status.state === 'error') {
      return (
        <Alert
          message="Your instance does not reply to ICMP requests"
          description="We are sincerely sorry for this trouble. Our team has been warned and will look at it as soon as possible."
          type="error"
          showIcon
          style={{ marginBottom: 16 }}
        />
      );
    }
    else if (service.status && service.status.type === 'host' && service.status.state === 'error') {
      return (
        <Alert
          message="Your instance's host has a problem"
          description="We are sincerely sorry for this trouble. Our team has been warned and is working on the problem."
          type="error"
          showIcon
          style={{ marginBottom: 16 }}
        />
      );
    }
    else if (service.status && service.status.type === 'gateway' && service.status.state === 'error') {
      return (
        <Alert
          message="Your instance's gateway has a problem"
          description="We are sincerely sorry for this trouble. Our team has been warned and is working on the problem."
          type="error"
          showIcon
          style={{ marginBottom: 16 }}
        />
      );
    }
    else if (service.status && service.status.state === 'error') {
      return (
        <Alert
          message="An error has been detected"
          description="We are sincerely sorry for this trouble. An issue has been opened and our team has been warned."
          type="error"
          showIcon
          style={{ marginBottom: 16 }}
        />
      );
    }
    else if (!service.isConfigured && service.canBeConfigured && [ 'running', 'serviceCreation', 'serviceBackup' ].indexOf(service.status.type) !== -1) {
        return (
        <Alert
          message="This service is not configured yet"
          description="You can configure it by clicking the button &#34;Configure the service&#34;"
          type="info"
          showIcon
          style={{ marginBottom: 16 }}
        />
      );
    }
  }


  renderTitle(service) {
    if (!this.state.serviceEdition.id) {
      return ( <ServiceTitle title={service.name} name={service.serviceStore.name} /> );
    }
    return (
      <Form.Item
        validateStatus={this.serviceNameValid(this.state.serviceEdition.name) ? 'success' : 'error'}
        style={{ margin: 0 }}
      >
        <Input
          autoFocus
          value={this.state.serviceEdition.name}
          onChange={e => {
            const name = e.target.value;
            this.setState(state => ({ serviceEdition: { ...state.serviceEdition, name } }));
          }}
          onBlur={() => this.serviceRename(service, this.state.serviceEdition.name)}
          onPressEnter={() => this.serviceRename(service, this.state.serviceEdition.name)}
        />
      </Form.Item>
    );
  }


  render() {
    const { organizationId, stackId, serviceId } = this.props.match.params;
    const route = `/dashboard/services/${serviceId}`;
    const service = this.props.api.subscriptions[route].datas;

    const routeOrganization = `/dashboard/organizations/${organizationId}`;
    const { currency } = this.props.api.subscriptions[routeOrganization].datas;

    // Temporary to debug STACKHERO-WEB-68
    if (!service || !service.name) {
      console.error('Debugging', route, JSON.stringify(this.props.api.subscriptions[route]));
    }

    const stack = this.props.api.subscriptions[`/dashboard/stacks/${stackId}`].datas;

    const activeTab = this.state.activeTab
      ? this.state.activeTab
      : service.tasks.findIndex(({ status }) => status !== 'done') !== -1
        ? 'tasks'
        : service.updatesCanBeApplied && service.updatesAvailable.length
          ? 'updates'
          : 'gettingStarted';


    const serviceUpgradeVisible = stack && stack.userPermissions.indexOf('servicesUpgrade') !== -1;
    const serviceUpgradeDisabled = (service.status.type !== 'serviceBackup' && service.status.type !== 'running') || service.status.state !== 'ok';

    return (
      <>
        {this.renderServiceAlert()}
        <Card
          title={this.renderTitle(service)}
          extra={
            stack.userPermissions.indexOf('servicesConfigure') !== -1
            ? (
              <Tooltip title="Edit this service name">
                <EditOutlined className={styles.icon} onClick={() => this.serviceNameEdit(service)} />
              </Tooltip>
            )
            : null
          }
          style={{ marginBottom: 16 }}
        >
          <Row gutter={[ 20, 20 ]}>
            <Col xs={24} sm={24} md={24} lg={18} xl={19} xxl={19}>
              <ServiceDetails service={service} />
            </Col>

            <Col xs={24} sm={24} md={24} lg={6} xl={5} xxl={5} className={styles.buttonsContainer}>
              <div>
                {
                  stack.userPermissions.indexOf('servicesConfigure') !== -1
                    ? this.renderActionConfigure()
                    : null
                }
              </div>

              <div style={{ marginTop: '10px' }}>
                <LinkLayout url={`${process.env.HTTP_SCHEME}://${process.env.SHOWCASE_HOST}/${this.props.app.languageAndRegion}/services/${service.serviceStore.name.replace('nodejs-lts', 'Node-js').replace('nodejs-current', 'Node-js')}/documentations`}>
                  <Button icon={<ReadOutlined />} block>
                    Documentation
                  </Button>
                </LinkLayout>
              </div>

              <div style={{ marginTop: '10px' }}>
                {
                  serviceUpgradeVisible
                    ? (
                      <Button
                        disabled={serviceUpgradeDisabled}
                        block
                        onClick={() => this.setState({ serviceUpgradeDrawerVisible: true })}
                        icon={<FullscreenOutlined />}
                      >
                        Upgrade
                      </Button>
                      )
                    : null
                }
              </div>

              <div style={{ marginTop: '10px' }}>
                {
                  stack.userPermissions.indexOf('servicesRestart') !== -1
                    ? this.renderServiceInstanceRestart()
                    : null
                }
              </div>

              <div style={{ marginTop: '10px' }}>
                {
                  stack.userPermissions.indexOf('servicesDelete') !== -1
                    ? this.renderActionDelete()
                    : null
                }
              </div>
            </Col>
          </Row>
        </Card>

        <Card
          title="Instance usage"
          style={{ marginBottom: 16 }}
        >
          <ServiceMetricsOverview
            route={`/dashboard/services/${serviceId}/metricsOverview`}
          />

          {
            serviceUpgradeVisible
              ? (
                <>
                  <Button
                    disabled={serviceUpgradeDisabled}
                    type="link"
                    block
                    onClick={() => this.setState({ serviceUpgradeDrawerVisible: true })}
                    style={{ marginTop: 10, whiteSpace: 'break-spaces' }}
                  >
                    Need more power? Click here to see other instances.
                  </Button>
                  {this.state.serviceUpgradeDrawerVisible
                    && (
                      <ServiceInstanceUpgrade
                        organizationId={organizationId}
                        serviceId={serviceId}
                        currency={currency}
                        onCancel={() => this.setState({ serviceUpgradeDrawerVisible: false })}
                      />
                    )}
                </>
              )
              : null
          }
        </Card>

        <Card>
          <Tabs
            animated={false}
            className={styles.tabs}
            onChange={activeTab => this.setState({ activeTab })}
            activeKey={activeTab}
            items={[
              // Getting started
              {
                key: 'gettingStarted',
                label: ( <Space><ReadOutlined />Getting started</Space> ),
                children: activeTab === 'gettingStarted' ? ( <ServiceGettingStarted service={service} /> ) : null
              },

              // Tasks
              {
                key: 'tasks',
                label: service.tasks
                  .find(({ status }) => status === 'doing')
                    ? ( <Badge status="processing" text="Tasks" /> )
                    : service.tasks.find(({ status }) => status === 'error')
                      ? ( <Badge status="error" text="Tasks" /> )
                      : ( <Space><ProfileOutlined />Tasks</Space> ),
                children: activeTab === 'tasks' ? ( <ServiceTasks tasks={service.tasks} /> ) : null
              },

              // Updates
              stack.userPermissions.indexOf('servicesUpdate') !== -1
                ? {
                  key: 'updates',
                  label: service.updatesCanBeApplied && service.updatesAvailable.length
                      ? ( <Badge status={ service.updatesAvailable.length === 1 ? 'warning' : 'error' } text="Updates" /> )
                      : ( <Space><BranchesOutlined />Updates</Space> ),
                  children: activeTab === 'updates'
                    ? (
                      <ServiceUpdates
                        service={service}
                        onUpdate={({ idNew }, cb) => {
                          const { serviceId } = this.props.match.params;
                          this.props.apiActions.patch(
                            {
                              route: `/dashboard/services/${serviceId}/serviceStore`,
                              routeArgs: { id: idNew }
                            },
                            (error, res) => {
                              cb(error, res);
                              if (!error) {
                                this.setState({ activeTab: 'tasks' });
                              }
                            }
                          );
                        }}
                      />
                    )
                    : null
                  }
                  : null,

              // Backups vault
              {
                key: 'backups',
                label: ( <Space><HddOutlined />Backups vault</Space> ),
                children: activeTab === 'backups'
                  ? (
                    <ServiceBackups
                      serviceId={service.id}
                      onRollback={() => this.setState({ activeTab: 'tasks' })}
                    />
                  )
                  : null
              },

              // Firewall
              service.hasFirewall && stack.userPermissions.indexOf('servicesConfigure') !== -1
                ? {
                  key: 'firewall',
                  label: ( <Space><SafetyCertificateOutlined />Firewall</Space> ),
                  children: activeTab === 'firewall'
                    ? (
                      <ServiceFirewall
                        service={service}
                        organizationId={organizationId}
                        stackId={stackId}
                        serviceId={serviceId}
                        onUpdate={() => this.setState({ activeTab: 'tasks' })}
                      />
                    )
                    : null
                }
                : null,

              // Logs
              {
                key: 'logs',
                label: ( <Space><SecurityScanOutlined />Logs</Space> ),
                children: ( <ServiceLogs serviceId={serviceId} /> )
              },

              // Logs forwarder
              service.hasLogsServer
                ? {
                  key: 'logsForwarder',
                  label: ( <Space><CloudUploadOutlined />Logs forwarder</Space> ),
                  children: activeTab === 'logsForwarder'
                    ? (
                      <ServiceLogsForwarder
                        serviceId={serviceId}
                        onUpdate={() => this.setState({ activeTab: 'tasks' })}
                      />
                    )
                    : null
                }
                : null,
            ].filter(v => v)}
          />
        </Card>
      </>
    );
  }
}

export default connect(
  ({ api, app }) => ({ api, app }),
  dispatch => ({
    apiActions: bindActionCreators(apiActions, dispatch)
  })
)(_default);
