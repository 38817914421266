// Generated by scripts/routesGenerator.js
module.exports = salt => [
{ salt, route: '/_container', component: require('websites/dashboard/routes/_container').default },
{ salt, route: '/_default', component: require('websites/dashboard/routes/_default').default },
{ salt, route: '/account/_container', component: require('websites/dashboard/routes/account/_container').default },
{ salt, route: '/account/_default', component: require('websites/dashboard/routes/account/_default').default },
{ salt, route: '/account/Logout', component: require('websites/dashboard/routes/account/Logout').default },
{ salt, route: '/account/organizations/_container', component: require('websites/dashboard/routes/account/organizations/_container').default },
{ salt, route: '/account/organizations/_default', component: require('websites/dashboard/routes/account/organizations/_default').default },
{ salt, route: '/account/organizations/{organizationId}/_container', component: require('websites/dashboard/routes/account/organizations/{organizationId}/_container').default },
{ salt, route: '/account/organizations/{organizationId}/_default', component: require('websites/dashboard/routes/account/organizations/{organizationId}/_default').default },
{ salt, route: '/account/organizations/{organizationId}/billing/_container', component: require('websites/dashboard/routes/account/organizations/{organizationId}/billing/_container').default },
{ salt, route: '/account/organizations/{organizationId}/billing/coupons/_container', component: require('websites/dashboard/routes/account/organizations/{organizationId}/billing/coupons/_container').default },
{ salt, route: '/account/organizations/{organizationId}/billing/coupons/_default', component: require('websites/dashboard/routes/account/organizations/{organizationId}/billing/coupons/_default').default },
{ salt, route: '/account/organizations/{organizationId}/billing/invoices/_container', component: require('websites/dashboard/routes/account/organizations/{organizationId}/billing/invoices/_container').default },
{ salt, route: '/account/organizations/{organizationId}/billing/invoices/_default', component: require('websites/dashboard/routes/account/organizations/{organizationId}/billing/invoices/_default').default },
{ salt, route: '/account/organizations/{organizationId}/billing/paymentMethod/_container', component: require('websites/dashboard/routes/account/organizations/{organizationId}/billing/paymentMethod/_container').default },
{ salt, route: '/account/organizations/{organizationId}/billing/paymentMethod/_default', component: require('websites/dashboard/routes/account/organizations/{organizationId}/billing/paymentMethod/_default').default },
{ salt, route: '/account/organizations/{organizationId}/Edit', component: require('websites/dashboard/routes/account/organizations/{organizationId}/Edit').default },
{ salt, route: '/account/organizations/{organizationId}/News', component: require('websites/dashboard/routes/account/organizations/{organizationId}/News').default },
{ salt, route: '/account/organizations/{organizationId}/Prometheus', component: require('websites/dashboard/routes/account/organizations/{organizationId}/Prometheus').default },
{ salt, route: '/account/organizations/{organizationId}/stacks/_container', component: require('websites/dashboard/routes/account/organizations/{organizationId}/stacks/_container').default },
{ salt, route: '/account/organizations/{organizationId}/stacks/_default', component: require('websites/dashboard/routes/account/organizations/{organizationId}/stacks/_default').default },
{ salt, route: '/account/organizations/{organizationId}/stacks/{stackId}/_container', component: require('websites/dashboard/routes/account/organizations/{organizationId}/stacks/{stackId}/_container').default },
{ salt, route: '/account/organizations/{organizationId}/stacks/{stackId}/_default', component: require('websites/dashboard/routes/account/organizations/{organizationId}/stacks/{stackId}/_default').default },
{ salt, route: '/account/organizations/{organizationId}/stacks/{stackId}/services/_container', component: require('websites/dashboard/routes/account/organizations/{organizationId}/stacks/{stackId}/services/_container').default },
{ salt, route: '/account/organizations/{organizationId}/stacks/{stackId}/services/_default', component: require('websites/dashboard/routes/account/organizations/{organizationId}/stacks/{stackId}/services/_default').default },
{ salt, route: '/account/organizations/{organizationId}/stacks/{stackId}/services/{serviceId}/_container', component: require('websites/dashboard/routes/account/organizations/{organizationId}/stacks/{stackId}/services/{serviceId}/_container').default },
{ salt, route: '/account/organizations/{organizationId}/stacks/{stackId}/services/{serviceId}/_default', component: require('websites/dashboard/routes/account/organizations/{organizationId}/stacks/{stackId}/services/{serviceId}/_default').default },
{ salt, route: '/account/organizations/{organizationId}/stacks/{stackId}/services/{serviceId}/configure/_container', component: require('websites/dashboard/routes/account/organizations/{organizationId}/stacks/{stackId}/services/{serviceId}/configure/_container').default },
{ salt, route: '/account/organizations/{organizationId}/stacks/{stackId}/services/{serviceId}/configure/_default', component: require('websites/dashboard/routes/account/organizations/{organizationId}/stacks/{stackId}/services/{serviceId}/configure/_default').default },
{ salt, route: '/account/organizations/{organizationId}/stacks/{stackId}/services/create/_container', component: require('websites/dashboard/routes/account/organizations/{organizationId}/stacks/{stackId}/services/create/_container').default },
{ salt, route: '/account/organizations/{organizationId}/stacks/{stackId}/services/create/_default', component: require('websites/dashboard/routes/account/organizations/{organizationId}/stacks/{stackId}/services/create/_default').default },
{ salt, route: '/account/organizations/{organizationId}/stacks/{stackId}/services/create/purchase/_container', component: require('websites/dashboard/routes/account/organizations/{organizationId}/stacks/{stackId}/services/create/purchase/_container').default },
{ salt, route: '/account/organizations/{organizationId}/stacks/{stackId}/services/create/purchase/_default', component: require('websites/dashboard/routes/account/organizations/{organizationId}/stacks/{stackId}/services/create/purchase/_default').default },
{ salt, route: '/account/organizations/{organizationId}/stacks/{stackId}/services/create/purchase/Organization', component: require('websites/dashboard/routes/account/organizations/{organizationId}/stacks/{stackId}/services/create/purchase/Organization').default },
{ salt, route: '/account/organizations/{organizationId}/stacks/{stackId}/services/create/purchase/Terms', component: require('websites/dashboard/routes/account/organizations/{organizationId}/stacks/{stackId}/services/create/purchase/Terms').default },
{ salt, route: '/account/organizations/{organizationId}/stacks/{stackId}/services/create/purchase/Verifications', component: require('websites/dashboard/routes/account/organizations/{organizationId}/stacks/{stackId}/services/create/purchase/Verifications').default },
{ salt, route: '/account/organizations/{organizationId}/stacks/{stackId}/services/create/servicePackStore/{servicePackStoreId}/_container', component: require('websites/dashboard/routes/account/organizations/{organizationId}/stacks/{stackId}/services/create/servicePackStore/{servicePackStoreId}/_container').default },
{ salt, route: '/account/organizations/{organizationId}/stacks/{stackId}/services/create/servicePackStore/{servicePackStoreId}/_default', component: require('websites/dashboard/routes/account/organizations/{organizationId}/stacks/{stackId}/services/create/servicePackStore/{servicePackStoreId}/_default').default },
{ salt, route: '/account/organizations/{organizationId}/stacks/{stackId}/services/create/serviceStore/{serviceStoreId}/_container', component: require('websites/dashboard/routes/account/organizations/{organizationId}/stacks/{stackId}/services/create/serviceStore/{serviceStoreId}/_container').default },
{ salt, route: '/account/organizations/{organizationId}/stacks/{stackId}/services/create/serviceStore/{serviceStoreId}/_default', component: require('websites/dashboard/routes/account/organizations/{organizationId}/stacks/{stackId}/services/create/serviceStore/{serviceStoreId}/_default').default },
{ salt, route: '/account/organizations/{organizationId}/stacks/create/_container', component: require('websites/dashboard/routes/account/organizations/{organizationId}/stacks/create/_container').default },
{ salt, route: '/account/organizations/{organizationId}/stacks/create/_default', component: require('websites/dashboard/routes/account/organizations/{organizationId}/stacks/create/_default').default },
{ salt, route: '/account/organizations/create/_container', component: require('websites/dashboard/routes/account/organizations/create/_container').default },
{ salt, route: '/account/organizations/create/_default', component: require('websites/dashboard/routes/account/organizations/create/_default').default },
{ salt, route: '/EmailsInformationalUnsubscribe', component: require('websites/dashboard/routes/EmailsInformationalUnsubscribe').default },
{ salt, route: '/EmailValidation', component: require('websites/dashboard/routes/EmailValidation').default },
{ salt, route: '/notLogged/_container', component: require('websites/dashboard/routes/notLogged/_container').default },
{ salt, route: '/notLogged/_default', component: require('websites/dashboard/routes/notLogged/_default').default },
{ salt, route: '/notLogged/Login', component: require('websites/dashboard/routes/notLogged/Login').default },
{ salt, route: '/notLogged/PasswordReset', component: require('websites/dashboard/routes/notLogged/PasswordReset').default },
{ salt, route: '/notLogged/SignUp', component: require('websites/dashboard/routes/notLogged/SignUp').default },
{ salt, route: '/notLogged/SingleUseTokenLogin', component: require('websites/dashboard/routes/notLogged/SingleUseTokenLogin').default }
];