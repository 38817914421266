import React from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as apiActions from 'redux/actions/api';

import { Drawer, Col, Row, Modal, Result, Button, Alert } from 'antd';

import InstanceStore from 'components/InstanceStore';

class ServiceInstanceUpgrade extends React.Component {
  static propTypes = {
    onCancel: PropTypes.func.isRequired,
    app: PropTypes.object.isRequired,
    apiActions: PropTypes.object.isRequired,
    serviceId: PropTypes.string.isRequired,
    organizationId: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired
  };

  state = {
    visible: true,
    instancesStore: null
  };

  UNSAFE_componentWillMount() {
    this._mounted = true;

    this.props.apiActions.get(
      {
        route: `/dashboard/services/${this.props.serviceId}/instancesStore`,
        routeArgs: {}
      },
      (error, instancesStore) => {
        if (error || !this._mounted) {
          return;
        }
        this.setState({ instancesStore });
      }
    );
  }

  componentWillUnmount() {
    this._mounted = false;
  }


  upgrade = (instanceStoreIdNew) => {
    this.props.apiActions.patch(
      {
        route: `/dashboard/services/${this.props.serviceId}/instanceStore`,
        routeArgs: {
          id: instanceStoreIdNew
        }
      },
      () => this._mounted && this.close()
    );
  };

  upgradeAskConfirmation = (instanceStoreIdNew) => {
    Modal.confirm({
      title: 'Upgrade confirmation',
      content: 'Note that your instance will restart a few seconds to apply the upgrade.',
      onOk: () => this.upgrade(instanceStoreIdNew)
    });
  };

  close = () => {
    this.setState(
      { visible: false },
      () => setTimeout(this.props.onCancel, 500)
    );
  };

  renderDrawerContent() {
    const { instancesStore } = this.state;
    const { currency } = this.props;

    if (!instancesStore) {
      return null;
    }

    if (!instancesStore.length) {
        return (
          <Result
            status="info"
            title="You already have the most powerful instance"
            subTitle={
              this.props.app.intercomAvailable
                ? 'Send us a message with your needs, maybe we can create a custom instance for you!'
                : ( <>Send us an email with your needs to <a href="mailto:support@stackhero.io">support@stackhero.io</a>, maybe we can create a custom instance for you!</> )
            }
            extra={this.props.app.intercomAvailable
              ? (
                <Button
                  type="primary"
                  onClick={() => {
                    window.Intercom(
                      'showNewMessage',
                      `Hi,\nI would like a custom instance for the service ${this.props.serviceId}. What could you do?\n`
                    );
                  }}
                >
                  Ask for a custom instance
                </Button>
              )
              : null
            }
          />
        );
    }
    else {
      return (
        <Row>
          {instancesStore
            .sort((a, b) => {
              const aPrice = a.prices.find(price => price.currency === currency);
              const bPrice = b.prices.find(price => price.currency === currency);
              return aPrice.amount - bPrice.amount;
            })
            .map(instanceStore => (
              <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8} key={instanceStore.id} style={{ minWidth: 300 }}>
                <InstanceStore
                  instanceStore={instanceStore}
                  currency={currency}
                  onClick={() => instanceStore.status === 'available'
                    ? this.upgradeAskConfirmation(instanceStore.id)
                    : document.location.href = `/account/organizations/${this.props.organizationId}/billing/paymentMethod/`
                  }
                />
              </Col>
            ))
          }
        </Row>
      );
    }
  }

  render() {
    const { instancesStore } = this.state;

    if (instancesStore === null) {
      return null;
    }

    return (
      <Drawer
        title="Upgrade to a new instance"
        open={this.state.visible}
        width="auto"
        styles={{ body: { backgroundColor: '#fafafa', minHeight: '100%', overflow: 'auto', maxWidth: 1000 }}}
        placement="right"
        onClose={this.close}
      >
        <Alert message="Note that instances cannot be downgraded" type="info" showIcon />
        <br />
        {this.renderDrawerContent()}
      </Drawer>
    );
  }
}

export default connect(
  ({ app }) => ({ app }),
  dispatch => ({
    apiActions: bindActionCreators(apiActions, dispatch)
  })
)(ServiceInstanceUpgrade);
