const initialState = {
	networkWorking: true,
	apiError: false,
  sessionAuth: {},
  isLogged: false,
  initialized: false,
  intercomAvailable: false,
  routeBase: '/v1',
  ipInformations: {},
  pageIsVisible: true,
  pageVisibilityLastTimeChanged: 0,
  currency: 'USD',

  language: undefined,
  region: undefined,
  languageAndRegion: undefined,

  menuLeftContent: []
};

const app = (state = initialState, action) => {
	switch (action.type) {

	case 'APP_INITIALIZE':
		return { ...state, initialized: true };

  case 'APP_NETWORK_STATUS_SET':
    return { ...state, networkWorking: action.working };

	case 'APP_API_ERROR_SET':
		return { ...state, apiError: action.error };

  case 'APP_SESSION_SET': {
    return {
      ...state,
      sessionAuth: action.sessionAuth || {}
    };
  }

  case 'APP_CURRENCY_SET':
    return {
      ...state,
      currency: action.currency
    };

  case 'APP_LOGGED_SET':
    return {
      ...state,
      isLogged: action.isLogged
    };

  case 'APP_IP_INFORMATIONS_SET':
    return {
      ...state,
      ipInformations: action.ipInformations
    };

  case 'APP_PAGE_VISIBILITY_SET':
    return {
      ...state,
      pageIsVisible: action.visible,
      pageVisibilityLastTimeChanged: Date.now()
    };

  case 'APP_LANGUAGE_SET':
    return {
      ...state,
      language: action.language,
      languageAndRegion: state.region ? `${action.language}-${state.region}` : action.language
    };

  case 'APP_REGION_SET':
    return {
      ...state,
      region: action.region,
      languageAndRegion: action.region ? `${state.language}-${action.region}` : state.language
    };

  case 'APP_INTERCOM_AVAILABILITY_SET':
    return {
      ...state,
      intercomAvailable: action.availability
    };

  case 'APP_MENU_LEFT_SET':
    return JSON.stringify(state.menuLeftContent) !== JSON.stringify(action.content)
      ? {
        ...state,
        menuLeftContent: action.content
      }
      : state;

	default:
		return state;
	}
};


export default app;
