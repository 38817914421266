import logo from 'images/servicesLogos/kubernetes-control-plane.svg';
import logoOpenGraph from 'images/servicesLogos/openGraphVersions/kubernetes-control-plane.png';

import growth from 'images/landings/icons/growth.svg';
import startup from 'images/landings/icons/startup.svg';
import medal from 'images/landings/icons/medal.svg';
import packageIcon from 'images/landings/icons/package.svg';
import shield from 'images/landings/icons/shield.svg';
import strategy from 'images/landings/icons/strategy.svg';

import checked from 'images/landings/icons/checked.svg';

const backgroundColor = '#303030';
const button = {
  color: '#ffffff',
  backgroundColor: '#326ce5'
};

const serviceName = 'Kubernetes Control Plane';
export default ({ t = () => [] }) => ({
  id: 'kubernetes-control-plane',
  name: 'Kubernetes Control Plane',
  nameUrl: 'Kubernetes-Control-Plane',
  title: t('services/kubernetes-control-plane:title', { serviceName }),
  logo,
  logoOpenGraph,
  button,
  backgroundColor,

  header: {
    title: 'Kubernetes Control Plane',
    h1: t('services/kubernetes-control-plane:h1', { serviceName }),
    description: t('services/kubernetes-control-plane:description', { serviceName }) + '\n\n' + t('services/kubernetes-control-plane:callToAction', { serviceName }),
    images: []
  },

  benefits: {
    items: [ growth, startup, medal, packageIcon, shield, strategy ].map((icon, index) => ({
      icon,
      ...t('services/kubernetes-control-plane:benefits', { serviceName })[index]
    }))
  },

  also: {
    color: button.backgroundColor,
    items: t('services/kubernetes-control-plane:also.items', { serviceName })
      .map(content => ({ icon: checked, ...content }))
  },

  pricing: {
    ...t('services/kubernetes-control-plane:pricing'),
    buttonBackgroundColor: button.backgroundColor,
    buttonColor: button.color,
    sentencesIconColor: button.backgroundColor
  },

  recall: {
    description: t('services/kubernetes-control-plane:callToAction', { serviceName })
  },

  faq: {
    items: t('services/kubernetes-control-plane:faq.items', { serviceName })
  }
});
