export function stepsSet(steps) {
  return {
    type: 'STEPS_SET',
    steps
  };
}

export function stepsUnset() {
  return {
    type: 'STEPS_UNSET'
  };
}

export function stepSelect(index) {
  return {
    type: 'STEP_SELECT',
    index
  };
}

export function stepSelectByLabel(label) {
  return {
    type: 'STEP_SELECT_LABEL',
    label
  };
}


export function breadcrumbAdd(name, url, key) {
  return {
    type: 'BREADCRUMB_ADD',
    name,
    url,
    key
  };
}

export function breadcrumbUpdate(name, url, key) {
  return {
    type: 'BREADCRUMB_UPDATE',
    name,
    url,
    key
  };
}

export function breadcrumbRemove(key) {
  return {
    type: 'BREADCRUMB_REMOVE',
    key
  };
}
