import React from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as apiActions from 'redux/actions/api';

import { Button, Modal, Alert, Input } from 'antd';
import { RollbackOutlined } from '@ant-design/icons';

class ServiceBackupsRollback extends React.Component {
  static propTypes = {
    serviceId: PropTypes.string.isRequired,
    backup: PropTypes.object.isRequired,
    onRollback: PropTypes.func.isRequired,
    apiActions: PropTypes.object.isRequired
  };

  state = {
    modalVisible: false,
    checkSentenceInput: ''
  };


  formatDate(date) {
    const dateOption = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
    return new Intl.DateTimeFormat(undefined, dateOption).format(new Date(date));
  }


  handleClose = () => {
    this.setState({ modalVisible: false, checkSentenceInput: '' });
  };


  handleSubmit = () => {
    this.props.apiActions.post(
      {
        route: `/dashboard/services/${this.props.serviceId}/backups/${this.props.backup.id}/rollback`,
        routeArgs: {}
      },
      (error, res) => {
        this.handleClose();
        if (!error) {
          this.props.onRollback();
        }
      }
    );
  };


  renderModal() {
    const { backup } = this.props;
    const checkSentence = 'Rollback to this older backup and delete my current data';
    return (
      <Modal key="modal" title="Rolling back to a previous backup"
        open={this.state.modalVisible}
        onOk={this.handleSubmit}
        onCancel={this.handleClose}
        footer={[
          <Button key="cancel" onClick={this.handleClose}>Cancel</Button>,
          <Button key="submit" type="primary" danger onClick={this.handleSubmit} disabled={this.state.checkSentenceInput.toLowerCase() !== checkSentence.toLowerCase()}>
            Rollback this service
          </Button>,
        ]}
      >

        {/* Beta alert */}
        <Alert message="This feature is in beta: instabilities can happen and you should avoid to use it." type="error" banner />
        <br />


        <Alert message="Rolling back to a previous backup takes time (sometimes hours) during which your instance will be shutted down. It is not a trivial operation and should only be done if you have no other option." banner />
        <br />
        This action will <span style={{ fontWeight: 'bold' }}>erase you current data</span> and replace them with data from <span style={{ fontWeight: 'bold' }}>{this.formatDate(backup.date)}</span>.
        <br />
        The configuration of your service will be rollbacked too.
        <br />
        <br />

        If you are sure of what you are doing, please type "{checkSentence}" to confirm.
        <br />
        <br />

        <Input
          placeholder={checkSentence}
          value={this.state.checkSentenceInput}
          onChange={(e) => this.setState({ checkSentenceInput: e.target.value })}
        />
      </Modal>
    );
  }


  render() {
    return [
      (
        <Button
          key="button"
          shape="circle"
          size="small"
          icon={ <RollbackOutlined /> }
          style={{ marginLeft: 10 }}
          onClick={() => this.setState({ modalVisible: true, checkSentenceInput: '' })}
        />
      ),
      this.renderModal()
    ];
  }
}

export default connect(
  () => ({}),
  dispatch => ({
    apiActions: bindActionCreators(apiActions, dispatch)
  })
)(ServiceBackupsRollback);
