import React from 'react';

import { Progress } from 'antd';


class ProgressBarFake extends React.Component {
  state = {
    percent: 0
  };

  UNSAFE_componentWillMount() {
    this.updateAutomatic(100);
  }

  componentWillUnmount() {
    clearTimeout(this._timeout);
  }

  updateAutomatic(time) {
    // 90% because we don't want it to go to 100
    if (this.state.percent >= 90) {
      return;
    }

    this._timeout = setTimeout(
      () => {
        this.setState(
          state => ({ percent: state.percent + 1 }),
          () => {
            const { percent } = this.state;
            const base = percent * percent;
            const random = Math.floor((Math.random() * base) + 1);
            this.updateAutomatic(base + random);
          }
        );
      },
      time
    );
  }


  render() {
    return (
      <Progress percent={this.state.percent} size="small" status="active" />
    );
  }
}

export default ProgressBarFake;
