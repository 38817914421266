import React from 'react';
import PropTypes from 'prop-types';

import styles from './DropdownElement.css';

DropdownElement.propTypes = {
  children: PropTypes.node.isRequired
};

function DropdownElement(props) {
  return (
    <li className={styles.li}>{props.children}</li>
  );
}

export default DropdownElement;