import React from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as navigationActions from 'redux/actions/navigation';

import { Layout } from 'antd';

class _container extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    match: PropTypes.object.isRequired,
    navigationActions: PropTypes.object.isRequired
  };

  componentDidMount() {
    const { url, path } = this.props.match;
    const urlCorrected = !/\/$/.test(url) && /\/$/.test(path) ? `${url}/` : url;
    this._url = urlCorrected;
    this.props.navigationActions.breadcrumbAdd('Create', urlCorrected, this._url);
  }

  componentWillUnmount() {
    this.props.navigationActions.breadcrumbRemove(this._url);
  }

  render() {
    return (
      <Layout style={{ maxWidth: 1000, margin: '60px auto' }}>
        {this.props.children}
      </Layout>
    );
  }
}

export default connect(
  state => ({}),
  dispatch => ({
    navigationActions: bindActionCreators(navigationActions, dispatch)
  })
)(_container);
