import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';

import { Card } from 'antd';

class _default extends React.Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };

  componentDidMount() {
    this.props.history.replace('services/');
  }

  render() {
    const { stackId } = this.props.match.params;
    const route = `/dashboard/stacks/${stackId}`;
    const stack = this.props.api.subscriptions[route].datas;
    return (
      <Card title={`Stack ${stack.name}`}>
        <Link to="services/">See services</Link>
      </Card>
    );
  }
}

export default connect(
  ({ api }) => ({ api })
)(_default);
