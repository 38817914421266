import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

// The goal here is to open the link in a new window if the host of the link is different from the current host

class LinkClass extends React.Component {
  static propTypes = {
    url: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired
  };

  render() {
    const { url, children, ...propsFiltered } = this.props;

    if (url.indexOf(`${process.env.HTTP_SCHEME}://${document.location.host}/`) === 0 || url[0] === '/') {
      if (url.indexOf('#') !== -1) {
        return (
          <HashLink
            to={url.replace(`${process.env.HTTP_SCHEME}://${document.location.host}`, '')}
            {...propsFiltered}
            smooth
          >
            {children}
          </HashLink>
        );
      }
      else {
        return (
          <Link
            to={url.replace(`${process.env.HTTP_SCHEME}://${document.location.host}`, '')}
            {...propsFiltered}
          >
            {children}
          </Link>
        );
      }
    }
    else {
      return (
        <a
          href={url}
          target="_blank"
          rel="noreferrer"
          {...propsFiltered}
        >
          {children}
        </a>
      );
    }
  }
}

export default LinkClass;
