import React from 'react';
import PropTypes from 'prop-types';

import styles from './RouterTransitions.css';

class RouterTransitions extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired
  };

  componentDidMount() {
    // Scroll to top when we change page
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className={styles.routerTransitions}>
        {this.props.children}
      </div>
    );
  }
}

export default RouterTransitions;
