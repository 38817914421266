import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';

import { UpOutlined } from '@ant-design/icons';

import styles from './ListCollapse.css';

class ListCollapse extends React.Component {
  static propTypes = {
    items: PropTypes.array.isRequired,
    location: PropTypes.object.isRequired
  };

  state = {
    openedIds: [],
  };


  componentDidMount() {
    this.setState({
      openedIds: [ this.props.items.findIndex(item => item.subItems && this.props.location.pathname.indexOf(item.url) === 0) ]
    });
  }


  toggle(id) {
    this.setState(({ openedIds }) => ({
      openedIds:
        openedIds.indexOf(id) === -1
          ?
            [ ...openedIds, id ]
          :
            openedIds.filter(idInArray => idInArray !== id)
    }));
  }


  renderItems(items) {
    return (
      <ul>
        {items.map((item, index) => ( <React.Fragment key={index}>{this.renderItem(item, index)}</React.Fragment> ))}
      </ul>
    );
  }


  renderItem(item, id) {
    if (item.subItems) {
      const opened = this.state.openedIds.indexOf(id) !== -1;
      return (
        <>
          <li
            className={[ styles.liWithSubItems, opened ? styles.liWithSubItemsOpened : null ].join(' ')}
          >
            <button
              onClick={() => this.toggle(id)}
            >
              <span>{item.label}</span>
              <UpOutlined />
            </button>
          </li>
          {opened && this.renderItems(item.subItems)}
        </>
      );
    }

    if (item.url) {
      const selected = this.props.location.pathname.indexOf(item.url) === 0;
      return (
        <li className={selected ? styles.liSelected : null}>
          {
            item.url.indexOf('https://') === 0
              ? (
                <a href={item.url} target="_blank" rel="noopener noreferrer">
                  {item.label}
                </a>
              )
              : (
                <Link to={item.url}>
                  {item.label}
                </Link>
              )
          }
        </li>
      );
    }
    else if (item.mailto) {
      return (
        <li>
          <a href={item.mailto}>
            {item.label}
          </a>
        </li>
      );
    }
    else if (item.onClick) {
      return (
        <li>
          <button
            onClick={item.onClick}
          >
            {item.label}
          </button>
        </li>
      );
    }
    else {
      throw Error('Item should have "url", "mailto" or "onClick" parameter.');
    }
  }


  render() {
    return (
      <div className={styles.container}>
        {this.renderItems(this.props.items)}
      </div>
    );
  }
}

export default withRouter(ListCollapse);
