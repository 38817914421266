/* eslint-disable react/no-danger */

import React from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as apiActions from 'redux/actions/api';

import { Form, Button, Modal, Alert } from 'antd';

import FormItems from 'components/FormItems';

import { marked } from 'marked';
import highlight from 'highlight.js/lib/core';
import yaml from 'highlight.js/lib/languages/yaml';
highlight.registerLanguage('yaml', yaml);
import highlightCss from 'highlight.js/styles/monokai.css';

import markdownStyles from './Markdown.css';

class ModalPrometheusToken extends React.Component {
  static propTypes = {
    apiActions: PropTypes.object.isRequired,
    organizationId: PropTypes.string.isRequired,
    enabled: PropTypes.bool.isRequired
  };

  state = this.generateState();

  componentDidMount() {
    this._mounted = true;
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  generateState() {
    return {
      modalVisible: false,
      formIsValid: true,
      form: {
        description: '',
        cidrsAllowed: [ '0.0.0.0/0' ]
      },
      error: null,
      showErrors: false
    };
  }

  show = () => {
    this.setState({
      ...this.generateState(),
      modalVisible: true
    });
  };


  handleOk = () => {
    this.setState({ showErrors: true });

    if (!this.state.formIsValid) {
      return false;
    }

    this.setState({ error: null });

    const { description, cidrsAllowed } = this.state.form;

    const { organizationId } = this.props;
    this.props.apiActions.post(
      {
        route: `/dashboard/organizations/${organizationId}/prometheusTokens`,
        routeArgs: { description, cidrsAllowed: cidrsAllowed.filter(v => v) }
      },
      (error, result) => {
        if (!this._mounted) { return false; }

        const classReplacer = string =>
          string
            // Webpack change classnames, so we replace them per there new names
            .replace(
              /class="(hljs-[^"]*)"/g,
              (match, p1) => `class="${p1} ${highlightCss[p1] || p1}"`
            )
            // Fix to prevent Marked to replace tabs per spaces
            .replace(/\\t/g, '\t');

        marked.use({
          highlight: (code, language) => {
            const languageFound = language && highlight.getLanguage(language);
            if (languageFound) {
              return classReplacer(highlight.highlight(code, { language }).value);
            }
            if (language) {
              console.warn(`Language "${language}" not found in HighlightJs`);
            }
            return classReplacer(highlight.highlightAuto(code).value);
          }
        });

        const { organizationId } = this.props;
        const content = `
Here is the configuration you can add at the end of your \`prometheus.yml\` file:

\`\`\`yaml
# Caution: the following line "- job_name" begins with 2 spaces!
  - job_name: "stackhero"
    scheme: "https"
    basic_auth:
      username: "${organizationId}"
      password: "${result.token}"
    tls_config:
      insecure_skip_verify: true
    http_sd_configs:
    - url: "${process.env.HTTP_API_HOST}/v1/prometheus/targets"
      refresh_interval: "15s" # Do not set it to less than 15 seconds!
      basic_auth:
        username: "${organizationId}"
        password: "${result.token}"
\`\`\`

> You should copy this code now as, by security, you will not be able to retrieve it later!`;

        Modal.success({
          title: 'Token created!',
          width: 800,
          content: (
            <>
              <div
                className={markdownStyles.markdown}
                dangerouslySetInnerHTML={{ __html: marked.parse(content) }}
              />
            </>
          ),
        });

        this.setState({ modalVisible: false });
      }
    );
  };


  handleCancel = () => {
    this.setState(this.generateState());
  };


  handleSubmit = (e) => {
    this.handleOk();
  };


  renderError() {
    if (!this.state.error) {
      return null;
    }

    return (
      <Form.Item>
        <Alert
          message="Error"
          description={this.state.error}
          type="error"
          showIcon
        />
      </Form.Item>
    );
  }


  renderFooter = () => {
    return [
      ( <Button key="cancel" onClick={this.handleCancel}>Cancel</Button> ),
      (
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          onClick={this.handleOk}
          disabled={!this.state.formIsValid}
        >
          Create
        </Button>
      )
    ];
  };


  render() {
    const entries = [
      {
        label: 'Description:',
        name: 'description',
        required: false,
        help: 'Just a note for you to remember it',
        input: {
          type: 'text',
          hasError: (name, value, form) => value.length > 128,
        }
      },
      {
        label: 'Allowed IPs',
        name: 'cidrsAllowed',
        required: false,
        extra: (<>
          0.0.0.0/0 means all IPs.<br />
          x.x.x.x/32 means only IP x.x.x.x.
        </>),
        input: {
          type: 'arrayText',
          min: 1,
          max: 10,
          onChange: value => value.replace(/[^0-9. /]+/g, ''),
          hasError: (name, value, form) => value && /^[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}\/[0-9]{1,2}$/.test(value) !== true
        }
      }
    ];

    return [
      (
        <Modal
          key="modal"
          title="Create a Prometheus token"
          open={this.state.modalVisible}
          onCancel={this.handleCancel}
          footer={this.renderFooter()}
        >
          <Form onFinish={this.handleSubmit}>
            {this.renderError()}
            <FormItems
              sizes={{ xs: 24, sm: 6 }}
              entries={entries}
              onStatusChange={formIsValid => this.setState({ formIsValid })}
              datas={this.state.form}
              onUpdate={form => this.setState({ form })}
              showErrors={this.state.showErrors}
            />

            {/* To handle the submission on enter key */}
            <Button htmlType="submit" style={{ display: 'none' }} />
          </Form>
        </Modal>
      )
      ,
      (
        <Button
          key="button"
          type="primary"
          onClick={() => this.show() }
          disabled={!this.props.enabled}
          title="You can create up to 3 tokens"
        >
          Create a token
        </Button>
      )
    ];
  }
}

export default connect(
  () => ({}),
  dispatch => ({
    apiActions: bindActionCreators(apiActions, dispatch)
  })
)(ModalPrometheusToken);
