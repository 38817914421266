import logo from 'images/servicesLogos/postfix.svg';
import logoOpenGraph from 'images/servicesLogos/openGraphVersions/postfix.png';

import growth from 'images/landings/icons/growth.svg';
import startup from 'images/landings/icons/startup.svg';
import shield from 'images/landings/icons/shield.svg';
import packageSvg from 'images/landings/icons/package.svg';
import strategy from 'images/landings/icons/strategy.svg';
import medal from 'images/landings/icons/medal.svg';

import checked from 'images/landings/icons/checked.svg';

const backgroundColor = '#ef2929';
const button = {
  color: '#555',
  backgroundColor: '#fff'
};

export default ({ t = () => [] }) => ({
  id: 'postfix',
  name: 'Postfix',
  nameUrl: 'Postfix',
  title: t('services/postfix:title'),
  logo,
  logoOpenGraph,
  button,
  backgroundColor,

  header: {
    title: 'Postfix',
    description: t('services/postfix:description'),
    images: []
  },

  benefits: {
    items: [
      {
        icon: growth,
        ...t('services/postfix:benefits.business')
      },
      {
        icon: startup,
        ...t('services/postfix:benefits.performant')
      },
      {
        icon: shield,
        ...t('services/postfix:benefits.secured')
      },
      {
        icon: medal,
        ...t('services/postfix:benefits.unlimited')
      },
      {
        icon: packageSvg,
        ...t('services/postfix:benefits.including')
      },
      {
        icon: strategy,
        ...t('services/postfix:benefits.updatable')
      }
    ]
  },

  also: {
    color: backgroundColor,
    items: t('services/postfix:also.items')
      .map(content => ({ icon: checked, ...content }))
  },

  pricing: {
    buttonBackgroundColor: backgroundColor,
    buttonColor: '#fff',
    sentencesIconColor: button.backgroundColor,
  },

  recall: {
    description: t('services/postfix:recall')
  },

  faq: {
    items: t('services/postfix:faq.items')
  }
});

