import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Button, Divider, Card, Tooltip } from 'antd';
import { CheckCircleTwoTone } from '@ant-design/icons';

import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

import coffee from 'images/landings/icons/coffee.svg';
import flask from 'images/landings/icons/flask.svg';
import office from 'images/landings/icons/office.svg';

import styles from './InstanceStore.css';

class InstanceStore extends React.Component {
  static propTypes = {
    currency: PropTypes.string.isRequired,
    instanceStore: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    features: PropTypes.array
  };


  _usages = {
    hobby: {
      label: 'Hobby',
      icon: coffee
    },
    startup: {
      label: 'Startup',
      icon: flask
    },
    enterprise: {
      label: 'Enterprise',
      icon: office
    }
  };

  fields(serviceStoreName) {
    const { t } = this.props;
    const fields = {
      rabbitmq: {
        maxMessagesPerSecond: v => (
          <>
            <span className={styles.strongLegend}>{v > 0 ? new Intl.NumberFormat(undefined).format(v) : 'unlimited'}</span>
            <br />
            <span className={styles.subLegend}>max. messages/second</span>
          </>
        ),
        maxConcurrentConnections: v => (
          <>
            <span className={styles.strongLegend}>{new Intl.NumberFormat(undefined).format(v)}</span>
            <br/>
            <span className={styles.subLegend}>max. simultaneous connections</span>
          </>
        ),
        hdd: v => (
          <>
            <span className={styles.strongLegend}>{v < 1024 * 1024 * 1024 ? `${Math.round(v / (1024 * 1024))} ${t('units.MB')}` : `${Math.round(v / (1024 * 1024 * 1024))} ${t('units.GB')}`}</span>
            <br />
            <span className={styles.subLegend}>SSD disk for persistent messages</span>
          </>
        ),
      },
      redis: {
        size: v => (
          <>
            <span className={styles.strongLegend}>{v < 1024 * 1024 * 1024 ? `${v / 1024 / 1024} ${t('units.MB')}` : `${Math.round(v / 1024 / 1024 / 1024)} ${t('units.GB')}`}</span>
            <br/>
            <span className={styles.subLegend}>size</span>
          </>
        ),
        maxConcurrentConnections: v => (
          <>
            <span className={styles.strongLegend}>{new Intl.NumberFormat(undefined).format(v)}</span>
            <br/>
            <span className={styles.subLegend}>max. simultaneous connections</span>
          </>
        ),
        maxDatabases: v => (
          <>
            <span className={styles.strongLegend}>{new Intl.NumberFormat(undefined).format(v)}</span>
            <br/>
            <span className={styles.subLegend}>max. databases</span>
          </>
        ),
        persistent: v => (
          <>
            <span className={styles.strongLegend}>{v ? '✅' : '❌'}</span>
            <br/>
            <span className={styles.subLegend}>persistent</span>
          </>
        )
      },
      valkey: {
        size: v => (
          <>
            <span className={styles.strongLegend}>{v < 1024 * 1024 * 1024 ? `${v / 1024 / 1024} ${t('units.MB')}` : `${Math.round(v / 1024 / 1024 / 1024)} ${t('units.GB')}`}</span>
            <br/>
            <span className={styles.subLegend}>size</span>
          </>
        ),
        maxConcurrentConnections: v => (
          <>
            <span className={styles.strongLegend}>{new Intl.NumberFormat(undefined).format(v)}</span>
            <br/>
            <span className={styles.subLegend}>max. simultaneous connections</span>
          </>
        ),
        maxDatabases: v => (
          <>
            <span className={styles.strongLegend}>{new Intl.NumberFormat(undefined).format(v)}</span>
            <br/>
            <span className={styles.subLegend}>max. databases</span>
          </>
        ),
        persistent: v => (
          <>
            <span className={styles.strongLegend}>{v ? '✅' : '❌'}</span>
            <br/>
            <span className={styles.subLegend}>persistent</span>
          </>
        )
      },
      matomo: {
        vCpus: v => (
          <>
            <span className={styles.strongLegend}>{v}</span>
            <br />
            <span className={styles.subLegend}>{v === 1 ? 'vCPU' : 'vCPUs'}</span>
          </>
        ),
        ram: v => (
          <>
            <span className={styles.strongLegend}>{v < 1024 * 1024 * 1024 ? `${Math.round(v / (1024 * 1024))} ${t('units.MB')}` : `${Math.round(v / (1024 * 1024 * 1024))} ${t('units.GB')}`}</span>
            <br />
            <span className={styles.subLegend}>RAM</span>
          </>
        ),
        hdd: v => (
          <>
            <span className={styles.strongLegend}>{v < 1024 * 1024 * 1024 ? `${Math.round(v / (1024 * 1024))} ${t('units.MB')}` : `${Math.round(v / (1024 * 1024 * 1024))} ${t('units.GB')}`}</span>
            <br />
            <span className={styles.subLegend}>SSD disk</span>
          </>
        ),
        maxConcurrentConnections: v => (
          <>
            <span className={styles.strongLegend}>{new Intl.NumberFormat(undefined).format(v)}</span>
            <br/>
            <span className={styles.subLegend}>max. simultaneous connections</span>
          </>
        ),
        pageViewsRecommended: v => (
          <>
            <span className={styles.strongLegend}>{new Intl.NumberFormat(undefined).format(v)}</span>
            <br/>
            <span className={styles.subLegend}>page views/month recommended</span>
          </>
        )
      },
      mattermost: {
        usersRecommended: v => (
          <>
            <span className={styles.strongLegend}>{new Intl.NumberFormat(undefined).format(v)}</span>
            <br/>
            <span className={styles.subLegend}>users recommended</span>
          </>
        ),
        hdd: v => (
          <>
            <span className={styles.strongLegend}>{v < 1024 * 1024 * 1024 ? `${Math.round(v / (1024 * 1024))} ${t('units.MB')}` : `${Math.round(v / (1024 * 1024 * 1024))} ${t('units.GB')}`}</span>
            <br />
            <span className={styles.subLegend}>SSD disk for persistent messages</span>
          </>
        ),
      },
      gitlab: {
        vCpus: v => (
          <>
            <span className={styles.strongLegend}>{v}</span>
            <br />
            <span className={styles.subLegend}>{v === 1 ? 'vCPU' : 'vCPUs'}</span>
          </>
        ),
        ram: v => (
          <>
            <span className={styles.strongLegend}>{v < 1024 * 1024 * 1024 ? `${Math.round(v / (1024 * 1024))} ${t('units.MB')}` : `${Math.round(v / (1024 * 1024 * 1024))} ${t('units.GB')}`}</span>
            <br />
            <span className={styles.subLegend}>RAM</span>
          </>
        ),
        hdd: v => (
          <>
            <span className={styles.strongLegend}>{v < 1024 * 1024 * 1024 ? `${Math.round(v / (1024 * 1024))} ${t('units.MB')}` : `${Math.round(v / (1024 * 1024 * 1024))} ${t('units.GB')}`}</span>
            <br />
            <span className={styles.subLegend}>SSD disk</span>
          </>
        ),
        usersAndRepositories: v => (
          <>
            <span className={styles.strongLegend}>unlimited</span>
            <br/>
            <span className={styles.subLegend}>users & repositories</span>
          </>
        ),
        ciCdMinutes: v => (
          <>
            <span className={styles.strongLegend}>unlimited</span>
            <br/>
            <span className={styles.subLegend}>CI/CD time</span>
          </>
        ),
        transfers: v => (
          <>
            <span className={styles.strongLegend}>unlimited</span>
            <br/>
            <span className={styles.subLegend}>transfers</span>
          </>
        )
      },
      'mercure-hub': {
        hdd: v => (
          <>
            <span className={styles.strongLegend}>{v < 1024 * 1024 * 1024 ? `${Math.round(v / (1024 * 1024))} ${t('units.MB')}` : `${Math.round(v / (1024 * 1024 * 1024))} ${t('units.GB')}`}</span>
            <br />
            <span className={styles.subLegend}>for history & logs storage (SSD disk)</span>
          </>
        ),
        maxConcurrentConnections: v => (
          <>
            <span className={styles.strongLegend}>{new Intl.NumberFormat(undefined).format(v)}</span>
            <br/>
            <span className={styles.subLegend}>max. simultaneous connections</span>
          </>
        ),
        messagesPerSecond: v => (
          <>
            <span className={styles.strongLegend}>{v > 0 ? new Intl.NumberFormat(undefined).format(v) : 'unlimited'}</span>
            <br/>
            <span className={styles.subLegend}>messages per second</span>
          </>
        ),
        postsPerSecond: v => (
          <>
            <span className={styles.strongLegend}>{v > 0 ? new Intl.NumberFormat(undefined).format(v) : 'unlimited'}</span>
            <br/>
            <span className={styles.subLegend}>POST per second</span>
          </>
        ),
        maxMessageSize: v => (
          <>
            <span className={styles.strongLegend}>{v > 0 ? new Intl.NumberFormat(undefined).format(v) : 'unlimited'}</span>
            <br/>
            <span className={styles.subLegend}>messages size</span>
          </>
        )
      },
      nextcloud: {
        vCpus: v => (
          <>
            <span className={styles.strongLegend}>{v}</span>
            <br />
            <span className={styles.subLegend}>{v === 1 ? 'vCPU' : 'vCPUs'}</span>
          </>
        ),
        ram: v => (
          <>
            <span className={styles.strongLegend}>{v < 1024 * 1024 * 1024 ? `${Math.round(v / (1024 * 1024))} ${t('units.MB')}` : `${Math.round(v / (1024 * 1024 * 1024))} ${t('units.GB')}`}</span>
            <br />
            <span className={styles.subLegend}>RAM</span>
          </>
        ),
        hdd: v => (
          <>
            <span className={styles.strongLegend}>{v < 1024 * 1024 * 1024 ? `${Math.round(v / (1024 * 1024))} ${t('units.MB')}` : `${Math.round(v / (1024 * 1024 * 1024))} ${t('units.GB')}`}</span>
            <br />
            <span className={styles.subLegend}>disk</span>
          </>
        ),
        users: v => (
          <>
            <span className={styles.strongLegend}>{v > 0 ? new Intl.NumberFormat(undefined).format(v) : 'unlimited'}</span>
            <br/>
            <span className={styles.subLegend}>users, apps & transfers</span>
          </>
        ),
        antivirus: v => (
          <>
            <span className={styles.strongLegend}>{v ? '✅' : '❌'}</span>
            <br/>
            <span className={styles.subLegend}>antivirus</span>
          </>
        )
      },
      grafana: {
        vCpus: v => (
          <>
            <span className={styles.strongLegend}>{v}</span>
            <br />
            <span className={styles.subLegend}>{v === 1 ? 'vCPU' : 'vCPUs'}</span>
          </>
        ),
        ram: v => (
          <>
            <span className={styles.strongLegend}>{v < 1024 * 1024 * 1024 ? `${Math.round(v / (1024 * 1024))} ${t('units.MB')}` : `${Math.round(v / (1024 * 1024 * 1024))} ${t('units.GB')}`}</span>
            <br />
            <span className={styles.subLegend}>RAM</span>
          </>
        ),
        hdd: v => (
          <>
            <span className={styles.strongLegend}>{v < 1024 * 1024 * 1024 ? `${Math.round(v / (1024 * 1024))} ${t('units.MB')}` : `${Math.round(v / (1024 * 1024 * 1024))} ${t('units.GB')}`}</span>
            <br />
            <span className={styles.subLegend}>disk</span>
          </>
        ),
        pluginsCountMax: v => (
          <>
            <span className={styles.strongLegend}>{new Intl.NumberFormat(undefined).format(v)}</span>
            <br/>
            <span className={styles.subLegend}>installable plugins</span>
          </>
        )
      },
      mosquitto: {
        maxConcurrentConnections: v => (
          <>
            <span className={styles.strongLegend}>{new Intl.NumberFormat(undefined).format(v)}</span>
            <br/>
            <span className={styles.subLegend}>max. simultaneous connections</span>
          </>
        ),
        vCpus: v => (
          <>
            <span className={styles.strongLegend}>{v}</span>
            <br />
            <span className={styles.subLegend}>{v === 1 ? 'vCPU' : 'vCPUs'}</span>
          </>
        ),
        ram: v => (
          <>
            <span className={styles.strongLegend}>{v < 1024 * 1024 * 1024 ? `${Math.round(v / (1024 * 1024))} ${t('units.MB')}` : `${Math.round(v / (1024 * 1024 * 1024))} ${t('units.GB')}`}</span>
            <br />
            <span className={styles.subLegend}>RAM</span>
          </>
        )
      },
      minio: {
        hdd: v => (
          <>
            <span className={styles.strongLegend}>{v < 1024 * 1024 * 1024 ? `${Math.round(v / (1024 * 1024))} ${t('units.MB')}` : `${Math.round(v / (1024 * 1024 * 1024))} ${t('units.GB')}`}</span>
            <br />
            <span className={styles.subLegend}>SSD disk for persistent messages</span>
          </>
        ),
        maxConcurrentConnections: v => (
          <>
            <span className={styles.strongLegend}>{new Intl.NumberFormat(undefined).format(v)}</span>
            <br/>
            <span className={styles.subLegend}>max. simultaneous connections</span>
          </>
        ),
        transfersPerMonth: v => (
          <>
            <span className={styles.strongLegend}>{v > 0 ? new Intl.NumberFormat(undefined).format(v) : 'unlimited'}</span>
            <br />
            <span className={styles.subLegend}>transfers/month</span>
          </>
        ),
        bandwidthBitsPerSecond: v => (
          <>
            <span className={styles.strongLegend}>{`${Math.round(v / 1024 / 1024)} Mbps`}</span>
            <br />
            <span className={styles.subLegend}>bandwidth</span>
          </>
        )
      },
      prometheus: {
        vCpus: v => (
          <>
            <span className={styles.strongLegend}>{v}</span>
            <br />
            <span className={styles.subLegend}>{v === 1 ? 'vCPU' : 'vCPUs'}</span>
          </>
        ),
        ram: v => (
          <>
            <span className={styles.strongLegend}>{v < 1024 * 1024 * 1024 ? `${Math.round(v / (1024 * 1024))} ${t('units.MB')}` : `${Math.round(v / (1024 * 1024 * 1024))} ${t('units.GB')}`}</span>
            <br />
            <span className={styles.subLegend}>RAM</span>
          </>
        ),
        hdd: v => (
          <>
            <span className={styles.strongLegend}>{v < 1024 * 1024 * 1024 ? `${Math.round(v / (1024 * 1024))} ${t('units.MB')}` : `${Math.round(v / (1024 * 1024 * 1024))} ${t('units.GB')}`}</span>
            <br />
            <span className={styles.subLegend}>SSD disk</span>
          </>
        ),
        retentionMaxDays: v => (
          <>
            <span className={styles.strongLegend}>
              {
                v === 365
                  ? '1 year'
                  : v >= 365
                    ? `${v / 365} years`
                    : `${v} days`
              }
            </span>
            <br/>
            <span className={styles.subLegend}>Maximal retention</span>
          </>
        )
      },
      memcached: {
        size: v => (
          <>
            <span className={styles.strongLegend}>{v < 1024 * 1024 * 1024 ? `${v / 1024 / 1024} ${t('units.MB')}` : `${Math.round(v / 1024 / 1024 / 1024)} ${t('units.GB')}`}</span>
            <br/>
            <span className={styles.subLegend}>size</span>
          </>
        ),
        maxConcurrentConnections: v => (
          <>
            <span className={styles.strongLegend}>{new Intl.NumberFormat(undefined).format(v)}</span>
            <br/>
            <span className={styles.subLegend}>max. simultaneous connections</span>
          </>
        )
      },
      directus: {
        vCpus: v => (
          <>
            <span className={styles.strongLegend}>{v}</span>
            <br />
            <span className={styles.subLegend}>{v === 1 ? 'vCPU' : 'vCPUs'}</span>
          </>
        ),
        ram: v => (
          <>
            <span className={styles.strongLegend}>{v < 1024 * 1024 * 1024 ? `${Math.round(v / (1024 * 1024))} ${t('units.MB')}` : `${Math.round(v / (1024 * 1024 * 1024))} ${t('units.GB')}`}</span>
            <br />
            <span className={styles.subLegend}>RAM</span>
          </>
        ),
        hdd: v => (
          <>
            <span className={styles.strongLegend}>{v < 1024 * 1024 * 1024 ? `${Math.round(v / (1024 * 1024))} ${t('units.MB')}` : `${Math.round(v / (1024 * 1024 * 1024))} ${t('units.GB')}`}</span>
            <br />
            <span className={styles.subLegend}>SSD disk</span>
          </>
        ),
        maxConcurrentConnections: v => (
          <>
            <span className={styles.strongLegend}>{new Intl.NumberFormat(undefined).format(v)}</span>
            <br/>
            <span className={styles.subLegend}>max. simultaneous connections</span>
          </>
        )
      },
      'node-red': {
        vCpus: v => (
          <>
            <span className={styles.strongLegend}>{v}</span>
            <br />
            <span className={styles.subLegend}>{v === 1 ? 'vCPU' : 'vCPUs'}</span>
          </>
        ),
        ram: v => (
          <>
            <span className={styles.strongLegend}>{v < 1024 * 1024 * 1024 ? `${Math.round(v / (1024 * 1024))} ${t('units.MB')}` : `${Math.round(v / (1024 * 1024 * 1024))} ${t('units.GB')}`}</span>
            <br />
            <span className={styles.subLegend}>RAM</span>
          </>
        ),
        hdd: v => (
          <>
            <span className={styles.strongLegend}>{v < 1024 * 1024 * 1024 ? `${Math.round(v / (1024 * 1024))} ${t('units.MB')}` : `${Math.round(v / (1024 * 1024 * 1024))} ${t('units.GB')}`}</span>
            <br />
            <span className={styles.subLegend}>disk</span>
          </>
        ),
        usersAdminMaximum: v => (
          <>
            <span className={styles.strongLegend}>{new Intl.NumberFormat(undefined).format(v)}</span>
            <br/>
            <span className={styles.subLegend}>admin users</span>
          </>
        )
      },
    };

    return fields[serviceStoreName]
      ? fields[serviceStoreName]
      : {
        vCpus: v => (
          <>
            <span className={styles.strongLegend}>{v}</span>
            <br />
            <span className={styles.subLegend}>{v > 1 ? ' vCPUs' : ' vCPU'}</span>
          </>
        ),
        ram: v => (
          <>
            <span className={styles.strongLegend}>{v < 1024 * 1024 * 1024 ? `${Math.round(v / (1024 * 1024))} ${t('units.MB')}` : `${Math.round(v / (1024 * 1024 * 1024))} ${t('units.GB')}`}</span>
            <br />
            <span className={styles.subLegend}>RAM</span>
          </>
        ),
        hdd: v => (
          <>
            <span className={styles.strongLegend}>{v < 1024 * 1024 * 1024 ? `${Math.round(v / (1024 * 1024))} ${t('units.MB')}` : `${Math.round(v / (1024 * 1024 * 1024))} ${t('units.GB')}`}</span>
            <br />
            <span className={styles.subLegend}>SSD disk storage</span>
          </>
        )
      };
  }


  renderPrice(instance) {
    if (!instance.prices) {
      return null;
    }

    const { currency } = this.props;
    const { amount } = instance.prices.find(b => b.currency === currency);
    const priceFormatedPerMonth = new Intl
      .NumberFormat(
        undefined,
        {
          style: 'currency',
          currency,
          minimumFractionDigits: amount === Math.round(amount) ? 0 : 2
        }
      )
      .format(amount);

    const amountPerHour = amount / (30 * 24);
    const priceFormatedPerHour = new Intl
      .NumberFormat(
        undefined,
        {
          style: 'currency',
          currency,
          minimumFractionDigits: 2
        }
      )
      .format(amountPerHour);

    // return (
    //   <>
    //     <DollarCircleTwoTone /> {priceFormatedPerHour}/hour ({priceFormatedPerMonth}/month)
    //     <Divider dashed className={styles.diviver} />
    //   </>
    // );

    return (
      <div style={{ textAlign: 'center', marginBottom: 20 }}>
        <span className={styles.strongLegend}>{priceFormatedPerHour} per hour</span>
        <br />
        <span style={{ fontSize: 13 }}>(or {priceFormatedPerMonth}/month)</span>
      </div>
    );
  }


  render() {
    const { instanceStore, onClick } = this.props;
    const fields = this.fields(instanceStore.serviceStoreName);

    const statuses = {
      available: {
        label: 'Start now',
        prompt: null,
        disabled: false
      },
      paymentMethodVerificationRequired: {
        label: 'Start now',
        prompt: null,
        disabled: false
      },
      emailHasToBeValidated: {
        label: 'Email to validate',
        prompt: 'To enable this plan please check your emails to validate your email address.',
        disabled: true
      },
      notAvailable: {
        label: 'Not available',
        prompt: 'This plan is not available for your account yet. Please contact the support to enable it.',
        disabled: true
      },
      paymentMethodRequired: {
        label: 'Add a payment method',
        prompt: null,
        disabled: false
      },
    };

    if (!statuses[instanceStore.status]) {
      throw Error(`Unknown status "${instanceStore.status}"`);
    }

    const { label, disabled, prompt } = statuses[instanceStore.status];

    // Note: data parameters are used by factory/tests
    return (
      <Card
        title={(
          <div style={{ textAlign: 'center' }}>
            <img
              src={this._usages[instanceStore.usage].icon}
              alt={instanceStore.usage}
              width={24}
              height={24}
              style={{ marginRight: 20 }}
            />
            {this._usages[instanceStore.usage].label}
          </div>
        )}
        className={styles.card}
        hoverable
      >

        {Object.keys(fields)
          .map(key => (
            <Fragment key={key}>
              {fields[key](instanceStore.configuration[key])}
              <Divider dashed className={styles.diviver} />
            </Fragment>
          ))
        }

        <CheckCircleTwoTone twoToneColor="#52c41a" /> Dedicated instance
        <Divider dashed className={styles.diviver} />

        {
          instanceStore.ipDedicatedRequired
            ? (
              <>
                <CheckCircleTwoTone twoToneColor="#52c41a" /> Dedicated IPv4
                <Divider dashed className={styles.diviver} />
              </>
            )
            : null
        }

        <CheckCircleTwoTone twoToneColor="#52c41a" /> Backup every 24 hours
        <Divider dashed className={styles.diviver} />

        <CheckCircleTwoTone twoToneColor="#52c41a" /> Billing per hour

        {(this.props.features || []).map(feature => (
          <React.Fragment key={feature}>
            <Divider dashed className={styles.diviver} />
            {feature}
          </React.Fragment>
        ))}

        <Divider className={styles.diviver} />

        {this.renderPrice(instanceStore)}

        <Tooltip title={prompt}>
          <Button type="primary" block size="large" onClick={onClick} disabled={disabled}>
            {label}
          </Button>
        </Tooltip>
      </Card>
    );
  }
}

export default compose(
  withTranslation()
)(InstanceStore);
