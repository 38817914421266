import React from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as apiActions from 'redux/actions/api';

import { Button } from 'antd';
import { ReadOutlined  } from '@ant-design/icons';
import isEqual from 'lodash.isequal';

import Spinner from 'components/Spinner';
import MarkdownWithHighlight from 'components/MarkdownWithHighlight';
import LinkLayout from 'components/Link';
import Summary from 'components/documentations/Summary';

import styles from './ServiceGettingStarted.css';

import Utils from 'modules/Utils';
const utils = new Utils();

class ServiceGettingStarted extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    apiActions: PropTypes.object.isRequired,
    service: PropTypes.object.isRequired
  };


  state = {
    documentationEntry: null,
    documentationSubEntries: null,
    documentation: null
  };


  componentDidMount() {
    this._mounted = true;
    this.documentationsLoad();
  }


  componentDidUpdate(prevProps) {
    if (prevProps.service.id !== this.props.service.id) {
      this.documentationsLoad();
    }
    else if (!isEqual(prevProps.service.environmentVariables, this.props.service.environmentVariables)) {
      this.variableSubstitution();
    }
  }


  componentWillUnmount() {
    this._mounted = false;
  }


  documentationsLoad() {
    this.setState(
      { documentation: null },
      () => this.props.apiActions.requestNew(
        {
          url: `${process.env.HTTP_SCHEME}://${process.env.SHOWCASE_HOST}/documentations_${this.props.app.language}.json?${process.env.VERSION}`,
          headers: { 'Accept': '*/*' } // Needed on dev, else we get a 404
        },
        (error, documentations) => {
          if (!this._mounted || error) {
            return;
          }

          const serviceStoreName = this.props.service.serviceStore.name;
          const documentationsFromServiceStore = documentations[0].subEntries.find(subEntry => subEntry.id === serviceStoreName);
          if (!documentationsFromServiceStore) {
            this.setState({ documentation: undefined });
            return;
          }

          const documentationEntry = documentationsFromServiceStore.subEntries.find(({ type }) => type === 'tlDr');
          if (!documentationEntry) {
            this.setState({ documentation: undefined });
            return;
          }

          const { directory, file } = documentationEntry;
          this.props.apiActions.requestNew(
            {
              url: `${process.env.HTTP_SCHEME}://${process.env.SHOWCASE_HOST}/${directory}/${file}?${process.env.VERSION}`,
              headers: { 'Accept': '*/*' } // Needed on dev, else we get a 404
            },
            (error, documentation) => {
              if (!this._mounted || error) {
                return;
              }
              this.setState(
                {
                  documentationEntry,
                  documentationSubEntries: documentationsFromServiceStore.subEntries,
                  documentation: documentation
                    // Remove metadata from documentationContent
                    .replace(/^<!--[\s\S]*?-->[\n]*([^]*)$/, '$1')
                },
                () => this.variableSubstitution()
              );
            }
          );
        }
      )
    );
  }


  variableSubstitution() {
    const environmentVariables = {
      ...this.props.service.environmentVariables,
      SERVICE_ID: this.props.service.id,
    };
    this.setState(state => {
      if (!state.documentation) {
        return state;
      }

      return {
        ...state,
        documentation: state.documentation
          .replace(
            RegExp('<([A-Z0-9_]+)>', 'g'),
            (match, variableName) => environmentVariables[variableName] || match
          )
          .replace(RegExp('<XXXXXX>.stackhero-network.com', 'g'), this.props.service.domain)
          .replace(/\${origin}/g, `${process.env.HTTP_SCHEME}://${process.env.SHOWCASE_HOST}/${this.props.app.languageAndRegion}`)
      };
    });
  }


  render() {
    const serviceStoreName = this.props.service.serviceStore.name;
    if (this.state.documentation === null) {
      return ( <Spinner /> );
    }

    const baseUrl = `${process.env.HTTP_SCHEME}://${process.env.SHOWCASE_HOST}/${this.props.app.languageAndRegion}/services/${serviceStoreName.replace('nodejs-lts', 'Node-js').replace('nodejs-current', 'Node-js')}/documentations`;

    return (
      <div className={styles.container}>
        {[
          this.state.documentation && (
            <MarkdownWithHighlight
              key="markdown"
              headersAddAnchor={false}
              content={[
                `# ${this.state.documentationEntry.description}`,
                this.state.documentation
              ].join('\n')}
              className={styles.markdown}
              baseUrlAssets={`${process.env.HTTP_SCHEME}://${process.env.SHOWCASE_HOST}/${this.state.documentationEntry?.directory}/`}
            />
          ),

          this.state.documentation && this.state.documentationSubEntries.length > 1 && (
            <React.Fragment key="summary">
              <h2 style={{ marginTop: 30 }}>Going further...</h2>
              <div className={styles.summary}>
                <Summary
                  baseUrl={baseUrl}
                  entries={this.state.documentationSubEntries
                    .filter(({ type }) => type !== 'tlDr')
                    .map(subEntry => ({
                      ...subEntry,
                      url: utils.textToUri(subEntry.title)
                    }))
                  }
                />
              </div>
            </React.Fragment>
          ),

          !this.state.documentation && (
            <LinkLayout
              key="button"
              url={baseUrl}
            >
              <div style={{ width: 'fit-content', margin: '20px auto 0 auto' }}>
                <Button type="primary" icon={<ReadOutlined />}>
                  See documentations
                </Button>
              </div>
            </LinkLayout>
          )
        ]}
      </div>
    );
  }
}

export default connect(
  ({ app }) => ({ app }),
  dispatch => ({
    apiActions: bindActionCreators(apiActions, dispatch)
  })
)(ServiceGettingStarted);
