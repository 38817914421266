/*eslint react/no-danger: "off"*/

import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash.isequal';

import { Switch, Input, Tooltip, Radio, Checkbox, Button, Select, Row, Col, message } from 'antd';
import { QuestionCircleOutlined, KeyOutlined, RollbackOutlined, CopyOutlined } from '@ant-design/icons';
import ConfiguratorArray from './ConfiguratorArray';
import Markdown from 'components/Markdown';

import styles from './ConfiguratorItems.css';

class ConfiguratorItems extends React.Component {
  static propTypes = {
    configuration: PropTypes.object.isRequired,
    configurationOriginal: PropTypes.oneOfType([ PropTypes.object, PropTypes.array ]).isRequired,
    service: PropTypes.object.isRequired,
    schema: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired
  };

  UNSAFE_componentWillMount() {
    this.schema = this.props.schema({}, this.props.service, this.props.configuration, this.props.configurationOriginal, []);

    // Set default values
    const defaultValues = Object.keys(this.schema)
      .map(key => (this.schema[key].defaultValue !== undefined && !this.schema[key].hidden ? { [key]: this.schema[key].defaultValue } : undefined))
      .filter(e => e !== undefined)
      .reduce((accumulator, value) => ({ ...accumulator, ...value }), {});

      this.props.onChange({ ...defaultValues, ...this.props.configuration });
  }


  UNSAFE_componentWillReceiveProps(nextProps) {
    this.schema = nextProps.schema({}, nextProps.service, nextProps.configuration, this.props.configurationOriginal, []);

    // Set default values
    const defaultValues = Object.keys(this.schema)
      .map(key => (this.schema[key].defaultValue !== undefined && !this.schema[key].hidden ? { [key]: this.schema[key].defaultValue } : undefined))
      .filter(e => e !== undefined)
      .reduce((accumulator, value) => ({ ...accumulator, ...value }), {});

    nextProps.onChange({ ...defaultValues, ...nextProps.configuration });
  }

  icons = {
    key: <KeyOutlined />,
    rollback: <RollbackOutlined />
  };


  _refs = [];


  renderTooltip(key, entry) {
    if (!entry.tooltip) { return null; }

    return (
      <Tooltip title={entry.tooltip}>
        &nbsp;<QuestionCircleOutlined />
      </Tooltip>
    );
  }


  renderString(key, entry, value, error) {
    const buttonAfter = typeof(entry.buttonAfter) === 'function' ? entry.buttonAfter(value) : entry.buttonAfter;
    const addonAfter = buttonAfter
      ? (
          <Button
            size="small"
            disabled={entry.disabled}
            icon={this.icons[buttonAfter.icon]}
            style={{ border: 'none', background: 'transparent', margin: 0 }}
            onClick={() => this.onChange(key, buttonAfter.onClick())}
          >
            {buttonAfter.label}
          </Button>
        )
      : entry.addonAfter;

    const suffix = !value
      ? <span />
      : (
        <CopyOutlined
          title="Click to copy"
          onMouseDown={() => {
            const el = document.createElement('textarea');
            el.value = value;
            el.style = { visible: false };
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
            message.info('Copied to clipboard');
          }}
          style={{ cursor: 'copy' }}
        />
      );

    return (
      <Input
        ref={node => this._refs[key] = node}
        placeholder={entry.placeholder}
        value={value}
        status={error ? 'error' : null}
        onChange={e =>
          this.onChange(
            key,
            e.target.value,
            // To avoid unfocus bug when addonAfter if added during run time. See https://github.com/ant-design/ant-design/issues/14033
            () => this._refs[key].focus()
          )}
        disabled={entry.disabled}
        {...entry.disabled
          ? {}
          : {
            suffix,
            addonAfter
          }
        }
      />
    );
  }


  renderNumber(key, entry, value, error) {
    const buttonAfter = typeof(entry.buttonAfter) === 'function' ? entry.buttonAfter(value) : entry.buttonAfter;
    const addonAfter = buttonAfter
      ? (
        <Button
          size="small"
          disabled={entry.disabled}
          icon={this.icons[buttonAfter.icon]}
          style={{ border: 'none', background: 'transparent' }}
          onClick={() => this.onChange(key, buttonAfter.onClick())}
        >
          {buttonAfter.label}
        </Button>
      )
      : entry.addonAfter;

    return (
      <Input
        className={styles.inputNumber}
        ref={node => this._refs[key] = node}
        placeholder={entry.placeholder}
        value={value}
        status={error ? 'error' : null}
        onChange={e => {
          const value = isNaN(parseInt(e.target.value)) ? e.target.value : parseInt(e.target.value);
          this.onChange(
            key,
            value,
            // To avoid unfocus bug when addonAfter if added during run time. See https://github.com/ant-design/ant-design/issues/14033
            () => this._refs[key].focus()
          );
        }}
        disabled={entry.disabled}

        {...entry.disabled
          ? {}
          : {
            addonAfter
          }
        }
      />
    );
  }


  renderBoolean(key, entry, value) {
    return (
      <Switch
        disabled={entry.disabled}
        checked={value}
        onChange={checked => this.onChange(key, checked)}
      />
    );
  }


  renderRadio(key, entry, value) {
    return (
      <Radio.Group
        disabled={entry.disabled}
        buttonStyle="solid"
        onChange={e => this.onChange(key, e.target.value)}
        value={value}
      >
        {entry.entries.map(({ label, value }) => ( <Radio.Button key={value} value={value}>{label}</Radio.Button> ))}
      </Radio.Group>
    );
  }


  renderCheckbox(key, entry, value) {
    return (
      <Checkbox.Group
        disabled={entry.disabled}
        onChange={values => this.onChange(key, values)}
        value={value}
        options={entry.entries}
        style={{ display: 'grid' }}
      />
    );
  }


  renderSelect(key, entry, value, error) {
    return (
      <Select
        disabled={entry.disabled}
        onChange={value => this.onChange(key, value)}
        value={value ? value : undefined}
        status={error ? 'error' : null}
        placeholder={entry.placeholder}
        popupMatchSelectWidth={false}
        style={{ maxWidth: '100%' }}
      >
        {entry.entries.map(({ label, value, disabled = false }) => (
          <Select.Option key={value} value={value} disabled={disabled}>{label}</Select.Option>
        ))}
      </Select>
    );
  }


  onChange(key, value, cb = () => {}) {
    const { onChange } = this.schema[key];
    if (onChange) {
      value = onChange(value);
    }

    const newConfiguration = {
      ...this.props.configuration,
      [key]: value
    };

    if (!isEqual(newConfiguration, this.props.configuration)) {
      this.props.onChange(newConfiguration, cb);
    }
  }


  renderEntry(key) {
    const entry = this.schema[key];
    const value = this.props.configuration[key];

    if (entry.hidden === true) {
      return;
    }

    const validateStatus = entry.isValid && value !== undefined
      ? (entry.isValid(value, entry) || (!entry.required && !value) ? 'success' : 'error')
      : undefined;

    let content;
    if (entry.type === 'boolean') {
      content = this.renderBoolean(key, entry, value);
    }
    else if (entry.type === 'string') {
      content = this.renderString(key, entry, value, validateStatus === 'error');
    }
    else if (entry.type === 'number') {
      content = this.renderNumber(key, entry, value, validateStatus === 'error');
    }
    else if (entry.type === 'radio') {
      content = this.renderRadio(key, entry, value);
    }
    else if (entry.type === 'checkbox') {
      content = this.renderCheckbox(key, entry, value);
    }
    else if (entry.type === 'select') {
      content = this.renderSelect(key, entry, value, validateStatus === 'error');
    }
    else if (entry.type === 'array') {
      content = (
        <ConfiguratorArray
          entry={entry}
          help={entry.help}
          configuration={value || entry.defaultValue}
          configurationOriginal={this.props.configurationOriginal[key] || entry.defaultValue}
          service={this.props.service}
          schema={entry.schema}
          onChange={this.onChange.bind(this, key)}
        />
      );
    }
    else if (entry.type === 'object') {
      content = (
        <ConfiguratorItems
          {...this.props}
          configuration={value || entry.defaultValue || {}}
          configurationOriginal={this.props.configurationOriginal[key] || {}}
          schema={entry.schema}
          onChange={this.onChange.bind(this, key)}
        />
      );
    }
    else {
      throw Error(`Unknown type ${entry.type}`);
    }


    const label = ( <span>{entry.label}{this.renderTooltip(key, entry)}</span> );

    const helpContent = typeof(entry.help) === 'function' ? entry.help(value) : entry.help;
    const help = helpContent && entry.type !== 'array' ? ( <Markdown content={helpContent} /> ) : '';

    return (
      <Row key={key} className={styles.entryContainer}>
        <Col xs={24} sm={24} md={24} lg={24} xl={6} xxl={6} className={styles.title}>
          {entry.required && entry.type !== 'boolean' ? ( <span className={styles.required}>* </span> ) : ''}{label}
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={18} xxl={18} className={styles.content}>
          { help && entry.type === 'object' ? <div className={styles.helpBefore}>{help}</div> : null }
          {content}
          { help && entry.type !== 'object' ? <div className={styles.helpAfter}>{help}</div> : null }
        </Col>
      </Row>
    );
  }


  render() {
    return Object.keys(this.schema)
      .map(key => this.renderEntry(key))
      .filter(v => v);
  }
}

export default ConfiguratorItems;
