import axios from 'axios';

class Request {
  constructor({ baseUrl, auth }) {
    this._baseUrl = baseUrl;
    this._auth = auth;
  }

  get(args, cb) {
    return this.request(
      {
        method: 'get',
        ...args
      },
      cb
    );
  }

  delete(args, cb) {
    return this.request(
      {
        method: 'delete',
        ...args
      },
      cb
    );
  }

  patch(args, cb) {
    return this.request(
      {
        method: 'patch',
        ...args
      },
      cb
    );
  }

  put(args, cb) {
    return this.request(
      {
        method: 'put',
        ...args
      },
      cb
    );
  }

  post(args, cb) {
    return this.request(
      {
        method: 'post',
        ...args
      },
      cb
    );
  }

  request({ method, route, routeArgs, options }, cb) {
    const url = this._baseUrl + route;
    const config = {
      auth: this._auth,
      params: method === 'get' || method === 'delete' ? routeArgs : null,
      ...options
    };
    const axiosArgs = method === 'post' || method === 'patch' || method === 'put' ? [ url, routeArgs, config ] : [ url, config ];

    axios[method].apply(axios, axiosArgs)
      .then(response => cb(null, response))
      .catch(error => cb(error, null));
  }
}

export default Request;
