import logo from 'images/servicesLogos/ruby.svg';
import logoOpenGraph from 'images/servicesLogos/openGraphVersions/ruby.png';

import growth from 'images/landings/icons/growth.svg';
import startup from 'images/landings/icons/startup.svg';
import shield from 'images/landings/icons/shield.svg';
import packageSvg from 'images/landings/icons/package.svg';
import target from 'images/landings/icons/target.svg';
import strategy from 'images/landings/icons/strategy.svg';

import checked from 'images/landings/icons/checked.svg';

// import imageRuby from 'images/landings/screenshots/ruby/ruby.png';

const backgroundColor = '#585450';
const button = {
  color: '#fff',
  backgroundColor: '#CC342D'
};

const serviceName = 'Ruby';
export default ({ t = () => [] }) => ({
  id: 'ruby',
  name: 'Ruby',
  nameUrl: 'Ruby',
  title: t('services/ruby:title', { serviceName }),
  logo,
  logoOpenGraph,
  button,
  backgroundColor,

  header: {
    title: 'Ruby',
    h1: t('services/ruby:h1', { serviceName }),
    description: t('services/ruby:description', { serviceName }) + '\n\n' + t('services/ruby:callToAction', { serviceName }),
    images: [
      // { image: imageRuby }
    ]
  },

  benefits: {
    items: [
      {
        icon: growth,
        ...t('services/ruby:benefits.business', { serviceName })
      },
      {
        icon: startup,
        ...t('services/ruby:benefits.performant', { serviceName })
      },
      {
        icon: target,
        ...t('services/ruby:benefits.configurable', { serviceName })
      },
      {
        icon: shield,
        ...t('services/ruby:benefits.secured', { serviceName })
      },
      {
        icon: packageSvg,
        ...t('services/ruby:benefits.deploy', { serviceName })
      },
      {
        icon: strategy,
        ...t('services/ruby:benefits.updatable', { serviceName })
      }
    ]
  },

  also: {
    color: button.backgroundColor,
    items: t('services/ruby:also.items', { serviceName })
      .map(content => ({ icon: checked, ...content }))
  },

  pricing: {
    buttonBackgroundColor: button.backgroundColor,
    buttonColor: button.color,
    sentencesIconColor: button.backgroundColor,
    includes: t('services/ruby:pricing.includes')
      .map(description => ({ icon: checked, description }))
  },

  recall: {
    description: t('services/ruby:callToAction', { serviceName })
  },

  faq: {
    items: t('services/ruby:faq.items', { serviceName })
  }
});
