import postgresql from './postgresql';
import mongodb from './mongodb';
import nodejs from './node-js';
import python from './python';
import mysql from './mysql';
import mariadb from './mariadb';
import elasticsearch from './elasticsearch';
import opensearch from './opensearch';
import redis from './redis';
import rabbitmq from './rabbitmq';
import graylog from './graylog';
import mattermost from './mattermost';
import minio from './minio';
import nextcloud from './nextcloud';
import rethinkdb from './rethinkdb';
import postfix from './postfix';
import mosquitto from './mosquitto';
import nodered from './node-red';
import mercurehub from './mercure-hub';
import grafana from './grafana';
import influxdb from './influxdb';
import gitlab from './gitlab';
import matomo from './matomo';
import php from './php';
import prometheus from './prometheus';
import memcached from './memcached';
import directus from './directus';
import docker from './docker';
import codehero from './code-hero';
import ruby from './ruby';
import valkey from './valkey';
import kubernetesControlPlane from './kubernetes-control-plane';
import kubernetesNode from './kubernetes-node';

const services = {
  postgresql,
  mysql,
  mariadb,
  mongodb,
  nodejs,
  python,
  elasticsearch,
  opensearch,
  redis,
  rabbitmq,
  graylog,
  mattermost,
  minio,
  nextcloud,
  rethinkdb,
  postfix,
  mosquitto,
  nodered,
  mercurehub,
  grafana,
  influxdb,
  gitlab,
  matomo,
  php,
  prometheus,
  memcached,
  directus,
  docker,
  codehero,
  ruby,
  valkey,
  kubernetesControlPlane,
  kubernetesNode
};

export default Object.keys(services)
  // Sort by alphabetical order
  .sort((a, b) => services[a]({}).id.localeCompare(services[b]({}).id))

  .map(key => services[key]);