import logo from 'images/servicesLogos/mongodb.svg';
import logoOpenGraph from 'images/servicesLogos/openGraphVersions/mongodb.png';

import growth from 'images/landings/icons/growth.svg';
import startup from 'images/landings/icons/startup.svg';
import shield from 'images/landings/icons/shield.svg';
import website from 'images/landings/icons/website.svg';
import strategy from 'images/landings/icons/strategy.svg';
import medal from 'images/landings/icons/medal.svg';

import checked from 'images/landings/icons/checked.svg';

import imageServicesMongodbNodejsGraylog from 'images/landings/screenshots/servicesMongodbNodejsGraylog.png';
import imageAdminMongoCollections from 'images/landings/screenshots/mongodb/adminMongoCollections.png';
import imageAdminMongoCollection from 'images/landings/screenshots/mongodb/adminMongoCollection.png';
import imageAdminMongoStatistics from 'images/landings/screenshots/mongodb/adminMongoStatistics.png';

const backgroundColor = '#12a951';
const button = {
  color: '#12a951',
  backgroundColor: '#fff'
};

export default ({ t = () => [] }) => ({
  hidden: true,
  id: 'mongodb',
  name: 'MongoDB',
  nameUrl: 'MongoDB',
  title: t('services/mongodb:title'),
  logo,
  logoOpenGraph,
  button,
  backgroundColor,

  header: {
    title: 'MongoDB',
    description: t('services/mongodb:description'),
    images: [
      { image: imageServicesMongodbNodejsGraylog },
      { image: imageAdminMongoCollections },
      { image: imageAdminMongoCollection },
      { image: imageAdminMongoStatistics }
    ]
  },

  benefits: {
    items: [
      {
        icon: growth,
        ...t('services/mongodb:benefits.business')
      },
      {
        icon: startup,
        ...t('services/mongodb:benefits.performant')
      },
      {
        icon: shield,
        ...t('services/mongodb:benefits.secured')
      },
      {
        icon: website,
        ...t('services/mongodb:benefits.simple')
      },
      {
        icon: medal,
        ...t('services/mongodb:benefits.unlimited')
      },
      {
        icon: strategy,
        ...t('services/mongodb:benefits.updatable')
      }
    ]
  },

  also: {
    color: backgroundColor,
    items: t('services/mongodb:also.items')
      .map(content => ({ icon: checked, ...content }))
  },

  recall: {
    description: t('services/mongodb:recall')
  },

  faq: {
    items: t('services/mongodb:faq.items')
  }
});