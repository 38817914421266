import React from 'react';
import PropTypes from 'prop-types';
import sha512 from 'hash.js/lib/hash/sha/512';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import * as apiActions from 'redux/actions/api';
import * as appActions from 'redux/actions/app';

import { withTranslation, Trans } from 'react-i18next';
import { UserOutlined, MailOutlined, LockOutlined, RobotOutlined } from '@ant-design/icons';

import Link from 'components/Link';

import Utils from 'modules/Utils';
const utils = new Utils();

import styles from './ModalSignUp.css';

class ModalSignUp extends React.Component {
  static propTypes = {
    apiActions: PropTypes.object.isRequired,
    app: PropTypes.object.isRequired,
    appActions: PropTypes.object.isRequired,
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
  };

  state = {
    name: '',
    nameIsValid: false,
    email: '',
    emailIsValid: false,
    password: '',
    passwordIsValid: false,
    captchaCode: '',
    captchaCodeIsValid: false,
    captchaData: null,
    captchaLoaded: false,
    loading: false
  };

  componentDidMount() {
    this._mounted = true;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.visible !== true && this.props.visible === true) {
      this.captchaGet();
      this.onOpen();
    }
  }

  componentWillUnmount() {
    this._mounted = false;
  }


  captchaGet = () => {
    this.props.apiActions.get(
      {
        route: '/captcha',
        routeArgs: {}
      },
      (error, captchaData) => {
        if (!this._mounted) { return; }
        this.setState({ captchaData, captchaLoaded: true });
      }
    );
  };


  onChange({ name, email, password, captchaCode }) {
    this.setState({
      ...name !== undefined
        ? {
          name,
          nameIsValid: name.trim().length >= 1 && name.trim().length <= 128
        }
        : {},

      ...email !== undefined
        ? {
          email,
          emailIsValid: utils.emailCheck(email)
        }
        : {},

      ...password !== undefined
        ? {
          password,
          passwordIsValid: password.length >= 8 && password.length <= 72
        }
        : {},

      ...captchaCode !== undefined
        ? {
          captchaCode,
          captchaCodeIsValid: captchaCode.length === 6
        }
        : {}
    });
  }

  isValid() {
    return this.state.nameIsValid && this.state.emailIsValid && this.state.passwordIsValid && this.state.captchaCodeIsValid;
  }

  onValid(event) {
    event.preventDefault();

    if (!this.isValid()) {
      return;
    }

    const { name, email, password, captchaCode, captchaData } = this.state;
    const { currency, language, region } = this.props.app;
    const locale = region ? `${language}-${region.toUpperCase()}` : language;
    const passwordPreHashed = sha512().update(password + 'stackhero' + email).digest('hex');

    this.setState({ loading: true });
    this.props.apiActions.post(
      {
        route: '',
        routeArgs: { name, email, passwordPreHashed, currency, locale, language, captchaCode, captchaId: captchaData.id },
        handleErrorAutomatically: false
      },
      (error, result) => {
        if (!this._mounted) { return; }

        if (error === 'userExistsYet') {
          this.setState({ loading: false });
          // If user exists yet, we redirect to login page and add email
          document.location.href = `${process.env.HTTP_SCHEME}://${process.env.DASHBOARD_HOST}/notLogged/login?email=${encodeURIComponent(email)}`;
          return;
        }
        else if (error === 'captchaInvalid') {
          this.setState({ loading: false });
          this.setState(
            {
              captchaCodeIsValid: false,
              captchaLoaded: false
            },
            () => this.captchaGet()
          );
          return;
        }
        else if (error) {
          this.setState({ loading: false });
          throw Error(error);
        }
        else {
          const { username, password, intercomAuthHash, organizationId, stackId, cookieMaxAge } = result;
          this.props.appActions.sessionSetDispatch({
            username,
            password,
            intercomAuthHash,
            cookieMaxAge,
            global: true
          });
          document.location.href = `${process.env.HTTP_SCHEME}://${process.env.DASHBOARD_HOST}/account/organizations/${organizationId}/stacks/${stackId}/services/create/`;
        }
      }
    );
  }

  onKeyboard = (event) => {
    if (event.keyCode !== 27) {
      return;
    }
    this.onClose();
  };

  onOpen() {
    document.body.style.overflow = 'hidden';
    document.addEventListener('keyup', this.onKeyboard);
  }

  onClose() {
    document.removeEventListener('keyup', this.onKeyboard);
    this.props.onClose();
    document.body.style.overflow = 'auto';
  }


  render() {
    if (!this.props.visible) {
      return null;
    }

    const { t } = this.props;

    return (
      <>
        {/* Overlay */}
        <div className={styles.overlay} />

        <form onSubmit={event => this.onValid(event)}>
          <div className={styles.modalContainer}>
            <div className={styles.modal}>

              <div className={styles.modalHeader}>
                {t('signup.title')}
              </div>

              <div className={styles.modalContent}>

                <div className={styles.alert}>
                  {t('signup.explain')}
                </div>


                {/* Full name */}
                <div
                  className={[
                    styles.inputContainer,
                    this.state.name && !this.state.nameIsValid ? styles.error : null
                  ].filter(v => v).join(' ')}
                >
                  <UserOutlined />
                  <input
                    type="text"
                    autoComplete="name"
                    required
                    minLength={1}
                    maxLength={128}
                    placeholder={t('signup.inputNamePlaceholder')}
                    value={this.state.name}
                    onChange={event => this.onChange({ name: event.target.value })}
                  />
                </div>

                {/* Email */}
                <div
                  className={[
                    styles.inputContainer,
                    this.state.email && !this.state.emailIsValid ? styles.error : null
                  ].filter(v => v).join(' ')}
                >
                  <MailOutlined />
                  <input
                    type="email"
                    autoComplete="email"
                    required
                    placeholder={t('signup.inputEmailPlaceholder')}
                    value={this.state.email}
                    onChange={event => this.onChange({ email: event.target.value })}
                  />
                </div>

                {/* Password */}
                <div
                  className={[
                    styles.inputContainer,
                    this.state.password && !this.state.passwordIsValid ? styles.error : null
                  ].filter(v => v).join(' ')}
                >
                  <LockOutlined />
                  <input
                    type="password"
                    autoComplete="new-password"
                    required
                    minLength={8}
                    maxLength={72}
                    placeholder={t('signup.inputPasswordPlaceholder')}
                    value={this.state.password}
                    onChange={event => this.onChange({ password: event.target.value })}
                  />
                </div>


                {/* Captcha */}
                <img
                  src={this.state.captchaLoaded && this.state.captchaData.imageBase64}
                  alt={t('signup.inputCaptchaPlaceholder')}
                  style={{ marginTop: '10px', marginBottom: '10px' }}
                />
                <div
                  className={[
                    styles.inputContainer,
                    this.state.captchaCode && !this.state.captchaCodeIsValid ? styles.error : null
                  ].filter(v => v).join(' ')}
                >
                  <RobotOutlined />
                  <input
                    type="text"
                    required
                    placeholder={t('signup.inputCaptchaPlaceholder')}
                    value={this.state.captchaCode}
                    onChange={event => this.onChange({ captchaCode: event.target.value.toUpperCase() })}
                  />
                </div>

                {/* Login link */}
                <div style={{ textAlign: 'center' }}>
                  <Trans i18nKey="signup.haveAnAccount">
                    Already have an account? <Link url={`${process.env.HTTP_SCHEME}://${process.env.DASHBOARD_HOST}/notLogged/login`}>Log In</Link>
                  </Trans>
                </div>
              </div>

              {/* Footer */}
              <div className={styles.modalFooter}>
                {/* Cancel */}
                <button
                  className={[ styles.button, styles.buttonCancel ].join(' ')}
                  onClick={() => this.onClose()}
                  type="button"
                >
                  {t('signup.cancel')}
                </button>

                {/* Validate */}
                <button
                  className={[ styles.button, styles.buttonOk ].join(' ')}
                  type="submit"
                  onClick={event => this.onValid(event)}
                  disabled={!this.isValid() || this.state.loading}
                >
                  {t('signup.validate')}
                </button>
              </div>
            </div>
          </div>
        </form>
      </>
    );
  }
}

export default compose(
  connect(
    ({ app }) => ({ app }),
    dispatch => ({
      apiActions: bindActionCreators(apiActions, dispatch),
      appActions: bindActionCreators(appActions, dispatch)
    })
  ),
  withTranslation()
)(ModalSignUp);
