import logo from 'images/servicesLogos/docker.svg';
import logoOpenGraph from 'images/servicesLogos/openGraphVersions/docker.png';

import growth from 'images/landings/icons/growth.svg';
import startup from 'images/landings/icons/startup.svg';
import shield from 'images/landings/icons/shield.svg';
import strategy from 'images/landings/icons/strategy.svg';

import checked from 'images/landings/icons/checked.svg';

import imagePortainerSummary from 'images/landings/screenshots/docker/portainer-summary.png';
import imagePortainerContainers from 'images/landings/screenshots/docker/portainer-containers.png';
import imageTraefik from 'images/landings/screenshots/docker/traefik.png';

const backgroundColor = '#003f8c';
const button = {
  color: '#fff',
  backgroundColor: '#2395ec'
};

const serviceName = 'Docker';
export default ({ t = () => [] }) => ({
  id: 'docker',
  name: 'Docker',
  nameUrl: 'Docker',
  title: t('services/docker:title', { serviceName }),
  logo,
  logoOpenGraph,
  button,
  backgroundColor,

  header: {
    title: 'Docker',
    description: t('services/docker:description', { serviceName }) + '\n\n' + t('services/docker:callToAction', { serviceName }),
    images: [
      { image: imagePortainerSummary },
      { image: imagePortainerContainers },
      { image: imageTraefik }
    ]
  },

  benefits: {
    items: [ growth, strategy, startup, shield ].map((icon, index) => ({
      icon,
      ...t('services/docker:benefits', { serviceName })[index]
    }))
  },

  also: {
    color: button.backgroundColor,
    items: t('services/docker:also.items', { serviceName })
      .map(content => ({ icon: checked, ...content }))
  },

  pricing: {
    buttonBackgroundColor: button.backgroundColor,
    buttonColor: button.color,
    sentencesIconColor: button.backgroundColor,
    includes: t('services/docker:pricing.includes')
      .map(description => ({ icon: checked, description }))
  },


  recall: {
    description: t('services/docker:callToAction', { serviceName })
  },

  faq: {
    items: t('services/docker:faq.items', { serviceName })
  }
});
