import logo from 'images/servicesLogos/rethinkdb.svg';
import logoOpenGraph from 'images/servicesLogos/openGraphVersions/rethinkdb.png';

import growth from 'images/landings/icons/growth.svg';
import startup from 'images/landings/icons/startup.svg';
import shield from 'images/landings/icons/shield.svg';
import strategy from 'images/landings/icons/strategy.svg';

import checked from 'images/landings/icons/checked.svg';

import imageDashboard from 'images/landings/screenshots/rethinkdb/dashboard.png';

const backgroundColor = '#1a5061';
const button = {
  color: '#fff',
  backgroundColor: '#be465c'
};

export default ({ t = () => [] }) => ({
  id: 'rethinkdb',
  name: 'RethinkDB',
  nameUrl: 'RethinkDB',
  title: t('services/rethinkdb:title'),
  logo,
  logoOpenGraph,
  button,
  backgroundColor,

  header: {
    title: 'RethinkDB',
    description: t('services/rethinkdb:description'),
    images: [
      { image: imageDashboard }
    ]
  },

  benefits: {
    items: [
      {
        icon: growth,
        ...t('services/rethinkdb:benefits.business')
      },
      {
        icon: startup,
        ...t('services/rethinkdb:benefits.performant')
      },
      {
        icon: shield,
        ...t('services/rethinkdb:benefits.secured')
      },
      {
        icon: strategy,
        ...t('services/rethinkdb:benefits.updatable')
      }
    ]
  },

  also: {
    color: button.backgroundColor,
    items: t('services/rethinkdb:also.items')
      .map(content => ({ icon: checked, ...content }))
  },

  pricing: {
    buttonBackgroundColor: button.backgroundColor,
    buttonColor: button.color,
    sentencesIconColor: button.backgroundColor,
  },

  recall: {
    description: t('services/rethinkdb:recall')
  },

  faq: {
    items: t('services/rethinkdb:faq.items')
  }
});
