import React from 'react';
import PropTypes from 'prop-types';
const images = require.context('images/servicesLogos/', true);

class ServiceTitle extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    size: PropTypes.oneOf([ 'big', 'small' ])
  };

  render() {
    const { name, title, size } = this.props;
    const filename = `./${name.toLowerCase().replace(/ /g, '')}`;
    const imageSrc = images(`${filename}.svg`, true);
    const sizes = size === 'small'
      ? {
        height: 18,
        maxWidth: 18
      }
      : {
        height: 36,
        maxWidth: 36
      };

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img
          src={imageSrc.default}
          alt={title}
          style={{ height: sizes.height, maxWidth: sizes.maxWidth, marginRight: 8 }}
        />
        <span style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
          {title}
        </span>
      </div>
    );
  }
}

export default ServiceTitle;
