import React from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import * as apiActions from 'redux/actions/api';
import { withRouter } from 'react-router';

import { Card, Spin } from 'antd';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import CreditCardForm from './CreditCardForm';

import Script from 'react-load-script';

class CreditCardFormParent extends React.Component {

  static propTypes = {
    apiActions: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    match: PropTypes.object.isRequired
  };

  state = {
    stripe: null,
    stripePaymentIntent: null,
    loading: false
  };

  UNSAFE_componentWillMount() {
    this._mounted = true;
    this.getStripePaymentIntent();
  }

  componentWillUnmount() {
    this._mounted = false;
  }


  onSuccess(paymentMethodId) {
    this.props.onSuccess(
      paymentMethodId,
      error => {
        if (error) {
          this.setState({ stripePaymentIntent: null });
          this.getStripePaymentIntent();
        }
      }
    );
  }


  getStripePaymentIntent() {
    const { organizationId } = this.props.match.params;
    this.props.apiActions.get(
      {
        route: `/dashboard/organizations/${organizationId}/billing/creditCard/stripePaymentIntent`,
        routeArgs: {}
      },
      (error, stripePaymentIntent) => {
        if (error || !this._mounted) {
          return;
        }
        this.setState({ stripePaymentIntent });
      }
    );
  }

  render() {
    return (
      <>
        <Script
          url="https://js.stripe.com/v3/"
          onError={() => location.reload()}
          onLoad={() => this.setState({ stripe: window.Stripe(process.env.STRIPE_PUBLIC_KEY) }) }
        />

        <Spin spinning={!this.state.stripe || !this.state.stripePaymentIntent || this.state.loading} delay={500}>
          <Card style={{ borderRadius: 10, backgroundColor: '#fafafa' }}>
            <Elements stripe={this.state.stripe}>
              <ElementsConsumer>
                {({stripe, elements}) => (
                  <CreditCardForm
                    type={this.props.type}
                    onSuccess={this.onSuccess.bind(this)}
                    onCancel={this.props.onCancel}
                    stripe={stripe}
                    elements={elements}
                    stripePaymentIntent={this.state.stripePaymentIntent}
                    loading={loading => this.setState({ loading })}
                  />
                )}
              </ElementsConsumer>
            </Elements>
          </Card>
        </Spin>
      </>
    );
  }
}

export default compose(
  withRouter,
  connect(
    () => ({}),
    dispatch => ({
      apiActions: bindActionCreators(apiActions, dispatch)
    })
  )
)(CreditCardFormParent);
