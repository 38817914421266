import React from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as apiActions from 'redux/actions/api';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { Spin, Alert, Tag, Button, Table, message, Card, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import ServiceFirewallModal from './ServiceFirewallModal';
import DragableBodyRow from './TableDnd/DragableBodyRow';

class ServiceFirewall extends React.Component {
  static propTypes = {
    apiActions: PropTypes.object.isRequired,
    serviceId: PropTypes.string.isRequired,
    onUpdate: PropTypes.func.isRequired
  };

  state = {
    loading: true,
    rules: [],
    ports: [],
    modalVisible: false,
    modalValuesToEdit: undefined,
    hasChanged: false
  };

  componentDidMount = () => {
    this._mounted = true;
    this.rulesLoad();
  };

  componentWillUnmount = () => {
    this._mounted = false;
  };

  tagColorsPerAction = {
    accept: 'green',
    reject: 'volcano',
    drop: 'red'
  };

  rulesLoad = () => {
    const { serviceId } = this.props;

    this.props.apiActions.get(
      {
        route: `/dashboard/services/${serviceId}/firewall`,
        routeArgs: {},
        spinner: false
      },
      (error, result) => {
        if (error || !this._mounted) {
          return;
        }
        const rules = result.rules.map((rule, index) => ({ ...rule, index }));
        this.setState({
          rules,
          rulesOrigin: rules,
          ports: result.ports,
          loading: false
        });
      }
    );
  };

  rulesSave = () => {
    const { serviceId } = this.props;
    const { rules } = this.state;

    this.props.apiActions.put(
      {
        route: `/dashboard/services/${serviceId}/firewall`,
        routeArgs: {
          rules: rules.map(({ source, portsIds, action, description }) => ({ source, portsIds, action, description }))
        }
      },
      (error, result) => {
        if (error || !this._mounted) {
          return;
        }
        message.success('Firewall rules have been saved');
        this.props.onUpdate();
      }
    );
  };

  ruleDelete = index => {
    this.setState(({ rules }) => ({
      hasChanged: true,
      rules: rules.filter(rule => rule.index !== index).map((rule, index) => ({ ...rule, index }))
    }));
  };

  ruleEdit = index => {
    this.setState(prevState => ({
      modalVisible: true,
      modalValuesToEdit: prevState.rules[index],
      modalIndexEditing: index
    }));
  };

  ruleUpdate = rule => {
    this.setState(({ rules, modalIndexEditing }) => ({
      rules: [
        ...rules.slice(0, modalIndexEditing),
        rule,
        ...rules.slice(modalIndexEditing + 1)
      ].map((rule, index) => ({ ...rule, index })),
      hasChanged: true,
      modalVisible: false,
      modalValuesToEdit: undefined,
      modalIndexEditing: undefined
    }));
  };

  ruleAdd = rule => {
    this.setState(({ rules }) => ({
      rules: [ ...rules, rule ].map((rule, index) => ({ ...rule, index })),
      hasChanged: true,
      modalVisible: false
    }));
  };

  renderButtons = () => {
    return (
      <div style={{ textAlign: 'right' }} title={this.state.hasChanged ? null : 'No rule has been changed'}>
        <Button
          disabled={!this.state.hasChanged}
          onClick={() => this.setState(({ rulesOrigin }) => ({ rules: rulesOrigin, hasChanged: false }))}
        >
          Cancel
        </Button>

        <Button
          disabled={!this.state.hasChanged}
          type="primary"
          style={{ marginLeft: 8 }}
          onClick={() => this.rulesSave()}
        >
          Validate
        </Button>
      </div>
    );
  };

  renderButtonRuleAdd = () => {
    if (this.state.rules.length >= 100) {
      return null;
    }

    return (
      <div style={{ textAlign: 'right' }}>
        <Button onClick={() => this.setState({ modalVisible: true })}>Add a rule</Button>
      </div>
    );
  };

  render = () => {
    const lastRule = {
      index: this.state.rules.length,
      source: '0.0.0.0/0',
      portsIds: this.state.ports.map(({ id }) => id),
      action: 'reject',
      description: 'Default rule rejecting everything',
      uneditable: true
    };

    return (
      <Spin spinning={this.state.loading}>
        <Alert
          showIcon
          message="Firewall configuration"
          description={(<>
            Each packet will go through this list of rules.<br />
            When a rule matches a packet, the corresponding action is executed and rules after are ignored.<br />
            You can drag and drop rules to change their position, except the last one that is fixed.
          </>)}
          style={{ marginBottom: 16 }}
        />

        <DndProvider backend={HTML5Backend}>
          <Card style={{ marginBottom: 16 }}>
            <Table
              dataSource={this.state.loading ? [] : [ ...this.state.rules, lastRule ]}
              rowKey="index"
              pagination={false}
              style={{ marginBottom: 16 }}

              // For drag and drop
              components={{ body: { row: DragableBodyRow } }}
              onRow={(record, index) => ({
                index,
                moveRow: (dragIndex, hoverIndex) => {
                  // Record is the destination
                  if (dragIndex === this.state.rules.length) {
                    message.error('You can\'t move the drop default rule');
                  }
                  else if (hoverIndex === this.state.rules.length) {
                    message.error('You can\'t move a rule after the drop default rule');
                  }
                  else {
                    // More dragIndex to hoverIndex
                    const a = dragIndex > hoverIndex ? hoverIndex : dragIndex;
                    const b = dragIndex > hoverIndex ? dragIndex : hoverIndex;
                    this.setState(({ rules }) => ({
                      hasChanged: true,
                      rules: [
                        ...rules.slice(0, a),
                        rules[b],
                        ...rules.slice(a + 1, b),
                        rules[a],
                        ...rules.slice(b + 1)
                      ].map((rule, index) => ({ ...rule, index }))
                    }));
                  }
                }
              })}
            >

              <Table.Column
                title="Position"
                key="index"
                render={({ index }) => `#${index + 1}`}
              />

              <Table.Column
                title="Source"
                dataIndex="source"
              />

              <Table.Column
                title="Ports"
                key="ports"
                render={({ portsIds }) => portsIds.sort().map(portIdToFind => {
                  const portFound = this.state.ports.find(({ id }) => id === portIdToFind);
                  const { ports, label, legend } = portFound;
                  const portsFormated = ports.map(({ port, protocol }) => `${port}/${protocol}`);
                  return (
                    <Tooltip key={portIdToFind} title={legend}>
                      <Tag>{label ? `${label}: ${portsFormated.join(', ')}` : portsFormated.join(', ')}</Tag>
                    </Tooltip>
                  );
                })}
              />

              <Table.Column
                title="Action"
                key="action"
                render={({ action }) => <Tag checked color={this.tagColorsPerAction[action]}>{action.toUpperCase()}</Tag> }
              />

              <Table.Column
                title="Description"
                dataIndex="description"
              />

              <Table.Column
                render={({ index, uneditable }) => uneditable
                  ? null
                  : (
                      <div style={{ whiteSpace: 'nowrap' }}>
                        <Button icon={<EditOutlined />} title="Edit this rule" style={{ marginRight: 8 }} onClick={() => this.ruleEdit(index)} />
                        <Button icon={<DeleteOutlined />} danger title="Delete this rule" onClick={() => this.ruleDelete(index)} />
                      </div>
                    )
                }
              />
            </Table>

            {this.renderButtonRuleAdd()}
          </Card>
        </DndProvider>

       {this.renderButtons()}

        <ServiceFirewallModal
          visible={this.state.modalVisible}
          valuesToEdit={this.state.modalValuesToEdit}
          onValidate={rule => this.state.modalValuesToEdit ? this.ruleUpdate(rule) : this.ruleAdd(rule)}
          onCancel={() => this.setState({ modalVisible: false, modalValuesToEdit: undefined })}
          ports={this.state.ports}
        />
      </Spin>
    );
  };
}

export default connect(
  state => ({}),
  dispatch => ({
    apiActions: bindActionCreators(apiActions, dispatch)
  })
)(ServiceFirewall);
