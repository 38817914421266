import logo from 'images/servicesLogos/php.svg';
import logoOpenGraph from 'images/servicesLogos/openGraphVersions/php.png';

import growth from 'images/landings/icons/growth.svg';
import startup from 'images/landings/icons/startup.svg';
import shield from 'images/landings/icons/shield.svg';
import packageSvg from 'images/landings/icons/package.svg';
import target from 'images/landings/icons/target.svg';
import strategy from 'images/landings/icons/strategy.svg';

import checked from 'images/landings/icons/checked.svg';

import imagePhp from 'images/landings/screenshots/php/php.png';

const backgroundColor = '#4F5B93';
const button = {
  color: '#333',
  backgroundColor: '#ffd343'
};

const serviceName = 'PHP';
export default ({ t = () => [] }) => ({
  id: 'php',
  name: 'PHP',
  nameUrl: 'PHP',
  title: t('services/php:title', { serviceName }),
  logo,
  logoOpenGraph,
  button,
  backgroundColor,

  header: {
    title: 'PHP',
    h1: t('services/php:h1', { serviceName }),
    description: t('services/php:description', { serviceName }) + '\n\n' + t('services/php:callToAction', { serviceName }),
    images: [
      { image: imagePhp }
    ]
  },

  benefits: {
    items: [
      {
        icon: growth,
        ...t('services/php:benefits.business', { serviceName })
      },
      {
        icon: startup,
        ...t('services/php:benefits.performant', { serviceName })
      },
      {
        icon: target,
        ...t('services/php:benefits.configurable', { serviceName })
      },
      {
        icon: shield,
        ...t('services/php:benefits.secured', { serviceName })
      },
      {
        icon: packageSvg,
        ...t('services/php:benefits.deploy', { serviceName })
      },
      {
        icon: strategy,
        ...t('services/php:benefits.updatable', { serviceName })
      }
    ]
  },

  also: {
    color: button.backgroundColor,
    items: t('services/php:also.items', { serviceName })
      .map(content => ({ icon: checked, ...content }))
  },

  pricing: {
    buttonBackgroundColor: button.backgroundColor,
    buttonColor: button.color,
    sentencesIconColor: button.backgroundColor,
    includes: t('services/php:pricing.includes')
      .map(description => ({ icon: checked, description }))
  },

  recall: {
    description: t('services/php:callToAction', { serviceName })
  },

  faq: {
    items: t('services/php:faq.items', { serviceName })
  }
});
