export default {
  // Remove emoji flags for Windows users (as it doesn't support such emojis) except for Firefox users (as it includes an emoji font that supports flags).
  'europeFalkenstein': {
    emoji: /Windows/.test(navigator.userAgent) && !/Firefox/.test(navigator.userAgent) ? '' : '🇪🇺',
    label: 'Europe'
  },
  'europeParis': {
    emoji: /Windows/.test(navigator.userAgent) && !/Firefox/.test(navigator.userAgent) ? '' : '🇪🇺',
    label: 'Europe'
  },
  'usaVirginia': {
    emoji: /Windows/.test(navigator.userAgent) && !/Firefox/.test(navigator.userAgent) ? '' : '🇺🇸',
    label: 'USA'
  }
};