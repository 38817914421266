import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { Card, Table } from 'antd';

class _default extends React.Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };

  couponsExpandedRow = (coupon) => {
    if (!coupon.usage.length) {
      return ( <span>Coupon not used</span> );
    }

    const { currency, currencyHasCents } = coupon;
    return (
      <Table
        dataSource={coupon.usage}
        rowKey={record => record.invoiceId}
        pagination={false}
        summary={pageData => {
          const amountX100 = pageData.reduce((accumulator, value) => accumulator + value.amountX100, 0);
          return (
            <Table.Summary.Row>
              <Table.Summary.Cell align="right" colSpan={3}>
                Total: {new Intl.NumberFormat(undefined, { style: 'currency', currency, minimumFractionDigits: currencyHasCents ? 2 : 0 }).format(amountX100 / 100)}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          );
        }}
      >

        <Table.Column
          title="Invoice ID"
          dataIndex="invoiceId"
        />

        <Table.Column
          title="Invoice date"
          key="invoiceCreationDate"
          render={({ invoiceCreationDate }) => (new Intl.DateTimeFormat(undefined).format(new Date(invoiceCreationDate)))}
        />

        <Table.Column
          title="Amount consumed"
          key="amountX100"
          align="right"
          render={({ amountX100 }) => (new Intl.NumberFormat(undefined, { style: 'currency', currency, minimumFractionDigits: currencyHasCents ? 2 : 0 }).format(amountX100 / 100))}
        />
      </Table>
    );
  };


  renderCoupons() {
    const { organizationId } = this.props.match.params;
    const route = `/dashboard/organizations/${organizationId}/billing/coupons`;
    const coupons = this.props.api.subscriptions[route].datas;

    if (!coupons || !coupons.length) {
      return ( <span>You didn't received any coupon yet</span> );
    }

    return (
      <Table
        dataSource={coupons}
        rowKey="id"
        expandedRowRender={this.couponsExpandedRow}
      >

        <Table.Column
          title="Date added"
          key="dateAdded"
          render={({ dateAdded }) => (new Intl.DateTimeFormat(undefined).format(new Date(dateAdded)))}
        />

        <Table.Column
          title="Available until"
          key="availableUntil"
          render={({ availableUntil }) => (new Intl.DateTimeFormat(undefined).format(new Date(availableUntil)))}
        />

        <Table.Column
          title="Amount"
          key="amountX100"
          align="right"
          render={({ amountX100, currency, currencyHasCents }) => (new Intl.NumberFormat(undefined, { style: 'currency', currency, minimumFractionDigits: currencyHasCents ? 2 : 0 }).format(amountX100 / 100))}
        />

        <Table.Column
          title="Reason"
          dataIndex="reason"
        />

        <Table.Column
          title="Services concerned"
          key="serviceStoreTitleConcerned"
          render={({ serviceStoreTitleConcerned }) => serviceStoreTitleConcerned ? serviceStoreTitleConcerned : 'All' }
        />

      </Table>
    );
  }


  render() {
    return (
      <Card title="Your coupons" styles={{ body: { overflow: 'scroll' }}}> {/* overflow for mobile */}
        {this.renderCoupons() }
      </Card>
    );
  }
}

export default connect(
  ({ api }) => ({ api })
)(_default);
