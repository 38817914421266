/* eslint-disable react/no-danger */

import React from 'react';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { compose } from 'redux';

import { marked } from 'marked';

import { LinkOutlined, WarningFilled, AlertFilled, InfoCircleFilled } from '@ant-design/icons';

import styles from './Markdown.css';

import Utils from 'modules/Utils';
const utils = new Utils();

class Markdown extends React.Component {
  static propTypes = {
    content: PropTypes.string,
    baseUrl: PropTypes.string,
    baseUrlAssets: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    location: PropTypes.object.isRequired,
    headersAddAnchor: PropTypes.bool
  };

  state = {
    content: ''
  };

  componentDidMount() {
    this.update(this.props.content);
  }


  componentDidUpdate(prevProps) {
    if (prevProps.content !== this.props.content) {
      this.update(this.props.content);
    }
  }


  update = content => {
    if (!content) {
      this.setState({ content: '' });
      return;
    }

    const baseUrl = this.props.baseUrl || '/';
    const baseUrlAssets = this.props.baseUrlAssets || baseUrl;

    const renderer = new marked.Renderer();

    // Set target="_blank" on links but not on anchor
    renderer.link = (href, title, text) =>
      href && href.indexOf('#') === 0
        ? `<a href="${href}" title="${title || ''}">${text}</a>`
        : (href.indexOf('http://') === 0 || href.indexOf('https://') === 0)
          ? `<a target="_blank" rel="noopener noreferrer" href="${href}" title="${title || ''}">${text}</a>`
          : href.indexOf('mailto:') === 0
            ? `<a target="_blank" rel="noopener noreferrer" href="${href}" title="${title || ''}">${text}</a>`
            : href.indexOf('./') === 0
              ? `<a target="_blank" rel="noopener noreferrer" href="${baseUrlAssets + href}" title="${title || ''}" download>${text}</a>`
              : href.indexOf('/') === 0
                ? `<a href="${href}" title="${title || ''}">${text}</a>`
                : `<a rel="noopener noreferrer" href="${baseUrl + href}" title="${title || ''}">${text}</a>`;

    renderer.image = (src, title, text) => [
      `<a href="${baseUrlAssets + src}" target="_blank">`,
        `<img src="${baseUrlAssets + src}" alt="${title || text}" />`,
      '</a>',
      `<span class="${styles.imgLegend}">`,
        text,
      '</span>'
    ].join('');


    // Add suppport for bloquotes styled like "> warning"
    renderer.blockquote = quote => {
      const quoteParsed = quote.match(/^(<p>)(?:(warning|alert)<br>)?([^]+)$/);
      if (!quoteParsed) {
        return ReactDOMServer.renderToString(
          <blockquote
            className={styles.blockquote_default}
            dangerouslySetInnerHTML={{ __html: quote }}
          />
        );
      }

      const [ /* null */, contentBegin, style, contentEnd ] = quoteParsed;
      const className = styles['blockquote_' + (style || 'default')];
      const content = ReactDOMServer
        .renderToString(
          <div>
            { style === 'warning' && <WarningFilled /> }
            { style === 'alert' && <AlertFilled /> }
            { !style && <InfoCircleFilled /> }

            <div
              dangerouslySetInnerHTML={{ __html: contentBegin + contentEnd }}
            />
          </div>
        );

      return ReactDOMServer.renderToString(
        <blockquote
          className={className}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      );
    };

    renderer.codespan = code => {
      const html = ReactDOMServer.renderToString(
        <code dangerouslySetInnerHTML={{ __html: code }} />
      );

      return `<span title="Click to copy" onmousedown="const el = document.createElement('textarea'); el.value = this.innerText; el.style = { visible: false }; document.body.appendChild(el); el.select(); document.execCommand('copy'); document.body.removeChild(el);">${html}</span>`;
    };

    // Add a anchor link on headers. See https://github.com/markedjs/marked/blob/master/docs/USING_PRO.md
    renderer.heading = this.props.headersAddAnchor !== false
      ? (text, level, raw, slugger) => {
        // Remove HTML from text, useful for titles like "# Error `this is an error`", where "<code>" tags are generated
        const element = document.createElement('div');
        element.innerHTML = text;

        const escapedText = utils.textToUri(element.innerText);

        const id = slugger.slug(escapedText); // slugger.slug generates a uniq value (adds suffix "-1" if needed)
        const HeadingTag = `h${level}`;
        return ReactDOMServer.renderToString(
          <HeadingTag id={id}>
            <span dangerouslySetInnerHTML={{ __html: text }} />
            <a className={styles.anchor} href={`#${id}`}>
              <LinkOutlined />
            </a>
          </HeadingTag>
        );
      }
      : renderer.heading;

    // The goal of renderToString is to escape the HTML content
    renderer.html = html => ReactDOMServer.renderToString(
      html
        // Remove comments
        .replace(/<!--(?!-?>)[\s\S]*?(?:-->|$)/g, '')
    );

    // To avoid to automaticall transform www.yyy.zzz texts to links
    marked.use({
      tokenizer: {
        url: (src) => ''
      }
    });

    marked(
      content,
      {
        baseUrl,
        renderer,
        breaks: true
      },
      (err, content) => {
        this.setState(
          { content },
          () => {
            if (this.props.location.hash) {
              const element = document.getElementById(this.props.location.hash.substring(1));
              if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
              }
            }
          }
        );
      }
    );
  };


  render() {
    if (!this.state.content) {
      return null;
    }
    return (
      <div
        className={[ styles.markdown, this.props.className ].join(' ')}
        style={this.props.style}
        dangerouslySetInnerHTML={{ __html: this.state.content }}
      />
    );
  }
}

export default compose(
  withRouter
)(Markdown);