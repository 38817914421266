/*eslint react/no-unused-class-component-methods: "off"*/

import React from 'react';
import PropTypes from 'prop-types';

import { Form, Input, Select, DatePicker, Radio, Checkbox, Tag, Switch, Button } from 'antd';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import styles from './FormItems.css';

class FormItems extends React.Component {
  static propTypes = {
    entries: PropTypes.array.isRequired,
    onStatusChange: PropTypes.func,
    onUpdate: PropTypes.func.isRequired,
    datas: PropTypes.object.isRequired,
    sizes: PropTypes.object,
    showErrors: PropTypes.bool
  };

  state = {
    formIsValid: undefined,
    formErrors: {},
    formErrorsVisibled: {},
    textTags: {
      value: '',
      inputVisibled: false
    }
  };


  UNSAFE_componentWillMount() {
    this._mounted = true;
    this.validityFormCheck(this.props);
  }


  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this._mounted) {
      return;
    }
    this.validityFormCheck(nextProps);

    if (JSON.stringify(this.props.entries.map(entry => entry.name)) !== JSON.stringify(nextProps.entries.map(entry => entry.name))) {
      // Remove entries from "datas" if they are not anymore defined in "entries".
      // Useful in cases like organization/edit, when we have a VAT defined, then we change our country to one that doesn't support VAT.
      this.props.onUpdate(
        Object.keys(nextProps.datas)
          .filter(key => nextProps.entries.findIndex(({ name }) => name === key) !== -1)
          .map(key => ({ [key]: nextProps.datas[key] }))
          .reduce((accumulator, value) => ({ ...accumulator, ...value }), {})
      );
    }
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  validityFormCheck(props) {
    const formErrors = props.entries
      .map(entry => {
        const { name, input } = entry;
        const { datas } = props;
        const value = datas[name] || '';
        const error = input.hasError
          ? entry.input.type.indexOf('array') === 0
            ? value.findIndex(value => input.hasError(name, value, datas)) !== -1
            : input.hasError(name, value, datas)
          : null;
        return error ? { [name]: error } : null;
      })
      .filter(v => v)
      .reduce((acc, value) => ({ ...acc, ...value }), {});

    const formIsValid = Object.keys(formErrors).length === 0;
    const formValidityChanged = formIsValid !== this.state.formIsValid;

    this.setState({
      formErrors,
      formIsValid
    });

    if (this.props.onStatusChange && formValidityChanged) {
      this.props.onStatusChange(formIsValid);
    }
  }


  renderTextarea(entry) {
    const { onUpdate, datas } = this.props;
    const { name, input } = entry;
    const value = datas[name];
    return (
      <Input.TextArea
        type={input.type}
        prefix={input.prefix}
        placeholder={input.placeholder}
        autoComplete={input.autoComplete}
        value={value}
        autoSize
        onChange={e => onUpdate({ ...datas, [name]: e.target.value })}
        onBlur={e => {
          // Make errors visibled onBlur
          this.setState(state => ({ ...state, formErrorsVisibled: { ...state.formErrorsVisibled, [name]: true } }));
        }}
      />
    );
  }


  renderInput(entry) {
    const { onUpdate, datas } = this.props;
    const { name, input } = entry;
    const value = datas[name];

    return (
      <Input
        type={input.type}
        prefix={input.prefix}
        placeholder={input.placeholder}
        addonAfter={input.addonAfter}
        autoComplete={input.autoComplete}
        value={value}
        onChange={e => {
          let value = e.target.value;
          if (input.onChange) {
            value = input.onChange(e.target.value);
          }
          onUpdate({ ...datas, [name]: value });
        }}
        onBlur={e => {
          // Make errors visibled onBlur
          this.setState(state => ({ ...state, formErrorsVisibled: { ...state.formErrorsVisibled, [name]: true } }));
        }}
      />
    );
  }

  renderArrayInput(entry) {
    const { onUpdate, datas } = this.props;
    const { name, input } = entry;
    const entries = datas[name];

    return (
      <>
        {entries.map((entry, index) => (
          <Form.Item
            key={index}
            validateStatus={
              (this.state.formErrorsVisibled[name] || this.props.showErrors) && input.hasError && input.hasError(name, entry, datas)
                ? 'error'
                : 'success'
            }
          >
            <Input
              type={input.type}
              prefix={input.prefix}
              placeholder={input.placeholder}
              autoComplete={input.autoComplete}
              value={entry}
              onChange={e => {
                let value = e.target.value;
                if (input.onChange) {
                  value = input.onChange(e.target.value);
                }
                onUpdate({
                  ...datas,
                  [name]: entries.map((entryTmp, indexTmp) => indexTmp === index ? value : entryTmp)
                });
              }}
              onBlur={e => {
                // Make errors visibled onBlur
                this.setState(state => ({ ...state, formErrorsVisibled: { ...state.formErrorsVisibled, [name]: true } }));
              }}
              addonAfter={input.min && entries.length > input.min &&
                (
                  <MinusCircleOutlined
                    onClick={() => onUpdate({
                      ...datas,
                      [name]: entries.filter((entryTmp, indexTmp) => indexTmp !== index)
                    })}
                  />
                )
              }
            />
          </Form.Item>
        ))}
        <div style={{ textAlign: 'right' }}>
          <Button
            type="primary"
            size="small"
            onClick={() => onUpdate({ ...datas, [name]: [ ...entries, '' ] })}
            disabled={input.max && entries.length >= input.max}
          >
            Add
          </Button>
        </div>
      </>
    );
  }


  renderTextTags(entry) {
    const { onUpdate, datas } = this.props;
    const { name, input } = entry;
    const value = datas[name];

    const entryAdd = entryValue => {
      const values = [ ...value, entryValue ];
      // Add entry
      const hasError = input.hasError && input.hasError(name, values, datas) || value.findIndex(v => v === entryValue) !== -1;
      if (!hasError) {
        onUpdate({ ...datas, [name]: values });
      }

      // Make errors visibled
      this.setState(state => ({ ...state, formErrorsVisibled: { ...state.formErrorsVisibled, [name]: true } }));

      this.setState(state => ({ textTags: { ...state.textTags, value: '', inputVisibled: false } }));
    };

    const inputShow = () => {
      this.setState(
        state => ({ textTags: { ...state.textTags, inputVisibled: true } }),
        () => this.input.focus());
    };


    return [
      (
        <React.Fragment key="tags">
          {datas[name].map(value => (
            <Tag
              key={value}
              closable={!input.min || datas[name].length > input.min}
              onClose={() => onUpdate({ ...datas, [name]: datas[name].filter(v => v !== value) })}
            >
              {value}
            </Tag>
          ))}
        </React.Fragment>
      ),

      this.state.textTags.inputVisibled && (
        <Input
          key="input"

          ref={input => this.input = input}

          size="small"
          type="text"
          placeholder={input.placeholder}
          value={this.state.textTags.value}

          onChange={e => {
            let value = e.target.value;

            if (input.onChange) {
              value = input.onChange(e.target.value);
            }
            this.setState(state => ({ textTags: { ...state.textTags, value } }));
          }}
          onBlur={e => entryAdd(e.target.value)}
          onPressEnter={e => { e.preventDefault(); entryAdd(e.target.value); }}

          style={{ width: '160px', marginRight: '8px', verticalAlign: 'top' }}
        />
      ),

      !this.state.textTags.inputVisibled && (!input.max || datas[name].length < input.max) && (
        <Tag
          key="add"
          onClick={inputShow}
        >
          <PlusOutlined /> Add an email
        </Tag>
      )
    ];
  }


  // renderDatePicker(entry) {
  //   const { onUpdate, datas } = this.props;
  //   const { name, input } = entry;
  //   const value = datas[name];

  //   return (
  //     <DatePicker
  //       placeholder={input.placeholder}
  //       value={value}
  //       disabledDate={input.disabledDate}
  //       onChange={date => onUpdate({ ...datas, [name]: date })}
  //     />
  //   );
  // }


  renderMonthPicker(entry) {
    const { onUpdate, datas } = this.props;
    const { name, input } = entry;
    const value = dayjs(datas[name]);

    return (
      <DatePicker.MonthPicker
        placeholder={input.placeholder}
        value={value}
        disabledDate={input.disabledDate}
        onChange={(dayjsDate, date) => onUpdate({ ...datas, [name]: new Date(date) })}
      />
    );
  }


  // renderRangePicker(entry) {
  //   const { onUpdate, datas } = this.props;
  //   const { name, input } = entry;
  //   const value = datas[name];

  //   return (
  //     <DatePicker.RangePicker
  //       value={value}
  //       disabledDate={input.disabledDate}
  //       onChange={date => onUpdate({ ...datas, [name]: date })}
  //     />
  //   );
  // }


  renderSelect(entry) {
    const { onUpdate, datas } = this.props;
    const { name, input } = entry;
    const value = datas[name];

    return (
      <Select
        placeholder={input.placeholder}
        showSearch
        value={value}
        optionFilterProp="children"
        popupMatchSelectWidth={false}
        onChange={value => onUpdate({ ...datas, [name]: value })}
        onBlur={e => {
          // Make errors visibled onBlur
          this.setState(state => ({ ...state, formErrorsVisibled: { ...state.formErrorsVisibled, [name]: true } }));
        }}
      >
        {input.entries.map(entry => ( <Select.Option key={entry.id} value={entry.id}>{entry.label}</Select.Option> ))}
      </Select>
    );
  }


  renderTag(entry) {
    const { onUpdate, datas } = this.props;
    const { name, input } = entry;

    return input.entries.map(entry => {
      const entryValueEncoded = JSON.stringify(entry.value);
      const checked = datas[name].findIndex(v => JSON.stringify(v) === entryValueEncoded) !== -1;

      return (
        <Tag.CheckableTag
          key={entryValueEncoded}
          checked={checked}
          onChange={checked => onUpdate({
            ...datas,
            [name]: checked
              ? [ ...datas[name], entry.value ]
              : datas[name].filter(v => JSON.stringify(v) !== entryValueEncoded)
          })}
          style={{ borderColor: checked ? '' : 'rgb(217, 217, 217)' }}
        >
          {entry.label}
        </Tag.CheckableTag>
      );
    });
  }


  renderRadio(entry) {
    const { onUpdate, datas } = this.props;
    const { name, input } = entry;

    return (
      <Radio.Group
        onChange={e => onUpdate({ ...datas, [name]: e.target.value })}
        value={datas[name]}
        style={{ marginTop: 10 }}
      >
        {input.entries.map(entry =>
          <Radio className={input.vertical ? styles.radioVertical : ''} key={entry.value} value={entry.value}>
            {entry.label}
            {entry.legend ? <><br /><span className={styles.radioLegend}>{entry.legend}</span></> : null}
          </Radio>
        )}
      </Radio.Group>
    );
  }


  renderRadioButtons(entry) {
    const { onUpdate, datas } = this.props;
    const { name, input } = entry;

    return (
      <Radio.Group
        onChange={e => onUpdate({ ...datas, [name]: e.target.value })}
        value={datas[name]}
        buttonStyle="solid"
      >
        {input.entries.map(entry =>
          <Radio.Button key={entry.value} value={entry.value}>
            {entry.label}
          </Radio.Button>
        )}
      </Radio.Group>
    );
  }


  renderCheckbox(entry) {
    const { onUpdate, datas } = this.props;
    const { name, input } = entry;

    return (
      <Checkbox.Group
        options={input.entries}
        value={datas[name]}
        onChange={values => onUpdate({ ...datas, [name]: values })}
      />
    );
  }


  renderSwitch(entry) {
    const { onUpdate, datas } = this.props;
    const { name, input } = entry;

    return (
      <Switch
        unCheckedChildren={input.entries[0]}
        checkedChildren={input.entries[1]}
        checked={datas[name]}
        onChange={checked => onUpdate({ ...datas, [name]: checked })}
      />
    );
  }


  renderElement(entry) {
    return entry.input.entry;
  }


  render() {
    const { entries, sizes } = this.props;

    const sizesFinal = sizes ? sizes : { xs: 24, sm: 4 };

    const labelCol = Object.keys(sizesFinal)
      .map(size => ({ [size]: { span: sizesFinal[size], offset: 0 } }))
      .reduce((acc, value) => ({ ...acc, ...value }), {});

    const wrapperCol = Object.keys(sizesFinal)
      .map(size => ({ [size]: { span: 24 - sizesFinal[size] || 24, offset: 0 } }))
      .reduce((acc, value) => ({ ...acc, ...value }), {});

    return entries.map((entry, index) => {
      const { name, required, label, extra, help } = entry;

      if (!name) {
        throw Error('Name is required!');
      }

      const types = {
        element: 'renderElement',
        textarea: 'renderTextarea',
        text: 'renderInput',
        password: 'renderInput',
        textTags: 'renderTextTags',
        select: 'renderSelect',
        datePicker: 'renderDatePicker',
        monthPicker: 'renderMonthPicker',
        rangePicker: 'renderRangePicker',
        radioButtons: 'renderRadioButtons',
        radio: 'renderRadio',
        checkbox: 'renderCheckbox',
        switch: 'renderSwitch',
        tag: 'renderTag',
        arrayText: 'renderArrayInput'
      };

      return (
        <Form.Item
          key={index}
          label={label}
          extra={extra}
          required={required}
          labelCol={labelCol}
          wrapperCol={wrapperCol}
          help={
            (this.state.formErrorsVisibled[name] || this.props.showErrors) && this.state.formErrors[name]  && this.state.formErrors[name] !== true
              ? this.state.formErrors[name]
              : help
          }
          validateStatus={
            (this.state.formErrorsVisibled[name] || this.props.showErrors) && this.state.formErrors[name] && entry.input.type.indexOf('array') !== 0
              ? 'error'
              : 'success'
          }
        >
          {this[types[entry.input.type]](entry)}
        </Form.Item>
      );
    });
  }
}

export default FormItems;
