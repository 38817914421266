import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { Card } from 'antd';

class _default extends React.Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  componentDidMount() {

    // Go to automatically to organization page if there is only one
    const route = '/dashboard/organizations';
    const organizations = this.props.api.subscriptions[route].datas;
    if (organizations.length === 1) {
      this.props.history.replace(`/account/organizations/${organizations[0].id}/`);
    }
  }

  // goTo = id => {
  //   this.props.history.replace(`/account/organizations/${id}/`);
  // };

  renderOrganizations() {
    const route = '/dashboard/organizations';
    const organizations = this.props.api.subscriptions[route].datas;
    return organizations.map(organization => (
      <Card
        key={organization.id}
        // actions={this.renderActions(organization)}
        style={{ marginBottom: 16 }}
      >
        {/* <Card.Meta
          title={this.renderTitle(stack)}
          description={this.renderDescription(stack)}
          onClick={() => this.state.stackEdition.id !== stack.id && this.goToStack(stack)}
          style={ this.state.stackEdition.id !== stack.id ? { cursor: 'pointer' } : {}}
        /> */}
      </Card>
    ));
  }

  render() {
    return (
      <Card title="Organizations" style={{ width: 1000, maxWidth: '100%', margin: '60px auto' }}>
        Choose an organization:
        <br />

        {this.renderOrganizations()}
      </Card>
    );
  }
}

export default connect(
  ({ api }) => ({ api })
)(_default);
