import React from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as apiActions from 'redux/actions/api';

class _container extends React.Component {
  static propTypes = {
    apiActions: PropTypes.object.isRequired,
    api: PropTypes.object.isRequired,
    children: PropTypes.node,
    match: PropTypes.object.isRequired
  };

  componentDidMount() {
    const { serviceStoreId, organizationId } = this.props.match.params;
    const route = `/dashboard/organizations/${organizationId}/servicesStore/${serviceStoreId}/instancesStore`;
    this.props.apiActions.subscribe({
      route,
      routeArgs: {}
    });
  }

  componentWillUnmount() {
    const { serviceStoreId, organizationId } = this.props.match.params;
    const route = `/dashboard/organizations/${organizationId}/servicesStore/${serviceStoreId}/instancesStore`;
    this.props.apiActions.unsubscribe({ route });
  }

  render() {
    const { serviceStoreId, organizationId } = this.props.match.params;
    const route = `/dashboard/organizations/${organizationId}/servicesStore/${serviceStoreId}/instancesStore`;
    const subscription = this.props.api.subscriptions[route];
    const display = subscription && subscription.status !== 'initialize';

    return display ? this.props.children : null;
  }
}

export default connect(
  ({ api }) => ({ api }),
  dispatch => ({
    apiActions: bindActionCreators(apiActions, dispatch)
  })
)(_container);
