import React from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as apiActions from 'redux/actions/api';
import * as navigationActions from 'redux/actions/navigation';

import { Card, Alert, Button } from 'antd';

import Steps from 'components/Steps';

import styles from './_container.css';

class _container extends React.Component {
  static propTypes = {
    apiActions: PropTypes.object.isRequired,
    api: PropTypes.object.isRequired,
    children: PropTypes.node,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    navigationActions: PropTypes.object.isRequired
  };

  componentDidMount() {
    const { organizationId } = this.props.match.params;
    this._routeServicesStore = `/dashboard/organizations/${organizationId}/servicesStore`;
    // this._routeServicesPacksStore = `/dashboard/organizations/${organizationId}/servicesPacksStore`;

    this.props.apiActions.subscribe({
      route: this._routeServicesStore,
      routeArgs: {}
    });

    // this.props.apiActions.subscribe({
    //   route: this._routeServicesPacksStore,
    //   routeArgs: {}
    // });

    const organization = this.props.api.subscriptions[`/dashboard/organizations/${organizationId}`].datas;

    this.props.navigationActions.stepsSet([
      { title: 'Select a service', label: 'serviceStore' },
      { title: 'Select an instance', label: 'instanceStore' },
      !organization.name ? { title: 'Your organization', label: 'organization' } : null,
      { title: 'Verifications', label: 'verifications' },
      { title: 'Validate terms', label: 'terms' }
    ].filter(v => v !== null));

    const { url, path } = this.props.match;
    const urlCorrected = !/\/$/.test(url) && /\/$/.test(path) ? `${url}/` : url;
    this._url = urlCorrected;
    this.props.navigationActions.breadcrumbAdd('Add a service', this._url, this._url);
  }

  componentWillUnmount() {
    this.props.navigationActions.breadcrumbRemove(this._url);
    this.props.navigationActions.stepsUnset();

    this.props.apiActions.unsubscribe({ route: this._routeServicesStore });
    this.props.apiActions.unsubscribe({ route: this._routeServicesPackStore });
  }

  renderCoupon() {
    const { organizationId } = this.props.match.params;
    const organization = this.props.api.subscriptions[`/dashboard/organizations/${organizationId}`].datas;
    const { coupons } = organization;

    if (!coupons.length) {
      return null;
    }
    else if (coupons.length > 1) {
      return (
        <Alert
          message="You have some coupons!"
          description={(
            <>
              <ul>
                {
                  coupons
                    .map((coupon, index) => {
                      const amountAvailableFormated = new Intl.NumberFormat(undefined, { style: 'currency', currency: coupon.currency, minimumFractionDigits: coupon.currencyHasCents ? 2 : 0 }).format(coupon.amountAvailable / 100);
                      const availableUntilFormated = new Intl.DateTimeFormat(undefined).format(new Date(coupon.availableUntil));

                      return (
                        <li key={index}>
                          {amountAvailableFormated} left until {availableUntilFormated}, available for {coupon.serviceStoreTitleConcerned || 'all services'}.
                        </li>
                      );
                    })
                }
              </ul>
              To use them, simply start a service, the rest is automatic.
            </>
          )}
          type="info"
          action={(
            <Button size="small" type="text" onClick={() => this.props.history.push(`/account/organizations/${organizationId}/billing/coupons/`)}>
              See coupons details
            </Button>
          )}
          showIcon
          style={{ marginBottom: 20 }}
        />
      );
    }
    else {
      const coupon = coupons[0];
      const amountAvailableFormated = new Intl.NumberFormat(undefined, { style: 'currency', currency: coupon.currency, minimumFractionDigits: coupon.currencyHasCents ? 2 : 0 }).format(coupon.amountAvailable / 100);
      const availableUntilFormated = new Intl.DateTimeFormat(undefined).format(new Date(coupon.availableUntil));

      return (
        <Alert
          message={(
            <>
              You have a {amountAvailableFormated} coupon left until {availableUntilFormated}, available for {coupon.serviceStoreTitleConcerned || 'all services'}.
              <br />
              {
                coupon.serviceStoreTitleConcerned
                  ? `To use it, simply start a ${coupon.serviceStoreTitleConcerned} service, the rest is automatic.`
                  : 'To use it, simply start a service, the rest is automatic.'
              }
            </>
          )}
          type="info"
          action={(
            <Button size="small" type="text" onClick={() => this.props.history.push(`/account/organizations/${organizationId}/billing/coupons/`)}>
              See coupon details
            </Button>
          )}
          showIcon
          style={{ marginBottom: 20 }}
        />
      );
    }
  }

  renderChildren() {
    return (
      <div>
        <Card className={styles.steps}>
          <Steps />
        </Card>
        {this.renderCoupon()}
        {this.props.children}
      </div>
    );
  }

  render() {
    const subscriptionServiceStore = this.props.api.subscriptions[this._routeServicesStore];
    const display = subscriptionServiceStore && subscriptionServiceStore.status !== 'initialize';
    // const subscriptionServicePackStore = this.props.api.subscriptions[this._routeServicesPacksStore];
    // const display = subscriptionServiceStore && subscriptionServiceStore.status !== 'initialize'
    //   && subscriptionServicePackStore && subscriptionServicePackStore.status !== 'initialize';

    return display ? this.renderChildren() : null;
  }
}

export default connect(
  ({ api }) => ({ api }),
  dispatch => ({
    navigationActions: bindActionCreators(navigationActions, dispatch),
    apiActions: bindActionCreators(apiActions, dispatch)
  })
)(_container);
