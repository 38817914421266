import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash.isequal';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as apiActions from 'redux/actions/api';

import { Card, message, Modal } from 'antd';

import Configurator from 'components/Configurator';

class _default extends React.Component {
  static propTypes = {
    apiActions: PropTypes.object.isRequired,
    api: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };

  state = {
    userConfigurationSchema: () => ({}),
    userConfiguration: {}
  };

  componentDidMount() {
    this._mounted = true;

    const { serviceId } = this.props.match.params;
    const route = `/dashboard/services/${serviceId}/configuration`;
    this.updateDatas(this.props.api.subscriptions[route].datas);
  }

  componentDidUpdate(prevProps) {
    const { serviceId } = prevProps.match.params;
    const route = `/dashboard/services/${serviceId}/configuration`;
    const prev = prevProps.api.subscriptions[route].datas;
    const current = this.props.api.subscriptions[route].datas;

    if (!isEqual(prev, current)) {
      this.updateDatas(current);
    }
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  updateDatas({ userConfiguration, userConfigurationSchema }) {
    if (!this._mounted) {
      return;
    }

    userConfigurationSchema = new Function('return ' + userConfigurationSchema.toString())();
    this.setState({ userConfigurationSchema, userConfiguration, configuration: {} });

    // Get default values
    const { serviceId } = this.props.match.params;
    const routeService = `/dashboard/services/${serviceId}`;
    const service = this.props.api.subscriptions[routeService].datas;
    const schema = userConfigurationSchema({}, service, userConfiguration, userConfiguration, []);
    for (const key in schema) {
      if (schema[key].defaultValue !== undefined) {
        this.setState(prevState => ({
          configuration: { ...prevState.configuration, [key]: schema[key].defaultValue }
        }));
      }
    }
  }

  onSubmit = (configuration) => {
    this.setState({ configuration });

    const { serviceId }= this.props.match.params;
    this.props.apiActions.patch(
      {
        route: `/dashboard/services/${serviceId}/configuration`,
        routeArgs: { configuration },
        handleErrorAutomatically: false
      },
      (error, res) => {
        if (!this._mounted) {
          return;
        }

        if (error && error.response && error.response.data && error.response.data.message) {
          Modal.error({
            title: 'Error with your configuration',
            content: error.response.data.message.split('\n').map((text, key) => <span key={key}>{text}<br/></span> )
          });
          return;
        }
        else if (error) {
          throw error;
        }

        message.success('Your configuration has been saved');
        this.props.history.push('../');
      }
    );
  };


  renderConfigurator() {
    if (!this.state.configuration) {
      return null;
    }

    const { serviceId } = this.props.match.params;
    const routeService = `/dashboard/services/${serviceId}`;
    const service = this.props.api.subscriptions[routeService].datas;
    return (
      <Configurator
        service={service}
        schema={this.state.userConfigurationSchema}
        configuration={this.state.userConfiguration}
        onCancel={() => this.props.history.push('../')}
        onSubmit={this.onSubmit}
      />
    );
  }


  render() {
    const { serviceId } = this.props.match.params;
    const routeService = `/dashboard/services/${serviceId}`;
    const service = this.props.api.subscriptions[routeService].datas;
    return (
      <Card title={( <span>Configuration of {service.name}</span> )}>
        {this.renderConfigurator()}
      </Card>
    );
  }
}

export default connect(
  ({ api }) => ({ api }),
  dispatch => ({
    apiActions: bindActionCreators(apiActions, dispatch)
  })
)(_default);
