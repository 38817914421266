import logo from 'images/servicesLogos/elasticsearch.svg';
import logoOpenGraph from 'images/servicesLogos/openGraphVersions/elasticsearch.png';

import growth from 'images/landings/icons/growth.svg';
import startup from 'images/landings/icons/startup.svg';
import shield from 'images/landings/icons/shield.svg';
import packageSvg from 'images/landings/icons/package.svg';
import website from 'images/landings/icons/website.svg';
import strategy from 'images/landings/icons/strategy.svg';

import checked from 'images/landings/icons/checked.svg';

import imageServicesPacksElaticsearch from 'images/landings/screenshots/servicesPacksElaticsearch.png';
import imageServicesElasticsearchKibana from 'images/landings/screenshots/servicesElasticsearchKibana.png';

const backgroundColor = '#00a9e5';
const button = {
  color: '#000',
  backgroundColor: '#f9b110'
};

const serviceName = 'Elasticsearch';
export default ({ t = () => [] }) => ({
  id: 'elasticsearch',
  name: 'Elasticsearch',
  nameUrl: 'Elasticsearch',
  title: t('services/elasticsearch:title', { serviceName }),
  logo,
  logoOpenGraph,
  button,
  backgroundColor,

  header: {
    title: 'Elasticsearch',
    description: t('services/elasticsearch:description', { serviceName }) + '\n\n' + t('services/elasticsearch:callToAction', { serviceName }),
    images: [
      { image: imageServicesPacksElaticsearch },
      { image: imageServicesElasticsearchKibana }
    ]
  },

  explanation: {
    title: t('services/elasticsearch:explanation.title'),
    content: t('services/elasticsearch:explanation.content')
  },

  benefits: {
    items: [
      {
        icon: growth,
        ...t('services/elasticsearch:benefits.business', { serviceName })
      },
      {
        icon: startup,
        ...t('services/elasticsearch:benefits.performant', { serviceName })
      },
      {
        icon: shield,
        ...t('services/elasticsearch:benefits.secured', { serviceName })
      },
      {
        icon: packageSvg,
        ...t('services/elasticsearch:benefits.complete', { serviceName })
      },
      {
        icon: website,
        ...t('services/elasticsearch:benefits.simple', { serviceName })
      },
      {
        icon: strategy,
        ...t('services/elasticsearch:benefits.updatable', { serviceName })
      }
    ]
  },

  also: {
    color: button.backgroundColor,
    items: t('services/elasticsearch:also.items', { serviceName })
      .map(content => ({ icon: checked, ...content }))
  },

  pricing: {
    buttonBackgroundColor: button.backgroundColor,
    buttonColor: button.color,
    sentencesIconColor: button.backgroundColor,
  },

  recall: {
    description: t('services/elasticsearch:callToAction', { serviceName })
  },

  faq: {
    items: t('services/elasticsearch:faq.items', { serviceName })
  }
});
