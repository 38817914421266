import logo from 'images/servicesLogos/node-red.svg';
import logoOpenGraph from 'images/servicesLogos/openGraphVersions/node-red.png';

import growth from 'images/landings/icons/growth.svg';
import startup from 'images/landings/icons/startup.svg';
import shield from 'images/landings/icons/shield.svg';
import packageSvg from 'images/landings/icons/package.svg';
import envelope from 'images/landings/icons/envelope.svg';
import strategy from 'images/landings/icons/strategy.svg';

import checked from 'images/landings/icons/checked.svg';

import imageMqtt from 'images/landings/screenshots/node-red/mqtt.png';
import imageTemperatures from 'images/landings/screenshots/node-red/temperatures.png';
import imageDashboard from 'images/landings/screenshots/node-red/dashboard.png';

const backgroundColor = '#333';
const button = {
  color: '#fff',
  backgroundColor: '#8f0000'
};

export default ({ t = () => [] }) => ({
  id: 'node-red',
  name: 'Node-RED',
  nameUrl: 'Node-RED',
  title: t('services/node-red:title'),
  logo,
  logoOpenGraph,
  button,
  backgroundColor,

  header: {
    title: 'Node-RED',
    description: t('services/node-red:description'),
    images: [
      { image: imageMqtt },
      { image: imageTemperatures },
      { image: imageDashboard }
    ]
  },

  explanation: {
    title: t('services/node-red:explanation.title'),
    content: t('services/node-red:explanation.content')
  },

  benefits: {
    items: [
      {
        icon: growth,
        ...t('services/node-red:benefits.business')
      },
      {
        icon: startup,
        ...t('services/node-red:benefits.performant')
      },
      {
        icon: shield,
        ...t('services/node-red:benefits.secured')
      },
      {
        icon: packageSvg,
        ...t('services/node-red:benefits.mqtt')
      },
      {
        icon: envelope,
        ...t('services/node-red:benefits.emails')
      },
      {
        icon: strategy,
        ...t('services/node-red:benefits.updatable')
      }
    ]
  },

  also: {
    color: button.backgroundColor,
    items: t('services/node-red:also.items')
      .map(content => ({ icon: checked, ...content }))
  },

  pricing: {
    ...t('services/node-red:pricing'),
    buttonBackgroundColor: button.backgroundColor,
    buttonColor: button.color,
    sentencesIconColor: button.backgroundColor,
    includes: t('services/node-red:pricing.includes')
      .map(description => ({ icon: checked, description })),
    fields: {
      vCpus: vCpus => [ vCpus, vCpus === 1 ? t('mainPage.pricing.subLegends.vCpu') : t('mainPage.pricing.subLegends.vCpus') ],
      ram: ram => [ ram < 1024 * 1024 * 1024 ? `${Math.round(ram / (1024 * 1024))} ${t('units.MB')}` : `${Math.round(ram / (1024 * 1024 * 1024))} ${t('units.GB')}`, t('mainPage.pricing.subLegends.ram') ],
      hdd: hdd => [ hdd < 1024 * 1024 * 1024 ? `${Math.round(hdd / (1024 * 1024))} ${t('units.MB')}` : `${Math.round(hdd / (1024 * 1024 * 1024))} ${t('units.GB')}`, t('mainPage.pricing.subLegends.disk') ],
      usersAdminMaximum: usersAdminMaximum => [
        new Intl.NumberFormat(undefined).format(usersAdminMaximum),
        t('services/node-red:pricing.subLegends.usersAdminMaximum')
      ]
    }
  },

  recall: {
    description: t('services/node-red:recall')
  },

  faq: {
    items: t('services/node-red:faq.items')
  }
});