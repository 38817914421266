import React from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as navigationActions from 'redux/actions/navigation';
import * as apiActions from 'redux/actions/api';

import { Card, Table, Spin, Alert, Button, Modal, message } from 'antd';
import { IdcardOutlined, DeleteOutlined } from '@ant-design/icons';
import LinkLayout from 'components/Link';
import ModalPrometheusToken from 'components/ModalPrometheusToken';


class Prometheus extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    api: PropTypes.object.isRequired,
    apiActions: PropTypes.object.isRequired,
    navigationActions: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };

  componentDidMount() {
    this._mounted = true;

    const { url, path } = this.props.match;
    const urlCorrected = !/\/$/.test(url) && /\/$/.test(path) ? `${url}/` : url;
    this.props.navigationActions.breadcrumbAdd(this.renderBreadcrumb(), null, 'organization');
    this.props.navigationActions.breadcrumbAdd('Prometheus', urlCorrected, urlCorrected);

    const { organizationId } = this.props.match.params;
    this._route = `/dashboard/organizations/${organizationId}/prometheusTokens`;
    this.props.apiActions.subscribe({
      route: this._route,
      routeArgs: {}
    });
  }

  componentWillUnmount() {
    this._mounted = false;

    this.props.apiActions.unsubscribe({ route: this._route });

    const { url, path } = this.props.match;
    const urlCorrected = !/\/$/.test(url) && /\/$/.test(path) ? `${url}/` : url;
    this.props.navigationActions.breadcrumbRemove(urlCorrected);
    this.props.navigationActions.breadcrumbRemove('organization');
  }

  tokenDelete(id) {
    const { organizationId } = this.props.match.params;
    this.props.apiActions.del(
      {
        route: `/dashboard/organizations/${organizationId}/prometheusTokens/${id}`,
        routeArgs: {}
      },
      (error, result) => {
        if (!this._mounted) { return false; }

        message.success('Token deleted');
      }
    );
  }

  tokenDeleteAsk(id) {
    Modal.confirm({
      title: 'Delete this token?',
      onOk: () => this.tokenDelete(id)
    });
  }

  renderBreadcrumb() {
    return (
      <>
        <IdcardOutlined />
        &nbsp;
        Organization
      </>
    );
  }

  render() {
    const subscription = this.props.api.subscriptions[this._route];
    const display = subscription && subscription.status !== 'initialize';
    const tokens = subscription?.datas?.tokens || [];

    return (
      <Card title="Prometheus tokens" style={{ marginBottom: 16 }}>
        <Alert
          showIcon
          message={(<>
            Stackhero gives you the ability to retrieve your real-time services metrics in a Prometheus compatible format.<br />
            You will find more informations in <LinkLayout url={`${process.env.HTTP_SCHEME}://${process.env.SHOWCASE_HOST}/${this.props.app.languageAndRegion}/stackhero/documentations/Monitor-your-instances`}>the documentation</LinkLayout>.
          </>)}
          style={{ marginBottom: 16 }}
        />

        <Card>
          <Spin spinning={!display}>
            <Table
              dataSource={tokens}
              rowKey="id"
              pagination={false}
              style={{ marginBottom: 16 }}
            >
              <Table.Column
                title="Token"
                dataIndex="token"
              />

              <Table.Column
                title="Description"
                key="description"
                render={({ description }) => description || 'No description'
                }
              />

              <Table.Column
                title="IPs allowed"
                key="cidrsAllowed"
                render={({ cidrsAllowed }) => cidrsAllowed.join(', ')
                }
              />

              <Table.Column
                title="Last used"
                key="lastUsedDate"
                render={({ lastUsedDate }) => lastUsedDate
                  ? new Intl.DateTimeFormat(
                      undefined,
                      { hour: 'numeric', minute: 'numeric', second: 'numeric', year: 'numeric', month: 'numeric', day: 'numeric' }
                    )
                    .format(new Date(lastUsedDate))
                  : 'Token not used yet'
                }
              />

              <Table.Column
                title="Last IP"
                key="lastUsedIp"
                render={({ lastUsedIp }) => lastUsedIp || 'Token not used yet'
                }
              />

              <Table.Column
                key="actions"
                align="right"
                render={token => (
                  <Button
                    onClick={this.tokenDeleteAsk.bind(this, token.id)}
                    icon={<DeleteOutlined />}
                    danger
                  />
                )}
              />
            </Table>
          </Spin>

          <div style={{ textAlign: 'right' }}>
            <ModalPrometheusToken enabled={tokens.length < 3} organizationId={this.props.match.params.organizationId} />
          </div>
        </Card>
      </Card>
    );
  }
}

export default connect(
  ({ api, app }) => ({ api, app }),
  dispatch => ({
    apiActions: bindActionCreators(apiActions, dispatch),
    navigationActions: bindActionCreators(navigationActions, dispatch)
  })
)(Prometheus);
