import logo from 'images/servicesLogos/minio.svg';
import logoOpenGraph from 'images/servicesLogos/openGraphVersions/minio.png';

import growth from 'images/landings/icons/growth.svg';
import startup from 'images/landings/icons/startup.svg';
import shield from 'images/landings/icons/shield.svg';
import key from 'images/landings/icons/key.svg';
import medal from 'images/landings/icons/medal.svg';
import strategy from 'images/landings/icons/strategy.svg';

import checked from 'images/landings/icons/checked.svg';

import imageDashboard from 'images/landings/screenshots/minio/dashboard.png';

const backgroundColor = '#32393f';
const button = {
  color: '#fff',
  backgroundColor: '#c72c48'
};

const serviceName = 'MinIO';
export default ({ t = () => [], region }) => ({
  id: 'minio',
  name: 'MinIO',
  nameUrl: 'MinIO',

  title: [
    region === 'fr' && t('services/minio:titles.fr'),
    t('services/minio:titles._')
  ].filter(v => v).shift(),

  logo,
  logoStyle: [
    {
      elementId: 'body',
      style: {
        fill: '#fff'
      }
    }
  ],
  logoOpenGraph,
  button,
  backgroundColor,

  header: {
    title: 'MinIO',

    description:
      [
        region === 'fr'
          ? t('services/minio:descriptions.fr')
          : t('services/minio:descriptions._'),
        '',
        t('services/minio:callToAction', { serviceName })
      ].join('\n'),

    images: [
      { image: imageDashboard }
    ]
  },

  benefits: {
    items: [
      {
        icon: growth,
        ...t('services/minio:benefits.business', { serviceName })
      },
      {
        icon: startup,
        ...t('services/minio:benefits.performant', { serviceName })
      },
      {
        icon: shield,
        ...t('services/minio:benefits.secured', { serviceName })
      },
      {
        icon: key,
        ...t('services/minio:benefits.private', { serviceName })
      },
      {
        icon: medal,
        ...t('services/minio:benefits.unlimited', { serviceName })
      },
      {
        icon: strategy,
        ...t('services/minio:benefits.updatable', { serviceName })
      }
    ]
  },

  also: {
    color: button.backgroundColor,
    items: t('services/minio:also.items', { serviceName })
      .map(content => ({ icon: checked, ...content }))
  },

  pricing: {
    buttonBackgroundColor: button.backgroundColor,
    buttonColor: button.color,
    sentencesIconColor: button.backgroundColor,
    includes: t('services/minio:pricing.includes')
      .map(description => ({ icon: checked, description })),
    fields: {
      hdd: hdd => [
        hdd < 1024 * 1024 * 1024 * 1024
          ? `${new Intl.NumberFormat(undefined).format(Math.round(hdd / (1024 * 1024 * 1024)))} ${t('units.GB')}`
          : `${new Intl.NumberFormat(undefined).format(Math.round(hdd / (1024 * 1024 * 1024 * 1024)))} ${t('units.TB')}`,
        t('services/minio:pricing.subLegends.storage')
      ],
      maxConcurrentConnections: v => [ new Intl.NumberFormat(undefined).format(v), t('services/minio:pricing.subLegends.maxConcurrentConnections') ],
      transfersPerMonth: v => [
        v > 0
          ? new Intl.NumberFormat(undefined).format(v)
          : t('mainPage.pricing.unlimited'),
        t('services/minio:pricing.subLegends.transfersPerMonth')
      ],
      bandwidthBitsPerSecond: bandwidth => [
        `${Math.round(bandwidth / 1024 / 1024)} Mbps`,
        t('services/minio:pricing.subLegends.bandwidthBitsPerSecond')
      ],

    }
  },

  recall: {
    description: t('services/minio:callToAction', { serviceName })
  },

  faq: {
    items: t('services/minio:faq.items', { serviceName })
  }
});
