import logo from 'images/servicesLogos/graylog.svg';
import logoOpenGraph from 'images/servicesLogos/openGraphVersions/graylog.png';

import growth from 'images/landings/icons/growth.svg';
import startup from 'images/landings/icons/startup.svg';
import shield from 'images/landings/icons/shield.svg';
import packageSvg from 'images/landings/icons/package.svg';
import envelope from 'images/landings/icons/envelope.svg';
import strategy from 'images/landings/icons/strategy.svg';

import checked from 'images/landings/icons/checked.svg';

import imageDashboard from 'images/landings/screenshots/graylog/dashboard.png';
import imageSearch from 'images/landings/screenshots/graylog/search.png';

const backgroundColor = '#1f1f1f';
const button = {
  color: '#fff',
  backgroundColor: '#ff3633'
};

const serviceName = 'Graylog';
export default ({ t = () => [] }) => ({
  id: 'graylog',
  name: 'Graylog',
  nameUrl: 'Graylog',
  title: t('services/graylog:title', { serviceName }),
  logo,
  logoOpenGraph,
  button,
  backgroundColor,

  header: {
    title: 'Graylog',
    description: t('services/graylog:description', { serviceName }) + '\n\n' + t('services/graylog:callToAction', { serviceName }),
    images: [
      { image: imageDashboard },
      { image: imageSearch }
    ]
  },

  benefits: {
    items: [
      {
        icon: growth,
        ...t('services/graylog:benefits.business', { serviceName })
      },
      {
        icon: startup,
        ...t('services/graylog:benefits.performant', { serviceName })
      },
      {
        icon: shield,
        ...t('services/graylog:benefits.secured', { serviceName })
      },
      {
        icon: packageSvg,
        ...t('services/graylog:benefits.complete', { serviceName })
      },
      {
        icon: envelope,
        ...t('services/graylog:benefits.emails', { serviceName })
      },
      {
        icon: strategy,
        ...t('services/graylog:benefits.updatable', { serviceName })
      }
    ]
  },

  also: {
    color: button.backgroundColor,
    items: t('services/graylog:also.items', { serviceName })
      .map(content => ({ icon: checked, ...content }))
  },

  pricing: {
    buttonBackgroundColor: button.backgroundColor,
    buttonColor: button.color,
    sentencesIconColor: button.backgroundColor,
  },

  recall: {
    description: t('services/graylog:callToAction', { serviceName })
  },

  faq: {
    items: t('services/graylog:faq.items', { serviceName })
  }
});