import React from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as apiActions from 'redux/actions/api';

import MenuTop from 'components/MenuTop';
import ListCollapse from 'components/ListCollapse';
import Link from 'components/Link';

import styles from './_container.css';

class _container extends React.Component {
  static propTypes = {
    apiActions: PropTypes.object.isRequired,
    api: PropTypes.object.isRequired,
    app: PropTypes.object.isRequired,
    children: PropTypes.node,
    history: PropTypes.object.isRequired,
  };

  state = {
    menuLeftOpened: false
  };

  componentDidMount() {
    const userId = this.props.app.sessionAuth.username;
    this._route = '/dashboard/account';

    // If the user is not logged
    if (!userId) {
      this.props.history.push('/notLogged/Login');
      return;
    }

    this.props.apiActions.subscribe({
      route: this._route,
      routeArgs: {},
      handleErrorAutomatically: false
    });
  }

  componentWillUnmount() {
    this.props.apiActions.unsubscribe({ route: this._route });
  }

  render() {
    const subscription = this.props.api.subscriptions[this._route];
    const display = subscription && subscription.status !== 'initialize';

    if (!display) {
      return null;
    }

    const me = subscription.datas;
    return (
      <>
        <MenuTop
          type="dashboard"
          // zIndex 3 is to be above contentSticked in accounts/organizations/{organizationId} that has zIndex 1 and menuLeft that has zIndex 3 on mobile
          style={{ backgroundColor: '#4a75e2', zIndex: 3 }}
          user={me}
          onMenuLeftChange={menuLeftOpened => this.setState({ menuLeftOpened })}
        />

        <div className={[ styles.container, this.props.app.menuLeftContent.length || styles.menuLeftDisabled ].filter(Boolean).join(' ')}>
          {/* Menu left */}
          <aside
            className={
              [ styles.menuLeft, this.state.menuLeftOpened && styles.menuLeftOpened ]
                .filter(Boolean).join(' ')
            }
          >
            {/* Menu content */}
            <ListCollapse items={this.props.app.menuLeftContent} />

            <div className={styles.menuLeftSpacer} />

            {/* Footer with link to showcase */}
            <div className={styles.menuLeftFooter}>
              <Link url={`${process.env.HTTP_SCHEME}://${process.env.SHOWCASE_HOST}`}>{process.env.SHOWCASE_HOST}</Link>
            </div>
          </aside>

          {/* Content */}
          <div className={styles.content}>
            {this.props.children}
          </div>
        </div>
      </>
    );
  }
}

export default connect(
  ({ app, api }) => ({ app, api }),
  dispatch => ({
    apiActions: bindActionCreators(apiActions, dispatch)
  })
)(_container);