import logo from 'images/servicesLogos/kubernetes-node.svg';
import logoOpenGraph from 'images/servicesLogos/openGraphVersions/kubernetes-node.png';

import growth from 'images/landings/icons/growth.svg';
import startup from 'images/landings/icons/startup.svg';
import shield from 'images/landings/icons/shield.svg';
import strategy from 'images/landings/icons/strategy.svg';

import checked from 'images/landings/icons/checked.svg';

const backgroundColor = '#303030';
const button = {
  color: '#ffffff',
  backgroundColor: '#326ce5'
};

const serviceName = 'Kubernetes Node';
export default ({ t = () => [] }) => ({
  id: 'kubernetes-node',
  name: 'Kubernetes Node',
  nameUrl: 'Kubernetes-Node',
  title: t('services/kubernetes-node:title', { serviceName }),
  logo,
  logoOpenGraph,
  button,
  backgroundColor,

  header: {
    title: 'Kubernetes Node',
    h1: t('services/kubernetes-node:h1', { serviceName }),
    description: t('services/kubernetes-node:description', { serviceName }) + '\n\n' + t('services/kubernetes-node:callToAction', { serviceName }),
    images: []
  },

  benefits: {
    items: [ growth, startup, shield, strategy ].map((icon, index) => ({
      icon,
      ...t('services/kubernetes-node:benefits', { serviceName })[index]
    }))
  },

  also: {
    color: button.backgroundColor,
    items: t('services/kubernetes-node:also.items', { serviceName })
      .map(content => ({ icon: checked, ...content }))
  },

  pricing: {
    ...t('services/kubernetes-node:pricing'),
    buttonBackgroundColor: button.backgroundColor,
    buttonColor: button.color,
    sentencesIconColor: button.backgroundColor
  },

  recall: {
    description: t('services/kubernetes-node:callToAction', { serviceName })
  },

  faq: {
    items: t('services/kubernetes-node:faq.items', { serviceName })
  }
});
