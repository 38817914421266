import React from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as apiActions from 'redux/actions/api';

class _container extends React.Component {
  static propTypes = {
    apiActions: PropTypes.object.isRequired,
    api: PropTypes.object.isRequired,
    children: PropTypes.node
  };

  componentDidMount() {
    this._route = '/dashboard/organizations';
    this.props.apiActions.subscribe({
      route: this._route,
      routeArgs: {}
    });
  }

  componentWillUnmount() {
    this.props.apiActions.unsubscribe({ route: this._route });
  }

  render() {
    const subscription = this.props.api.subscriptions[this._route];
    const display = subscription && subscription.status !== 'initialize';

    return display ? this.props.children : null;
  }
}

export default connect(
  ({ api }) => ({ api }),
  dispatch => ({
    apiActions: bindActionCreators(apiActions, dispatch)
  })
)(_container);
