import React from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as apiActions from 'redux/actions/api';

import sha512 from 'hash.js/lib/hash/sha/512';

import { Form, Button, Modal, Alert, message } from 'antd';

import FormItems from 'components/FormItems';

class ModalChangePassword extends React.Component {
  static propTypes = {
    apiActions: PropTypes.object.isRequired,
    email: PropTypes.string.isRequired
  };

  state = this.generateState();

  UNSAFE_componentWillMount() {
    this._mounted = true;
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  generateState() {
    return {
      modalVisible: false,
      formIsValid: false,
      form: {},
      error: null,
      showErrors: false
    };
  }


  show = () => {
    this.setState({
      modalVisible: true,
      formIsValid: false,
      form: {},
      error: null
    });
  };


  handleOk = () => {
    this.setState({ showErrors: true });
    if (!this.state.formIsValid) {
      return false;
    }

    this.setState({ error: null });

    const { password } = this.state.form;

    const passwordPreHashed = sha512().update(password + 'stackhero' + this.props.email).digest('hex');

    this.props.apiActions.patch(
      {
        route: '/dashboard/account/password',
        routeArgs: { passwordPreHashed },
        handleErrorAutomatically: false
      },
      (error, result) => {
        if (!this._mounted) { return false; }

        if (error) {
          const errors = {
            authenticationFailed: 'Your old password is not the good one'
          };

          if (!errors[error]) {
            throw error;
          }

          this.setState({ error: errors[error] });
          return;
        }

        this.setState(this.generateState());
        message.success('Your password has been successfully updated');
      }
    );
  };


  handleCancel = () => {
    this.setState(this.generateState());
  };


  handleSubmit = (e) => {
    this.handleOk();
  };


  renderError() {
    if (!this.state.error) {
      return null;
    }

    return (
      <Form.Item>
        <Alert
          message="Error"
          description={this.state.error}
          type="error"
          showIcon
        />
      </Form.Item>
    );
  }


  renderFooter = () => {
    return [
      ( <Button key="cancel" onClick={this.handleCancel}>Cancel</Button> ),
      (
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          onClick={this.handleOk}
        >
          Change password
        </Button>
      )
    ];
  };


  entries = [
    {
      label: 'New password',
      name: 'password',
      required: true,
      input: {
        type: 'password',
        hasError: (name, value, form) => value.length < 8 || value.length > 72 ? 'Should be between 8 and 72 characters' : '',
        autoComplete: 'new-password'
      }
    },
    {
      label: 'Confirmation',
      name: 'passwordConfirmation',
      required: true,
      input: {
        type: 'password',
        hasError: (name, value, form) => !value || value !== form.password ? 'Should be identical as your new password' : '',
        autoComplete: 'new-password'
      }
    }
  ];


  render() {
    return [
      (
        <Modal
          key="modal"
          title="Change password"
          open={this.state.modalVisible}
          onCancel={this.handleCancel}
          footer={this.renderFooter()}
        >
          <Form onFinish={this.handleSubmit}>
            {this.renderError()}
            <FormItems
              sizes={{ xs: 24, sm: 6 }}
              entries={this.entries}
              onStatusChange={formIsValid => this.setState({ formIsValid })}
              datas={this.state.form}
              onUpdate={form => this.setState({ form })}
              showErrors={this.state.showErrors}
            />

            {/* To handle the submission on enter key */}
            <Button htmlType="submit" style={{ display: 'none' }} />
          </Form>
        </Modal>
      )
      ,
      ( <Button key="button" size="small" onClick={() => this.show() }>Change password</Button> )
    ];
  }
}

export default connect(
  () => ({}),
  dispatch => ({
    apiActions: bindActionCreators(apiActions, dispatch)
  })
)(ModalChangePassword);
