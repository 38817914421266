import React from 'react';
import PropTypes from 'prop-types';

import { Row, Col, Typography, Spin, Tooltip } from 'antd';
import Gauge from 'components/Gauge';
import { InfoCircleOutlined } from '@ant-design/icons';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as apiActions from 'redux/actions/api';

class ServiceMetricsOverview extends React.Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    apiActions: PropTypes.object.isRequired,
    route: PropTypes.string.isRequired
  };

  componentDidMount() {
    this.props.apiActions.subscribe({
      route: this.props.route,
      routeArgs: {},
      refreshInterval: 10 * 1000,
      spinner: false,
      handleErrorAutomatically: false
    });
  }

  componentWillUnmount() {
    this.props.apiActions.unsubscribe({
      route: this.props.route
    });
  }

  render() {
    const loaded = this.props.api.subscriptions[this.props.route]
      && this.props.api.subscriptions[this.props.route].status !== 'initialize'
      && this.props.api.subscriptions[this.props.route].datas
      && Object.keys(this.props.api.subscriptions[this.props.route].datas).length > 0;

    const {
      load,
      cpusCountFromStore, cpusUsageAveragePercent,
      ramTotalFromStore, ramTotal, ramUsed,
      diskTotalFromStore, diskTotal, diskUsed,

      pressureCpuWaitingPercent, pressureMemoryStalledPercent, pressureMemoryWaitingPercent, pressureIoStalledPercent, pressureIoWaitingPercent,

      maxConcurrentConnectionsFromStore,
      rabbitmqConnections, redisConnections, valkeyConnections, mosquittoConnections, memcachedConnections, matomoConnections, directusConnections, mercureHubConnections,

      bandwidthReceiveBitsPerSecond, bandwidthTransmitBitsPerSecond,
      maxBandwidthBitsPerSecondFromStore
    } = this.props.api.subscriptions[this.props.route]?.datas || {};

    const bandwidthBitsPerSecond = bandwidthReceiveBitsPerSecond + bandwidthTransmitBitsPerSecond;

    const loadPercentage = Math.round((load / cpusCountFromStore) * 100);
    const loadsMaximum = Math.max(loadPercentage, pressureCpuWaitingPercent, pressureMemoryStalledPercent, pressureMemoryWaitingPercent, pressureIoStalledPercent,pressureIoWaitingPercent);
    const gauges = [
      // Loads
      {
        legendWhenNotLoaded: 'Loads',
        legend: 'Loads',
        percent: loadsMaximum,
        tooltip: loadPercentage >= 0
          && pressureCpuWaitingPercent >= 0
          && pressureMemoryStalledPercent >= 0
          && pressureMemoryWaitingPercent >= 0
          && pressureIoStalledPercent >= 0
          && pressureIoWaitingPercent >= 0
          ? (
            <>
              Pressures of your system:
              <br />
              <br />

              - Load: {loadPercentage}%
              <br />
              - CPU pressure: {pressureCpuWaitingPercent}%
              <br />
              - Memory pressure: {Math.max(pressureMemoryStalledPercent, pressureMemoryWaitingPercent)}%
              <br />
              - Disk pressure: {Math.max(pressureIoStalledPercent, pressureIoWaitingPercent)}%
              <br />
              <br />

              Having an important pressure on a component means you should seriously considere upgrading your instance.
            </>
          )
          : null,
        limits: [ 60, 80 ]
      },
      // vCPUs
      {
        legendWhenNotLoaded: 'vCPUs',
        legend: cpusCountFromStore > 1 ? `${cpusCountFromStore} vCPUs` : '1 vCPU',
        percent: Math.max(Math.min(cpusUsageAveragePercent, 100), 0),
        tooltip: cpusCountFromStore === 0
          ? 'Consumption of the vCPU.'
          : 'Average consumption of all the vCPUs.'
      },
      // RAM
      {
        legendWhenNotLoaded: 'RAM',
        legend: ramTotalFromStore < 1024 * 1024 * 1024
        ? `${Math.round(ramTotalFromStore / (1024 * 1024))} MB RAM`
        : `${Math.round(ramTotalFromStore / (1024 * 1024 * 1024))} GB RAM`,
        percent: Math.max(Math.min(Math.round((ramUsed / ramTotal) * 100), 100), 0),
        limits: [ 50, 70 ],
        tooltip: 'Consumption of RAM, without the memory used for caches and buffers. Over 70% you should seriously consider to upgrade your service to avoid crashes.'
      },
      // Disk
      {
        legendWhenNotLoaded: 'Disk',
        legend: diskTotalFromStore < 1024 * 1024 * 1024
          ? `${Math.round(diskTotalFromStore / (1024 * 1024))} MB disk`
          : `${Math.round(diskTotalFromStore / (1024 * 1024 * 1024))} GB disk`,
        percent: Math.max(Math.min(Math.round((diskUsed / diskTotal) * 100), 100), 0),
        limits: [ 70, 80 ],
        tooltip: 'Disk occupancy. Over 70% you should seriously consider to upgrade your service to avoid any space saturation and data loss.'
      },
      // RabbitMQ connections
      rabbitmqConnections >= 0 && {
        legendWhenNotLoaded: 'Connections',
        legend: 'Connections',
        percent: Math.round((rabbitmqConnections / maxConcurrentConnectionsFromStore) * 100),
        tooltip: `Concurrent connections currently used: ${rabbitmqConnections} on ${maxConcurrentConnectionsFromStore} allowed by your plan.`
      },
      // Redis connections
      redisConnections >= 0 && {
        legendWhenNotLoaded: 'Connections',
        legend: 'Connections',
        percent: Math.round((redisConnections / maxConcurrentConnectionsFromStore) * 100),
        tooltip: `Concurrent connections currently used: ${redisConnections} on ${maxConcurrentConnectionsFromStore} allowed by your plan.`
      },
      // Valkey connections
      valkeyConnections >= 0 && {
        legendWhenNotLoaded: 'Connections',
        legend: 'Connections',
        percent: Math.round((valkeyConnections / maxConcurrentConnectionsFromStore) * 100),
        tooltip: `Concurrent connections currently used: ${valkeyConnections} on ${maxConcurrentConnectionsFromStore} allowed by your plan.`
      },
      // Mosquitto connections
      mosquittoConnections >= 0 && {
        legendWhenNotLoaded: 'Connections',
        legend: 'Connections',
        percent: Math.round((mosquittoConnections / maxConcurrentConnectionsFromStore) * 100),
        tooltip: `Concurrent connections currently used: ${mosquittoConnections} on ${maxConcurrentConnectionsFromStore} allowed by your plan.`
      },
      // Memcached connections
      memcachedConnections >= 0 && {
        legendWhenNotLoaded: 'Connections',
        legend: 'Connections',
        percent: Math.round((memcachedConnections / maxConcurrentConnectionsFromStore) * 100),
        tooltip: `Concurrent connections currently used: ${memcachedConnections} on ${maxConcurrentConnectionsFromStore} allowed by your plan.`
      },
      // Matomo connections
      matomoConnections >= 0 && {
        legendWhenNotLoaded: 'Connections',
        legend: 'Connections',
        percent: Math.round((matomoConnections / maxConcurrentConnectionsFromStore) * 100),
        tooltip: `Concurrent connections currently used: ${matomoConnections} on ${maxConcurrentConnectionsFromStore} allowed by your plan.`
      },
      // Directus connections
      directusConnections >= 0 && {
        legendWhenNotLoaded: 'Connections',
        legend: 'Connections',
        percent: Math.round((directusConnections / maxConcurrentConnectionsFromStore) * 100),
        tooltip: `Concurrent connections currently used: ${directusConnections} on ${maxConcurrentConnectionsFromStore} allowed by your plan.`
      },
      // Mercure-Hub connections
      mercureHubConnections >= 0 && {
        legendWhenNotLoaded: 'Connections',
        legend: 'Connections',
        percent: Math.round((mercureHubConnections / maxConcurrentConnectionsFromStore) * 100),
        tooltip: `Concurrent connections currently used: ${mercureHubConnections} on ${maxConcurrentConnectionsFromStore} allowed by your plan.`
      },

      // MinIO bandwidth
      bandwidthBitsPerSecond >= 0 && {
        legendWhenNotLoaded: 'Bandwidth',
        legend: 'Bandwidth',
        percent: Math.round((bandwidthBitsPerSecond / maxBandwidthBitsPerSecondFromStore) * 100),
        tooltip:
          [
            maxBandwidthBitsPerSecondFromStore >= 1024 * 1024 * 1024
              && `${Math.round(maxBandwidthBitsPerSecondFromStore / (1024 * 1024 * 1024))} Gbps`,
            maxBandwidthBitsPerSecondFromStore >= 1024 * 1024
              && `${Math.round(maxBandwidthBitsPerSecondFromStore / (1024 * 1024))} Mbps`
          ]
          .filter(v => v)
          .map(bandwidthLabel => `Your plan guarantees up to ${bandwidthLabel}`)
          .shift()
      }
    ].filter(v => v);

    return (
      <Spin spinning={!loaded}>
        <Row justify="center" style={{ textAlign: 'center' }}>
          {gauges.map((gauge, index) => (
            <Col key={index} xs={12} sm={12} md={12} lg={12} xl={4} style={{ marginBottom: 20 }}>
              <Gauge
                percent={gauge.percent}
                limits={gauge.limits}
              />
              <br />
              <Typography.Text strong>
                {loaded && !isNaN(gauge.percent) ? gauge.legend : gauge.legendWhenNotLoaded}
                &nbsp;
                {gauge.tooltip
                  ? (
                    <Tooltip title={gauge.tooltip}>
                      <InfoCircleOutlined style={{ filter: 'brightness(150%)', fontSize: 'smaller', cursor: 'help' }} />
                    </Tooltip>
                  )
                  : null
                }
              </Typography.Text>
            </Col>
          ))}
        </Row>
      </Spin>
    );
  }
}

export default connect(
  ({ api }) => ({ api }),
  dispatch => ({
    apiActions: bindActionCreators(apiActions, dispatch)
  })
)(ServiceMetricsOverview);
