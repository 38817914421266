import logo from 'images/servicesLogos/gitlab.svg';
import logoOpenGraph from 'images/servicesLogos/openGraphVersions/gitlab.png';

import growth from 'images/landings/icons/growth.svg';
import startup from 'images/landings/icons/startup.svg';
import shield from 'images/landings/icons/shield.svg';
import packageSvg from 'images/landings/icons/package.svg';
import medal from 'images/landings/icons/medal.svg';
import strategy from 'images/landings/icons/strategy.svg';

import checked from 'images/landings/icons/checked.svg';

import gitlabProjects from 'images/landings/screenshots/gitlab/gitlabProjects.png';
import gitlabActivity from 'images/landings/screenshots/gitlab/gitlabActivity.png';
import gitlabPipelines from 'images/landings/screenshots/gitlab/gitlabPipelines.png';
import gitlabPipeline from 'images/landings/screenshots/gitlab/gitlabPipeline.png';

const backgroundColor = '#403368';
const button = {
  color: '#fff',
  backgroundColor: '#fc6d26'
};

const serviceName = 'GitLab';
export default ({ t = () => [] }) => ({
  id: 'gitlab',
  name: 'GitLab',
  nameUrl: 'GitLab',
  title: t('services/gitlab:title', { serviceName }),
  logo,
  logoOpenGraph,
  button,
  backgroundColor,

  header: {
    title: 'GitLab',
    description: t('services/gitlab:description', { serviceName }) + '\n\n' + t('services/gitlab:callToAction', { serviceName }),
    images: [
      { image: gitlabProjects },
      { image: gitlabActivity },
      { image: gitlabPipelines },
      { image: gitlabPipeline }
    ]
  },

  benefits: {
    items: [
      {
        icon: growth,
        ...t('services/gitlab:benefits.business', { serviceName })
      },
      {
        icon: startup,
        ...t('services/gitlab:benefits.performant', { serviceName })
      },
      {
        icon: shield,
        ...t('services/gitlab:benefits.secured', { serviceName })
      },
      {
        icon: strategy,
        ...t('services/gitlab:benefits.updatable', { serviceName })
      },
      {
        icon: medal,
        ...t('services/gitlab:benefits.unlimited', { serviceName })
      },
      {
        icon: packageSvg,
        ...t('services/gitlab:benefits.registries', { serviceName })
      },
    ]
  },

  also: {
    color: button.backgroundColor,
    items: t('services/gitlab:also.items', { serviceName })
      .map(content => ({ icon: checked, ...content }))
  },

  pricing: {
    buttonBackgroundColor: button.backgroundColor,
    buttonColor: button.color,
    sentencesIconColor: button.backgroundColor,
    includes: t('services/gitlab:pricing.includes')
      .map(description => ({ icon: checked, description })),
    fields: {
      vCpus: vCpus => [ vCpus, vCpus === 1 ? t('services/gitlab:pricing.subLegends.vCpu') : t('services/gitlab:pricing.subLegends.vCpus') ],
      ram: ram => [ ram < 1024 * 1024 * 1024 ? `${Math.round(ram / (1024 * 1024))} ${t('units.MB')}` : `${Math.round(ram / (1024 * 1024 * 1024))} ${t('units.GB')}`, t('services/gitlab:pricing.subLegends.ram') ],
      hdd: hdd => [ hdd < 1024 * 1024 * 1024 ? `${Math.round(hdd / (1024 * 1024))} ${t('units.MB')}` : `${Math.round(hdd / (1024 * 1024 * 1024))} ${t('units.GB')}`, t('services/gitlab:pricing.subLegends.storage') ],
      usersAndRepositories: v => [
        t('services/gitlab:pricing.subLegends.unlimited'),
        t('services/gitlab:pricing.subLegends.usersAndRepositories')
      ],
      ciCdTime: v => [
        t('services/gitlab:pricing.subLegends.unlimited'),
        t('services/gitlab:pricing.subLegends.ciCdTime')
      ],
      transfers: v => [
        t('services/gitlab:pricing.subLegends.unlimited'),
        t('services/gitlab:pricing.subLegends.transfers')
      ]
    }
  },

  recall: {
    description: t('services/gitlab:callToAction', { serviceName })
  },

  faq: {
    items: t('services/gitlab:faq.items', { serviceName })
  }
});
