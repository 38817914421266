import React from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as apiActions from 'redux/actions/api';

import { Spin, Alert, Checkbox, Button, Empty } from 'antd';

import styles from './ServiceLogs.css';

class ServiceLogs extends React.Component {
  static propTypes = {
    apiActions: PropTypes.object.isRequired,
    serviceId: PropTypes.string.isRequired
  };

  state = {
    loading: true,
    logs: [],
    lastId: undefined,
    autoScroll: true
  };

  componentDidMount() {
    this._mounted = true;

    this.logsLoad();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  componentWillUnmount() {
    this._mounted = false;
    clearTimeout(this._timeout);
  }

  scrollToBottom() {
    // Note: we check that scrollTo is a function because it seems to not be one on at least Chrome 55 (probably old versions)
    if (!this._container
        || !this.state.autoScroll
        || typeof(this._container.scrollTo) !== 'function') {
      return false;
    }

    const scrollTopMax = this._container.scrollHeight - this._container.clientHeight;
    if (scrollTopMax === this._container.scrollTop) {
      return false;
    }

    this._container.scrollTo({
      top: scrollTopMax,
      behavior: 'smooth'
    });
  }

  logsLoad() {
    if (!this._mounted) {
      return false;
    }

    const { serviceId } = this.props;

    this.props.apiActions.get(
      {
        route: `/dashboard/services/${serviceId}/logs`,
        routeArgs: { afterId: this.state.lastId },
        spinner: false
      },
      (error, logs) => {
        if (error || !this._mounted) {
          return;
        }

        if (logs.length) {
          this.setState(state => ({
            logs: [ ...state.logs, ...logs ].slice(-500), // Limit to 500 lines to avoid RAM consumption
            lastId: logs[logs.length - 1].id
          }));
        }

        this.scrollToBottom();

        if (this.state.loading === true) {
          this.setState({ loading: false });
        }

        this._timeout = setTimeout(this.logsLoad.bind(this), 1000);
      }
    );
  }

  render() {
    return (
      <>
        {
          this.state.logs.length !== 0 && !this.state.loading ?
            (
              <Alert
                showIcon
                description={(
                  <>
                    These logs are retrieved in real time from this instance. You can see up to 100 lines.
                    <br />
                    If you want to analyze these logs further, you can forward them to Graylog by using the "Logs forwarder".
                  </>
                )}
                style={{ marginBottom: 16 }}
              />
            )
            : null
        }

        <Spin spinning={this.state.loading}>
          {
            this.state.logs.length === 0 && !this.state.loading ?
              ( <Empty description="Your instance hasn't send logs yet, probably because it is starting." /> )
            :
              [
                (
                  <div
                    key="logs"
                    ref={ref => this._container = ref}
                    className={styles.container}
                    onWheel={() => {
                      const scrollTopMax = this._container.scrollHeight - this._container.clientHeight;
                      if (this._container.scrollTop < scrollTopMax && this.state.autoScroll) {
                        this.setState({ autoScroll: false });
                      }
                      else if (this._container.scrollTop >= scrollTopMax && !this.state.autoScroll) {
                        // If arrived at bottom, activate autoScroll automatically
                        this.setState({ autoScroll: true });
                      }
                    }}
                  >
                    <samp>
                      {this.state.logs.map(({ id, date, containerName, line }) => {
                        const dateOption = {
                          year: 'numeric', month: 'numeric', day: 'numeric',
                          hour: 'numeric', minute: 'numeric', second: 'numeric'
                        };
                        const dateFormated = new Intl.DateTimeFormat(undefined, dateOption).format(new Date(date));

                        return (
                          <p key={id}>
                            <span>{dateFormated}, {containerName} </span>
                            {/* See https://en.wikipedia.org/wiki/ANSI_escape_code */}
                            {line
                              .replace(/#033[[0-9;]*[a-zA-Z]{1}/g, '') // Remove all escape sequences (colors etc...)

                              .replace(/#0337/g, '') // #07 is bell
                              .replace(/#0338/g, '\n') // #08 is backspace. The goal is to show correctly lines like "7 26% ###########8"

                              .replace(/#011/g, '\t') // #011 is \t
                              .replace(/#015/g, '') // #015 is \r
                            }
                          </p>
                        );
                      })}
                    </samp>
                  </div>
                ),

                ( <br key="br" /> ),

                (
                  <div key="control" style={{ textAlign: 'right' }}>
                    <Checkbox
                      checked={this.state.autoScroll}
                      onChange={() => this.setState(({ autoScroll }) => ({ autoScroll: !autoScroll }))}
                      style={{ marginRight: 10 }}
                    >
                      Automatic scroll
                    </Checkbox>

                    <Button size="small" onClick={() => this.setState({ logs: [] })}>Clear</Button>
                  </div>
                )
              ]
          }
        </Spin>
      </>
    );
  }
}

export default connect(
  () => ({}),
  dispatch => ({
    apiActions: bindActionCreators(apiActions, dispatch)
  })
)(ServiceLogs);
