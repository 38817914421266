import React from 'react';
import PropTypes from 'prop-types';

import styles from './Button.css';

class Button extends React.Component {
  static propTypes = {
    backgroundColor: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    href: PropTypes.string,
    onClick: PropTypes.func,
    size: PropTypes.oneOf([ 'big', 'normal', 'small' ]),
    label: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node
    ]).isRequired,
    newPage: PropTypes.bool
  };

  renderContent = () => {
    return (
      <button
        onClick={this.props.onClick}
        className={[
          styles.button,
          this.props.size === 'big' && styles.buttonSizeBig,
          this.props.size === 'small' && styles.buttonSizeSmall
        ].filter(Boolean).join(' ')}
        style={{ backgroundColor: this.props.backgroundColor, color: this.props.color }}
      >
        {this.props.label}
      </button>
    );
  };

  render = () => {
    if (!this.props.href) {
      return this.renderContent();
    }

    return (
      <a
        href={this.props.href}
        {
          ...this.props.newPage !== false
            ? { target: '_BLANK', rel: 'noreferrer' }
            : {}
        }
      >
        {this.renderContent()}
      </a>
    );
  };
}


export default Button;
