import logo from 'images/servicesLogos/memcached.svg';
import logoOpenGraph from 'images/servicesLogos/openGraphVersions/memcached.png';

import growth from 'images/landings/icons/growth.svg';
import startup from 'images/landings/icons/startup.svg';
import shield from 'images/landings/icons/shield.svg';
import strategy from 'images/landings/icons/strategy.svg';

import checked from 'images/landings/icons/checked.svg';

import imageMemcached from 'images/landings/screenshots/memcached/memcached.png';

const backgroundColor = '#514845';
const button = {
  color: '#fff',
  backgroundColor: '#B5463F'
};

export default ({ t = () => [] }) => ({
  id: 'memcached',
  name: 'Memcached',
  nameUrl: 'Memcached',
  title: t('services/memcached:title'),
  logo,
  logoOpenGraph,
  button,
  backgroundColor,

  header: {
    title: 'Memcached',
    description: t('services/memcached:description'),
    images: [
      { image: imageMemcached }
    ]
  },

  benefits: {
    items: [
      {
        icon: growth,
        ...t('services/memcached:benefits.business')
      },
      {
        icon: startup,
        ...t('services/memcached:benefits.performant')
      },
      {
        icon: shield,
        ...t('services/memcached:benefits.secured')
      },
      {
        icon: strategy,
        ...t('services/memcached:benefits.updatable')
      }
    ]
  },

  also: {
    color: button.backgroundColor,
    items: t('services/memcached:also.items')
      .map(content => ({ icon: checked, ...content }))
  },

  pricing: {
    buttonBackgroundColor: button.backgroundColor,
    buttonColor: button.color,
    sentencesIconColor: button.backgroundColor,
    fields: {
      size: v => [
        v < 1024 * 1024 * 1024
          ? `${v / 1024 / 1024} ${t('units.MB')}`
          : `${Math.round(v / 1024 / 1024 / 1024)} ${t('units.GB')}`,
        t('services/memcached:pricing.subLegends.size')
      ],
      maxConcurrentConnections: v => [
        new Intl.NumberFormat(undefined).format(v),
        t('services/memcached:pricing.subLegends.maxConcurrentConnections')
      ]
    }
  },

  recall: {
    description: t('services/memcached:recall')
  },

  faq: {
    items: t('services/memcached:faq.items')
  }
});
