import React from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Card, Alert } from 'antd';
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import sha512 from 'hash.js/lib/hash/sha/512';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import * as apiActions from 'redux/actions/api';
import * as appActions from 'redux/actions/app';
import queryString from 'query-string';

import { withTranslation, Trans } from 'react-i18next';

import Utils from 'modules/Utils';
const utils = new Utils();

import FormItems from 'components/FormItems';

class Login extends React.Component {

  static propTypes = {
    app: PropTypes.object.isRequired,
    apiActions: PropTypes.object.isRequired,
    appActions: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired
  };

  state = {
    error: false,
    form: {
      email: this.props.history.location && this.props.history.location.state && this.props.history.location.state.email
        ? this.props.history.location.state.email
        : ''
    },
    formIsValid: false,
    showErrors: false,
    totpVisible: false
  };

  componentDidMount() {
    this._mounted = true;

    if (queryString.parse(this.props.location.search).email) {
      this.setState({
        form: {
          email: queryString.parse(this.props.location.search).email
        }
      });
    }

    if (this.props.history.location && this.props.history.location.state && this.props.history.location.state.email && this.props.history.location.state.password) {
      this.setState(
        state => ({
          ...state,
          form: {
            email: this.props.history.location.state.email,
            password: this.props.history.location.state.password
          },
          formIsValid: true
        }),
        () => this.handleSubmit()
      );
    }
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  handleSubmit = () => {
    this.setState({ showErrors: true });
    if (!this.state.formIsValid) {
      return false;
    }

    this.setState({ error: false });

    const { email: emailOrg, password, totpToken } = this.state.form;
    const email = emailOrg.toLowerCase();
    const passwordPreHashed = sha512().update(password + 'stackhero' + email).digest('hex');
    const { t } = this.props;
    const { language, region } = this.props.app;
    const locale = region ? `${language}-${region.toUpperCase()}` : language;

    this.props.apiActions.post(
      {
        route: '/tokens',
        routeArgs: { email, passwordPreHashed, totpToken: totpToken ? totpToken.replace('-', '') : totpToken, locale, language },
        handleErrorAutomatically: false
      },
      (error, response) => {
        if (!this._mounted) { return false; }

        if (error === 'totpAuthenticationRequired') {
          this.setState({ totpVisible: true, showErrors: false });
          return;
        }
        else if (error === 'totpAuthenticationFailed') {
          this.setState({ error: t('login.totpAuthenticationFailed') });
          return;
        }
        else if (error) {
          this.props.appActions.sessionSetDispatch(false);
          if (error.response && error.response.status === 401) {
            this.setState({ error: t('login.authenticationFailed'), totpVisible: false });
            return;
          }

          throw error;
        }

        this.props.appActions.sessionSetDispatch({
          username: response.userId,
          password: response.tokenId,
          intercomAuthHash: response.intercomAuthHash,
          cookieMaxAge: response.cookieMaxAge
        });
        this.props.history.replace('/account/organizations/');
      }
    );
  };


  renderAccountExistsYet() {
    const { email } = queryString.parse(this.props.location.search);
    if (this.state.error || !email || this.state.form.email !== email) {
      return null;
    }

    const { t } = this.props;
    return (
      <Form.Item>
        <Alert
          description={t('login.accountExistsYey')}
          type="info"
          showIcon
        />
      </Form.Item>
    );
  }


  renderError() {
    if (!this.state.error) {
      return null;
    }

    const { t } = this.props;
    return (
      <Form.Item>
        <Alert
          message={t('login.errorTitle')}
          description={this.state.error}
          type="error"
          showIcon
        />
      </Form.Item>
    );
  }


  entries = () => {
    const { t } = this.props;
    return [
      {
        name: 'email',
        required: true,
        input: {
          type: 'text',
          onChange: value => value.replace(/ /g, ''),
          prefix: ( <MailOutlined style={{ fontSize: 13 }} /> ),
          placeholder: t('login.email'),
          hasError: (name, value, form) => !utils.emailCheck(value) ? true : '',
          autoComplete: 'email'
        }
      },
      {
        name: 'password',
        required: true,
        input: {
          type: 'password',
          prefix: ( <LockOutlined style={{ fontSize: 13 }} /> ),
          placeholder: t('login.password'),
          hasError: (name, value, form) => value.length < 8 || value.length > 72 ? true : '',
          autoComplete: 'current-password'
        }
      },
      this.state.totpVisible
        ? {
            label: t('login.totp'),
            name: 'totpToken',
            required: true,
            input: {
              type: 'text',
              onChange: value => value.replace(/^([0-9]{3})-?([0-9]+)$/, '$1-$2').replace(/^(.*)-$/, '$1'),
              hasError: (name, value, form) => /^[0-9]{3}-[0-9]{3}$/.test(value) ? '' : true,
              autoComplete: 'one-time-code'
            }
          }
        : null
    ].filter(v => v);
  };


  render() {
    const { t } = this.props;
    return (
      <>
        <Card title={t('login.action')} style={{ marginBottom: '30px' }}>
          <Form onFinish={this.handleSubmit} layout="vertical">
            {this.renderError()}
            {this.renderAccountExistsYet()}

            <FormItems
              sizes={{ xxl: 24, xl: 24, lg: 24, md: 24, sm: 24, xs: 24 }}
              entries={this.entries()}
              onStatusChange={formIsValid => this.setState({ formIsValid })}
              datas={this.state.form}
              onUpdate={form => this.setState({ form })}
              showErrors={this.state.showErrors}
            />

            <Form.Item style={{ marginBottom: 0 }}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: '100%' }}
              >
                {t('login.action')}
              </Button>

              <div style={{ textAlign: 'right', marginTop: 20 }}>
                <Link to={'/notLogged/passwordReset'}>{t('login.passwordForgotten')}</Link>
              </div>
            </Form.Item>
          </Form>
        </Card>

        <div style={{ textAlign: 'center' }}>
          <Trans i18nKey="login.noAccount">
            Don't have an account yet? <Link to={'/notLogged/signup'}>Sign Up</Link>
          </Trans>
        </div>
      </>
    );
  }
}

export default compose(
  connect(
    ({ app }) => ({ app }),
    dispatch => ({
      apiActions: bindActionCreators(apiActions, dispatch),
      appActions: bindActionCreators(appActions, dispatch)
    })
  ),
  withTranslation()
)(Login);
