import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import LinkLayout from 'components/Link';

import { MenuOutlined } from '@ant-design/icons';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';

import MenuAvatar from 'components/MenuAvatar';
import MenuServices from 'components/MenuServices';
import services from 'contents/services';
import ModalSignUp from 'components/landings/ModalSignUp';

import logoWhite from 'images/logoWhite.svg';

import styles from './MenuTop.css';

MenuTop.propTypes = {
  app: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  style: PropTypes.object.isRequired,
  centered: PropTypes.bool,
  type: PropTypes.oneOf([ 'mainLanding', 'servicesLandings', 'others', 'dashboard', 'backoffice' ]).isRequired,
  onMenuLeftChange: PropTypes.func,
  user: PropTypes.object,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  data: PropTypes.object
};



function MenuTop(props) {
  const { t } = props;
  const { languageAndRegion } = props.app;

  const [ modalSignUpVisible, setModalSignUpVisible ] = useState(false);
  const [ menuLeftOpened, setMenuLeftOpened ] = useState(false);
  const [ menuRightOpened, setMenuRightOpened ] = useState(false);


  function renderButtonsWhenLogged() {
    return (
      <div className={[ styles.menuSublinksContainer, styles.menuSublinksContainerCallToAction ].join(' ')}>
        <LinkLayout url={`${process.env.HTTP_SCHEME}://${process.env.DASHBOARD_HOST}/`} className={styles.menuCallToAction}>
          {t('mainPage.menu.dashboard')}
        </LinkLayout>
      </div>
    );
  }


  function renderButtonsWhenNotLogged() {
    return [
      <div key="logIn" className={[ styles.menuSublinksContainer, styles.menuSublinksContainerSelectable].join(' ')}>
        <LinkLayout url={`${process.env.HTTP_SCHEME}://${process.env.DASHBOARD_HOST}/notLogged/login`}>
          {t('mainPage.menu.login')}
        </LinkLayout>
      </div>,

      <ModalSignUp
        key="modalSignUp"
        visible={modalSignUpVisible}
        onClose={() => setModalSignUpVisible(false)}
      />,

      <div key="signUp" className={[ styles.menuSublinksContainer, styles.menuSublinksContainerCallToAction ].join(' ')}>
        <button
          onClick={event => {
            event.nativeEvent.stopImmediatePropagation();
            setModalSignUpVisible(true);
          }}
          className={styles.menuCallToAction}
        >
          {t('mainPage.menu.signup')}
        </button>
      </div>
    ];
  }


  function renderMainLanding() {
    return (
      <div className={[ styles.menuContainer, props.centered && styles.centered, props.onMenuLeftChange && styles.menuContainerDocumentation ].filter(Boolean).join(' ')}>
        {/* Sandwich button */}
        {
          props.onMenuLeftChange &&
            <button
              onClick={event => {
                event.nativeEvent.stopImmediatePropagation();
                setMenuLeftOpened(!menuLeftOpened);
                setMenuRightOpened(false);
                props.onMenuLeftChange(!menuLeftOpened);
              }}
              className={styles.sandwichButton}
            >
              <MenuOutlined />
            </button>
        }

        {/* Logo */}
        <HashLink className={styles.logo} to={`/${props.app.languageAndRegion}/#`} smooth>
          <img src={logoWhite} alt="Stackhero" width="22" height="22" />
          <span>Stackhero</span>
        </HashLink>

        {/* Empty space */}
        <div className={styles.spacer} />

        {/* Sandwich button */}
        <button
          onClick={event => {
            event.nativeEvent.stopImmediatePropagation();
            setMenuRightOpened(!menuRightOpened);
            setMenuLeftOpened(false);
            props.onMenuLeftChange && props.onMenuLeftChange(false);
          }}
          className={styles.sandwichButton}
        >
          <MenuOutlined />
        </button>

        {/* Menu */}
        <div
          className={[ styles.menuSublinks, menuRightOpened && styles.menuSublinksVisible ].filter(Boolean).join(' ')}
          style={props.style}
        >
          {/* Benefit */}
          <div className={[ styles.menuSublinksContainer, styles.menuSublinksContainerSelectable ].filter(Boolean).join(' ')}>
            <HashLink to={`/${props.app.languageAndRegion}/#benefits`} smooth>
              {t('mainPage.menu.benefits')}
            </HashLink>
          </div>

          {/* Our services */}
          <div className={[ styles.menuSublinksContainer, styles.menuSublinksContainerSelectable ].filter(Boolean).join(' ')}>
            <HashLink to={`/${props.app.languageAndRegion}/#services`} smooth>
              {t('mainPage.menu.ourServices')}
            </HashLink>
          </div>

          {/* FAQ */}
          <div className={[ styles.menuSublinksContainer, styles.menuSublinksContainerSelectable ].filter(Boolean).join(' ')}>
            <HashLink to={`/${props.app.languageAndRegion}/#faq`} smooth>
              {t('mainPage.menu.faq')}
            </HashLink>
          </div>

          {/* Services */}
          <div className={[ styles.menuSublinksContainer, styles.menuSublinksContainerServices ].join(' ')}>
            <MenuServices services={services} />
          </div>

          {/* Documentations */}
          <div className={[ styles.menuSublinksContainer, styles.menuSublinksContainerSelectable ].filter(Boolean).join(' ')}>
            <Link className={styles.link} to={`/${props.app.languageAndRegion}/stackhero/documentations`}>
              {t('mainPage.menu.documentations')}
            </Link>
          </div>

          {/* Button to dashboard or signup */}
          {props.app.isLogged ? renderButtonsWhenLogged() : renderButtonsWhenNotLogged()}

          {/* Services */}
          {renderServices()}
        </div>
      </div>
    );
  }


  function renderOthers() {
    return (
      <div className={[ styles.menuContainer, props.centered && styles.centered ].filter(Boolean).join(' ')}>
        {/* Logo */}
        <LinkLayout url={`${process.env.HTTP_SCHEME}://${process.env.SHOWCASE_HOST}/${props.app.languageAndRegion}/`} className={styles.logo}>
          <img src={logoWhite} alt="Stackhero" width="22" height="22" />
          <span>Stackhero</span>
        </LinkLayout>

        {/* Empty space */}
        <div className={styles.spacer} />

        {/* Sandwich button */}
        <button
          onClick={event => { event.nativeEvent.stopImmediatePropagation(); setMenuRightOpened(!menuRightOpened); }}
          className={styles.sandwichButton}
        >
          <MenuOutlined />
        </button>

        {/* Menu */}
        <div
          className={[ styles.menuSublinks, menuRightOpened && styles.menuSublinksVisible ].filter(Boolean).join(' ')}
          style={props.style}
        >
          {/* Benefit */}
          <div className={[ styles.menuSublinksContainer, styles.menuSublinksContainerSelectable ].join(' ')}>
            <LinkLayout url={`${process.env.HTTP_SCHEME}://${process.env.SHOWCASE_HOST}/${props.app.languageAndRegion}/#benefits`}>
              {t('mainPage.menu.benefits')}
            </LinkLayout>
          </div>

          {/* Our services */}
          <div className={[ styles.menuSublinksContainer, styles.menuSublinksContainerSelectable ].join(' ')}>
          <LinkLayout url={`${process.env.HTTP_SCHEME}://${process.env.SHOWCASE_HOST}/${props.app.languageAndRegion}/#services`}>
              {t('mainPage.menu.ourServices')}
            </LinkLayout>
          </div>

          {/* FAQ */}
          <div className={[ styles.menuSublinksContainer, styles.menuSublinksContainerSelectable ].join(' ')}>
            <LinkLayout url={`${process.env.HTTP_SCHEME}://${process.env.SHOWCASE_HOST}/${props.app.languageAndRegion}/#faq`}>
              {t('mainPage.menu.faq')}
            </LinkLayout>
          </div>

          {/* Services */}
          <div className={[ styles.menuSublinksContainer, styles.menuSublinksContainerServices ].join(' ')}>
            <MenuServices services={services} />
          </div>

          {/* Documentations */}
          <div className={[ styles.menuSublinksContainer, styles.menuSublinksContainerSelectable ].join(' ')}>
            <LinkLayout url={`${process.env.HTTP_SCHEME}://${process.env.SHOWCASE_HOST}/${props.app.languageAndRegion}/stackhero/documentations`} className={styles.link}>
              {t('mainPage.menu.documentations')}
            </LinkLayout>
          </div>

          {/* Button to dashboard or signup */}
          {props.app.isLogged ? renderButtonsWhenLogged() : renderButtonsWhenNotLogged()}

          {/* Services */}
          {renderServices()}
        </div>
      </div>
    );
  }


  function renderDashboard() {
    return (
      <div className={[ styles.menuContainer, props.centered && styles.centered ].filter(Boolean).join(' ')}>
        {/* Sandwich button */}
        <button
          onClick={event => {
            event.nativeEvent.stopImmediatePropagation();
            setMenuLeftOpened(!menuLeftOpened);
            setMenuRightOpened(false);
            props.onMenuLeftChange(!menuLeftOpened);
          }}
          className={styles.sandwichButton}
        >
          <MenuOutlined />
        </button>


        {/* Logo */}
        <Link to="/" className={styles.logo}>
          <img src={logoWhite} alt="Stackhero" width="22" height="22" />
          <span>Stackhero</span>
        </Link>

        {/* Empty space */}
        <div className={styles.spacer} />

        {/* Documentations */}
        <div className={styles.menuSublinksContainer}>
          <LinkLayout url={`${process.env.HTTP_SCHEME}://${process.env.SHOWCASE_HOST}/${props.app.languageAndRegion}/stackhero/documentations`} className={styles.link}>
            {t('mainPage.menu.documentations')}
          </LinkLayout>
        </div>

        <MenuAvatar user={props.user} />
      </div>
    );
  }


  function renderBackoffice() {
    return (
      <div className={[ styles.menuContainer, props.centered && styles.centered ].filter(Boolean).join(' ')}>
        {/* Sandwich button */}
        <button
          onClick={event => {
            event.nativeEvent.stopImmediatePropagation();
            setMenuLeftOpened(!menuLeftOpened);
            setMenuRightOpened(false);
            props.onMenuLeftChange(!menuLeftOpened);
          }}
          className={styles.sandwichButton}
        >
          <MenuOutlined />
        </button>


        {/* Logo */}
        <Link to="/" className={styles.logo}>
          <img src={logoWhite} alt="Stackhero" width="22" height="22" />
          <span>Stackhero backoffice</span>
        </Link>

        {/* Empty space */}
        <div className={styles.spacer} />

        <MenuAvatar user={props.user} />
      </div>
    );
  }


  function renderServicesLandings() {
    const { pageSelected } = props.data;
    return (
      <div className={[ styles.menuContainer, props.centered && styles.centered, props.onMenuLeftChange && styles.menuContainerDocumentation ].filter(Boolean).join(' ')}>
        {/* Sandwich button */}
        {
          props.onMenuLeftChange &&
            <button
              onClick={event => {
                event.nativeEvent.stopImmediatePropagation();
                setMenuLeftOpened(!menuLeftOpened);
                setMenuRightOpened(false);
                props.onMenuLeftChange(!menuLeftOpened);
              }}
              className={styles.sandwichButton}
            >
              <MenuOutlined />
            </button>
        }


        {/* Logo */}
        <Link
          className={styles.logo}
          to={`/${props.app.languageAndRegion}/`}
        >
          <img src={logoWhite} alt="Stackhero" width="22" height="22" />
          <span>
            <span>Stackhero</span><span className={styles.logoLegend}> {t('mainPage.menu.logoForService', { serviceName: props.data.service.name })}</span>
          </span>
        </Link>


        {/* Empty space */}
        <div className={styles.spacer} />

        {/* Sandwich button */}
        <button
          onClick={event => {
            event.nativeEvent.stopImmediatePropagation();
            setMenuRightOpened(!menuRightOpened);
            setMenuLeftOpened(false);
            props.onMenuLeftChange && props.onMenuLeftChange(false);
          }}
          className={styles.sandwichButton}
        >
          <MenuOutlined />
        </button>

        {/* Menu */}
        <div
          className={[ styles.menuSublinks, menuRightOpened && styles.menuSublinksVisible ].filter(Boolean).join(' ')}
          style={props.style}
        >
          {/* Benefit */}
          <div className={[ styles.menuSublinksContainer, styles.menuSublinksContainerSelectable, pageSelected === 'benefits' && styles.menuSublinksContainerSelected ].filter(Boolean).join(' ')}>
            <Link to={`/${languageAndRegion}/services/${props.match.params.service}/benefits`}>
              {t('mainPage.menu.benefits')}
            </Link>
          </div>

          {/* Pricing */}
          <div className={[ styles.menuSublinksContainer, styles.menuSublinksContainerSelectable, pageSelected === 'pricing' && styles.menuSublinksContainerSelected ].filter(Boolean).join(' ')}>
            <Link to={`/${languageAndRegion}/services/${props.match.params.service}/pricing`}>
              {t('mainPage.menu.pricing')}
            </Link>
          </div>

          {/* FAQ */}
          <div className={[ styles.menuSublinksContainer, styles.menuSublinksContainerSelectable, pageSelected === 'faq' && styles.menuSublinksContainerSelected ].filter(Boolean).join(' ')}>
            <Link to={`/${languageAndRegion}/services/${props.match.params.service}/faq`}>
              {t('mainPage.menu.faq')}
            </Link>
          </div>

          {/* Documentations */}
          <div className={[ styles.menuSublinksContainer, styles.menuSublinksContainerSelectable, pageSelected === 'documentations' && styles.menuSublinksContainerSelected ].filter(Boolean).join(' ')}>
            <Link to={`/${languageAndRegion}/services/${props.match.params.service}/documentations`}>
              {t('mainPage.menu.documentations')}
            </Link>
          </div>

          {/* Services */}
          <div className={[ styles.menuSublinksContainer, styles.menuSublinksContainerServices ].join(' ')}>
            <MenuServices services={services}>
              {t('mainPage.menu.otherServices')}
            </MenuServices>
          </div>

          {/* Button to dashboard or signup */}
          {props.app.isLogged ? renderButtonsWhenLogged() : renderButtonsWhenNotLogged()}

          {/* Services */}
          {renderServices()}
        </div>
      </div>
    );
  }


  function renderServices() {
    return services
      .filter(serviceFunc => serviceFunc({}).hidden !== true)
      .map((serviceFunc, index) => {
        const { name, nameUrl, logo } = serviceFunc({});
        return (
          <div
            key={name}
            className={[
              styles.menuSublinksContainer,
              styles.menuSublinksContainerService,
              index === 0 && styles.menuSublinksContainerServiceFirst
            ].filter(Boolean).join(' ')}
          >
            <LinkLayout
              className={styles.link}
              url={`${process.env.HTTP_SCHEME}://${process.env.SHOWCASE_HOST}/${languageAndRegion}/services/${nameUrl}/benefits`}
            >
              <div className={styles.serviceLogo} style={{ backgroundImage: `url(${logo})` }} />
              <span>{name}</span>
            </LinkLayout>
          </div>
        );
      });
  }


  // On location change, we close the menus
  useEffect(
    () => {
      setMenuLeftOpened(false);
      props.onMenuLeftChange && props.onMenuLeftChange(false);
      setMenuRightOpened(false);
    },
    [ props.location ]
  );


  // When a side menu is opened, we have to block the body scroll else we scroll the body as we scroll the menu.
  useEffect(
    () => {
      // document.documentElement.style.overflowY = menuLeftOpened || menuRightOpened ? 'hidden' : 'auto';
      document.body.style.overflowY = menuLeftOpened || menuRightOpened ? 'hidden' : 'auto';
    },
    [ menuLeftOpened, menuRightOpened ]
  );


  return (
    <nav className={styles.menu} style={props.style}>
      {props.type === 'mainLanding' && renderMainLanding()}
      {props.type === 'servicesLandings' && renderServicesLandings()}
      {props.type === 'others' && renderOthers()}
      {props.type === 'dashboard' && renderDashboard()}
      {props.type === 'backoffice' && renderBackoffice()}
    </nav>
  );
}

export default compose(
  connect(
    ({ app }) => ({ app })
  ),
  withTranslation(),
  withRouter
)(MenuTop);
