import React from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as apiActions from 'redux/actions/api';
import * as navigationActions from 'redux/actions/navigation';

class _container extends React.Component {
  static propTypes = {
    apiActions: PropTypes.object.isRequired,
    api: PropTypes.object.isRequired,
    children: PropTypes.node,
    match: PropTypes.object.isRequired,
    navigationActions: PropTypes.object.isRequired
  };

  componentDidMount() {
    const { organizationId } = this.props.match.params;
    this._route = `/dashboard/organizations/${organizationId}/billing/coupons`;
    this.props.apiActions.subscribe({
      route: this._route,
      routeArgs: {}
    });

    const { url, path } = this.props.match;
    const urlCorrected = !/\/$/.test(url) && /\/$/.test(path) ? `${url}/` : url;
    this._url = urlCorrected;
    this.props.navigationActions.breadcrumbAdd('Coupons', urlCorrected, this._url);
  }

  componentWillUnmount() {
    this.props.navigationActions.breadcrumbRemove(this._url);
    this.props.apiActions.unsubscribe({ route: this._route });
  }

  render() {
    const subscription = this.props.api.subscriptions[this._route];
    const display = subscription && subscription.status !== 'initialize';

    return display ? this.props.children : null;
  }
}

export default connect(
  ({ api }) => ({ api }),
  dispatch => ({
    navigationActions: bindActionCreators(navigationActions, dispatch),
    apiActions: bindActionCreators(apiActions, dispatch)
  })
)(_container);
