import React from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as apiActions from 'redux/actions/api';
import * as navigationActions from 'redux/actions/navigation';

import { AppstoreOutlined } from '@ant-design/icons';

class _container extends React.Component {
  static propTypes = {
    apiActions: PropTypes.object.isRequired,
    api: PropTypes.object.isRequired,
    children: PropTypes.node,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    navigationActions: PropTypes.object.isRequired
  };

  componentDidMount() {
    const { organizationId } = this.props.match.params;
    this._route = `/dashboard/organizations/${organizationId}/stacks`;
    this.props.apiActions.subscribe({
      route: this._route,
      routeArgs: {},
    });

    const { url, path } = this.props.match;
    const urlCorrected = !/\/$/.test(url) && /\/$/.test(path) ? `${url}/` : url;
    this._url = urlCorrected;
    this.props.navigationActions.breadcrumbAdd(this.renderBreadcrumb(), urlCorrected, this._url);


    // Go back to organization if we don't have the permission to see this
    const { userPermissions } = this.props.api.subscriptions[`/dashboard/organizations/${organizationId}`].datas;
    if (userPermissions.indexOf('stacksAccess') === -1) {
      this.props.history.replace(`/account/organizations/${organizationId}/`);
    }
  }

  componentWillUnmount() {
    this.props.navigationActions.breadcrumbRemove(this._url);
    this.props.apiActions.unsubscribe({ route: this._route });
  }

  renderBreadcrumb() {
    return (
      <>
        <AppstoreOutlined />
        &nbsp;
        Stacks
      </>
    );
  }

  render() {
    // Stop if we don't have the permission to see this
    const { organizationId } = this.props.match.params;
    const { userPermissions } = this.props.api.subscriptions[`/dashboard/organizations/${organizationId}`].datas;
    if (userPermissions.indexOf('stacksAccess') === -1) {
      return null;
    }

    const subscription = this.props.api.subscriptions[this._route];
    const display = subscription && subscription.status !== 'initialize';
    return display ? this.props.children : null;
  }
}

export default connect(
  ({ api }) => ({ api }),
  dispatch => ({
    navigationActions: bindActionCreators(navigationActions, dispatch),
    apiActions: bindActionCreators(apiActions, dispatch)
  })
)(_container);
