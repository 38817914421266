import React from 'react';
import PropTypes from 'prop-types';

class ButtonLink extends React.Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    colored: PropTypes.bool,
    className: PropTypes.string
  };

  render = () => {
    return (
      <button
        className={this.props.className}
        onClick={e => { e.preventDefault(); this.props.onClick(e); }}
        style={{
          color: this.props.colored === false ? undefined : '#1677ff',
          border: 'none',
          background: 'none',
          cursor: 'pointer',
          outline: 'none',
          padding: 0
        }}
      >
        {this.props.children}
      </button>
    );
  };
}


export default ButtonLink;
