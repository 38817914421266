class Cookies {
  constructor() {
    this._rootDomain = process.env.ROOT_DOMAIN;
  }


  getAll() {
    const cookies = document.cookie.split(';') || [];

    const cookiesObject = [];
    for (const cookie of cookies) {
      const cookieObject = cookie.match(/^[ ]?([^=]+)=(.*)$/);
      if (cookieObject && cookieObject.length === 3) {

        const key = decodeURIComponent(cookieObject[1]);
        const content = decodeURIComponent(cookieObject[2]);
        try {
          cookiesObject.push({
            [key]: JSON.parse(content)
          });
        }
        catch (error) {
          cookiesObject.push({
            [key]: content
          });
        }
      }
    }

    return cookiesObject.reduce((accumulator, value) => ({ ...accumulator, ...value }), {});
  }


  delete(name) {
    // Note: to delete a cookie, we have to define the same path and domain.
    // As we don't know how the domain have been set, we delete it a first time with default settings (to handle generic case) and a second time setting domain to the root domain (to handle case where we set it with `global`).
    document.cookie = [
      `${encodeURIComponent(name)}=`,
      'max-Age=-1',
      'path=/',
      'secure'
    ].filter(v => v).join(';');

    document.cookie = [
      `${encodeURIComponent(name)}=`,
      'max-Age=-1',
      'path=/',
      `domain=${this._rootDomain}`,
      'secure'
    ].filter(v => v).join(';');
  }


  set({ name, value, global, maxAge }) {
    document.cookie = [
      encodeURIComponent(name) + '=' + encodeURIComponent(JSON.stringify(value)),

      // If we set domain to the root domain, then the cookie will be available to subdomains too
      // See https://javascript.info/cookie#domain
      global ? `domain=${this._rootDomain}` : null,

      maxAge ? `max-Age=${maxAge}` : null,

      'path=/',
      'samesite=strict',
      'secure'
    ].filter(v => v).join(';');
  }
}

export default Cookies;
