import React from 'react';
import PropTypes from 'prop-types';

import { Button, Modal } from 'antd';
import { ApiOutlined } from '@ant-design/icons';

class ServiceInstanceRestart extends React.Component {
  static propTypes = {
    service: PropTypes.object.isRequired,
    disabled: PropTypes.bool.isRequired,
    onRestart: PropTypes.func.isRequired
  };

  state = {
    modalVisible: false
  };


  handleClose = () => {
    this.setState({ modalVisible: false });
  };


  handleSubmit = () => {
    this.handleClose();
    this.props.onRestart();
  };


  renderModal() {
    return (
      <Modal key="modal" title={`Restart instance ${this.props.service.name}?`}
        open={this.state.modalVisible}
        onOk={this.handleSubmit}
        onCancel={this.handleClose}
        footer={[
          <Button key="cancel" onClick={this.handleClose}>Cancel</Button>,
          <Button key="submit" type="primary" danger onClick={this.handleSubmit}>
            Restart
          </Button>
        ]}
      >
        It will restart the instance running your service.
        <br />
        You shouldn't do that unless you know exactly what you're doing.
      </Modal>
    );
  }


  render() {
    return [
      (
        <Button
          key="button"
          type="default"
          icon={<ApiOutlined />}
          block
          onClick={() => this.setState({ modalVisible: true })}
          disabled={this.props.disabled}
        >
          Restart
        </Button>
      ),
      this.renderModal()
    ];
  }
}

export default ServiceInstanceRestart;
