import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import DropdownElement from './DropdownElement';
import styles from './Dropdown.css';


Dropdown.propTypes = {
  triggeredOnMouseOver: PropTypes.bool,
  columns: PropTypes.number,
  placement: PropTypes.oneOf([ 'left', 'right' ]),
  maxHeight: PropTypes.number,
  children: PropTypes.node.isRequired,
  elements: PropTypes.oneOfType([
    PropTypes.shape({
      type: PropTypes.oneOf([ DropdownElement ]),
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.oneOf([ DropdownElement ]),
      })
    ),
  ]).isRequired
};


function Dropdown(props) {
  const [ opened, setOpened ] = useState(false);

  function renderContent() {
    return (
      <div
        className={[
          styles.dropdownContent,
          opened && styles.dropdownContentDisplayed,
          props.placement === 'right' ? styles.dropdownContentRight : styles.dropdownContentLeft
        ].filter(Boolean).join(' ')}
      >
        <ul style={{
          ...props.columns !== 'undefined' ? { columnCount: props.columns } : {},
          ...props.maxHeight !== 'undefined' ? { maxHeight: props.maxHeight } : {},
          }}
        >
          {props.elements}
        </ul>
      </div>
    );
  }


  function useOutsideAlerter(ref) {
    useEffect(
      () => {
        let timeout;

        function handleClose(delay) {
          if (!props.triggeredOnMouseOver) {
            return;
          }

          if (parseInt(delay) === delay) {
            timeout = setTimeout(() => setOpened(false), delay);
          }
          else {
            setOpened(false);
          }
        }
        const handleCloseAfterDelay = handleClose.bind(null, 500);

        function clearCloseTimeout() {
          clearTimeout(timeout);
        }

        ref.current.addEventListener('mouseenter', clearCloseTimeout);
        ref.current.addEventListener('mouseleave', handleCloseAfterDelay);

        document.addEventListener('mouseup', handleClose);

        return () => {
          if (props.triggeredOnMouseOver) {
            ref.current.removeEventListener('mouseleave', handleCloseAfterDelay);
            ref.current.removeEventListener('mouseenter', clearCloseTimeout);
          }

          document.removeEventListener('mouseup', handleClose);
        };
      },
      [ ref ]
    );
  }


  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <div ref={wrapperRef} className={styles.dropdownContainer}>
      <button
        onClick={() => setOpened(!opened)}
        onMouseOver={() => props.triggeredOnMouseOver && setOpened(true)}
        onFocus={() => props.triggeredOnMouseOver && setOpened(true)}
        className={styles.dropdownButton}
      >
        {props.children}
      </button>

      {renderContent()}
    </div>
  );
}

export default Dropdown;
export { DropdownElement };