import React from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as apiActions from 'redux/actions/api';
import * as appActions from 'redux/actions/app';

class Logout extends React.Component {
  static propTypes = {
    apiActions: PropTypes.object.isRequired,
    appActions: PropTypes.object.isRequired
  };

  componentDidMount() {
    this.props.apiActions.del(
      {
        route: '/tokens/this',
        routeArgs: {},
        handleErrorAutomatically: false
      },
      (err, res) => {
        this.props.appActions.sessionSetDispatch(false);
        document.location.href = '/';
      }
    );
  }

  render() {
    return null;
  }
}

export default connect(
  () => ({}),
  dispatch => ({
    apiActions: bindActionCreators(apiActions, dispatch),
    appActions: bindActionCreators(appActions, dispatch)
  })
)(Logout);
