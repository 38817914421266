import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as apiActions from 'redux/actions/api';

import { Card, Button, Row, Col, Tag, Descriptions, Progress } from 'antd';
import { PlusCircleOutlined, CheckOutlined, LoadingOutlined, ApiOutlined } from '@ant-design/icons';

import ServiceTitle from 'components/ServiceTitle';
import ServiceStatus from 'components/ServiceStatus';

class _default extends React.Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    apiActions: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };

  componentDidMount() {
    const { stackId } = this.props.match.params;
    const route = `/dashboard/stacks/${stackId}/services`;
    const services = this.props.api.subscriptions[route].datas;
    if (!services.length) {
      this.props.history.replace('create/');
      return;
    }

    // Subscribe to metricsOverview for each service
    for (const service of services) {
      this.props.apiActions.subscribe({
        route: `/dashboard/services/${service.id}/metricsOverview`,
        routeArgs: {},
        refreshInterval: 10 * 1000,
        spinner: false,
        handleErrorAutomatically: false
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { stackId } = this.props.match.params;
    const route = `/dashboard/stacks/${stackId}/services`;
    const services = this.props.api.subscriptions[route].datas;
    if (!services.length) {
      this.props.history.replace('create/');
      return;
    }
  }

  componentWillUnmount() {
    const { stackId } = this.props.match.params;
    const route = `/dashboard/stacks/${stackId}/services`;
    const services = this.props.api.subscriptions[route].datas;

    // Unsubscribe from metricsOverview for each service
    for (const service of services) {
      this.props.apiActions.unsubscribe({
        route: `/dashboard/services/${service.id}/metricsOverview`
      });
    }
  }

  renderEmpty() {
    return ( <Card style={{ marginBottom: 16 }}>You don't have any service added yet</Card> );
  }

  renderService(service) {
    const { updatesAvailableCount, name, serviceStore } = service;

    const extra = updatesAvailableCount === 0
      ? null
      : updatesAvailableCount === 1
        ? ( <Tag color="blue">One update is available!</Tag> )
        : ( <Tag color="red">Updates should be applied!</Tag> );

    const entries = [
      {
        label: 'Service ID',
        content: service.id
      },
      {
        label: 'Status',
        content: ( <ServiceStatus status={service.status}/> )
      },
      {
        label: 'Service',
        content: service.serviceStore.title
      },
      {
        label: 'Version',
        content: service.serviceStore.version
      }
    ];


    // Add metrics
    const metricsRoute = `/dashboard/services/${service.id}/metricsOverview`;
    const metricsLoaded = this.props.api.subscriptions[metricsRoute]
      && this.props.api.subscriptions[metricsRoute].status !== 'initialize'
      && this.props.api.subscriptions[metricsRoute].datas
      && Object.keys(this.props.api.subscriptions[metricsRoute].datas).length > 0;

    const metricsOverview = this.props.api.subscriptions[metricsRoute]?.datas || {};

    const loadPercent = Math.round((metricsOverview.load / metricsOverview.cpusCountFromStore) * 100);
    const { pressureCpuWaitingPercent, pressureMemoryStalledPercent, pressureMemoryWaitingPercent, pressureIoStalledPercent,pressureIoWaitingPercent } = metricsOverview;
    const loadsMaximum = Math.max(loadPercent, pressureCpuWaitingPercent, pressureMemoryStalledPercent, pressureMemoryWaitingPercent, pressureIoStalledPercent,pressureIoWaitingPercent);

    // Add load
    const loadColor = loadsMaximum >= 80
      ? '#f5222d' // red
      : loadsMaximum >= 60
        ? '#fa8c16' // orange
        : '#52c41a'; // green

    entries.push({
      label: 'VM load',
      content: [
        !metricsLoaded && ( <LoadingOutlined /> ),
        isNaN(loadsMaximum) && ( <ApiOutlined /> ),
        loadsMaximum < 60 && ( <CheckOutlined style={{ color: loadColor }} /> ),
        (
          <Progress
            key="progress"
            percent={loadsMaximum}
            strokeColor={loadColor}
            status="normal"
            size="small"
          />
        )
      ].find(v => v)
    });

    // Add disk usage
    const diskUsagePercent = Math.round((metricsOverview.diskUsed / metricsOverview.diskTotal) * 100);
    const diskUsageColor = diskUsagePercent >= 90
      ? '#f5222d' // red
      : diskUsagePercent >= 70
        ? '#fa8c16' // orange
        : '#52c41a'; // green

    entries.push({
      label: 'Disk usage',
      content: [
        !metricsLoaded && ( <LoadingOutlined /> ),
        isNaN(diskUsagePercent) && ( <ApiOutlined /> ),
        diskUsagePercent < 70 && ( <CheckOutlined style={{ color: diskUsageColor }} /> ),
        (
          <Progress
            key="progress"
            percent={diskUsagePercent}
            strokeColor={diskUsageColor}
            status="normal"
            size="small"
          />
        )
      ].find(v => v)
    });

    return (
      <Col key={service.id} xs={24} lg={12} style={{ paddingBottom: 16 }}>
        <Link
          to={`${service.id}/`}
          disabled={service.status.type === 'serviceDestruction'}
        >
          <Card
            title={( <ServiceTitle title={name} name={serviceStore.name} /> )}
            style={{ cursor: 'pointer' }}
            extra={extra}
            hoverable
          >

            <Descriptions
              size="small"
              bordered
              column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
              items={entries.map(({ label, content }) => ({
                key: label,
                label,
                children: content
              }))}
            />
          </Card>
        </Link>
      </Col>
    );
  }

  renderServices() {
    const { stackId } = this.props.match.params;
    const route = `/dashboard/stacks/${stackId}/services`;
    const services = this.props.api.subscriptions[route].datas;
    const servicesToRender = services.map(service => this.renderService(service));
    return ( <Row gutter={16}>{servicesToRender}</Row> );
  }


  render() {
    const { stackId } = this.props.match.params;
    const stack = this.props.api.subscriptions[`/dashboard/stacks/${stackId}`].datas;
    const serviceCreateVisible = stack.userPermissions.indexOf('servicesCreate') !== -1;

    const route = `/dashboard/stacks/${stackId}/services`;
    const services = this.props.api.subscriptions[route].datas;
    return (
      <>
        { services.length ? this.renderServices() : this.renderEmpty() }

        {
          serviceCreateVisible
            ? (
              <Card>
                <div style={{ width: '100%', textAlign: 'center' }}>
                  <Link to="create/">
                    <Button type="primary" size="large" icon={ <PlusCircleOutlined /> }>Add a service</Button>
                  </Link>
                </div>
              </Card>
            )
            : null
        }
      </>
    );
  }
}

export default connect(
  ({ api }) => ({ api }),
  dispatch => ({
    apiActions: bindActionCreators(apiActions, dispatch)
  })
)(_default);
