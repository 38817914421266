import React from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as apiActions from 'redux/actions/api';

import { Row, Col, Card, Button, Alert, Result } from 'antd';

import CreditCardFormParent from 'components/CreditCardFormParent';

class _default extends React.Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    apiActions: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };

  state = {
    changeCard: false
  };

  componentDidMount() {
    this._mounted = true;
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  renderCreditCard(creditCard) {
    return (
      <Card title="Payment card">
        <Card style={{ marginBottom: 10 }}>
          <Row>
            <Col span={21}>
              <h3>xxxx-xxxx-xxxx-{creditCard.last4}</h3>
              Expire on {creditCard.expirationMonth}/{creditCard.expirationYear}
            </Col>
            <Col span={3} style={{ textAlign: 'center' }}>
              <Button type="default" onClick={() => this.setState({ changeCard: true }) }>
                Change card
              </Button>
            </Col>
          </Row>
        </Card>
      </Card>
    );
  }


  renderAlert(creditCard) {
    if (creditCard && creditCard.type) {
      return null;
    }

    return (
      <div style={{ maxWidth: 540, marginLeft: 'auto', marginRight: 'auto', marginBottom: 20 }}>
        <Alert
          key="alert"
          message="To avoid frauds, we need your payment card"
          description={(
            <>
              Billing will be done at the beginning of each month for the services consumed and only if you do not have a free coupon.
              <br />
              You can check your current consumption at any time in "Billing"/"Invoices".
            </>
          )}
          type="info"
        />
      </div>
    );
  }


  renderCreditCardForm(creditCard) {
    const title = this.state.changeCard ? 'Change your payment card' : 'Add a payment card';
    return (
      <Card title={title} key="creditCardForm">
        {this.renderAlert(creditCard)}
        <div style={{ maxWidth: 540, marginLeft: 'auto', marginRight: 'auto' }}>
          <CreditCardFormParent
            type={this.state.changeCard ? 'change' : 'add'}
            onCancel={() => this.setState({ changeCard: false })}
            onSuccess={(paymentMethodId, cb) => {
              // Send token to API
              const { organizationId } = this.props.match.params;
              this.props.apiActions.post(
                {
                  route: `/dashboard/organizations/${organizationId}/billing/creditCard`,
                  // "With verification" for new cards. The goal is to capture a small amount when a new card is added directly in "billing"/"payment method" when the client adds a card before starting a service.
                  routeArgs: { paymentMethodId, withVerification: this.state.changeCard === false }
                },
                (error, result) => {
                  if (error) {
                    cb(error, null);
                    return;
                  }
                  if (!this._mounted) {
                    return;
                  }

                  cb(null, null);
                  if (!creditCard || !creditCard.type) {
                    // Go to stacks if the user didn't had a payment card
                    this.props.history.replace('../../stacks/');
                  }
                  else {
                    this.setState({ changeCard: false });
                  }
                }
              );
            }}
          />
        </div>
      </Card>
    );
  }


  renderBankTransfer() {
    return (
      <Card title="Payment method">
        <Result
          status="success"
          title="Your account is set up with a bank transfer payment method"
        />
      </Card>
    );
  }

  render() {
    const { organizationId } = this.props.match.params;
    const route = `/dashboard/organizations/${organizationId}/billing/paymentMethod`;
    const creditCard = this.props.api.subscriptions[route].datas;

    if (creditCard && creditCard.type === 'card' && !this.state.changeCard) {
      return this.renderCreditCard(creditCard);
    }
    else if (creditCard && creditCard.type === 'bankTransfer') {
      return this.renderBankTransfer();
    }
    else {
      return this.renderCreditCardForm(creditCard);
    }
  }
}

export default connect(
  state => ({
    api: state.api
  }),
  dispatch => ({
    apiActions: bindActionCreators(apiActions, dispatch)
  })
)(_default);
