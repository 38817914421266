import React from 'react';
import PropTypes from 'prop-types';

import { Button, Modal, Alert, Input } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

class ServiceDelete extends React.Component {
  static propTypes = {
    service: PropTypes.object.isRequired,
    stack: PropTypes.object.isRequired,
    disabled: PropTypes.bool.isRequired,
    onDelete: PropTypes.func.isRequired
  };

  state = {
    modalVisible: false,
    checkSentenceInput: ''
  };


  handleClose = () => {
    this.setState({ modalVisible: false, checkSentenceInput: '' });
  };


  handleSubmit = () => {
    this.handleClose();
    this.props.onDelete();
  };


  renderModal() {
    const { stack } = this.props;
    const checkSentence = `Delete ${this.props.service.name} from ${stack.name}`;
    return (
      <Modal key="modal" title={`Delete service ${this.props.service.name}?`}
        open={this.state.modalVisible}
        onOk={this.handleSubmit}
        onCancel={this.handleClose}
        footer={[
          <Button key="cancel" onClick={this.handleClose}>Cancel</Button>,
          <Button key="submit" type="primary" danger onClick={this.handleSubmit} disabled={this.state.checkSentenceInput.toLowerCase() !== checkSentence.toLowerCase()}>
            Delete this service and its data
          </Button>,
        ]}
      >
        <Alert message="You will loose all your data!" banner />
        <br />
        This action <span style={{ fontWeight: 'bold' }}>cannot be undone</span>. You will <span style={{ fontWeight: 'bold' }}>loose all data</span> related to this service.
        <br />
        <br />

        If you are sure of what you are doing, please type "{checkSentence}" to confirm.
        <br />
        <br />

        <Input
          placeholder={checkSentence}
          value={this.state.checkSentenceInput}
          onChange={(e) => this.setState({ checkSentenceInput: e.target.value })}
        />
      </Modal>
    );
  }


  render() {
    return [
      (
        <Button
          key="button"
          type="dashed"
          icon={<DeleteOutlined />}
          block
          onClick={() => this.setState({ modalVisible: true, checkSentenceInput: '' })}
          disabled={this.props.disabled}
        >
          Delete
        </Button>
      ),
      this.renderModal()
    ];
  }
}

export default ServiceDelete;
