import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as apiActions from 'redux/actions/api';
import * as navigationActions from 'redux/actions/navigation';

import { Card, Button, Checkbox } from 'antd';
import Markdown from 'components/Markdown';

class Terms extends React.Component {
  static propTypes = {
    apiActions: PropTypes.object.isRequired,
    app: PropTypes.object.isRequired,
    navigationActions: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };

  state = {
    termsValidated: false,
    terms: null
  };

  componentDidMount() {
    this._mounted = true;

    this.props.navigationActions.stepSelectByLabel('terms');

    const { language } = this.props.app;
    this.props.apiActions.requestNew(
      {
        url: `/locales/terms.${language}.md`
      },
      (error, result) => {
        if (error || !this._mounted) {
          return;
        }

        this.setState({ terms: result });
      }
    );
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  onValidation = () => {

    const { type, servicePackStoreId } = this.props.location.state;
    const { stackId } = this.props.match.params;

    const services = [];

    if (type === 'servicePackStore') {
      const { instancesConfiguration } = this.props.location.state;
      const servicesStoreIds = Object.keys(instancesConfiguration);
      for (const serviceStoreId of servicesStoreIds) {
        const { instancesCount, instanceStoreId } = instancesConfiguration[serviceStoreId];

        for (let i = 0; i < instancesCount; i++) {
          services.push({ serviceStoreId, instanceStoreId });
        }
      }
    }
    else {
      const { serviceStoreId, instanceStoreId, regionId } = this.props.location.state;
      services.push({ serviceStoreId, instanceStoreId, regionId });
    }

    this.props.apiActions.post(
      {
        route: `/dashboard/stacks/${stackId}/services`,
        routeArgs: { servicePackStoreId, services }
      },
      (error, result) => {
        if (error || !this._mounted) {
          return;
        }

        const route = result.servicesIds.length > 1 ? '../../../' : `../../../services/${result.servicesIds[0]}/`;
        this.props.history.push(route);
      }
    );
  };

  render() {
    if (!this.state.terms) {
      return null;
    }

    // Note: data parameters are used by factory/tests
    return (
      <Card title="Validate terms">
        <Card styles={{ body: { height: 200, overflowY: 'scroll' }}} style={{ marginBottom: 16 }}>
          <Markdown content={this.state.terms} />
        </Card>

        <div style={{ textAlign: 'right', margin: '10px' }}>
          <Checkbox
            checked={this.state.termsValidated}
            onChange={(e) => this.setState({ termsValidated: e.target.checked })}
          >
            I agree with the following terms
          </Checkbox>
          <br />
          <br />

          <Link to="../">
            <Button style={{ marginRight: 8 }}>Cancel</Button>
          </Link>

          <Button type="primary" onClick={this.onValidation} disabled={!this.state.termsValidated}>Confirm</Button>
        </div>
      </Card>
    );
  }
}

export default connect(
  ({ app }) => ({ app }),
  dispatch => ({
    apiActions: bindActionCreators(apiActions, dispatch),
    navigationActions: bindActionCreators(navigationActions, dispatch)
  })
)(Terms);
