import React from 'react';
import PropTypes from 'prop-types';

class ErrorBoundary extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired
  };

  state = { hasError: false };

  componentDidCatch(error, info) {
    console.error(error, info);

    this.setState({ hasError: true });

    // This is awful
    if (process.env.NODE_ENV === 'production') {
      this._timeout = setTimeout(
        () => {
          document.location.reload();
        },
        15 * 1000
      );
    }
  }

  componentWillUnmount() {
    if (this._timeout) {
      clearTimeout(this._timeout);
    }
  }

  render() {
    if (this.state.hasError) {
      return <span>Oops... An error occured. We are sorry for that and will resolve this bug ASAP.</span>;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
