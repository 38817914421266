const currencies = {
  EUR: [ 'AD', 'AT', 'AX', 'BE', 'BL', 'CY', 'DE', 'EE', 'ES', 'FI', 'FR', 'GF', 'GP', 'GR', 'IE', 'IT', 'LU', 'MC', 'ME', 'MF', 'MQ', 'MT', 'NL', 'PM', 'PT', 'RE', 'SI', 'SK', 'SM', 'TF', 'VA', 'YT' ],
  AED: [ 'AE' ],
  AFN: [ 'AF' ],
  XCD: [ 'AG', 'AI', 'DM', 'GD', 'KN', 'LC', 'MS', 'VC' ],
  ALL: [ 'AL' ],
  AMD: [ 'AM' ],
  ANG: [ 'AN', 'CW', 'SX' ],
  AOA: [ 'AO' ],
  ARS: [ 'AR' ],
  USD: [ 'AS', 'BQ', 'EC', 'FM', 'GU', 'IO', 'MH', 'MP', 'PR', 'PW', 'TC', 'TL', 'UM', 'US', 'VG', 'VI' ],
  AUD: [ 'AU', 'CC', 'CX', 'HM', 'KI', 'NF', 'NR', 'TV' ],
  AWG: [ 'AW' ],
  AZN: [ 'AZ' ],
  BAM: [ 'BA' ],
  BBD: [ 'BB' ],
  BDT: [ 'BD' ],
  XOF: [ 'BF', 'BJ', 'CI', 'GW', 'ML', 'NE', 'SN', 'TG' ],
  BGN: [ 'BG' ],
  BHD: [ 'BH' ],
  BIF: [ 'BI' ],
  BMD: [ 'BM' ],
  BND: [ 'BN' ],
  BOB: [ 'BO' ],
  BRL: [ 'BR' ],
  BSD: [ 'BS' ],
  BTN: [ 'BT' ],
  NOK: [ 'BV', 'NO', 'SJ' ],
  BWP: [ 'BW' ],
  BYR: [ 'BY' ],
  BZD: [ 'BZ' ],
  CAD: [ 'CA' ],
  CDF: [ 'CD' ],
  XAF: [ 'CF', 'CG', 'CM', 'GA', 'GQ', 'TD' ],
  CHF: [ 'CH', 'LI' ],
  NZD: [ 'CK', 'NU', 'NZ', 'PN', 'TK' ],
  CLP: [ 'CL' ],
  CNY: [ 'CN' ],
  COP: [ 'CO' ],
  CRC: [ 'CR' ],
  CUP: [ 'CU' ],
  CVE: [ 'CV' ],
  CZK: [ 'CZ' ],
  DJF: [ 'DJ' ],
  DKK: [ 'DK', 'FO', 'GL' ],
  DOP: [ 'DO' ],
  DZD: [ 'DZ' ],
  EGP: [ 'EG' ],
  MAD: [ 'EH', 'MA' ],
  ERN: [ 'ER' ],
  ETB: [ 'ET' ],
  FJD: [ 'FJ' ],
  FKP: [ 'FK' ],
  GBP: [ 'GB', 'GG', 'GS', 'IM', 'JE' ],
  GEL: [ 'GE' ],
  GHS: [ 'GH' ],
  GIP: [ 'GI' ],
  GMD: [ 'GM' ],
  GNF: [ 'GN' ],
  GTQ: [ 'GT' ],
  GYD: [ 'GY' ],
  HKD: [ 'HK' ],
  HNL: [ 'HN' ],
  HRK: [ 'HR' ],
  HTG: [ 'HT' ],
  HUF: [ 'HU' ],
  IDR: [ 'ID' ],
  ILS: [ 'IL', 'PS' ],
  INR: [ 'IN' ],
  IQD: [ 'IQ' ],
  IRR: [ 'IR' ],
  ISK: [ 'IS' ],
  JMD: [ 'JM' ],
  JOD: [ 'JO' ],
  JPY: [ 'JP' ],
  KES: [ 'KE' ],
  KGS: [ 'KG' ],
  KHR: [ 'KH' ],
  KMF: [ 'KM' ],
  KPW: [ 'KP' ],
  KRW: [ 'KR' ],
  KWD: [ 'KW' ],
  KYD: [ 'KY' ],
  KZT: [ 'KZ' ],
  LAK: [ 'LA' ],
  LBP: [ 'LB' ],
  LKR: [ 'LK' ],
  LRD: [ 'LR' ],
  LSL: [ 'LS' ],
  LTL: [ 'LT' ],
  LVL: [ 'LV' ],
  LYD: [ 'LY' ],
  MDL: [ 'MD' ],
  MGA: [ 'MG' ],
  MKD: [ 'MK' ],
  MMK: [ 'MM' ],
  MNT: [ 'MN' ],
  MOP: [ 'MO' ],
  MRO: [ 'MR' ],
  MUR: [ 'MU' ],
  MVR: [ 'MV' ],
  MWK: [ 'MW' ],
  MXN: [ 'MX' ],
  MYR: [ 'MY' ],
  MZN: [ 'MZ' ],
  NAD: [ 'NA' ],
  XPF: [ 'NC', 'PF', 'WF' ],
  NGN: [ 'NG' ],
  NIO: [ 'NI' ],
  NPR: [ 'NP' ],
  OMR: [ 'OM' ],
  PAB: [ 'PA' ],
  PEN: [ 'PE' ],
  PGK: [ 'PG' ],
  PHP: [ 'PH' ],
  PKR: [ 'PK' ],
  PLN: [ 'PL' ],
  PYG: [ 'PY' ],
  QAR: [ 'QA' ],
  RON: [ 'RO' ],
  RSD: [ 'RS' ],
  RUB: [ 'RU' ],
  RWF: [ 'RW' ],
  SAR: [ 'SA' ],
  SBD: [ 'SB' ],
  SCR: [ 'SC' ],
  SDG: [ 'SD' ],
  SEK: [ 'SE' ],
  SGD: [ 'SG' ],
  SHP: [ 'SH' ],
  SLL: [ 'SL' ],
  SOS: [ 'SO' ],
  SRD: [ 'SR' ],
  STD: [ 'ST' ],
  SVC: [ 'SV' ],
  SYP: [ 'SY' ],
  SZL: [ 'SZ' ],
  THB: [ 'TH' ],
  TJS: [ 'TJ' ],
  TMT: [ 'TM' ],
  TND: [ 'TN' ],
  TOP: [ 'TO' ],
  TRY: [ 'TR' ],
  TTD: [ 'TT' ],
  TWD: [ 'TW' ],
  TZS: [ 'TZ' ],
  UAH: [ 'UA' ],
  UGX: [ 'UG' ],
  UYU: [ 'UY' ],
  UZS: [ 'UZ' ],
  VEF: [ 'VE' ],
  VND: [ 'VN' ],
  VUV: [ 'VU' ],
  WST: [ 'WS' ],
  YER: [ 'YE' ],
  ZAR: [ 'ZA' ],
  ZMK: [ 'ZM' ],
  ZWL: [ 'ZW' ]
};

class Utils {
  getCurrencyFromlanguage(languageCode) {
    const language = languageCode.replace(/^.+-/, '');
    return Object.keys(currencies).find(currency => currencies[currency].find(l => l === language));
  }

  // Matomo doesn't handle directly single page applications.
  // We use Helmet to detect that a page has changed and sent data to Matomo.
  // Note that we have to disable the first "trackPageView" in index.html to avoid tracking 2 times the first load of a page
  matomoHelmetOnChangeClientState(newState, addedTags, removedTags) {

    // // Check title and description sizes
    // if (newState.title.length < 50 || newState.title.length > 70) {
    //   console.error(`Title should contain 50 to 70 characters but contains ${newState.title.length}: ${newState.title}`);
    // }
    // const descriptionObject = newState.metaTags.find(metaTag => metaTag.name === 'description');
    // if (descriptionObject) {
    //   const description = descriptionObject.content;
    //   if (description.length < 110 || description.length > 160) {
    //     console.error(`Description should contain 110 to 160 characters but contains ${description.length}: ${description}`);
    //   }
    // }


    if (!Object.keys(addedTags).length || !window._paq) {
      return;
    }

    window.matomoBySh = window.matomoBySh || {};
    if (window.matomoBySh.locationLast) {
      window._paq.push([ 'setReferrerUrl', window.matomoBySh.locationLast ]);
    }
    window.matomoBySh.locationLast = location.href.toLowerCase();

    window._paq.push([ 'setCustomUrl', location.href.toLowerCase() ]);
    window._paq.push([ 'setDocumentTitle', document.title ]);
    // window._paq.push([ 'setGenerationTimeMs', timeItTookToLoadPage ]);
    window._paq.push([ 'deleteCustomVariables', 'page' ]);
    window._paq.push([ 'trackPageView' ]);

    // Make Matomo aware of newly added content
    window._paq.push([ 'MediaAnalytics::scanForMedia' ]);
    window._paq.push([ 'FormAnalytics::scanForForms' ]);
    window._paq.push([ 'trackContentImpressionsWithinNode' ]);
  }

  versionsSort(a, b) {
    if (/^[0-9]+\.[0-9]+(?:\.[0-9]+)?$/.test(a) && /^[0-9]+\.[0-9]+(?:\.[0-9]+)?$/.test(b)) {
      const [ /* null */, aMajor, aMinor, aPatch ] = a.match(/^([0-9]+)\.([0-9]+)(?:\.([0-9]+))?$/);
      const [ /* null */, bMajor, bMinor, bPatch ] = b.match(/^([0-9]+)\.([0-9]+)(?:\.([0-9]+))?$/);

      if (aMajor !== bMajor) {
        return parseInt(aMajor) - parseInt(bMajor);
      }
      else if (aMinor !== bMinor) {
        return parseInt(aMinor) - parseInt(bMinor);
      }
      else if (aPatch !== bPatch && aPatch && bPatch) {
        return parseInt(aPatch) - parseInt(bPatch);
      }
      else {
        return 0;
      }
    }
    else if (parseInt(a).toString() === a && parseInt(b).toString() === b) {
      return a - b;
    }
    else {
      return a.localeCompare(b);
    }
  }

  stackheroVersionsSort(a, b) {
    const aPart1 = a.replace(/^(.+)-([0-9]+)$/, '$1');
    const bPart1 = b.replace(/^(.+)-([0-9]+)$/, '$1');
    const aPart2 = a.replace(/^(.+)-([0-9]+)$/, '$2');
    const bPart2 = b.replace(/^(.+)-([0-9]+)$/, '$2');

    const comparisonPart1 = this.versionsSort(aPart1, bPart1);
    if (comparisonPart1 === 0) {
      return this.versionsSort(aPart2, bPart2);
    }
    else {
      return comparisonPart1;
    }
  }

  emailCheck(email) {
    // See https://emailregex.com/
    // Modified version to force at least a dot in domain
    return /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]{2,}(?:.[a-zA-Z0-9-]+)*$/.test(email);
  }

  textToUri(text) {
    return text
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '') // Remove accents
      .replace(/[' /.]/g, '-') // Replace " ", "/", "'" and "." per "-"
      .replace(/[^A-Za-z0-9-]+/g, '') // Remove other special characters
      .replace(/(-)\1+/g, '$1') // Deduplicate "-"
      .replace(/[-]*$/g, ''); // Remove ending "-"
  }
}

export default Utils;
