import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import queryString from 'query-string';

import Page404 from 'components/Page404';
import ErrorBoundary from 'components/ErrorBoundary';
import RouterTransitions from 'components/RouterTransitions';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as appActions from 'redux/actions/app';

// Be carefull here.
// Don't try to factorize with Router!
// We need to have Routes component to be able to retrieve props passed in components "_container"

import Spinner from 'components/Spinner';

class Routes extends React.Component {
  static propTypes = {
    appActions: PropTypes.object.isRequired,
    rootPath: PropTypes.string.isRequired,
    routes: PropTypes.array,
    renderRoutes: PropTypes.func
  };

  componentDidMount() {
    this.props.appActions.onRouteChange();
  }

  componentDidUpdate() {
    this.props.appActions.onRouteChange();
  }


  renderRoute(routeObject, props, children = null) {
    return (
      <ErrorBoundary>
        <Suspense fallback={ <Spinner /> }>
          <RouterTransitions>
            <routeObject.component
              {...props}
              {...queryString.parse(props.location.search)}
            >
              {children}
            </routeObject.component>
          </RouterTransitions>
        </Suspense>
      </ErrorBoundary>
    );
  }


  render() {
    const renderedRoutes = this.props.routes.map((route, index) => {
      return (
        <Route
          key={index}
          path={`${this.props.rootPath}${route.path}`}
          exact={route.exact}
          render={props => this.renderRoute(route, { ...this.props, ...props }, this.props.renderRoutes(route.routes))}
        />
      );
    });

    return (
      <Switch>
        {renderedRoutes}

        {/* Catch not declared routes and display 404 page */}
        <Route>
          <Page404 />
        </Route>
      </Switch>
    );
  }
}

export default connect(
  () => ({}),
  dispatch => ({
    appActions: bindActionCreators(appActions, dispatch)
  })
)(Routes);