import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash.isequal';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as navigationActions from 'redux/actions/navigation';
import * as apiActions from 'redux/actions/api';

import { Card, Form, Button, message } from 'antd';
import { IdcardOutlined } from '@ant-design/icons';
import FormItems from 'components/FormItems';

import Utils from 'modules/Utils';
const utils = new Utils();

import countries from 'contents/countries';

class Edit extends React.Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    apiActions: PropTypes.object.isRequired,
    navigationActions: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };

  state = {
    formIsValid: false,
    showErrors: false,
    organization: null
  };

  componentDidMount() {
    this._mounted = true;

    const { url, path } = this.props.match;
    const urlCorrected = !/\/$/.test(url) && /\/$/.test(path) ? `${url}/` : url;
    this.props.navigationActions.breadcrumbAdd(this.renderBreadcrumb(), null, 'organization');
    this.props.navigationActions.breadcrumbAdd('Edit', urlCorrected, urlCorrected);

    const { organizationId } = this.props.match.params;
    const route = `/dashboard/organizations/${organizationId}`;
    this.setState({ organization: this.props.api.subscriptions[route].datas });
  }

  componentDidUpdate(prevProps) {
    const { organizationId } = this.props.match.params;
    const route = `/dashboard/organizations/${organizationId}`;

    const organizationPrevious = prevProps.api.subscriptions[route].datas;
    const organizationCurrent = this.props.api.subscriptions[route].datas;

    if (!isEqual(organizationPrevious, organizationCurrent)) {
      this.setState({ organization: organizationCurrent });
    }
  }

  componentWillUnmount() {
    this._mounted = false;
    const { url, path } = this.props.match;
    const urlCorrected = !/\/$/.test(url) && /\/$/.test(path) ? `${url}/` : url;
    this.props.navigationActions.breadcrumbRemove(urlCorrected);
    this.props.navigationActions.breadcrumbRemove('organization');
  }

  handleSubmit = () => {
    this.setState({ showErrors: true });
    if (!this.state.formIsValid) {
      return false;
    }

    const { organizationId } = this.props.match.params;
    const route = `/dashboard/organizations/${organizationId}`;

    const { name, address, city, postalCode, countryCode, vatNumber, invoicesRecipients, invoiceAnnotation } = this.state.organization;
    this.props.apiActions.patch(
      {
        route: route,
        routeArgs: { name, address, city, postalCode, countryCode, vatNumber, invoicesRecipients, invoiceAnnotation }
      },
      (error, result) => !error && this._mounted && message.success('Data have been saved') && this.props.history.push('../')
    );
  };


  renderBreadcrumb() {
    return (
      <>
        <IdcardOutlined />
        &nbsp;
        Organization
      </>
    );
  }


  render() {
    if (!this.state.organization) {
      return null;
    }

    const { countryCode } = this.state.organization;
    const entries = [
      {
        label: 'Name',
        name: 'name',
        required: true,
        input: {
          type: 'text',
          hasError: (name, value, form) => value.length < 1 || value.length > 64 ? 'Should be between 1 and 64 characters' : '',
          autoComplete: 'organization'
        }
      },
      {
        label: 'Address',
        name: 'address',
        required: true,
        input: {
          type: 'textarea',
          hasError: (name, value, form) => value.length < 1 || value.length > 128 ? 'Should be between 1 and 128 characters' : '',
          autoComplete: 'street-address'
        }
      },
      {
        label: 'Postal code',
        name: 'postalCode',
        required: true,
        input: {
          type: 'text',
          hasError: (name, value, form) => value.length < 2 || value.length > 16 ? 'Should be between 2 and 16 characters' : '',
          autoComplete: 'postal-code'
        }
      },
      {
        label: 'City',
        name: 'city',
        required: true,
        input: {
          type: 'text',
          hasError: (name, value, form) => value.length < 1 || value.length > 32 ? 'Should be between 1 and 32 characters' : ''
        }
      },
      {
        label: 'Country',
        name: 'countryCode',
        required: true,
        input: {
          type: 'select',
          placeholder: 'Select your country',
          entries: countries.namesAndCodes.map(([ label, id ]) => ({ id, label })),
          hasError: (name, value, form) => countries.namesAndCodes.find(([ /* null */, id ]) => id === value) ? '' : 'You should select your country'
        }
      },

      countryCode
        && countries.vatNumbersFormats[countryCode]
        ? {
          label: 'VAT number',
          name: 'vatNumber',
          required: false,
          input: {
            type: 'text',
            placeholder: countries.vatNumbersFormats[countryCode][1],
            hasError: (name, value, form) => !value || countries.vatNumbersFormats[countryCode][0].test(value) ? '' : 'Format should be ' + countries.vatNumbersFormats[countryCode][1]
          }
        }
        : null,

      {
        label: 'Send invoices to',
        name: 'invoicesRecipients',
        required: true,
        input: {
          type: 'textTags',
          placeholder: 'Email address',
          min: 1,
          max: 3,
          onChange: email => email.toLowerCase(),
          hasError: (name, values, form) => values.findIndex(value => !utils.emailCheck(value)) !== -1
        }
      },

      {
        label: 'Invoice annotation',
        name: 'invoiceAnnotation',
        required: false,
        help: 'You can add an annotation to your invoices PDF, like a reference for your accounting department.',
        input: {
          type: 'textarea',
          hasError: (name, value, form) => value.length > 128 ? 'Can\'t have more than 128 characters' : '',
        }
      }
    ].filter(v => v !== null);

    return (
      <Card title="Your organization">
        <Form onFinish={this.handleSubmit}>
          <FormItems
            entries={entries}
            onStatusChange={formIsValid => this.setState({ formIsValid })}
            datas={this.state.organization}
            onUpdate={organization => this.setState({ organization })}
            showErrors={this.state.showErrors}
          />

          <Form.Item style={{ textAlign: 'right', marginBottom: 0 }}>
            <Button type="primary" htmlType="submit">Save</Button>
          </Form.Item>

        </Form>
      </Card>
    );
  }
}

export default connect(
  ({ api }) => ({ api }),
  dispatch => ({
    apiActions: bindActionCreators(apiActions, dispatch),
    navigationActions: bindActionCreators(navigationActions, dispatch)
  })
)(Edit);
