import React from 'react';
import PropTypes from 'prop-types';

import { Card, Modal, Button, Alert, Result } from 'antd';

import Markdown from 'components/Markdown';

class ServiceUpdates extends React.Component {
  static propTypes = {
    service: PropTypes.object.isRequired,
    onUpdate: PropTypes.func.isRequired
  };

  state = {
    modalVisible: false,
    update: {}
  };

  handleClose = () => {
    this.setState({
      modalVisible: false
    });
  };

  handleSubmit = () => {
    this.props.onUpdate(
      {
        idNew: this.state.update.id
      },
      () => {
        this.handleClose();
      }
    );
  };


  renderModal() {
    const { service } = this.props;
    const { update } = this.state;

    return (
      <Modal title={`Update ${service.name} to ${update.version}?`}
        open={this.state.modalVisible}
        onOk={this.handleSubmit}
        onCancel={this.handleClose}
        footer={[
          <Button key="cancel" onClick={this.handleClose}>Cancel</Button>,
          <Button key="submit" type="primary" onClick={this.handleSubmit}>I get it, update the service</Button>,
        ]}
      >
        Note that this update will restart the service.<br /><br />
        You should have a backup of all data stored on this service before starting the update.
      </Modal>
    );
  }


  renderUpdate(update, index = 0) {
    const title = `Update ${update.version} available!`;
    const extra = !index && (
      <Button
        type="link"
        size="small"
        onClick={() => this.setState({ modalVisible: true, update })}
        style={{ padding: 0 }}
      >
        Install the update
      </Button>
    );

    const style = index === 0 ? {} : { marginTop: 16 };
    const note = index ? ( <span>Note: you have to install the previous updates before installing this one.</span> ) : null;

    const alertExperimental = (
      <Alert
        type="warning"
        message="This update is experimental!"
        description={(
          <span>
            This update is not production ready!<br />
            You shouldn't install it unless you want to test it and are confortable with the idea of getting stuck or loose datas.
          </span>
        )}
        style={{ marginBottom: 16 }}
      />
    );
    const alertWip = ( <Alert type="error" message={'This update is a WIP and may not be used!'} style={{ marginBottom: 16 }} /> );
    const alert = update.status === 'experimental' ? alertExperimental
      : update.status === 'wip' ? alertWip
      : null;

    return (
      <Card key={update.version} title={title} extra={extra} style={style}>
        {alert}
        <Markdown content={update.changelog} />
        {note}
      </Card>
    );
  }


  render() {
    const { service } = this.props;
    const { updatesAvailable, updatesCanBeApplied } = service;

    if (!updatesCanBeApplied && updatesAvailable.length) {
      return (
        <Result
          status="warning"
          title="Can't apply updates"
          subTitle="Some updates are available but can't be applied now, probably because your service needs to be configured first or isn't running yet."
        />
      );
    }
    else if (!updatesAvailable || !updatesAvailable.length) {
      return (
        <Result
          status="success"
          title="Your service is up-to-date"
          subTitle="When an update is available, its content is displayed here and you can apply it with just a click."
        />
      );
    }

    return (
      <div style={{ marginTop: 16 }}>
        {updatesAvailable
          .map((update, index) => this.renderUpdate(update, index))
        }

        {this.renderModal()}
      </div>
    );
  }
}

export default ServiceUpdates;
