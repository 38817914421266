import React from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as navigationActions from 'redux/actions/navigation';

import { CalculatorOutlined } from '@ant-design/icons';

class _container extends React.Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    navigationActions: PropTypes.object.isRequired
  };

  componentDidMount() {
    const { url, path } = this.props.match;
    const urlCorrected = !/\/$/.test(url) && /\/$/.test(path) ? `${url}/` : url;
    this._url = urlCorrected;
    this.props.navigationActions.breadcrumbAdd(this.renderBreadcrumb(), null, this._url);

    // Go back to organization if we don't have the permission to see this
    const { organizationId } = this.props.match.params;
    const { userPermissions } = this.props.api.subscriptions[`/dashboard/organizations/${organizationId}`].datas;
    if (userPermissions.indexOf('billing') === -1) {
      this.props.history.replace(`/account/organizations/${organizationId}/`);
    }
  }

  componentWillUnmount() {
    this.props.navigationActions.breadcrumbRemove(this._url);
  }

  renderBreadcrumb() {
    return (
      <>
        <CalculatorOutlined />
        &nbsp;
        Billing
      </>
    );
  }

  render() {
    // Stop if we don't have the permission to see this
    const { organizationId } = this.props.match.params;
    const { userPermissions } = this.props.api.subscriptions[`/dashboard/organizations/${organizationId}`].datas;
    if (userPermissions.indexOf('billing') === -1) {
      return null;
    }

    return this.props.children;
  }
}

export default connect(
  ({ api }) => ({ api }),
  dispatch => ({
    navigationActions: bindActionCreators(navigationActions, dispatch)
  })
)(_container);
