import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';

import styles from './Spinner.css';

class Spinner extends React.Component {
  render() {
    return (
      <div className={styles.spinner}>
        <div className={styles.spinnerSubcontainer}>
          <LoadingOutlined />
        </div>
      </div>
    );
  }
}

export default Spinner;