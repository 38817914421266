import Request from 'modules/Request';
import axios from 'axios';

let subscriptionsTimers = {};

export function subscriptionAdd(route) {
  return {
    type: 'API_SUBSCRIPTION_ADD',
    route
  };
}

export function subscriptionRemove(route) {
  return {
    type: 'API_SUBSCRIPTION_REMOVE',
    route
  };
}

export function subscriptionUpdate(route, status, datas) {
  return {
    route,
    status,
    datas,
    type: 'API_SUBSCRIPTION_UPDATE'
  };
}

export function subscriptionReplace(route, response) {
  response.data = !response.data ? null : response.data;
  return {
    route,
    response,
    status: 'loaded',
    type: 'API_SUBSCRIPTION_REPLACE'
  };
}

export function subscriptionLoadingInc() {
  return {
    value: 1,
    type: 'API_SUBSCRIPTION_LOADING'
  };
}

export function subscriptionLoadingDec() {
  return {
    value: -1,
    type: 'API_SUBSCRIPTION_LOADING'
  };
}

export function errorSet({ errorType, reason, data }) {
  return {
    type: 'API_ERROR',
    errorType,
    reason,
    data
  };
}





export function request(args, cb = () => {}) {
  const { method, route, routeArgs, requestObj, options, spinner, handleErrorAutomatically = true } = args;

  return (dispatch, getState) => {
    const state = getState();
    const { api, app } = state;
    const { routeBase } = app;

    // If an error has ocurred, we don't execute any other request
    if (api.error.type === 'server') {
      return;
    }

    if (spinner) {
      dispatch(subscriptionLoadingInc());
    }

    // Do request
    requestObj[method](
      { route: routeBase + route, routeArgs, options },
      (error, response) => {

        if (spinner) {
          dispatch(subscriptionLoadingDec());
        }

        // When we quit the page, requests are aborted. We ignore the error.
        // Note: it seems that ECONNABORTED is triggered on timeout too: https://github.com/axios/axios/issues/1543
        if (error && error.code === 'ECONNABORTED') {
          return;
        }
        else if (error && (error.toString() === 'Error: Network Error' || (error.response && error.response.status === 503))) {
          // If the network seems not working, or a 503 error (maintenance mode), we will try again
          error.response && error.response.status === 503
            ? console.warn(error)
            : console.error(error);

          // Note: if we don't want to see the spinner, we probably don't want to see temporary errors.
          // This is the case with "/informations" route.
          if (spinner) {
            dispatch(errorSet({
              errorType: error.response && error.response.status === 503 ? 'maintenance' : 'network'
            }));
          }
          setTimeout(
            () => { dispatch(request(args, cb)); },
            2 * 1000
          );
          return;
        }
        else if (error && (handleErrorAutomatically || (error.response && error.response.status >= 500))) {
          // If an error occured (HTTP code !== 2xx)
          console.warn(error, error.response);
          dispatch(errorSet({
            errorType: 'server',
            reason: error.response
              ? [
                  error.response.status + ': ' + error.response.statusText,
                  error.response.data.message,
                  error.response.data.data
                ].filter(v => v).join('\n')
              : 'Unknown',
            data: error.response.data
          }));
          return;
        }
        else if (response && response.data && response.data.error && handleErrorAutomatically) {
          // If the API returned a soft error
          console.warn(response.data.error);
          dispatch(errorSet({ errorType: 'api', reason: response.data.error }));
        }

        if (api.error.type === 'network' || api.error.type === 'maintenance') {
          dispatch(errorSet({}));
        }

        try {
          const softError = response && response.data && response.data.error ? response.data.error : null;
          const cbError = error ? error : softError ? softError : null;
          cb(cbError, response);
        }
        catch (error) {
          console.error(error);
          dispatch(errorSet({
            errorType: 'api',
            reason: error.response
              ? [
                  error.response.status + ': ' + error.response.statusText,
                  error.response.data.message,
                  error.response.data.data
                ].filter(v => v).join('\n')
              : 'Unknown',
            data: error.response ? error.response.data : error
          }));
        }
      }
    );
  };
}



export function subscribe(args, cb = () => {}) {
  return (dispatch, getState) => {
    const { route, routeArgs } = args;

    if (Object.keys(routeArgs).length) {
      // We allow to have multiple subscriptions.
      // Then we access subscriptions using `route` (`this.props.api.subscriptions[route]`).
      // If we subcribe 2 times with different routeArgs, only the first subscription will be running and retrieved when using `this.props.api.subscriptions[route]`.
      // This is a case to handle. For now, we just throw an error.
      throw Error(`routeArgs (${JSON.stringify(routeArgs)}) is defined for ${route} but isn't supported!`);
    }

    const state = getState();
    if (state.api.subscriptions[route]) {
      console.error(`Subscription has been done yet for ${route}`);
    }

    // Add subscription route
    dispatch(subscriptionAdd(route));

    // Prepare request
    const requestObj = new Request({
      baseUrl: `${process.env.HTTP_SCHEME}://${process.env.API_HOST}`,
      auth: state.app.sessionAuth
    });

    // Do request
    const autoUpdate = (firstRequest) => {

      // If the tab is not visible since 1 minute, we don't load the data and retry every second
      const state = getState();
      if (!state.app.pageIsVisible && state.app.pageVisibilityLastTimeChanged < Date.now() - 60 * 1000) {
        subscriptionsTimers[route] = setTimeout(autoUpdate.bind(this, false), 1 * 1000);
        return;
      }

      dispatch(request(
        { ...args, requestObj, method: 'get', spinner: firstRequest },
        (error, response) => {

          // if the route is no more in subscriptions, because we unsubscribed it, we ignore it
          const state = getState();
          if (!state.api.subscriptions[route]) {
            cb(null, null);
            return;
          }

          if (!error) {
            dispatch(subscriptionReplace(route, response));
            subscriptionsTimers[route] = setTimeout(autoUpdate.bind(this, false), args.refreshInterval || 2 * 1000);
          }
          cb(error, error ? null : response.data);
        }
      ));
    };

    autoUpdate(!(args.spinner === false));
  };
}


export function unsubscribe({ route }) {
  return (dispatch, getState) => {
    dispatch(subscriptionRemove(route));

    const state = getState();
    if (!state.api.subscriptions[route]) {
      clearTimeout(subscriptionsTimers[route]);
      delete subscriptionsTimers[route];
    }
  };
}


export function get(args, cb = () => {}) {
  return (dispatch, getState) => {
    const state = getState();
    const { app } = state;
    const { route } = args;

    if (state.api.subscriptions[route + '(GET)']) {
      console.error(`Subscription has been done yet for ${route + '(GET)'}`);
    }

    dispatch(subscriptionAdd(route + '(GET)'));

    // Prepare request
    const requestObj = new Request({
      baseUrl: `${process.env.HTTP_SCHEME}://${process.env.API_HOST}`,
      auth: app.sessionAuth
    });

    // Do request
    dispatch(request(
      { spinner: true, ...args, requestObj, method: 'get' },
      (error, response) => {
        dispatch(subscriptionUpdate(route + '(GET)', 'loaded', response ? response.data : null));
        dispatch(subscriptionRemove(route + '(GET)'));
        cb(error, error ? null : response.data);
      }
    ));
  };
}


export function put(args, cb = () => {}) {
  return (dispatch, getState) => {
    const state = getState();
    const { app } = state;
    const { route, routeArgs } = args;

    // TODO: check if subscribed yet

    // Update subscription status and data
    dispatch(subscriptionUpdate(route, 'loading', routeArgs));

    // Prepare request
    const requestObj = new Request({
      baseUrl: `${process.env.HTTP_SCHEME}://${process.env.API_HOST}`,
      auth: app.sessionAuth
    });

    // Do request
    dispatch(request(
      { spinner: true, ...args, requestObj, method: 'put' },
      (error, response) => {
        dispatch(subscriptionUpdate(route, 'loaded', response ? response.data : null));
        cb(error, error ? null : response.data);
      }
    ));
  };
}


export function patch(args, cb = () => {}) {
  return (dispatch, getState) => {
    const state = getState();
    const { app } = state;
    const { route, routeArgs } = args;

    // TODO: check if subscribed yet

    // Update subscription status and data
    dispatch(subscriptionUpdate(route, 'loading', routeArgs));

    // Prepare request
    const requestObj = new Request({
      baseUrl: `${process.env.HTTP_SCHEME}://${process.env.API_HOST}`,
      auth: app.sessionAuth
    });

    // Do request
    dispatch(request(
      { spinner: true, ...args, requestObj, method: 'patch' },
      (error, response) => {
        dispatch(subscriptionUpdate(route, 'loaded', response ? response.data : null));
        cb(error, error ? null : response.data);
      }
    ));
  };
}


export function post(args, cb = () => {}) {
  return (dispatch, getState) => {
    const state = getState();
    const { app } = state;
    const { route } = args;

    if (state.api.subscriptions[route + '(POST)']) {
      console.error(`Subscription has been done yet for ${route + '(POST)'}`);
    }

    // Add subscription route
    dispatch(subscriptionAdd(route + '(POST)'));

    // Prepare request
    const requestObj = new Request({
      baseUrl: `${process.env.HTTP_SCHEME}://${process.env.API_HOST}`,
      auth: app.sessionAuth
    });

    // Do request
    dispatch(request(
      { spinner: true, ...args, requestObj, method: 'post' },
      (error, response) => {
        dispatch(subscriptionUpdate(route + '(POST)', 'loaded', response ? response.data : null));
        dispatch(subscriptionRemove(route + '(POST)'));
        cb(error, error ? null : response.data);
      }
    ));
  };
}


export function del(args, cb = () => {}) {
  return (dispatch, getState) => {
    const state = getState();
    const { app } = state;
    const { route } = args;

    if (state.api.subscriptions[route + '(DELETE)']) {
      console.error(`Subscription has been done yet for ${route + '(DELETE)'}`);
    }

    // Add subscription route
    dispatch(subscriptionAdd(route + '(DELETE)'));

    // Prepare request
    const requestObj = new Request({
      baseUrl: `${process.env.HTTP_SCHEME}://${process.env.API_HOST}`,
      auth: app.sessionAuth
    });

    // Do request
    dispatch(request(
      { spinner: true, ...args, requestObj, method: 'delete' },
      (error, response) => {
        dispatch(subscriptionUpdate(route + '(DELETE)', 'loaded', response ? response.data : null));
        dispatch(subscriptionRemove(route + '(DELETE)'));
        cb(error, error ? null : response.data);
      }
    ));
  };
}


export function requestNew(args, cb) {
  return (dispatch, getState) => {
    const { api } = getState();
    const handleErrorAutomatically = args.handleErrorAutomatically === undefined ? true : args.handleErrorAutomatically;

    // If an error has ocurred, we don't execute any other request
    if (api.error.type === 'server') {
      return;
    }

    if (args.spinner !== false) {
      dispatch(subscriptionLoadingInc());
    }

    const cbExec = (err, res) => {
      try {
        cb(err, res);
      }
      catch (err) {
        console.error(err);
        dispatch(errorSet({
          errorType: 'api',
          reason: err.response
            ? [
                err.response.status + ': ' + err.response.statusText,
                err.response.data.message,
                err.response.data.data
              ].filter(v => v).join('\n')
            : 'Unknown',
          data: err.response ? err.response.data : err
        }));
      }
    };

    const handleError = error => {
      if (args.spinner !== false) {
        dispatch(subscriptionLoadingDec());
      }

      // When we quit the page, requests are aborted. We ignore the error.
      // Note: it seems that ECONNABORTED is triggered on timeout too: https://github.com/axios/axios/issues/1543
      if (error && error.code === 'ECONNABORTED') {
        return;
      }
      else if (error && (error.toString() === 'Error: Network Error' || (error.response && error.response.status === 503))) {
        // If the network seems not working, or a 503 error (maintenance mode), we will try again
        error.response && error.response.status === 503
          ? console.warn(error)
          : console.error(error);
        dispatch(errorSet({
          errorType: error.response && error.response.status === 503 ? 'maintenance' : 'network'
        }));
        setTimeout(
          () => { dispatch(requestNew(args, cb)); },
          2 * 1000
        );
        return;
      }
      else if (error && (handleErrorAutomatically || (error.response && error.response.status >= 500))) {
        // If an error occured (HTTP code !== 2xx)
        console.warn(error, error.response);
        dispatch(errorSet({
          errorType: 'server',
          reason: error.response
            ? [
                error.response.status + ': ' + error.response.statusText,
                error.response.data.message,
                error.response.data.data
              ].filter(v => v).join('\n')
            : 'Unknown',
          data: error.response.data
        }));
        return;
      }

      cbExec(error, null);
    };

    try {
      axios(args)
        .then(result => {
          if (result.data && result.data.error && handleErrorAutomatically) {
            // If the API returned a soft error
            dispatch(errorSet({ errorType: 'api', reason: result.data.error }));
          }

          // If we had a previous network error or a maintenance, we remove it
          if (api.error.type === 'network' || api.error.type === 'maintenance') {
            dispatch(errorSet({}));
          }

          cbExec(null, result.data);
          if (args.spinner !== false) {
            dispatch(subscriptionLoadingDec());
          }
        })
        .catch(error => handleError(error));
    }
    catch (error) {
      handleError(error);
    }
  };
}
