import logo from 'images/servicesLogos/prometheus.svg';
import logoOpenGraph from 'images/servicesLogos/openGraphVersions/prometheus.png';

import growth from 'images/landings/icons/growth.svg';
import startup from 'images/landings/icons/startup.svg';
import megaphone from 'images/landings/icons/megaphone.svg';
import server from 'images/landings/icons/server.svg';
import shield from 'images/landings/icons/shield.svg';
import strategy from 'images/landings/icons/strategy.svg';

import checked from 'images/landings/icons/checked.svg';

import prometheusGraph from 'images/landings/screenshots/prometheus/prometheusGraph.png';
import prometheusAlerts from 'images/landings/screenshots/prometheus/prometheusAlerts.png';

const backgroundColor = '#222';
const button = {
  color: '#fff',
  backgroundColor: '#e6522c'
};

export default ({ t = () => [] }) => ({
  id: 'prometheus',
  name: 'Prometheus',
  nameUrl: 'Prometheus',
  title: t('services/prometheus:title'),
  logo,
  logoOpenGraph,
  button,
  backgroundColor,

  header: {
    title: 'Prometheus',
    description: t('services/prometheus:description'),
    images: [
      { image: prometheusGraph },
      { image: prometheusAlerts }
    ]
  },

  benefits: {
    items: [ growth, startup, megaphone, server, shield, strategy ].map((icon, index) => ({
      icon,
      ...t('services/prometheus:benefits')[index]
    }))
  },

  also: {
    color: button.backgroundColor,
    items: t('services/prometheus:also.items')
      .map(content => ({ icon: checked, ...content }))
  },

  pricing: {
    ...t('services/prometheus:pricing'),
    buttonBackgroundColor: button.backgroundColor,
    buttonColor: button.color,
    sentencesIconColor: button.backgroundColor,
    includes: t('services/prometheus:pricing.includes')
      .map(description => ({ icon: checked, description })),
    fields: {
      vCpus: vCpus => [ vCpus, vCpus === 1 ? t('mainPage.pricing.subLegends.vCpu') : t('mainPage.pricing.subLegends.vCpus') ],
      ram: ram => [ ram < 1024 * 1024 * 1024 ? `${Math.round(ram / (1024 * 1024))} ${t('units.MB')}` : `${Math.round(ram / (1024 * 1024 * 1024))} ${t('units.GB')}`, t('mainPage.pricing.subLegends.ram') ],
      hdd: hdd => [ hdd < 1024 * 1024 * 1024 ? `${Math.round(hdd / (1024 * 1024))} ${t('units.MB')}` : `${Math.round(hdd / (1024 * 1024 * 1024))} ${t('units.GB')}`, t('mainPage.pricing.subLegends.ssdDisk') ],
      retentionMaxDays: v => [
        v === 365
          ? `1 ${t('units.year')}`
          : v >= 365
            ? `${v / 365} ${t('units.years')}`
            : `${v} ${t('units.days')}`,
        t('services/prometheus:pricing.subLegends.retentionMaxDays')
      ],
    }
  },

  recall: {
    description: t('services/prometheus:recall')
  },

  faq: {
    items: t('services/prometheus:faq.items')
  }
});
