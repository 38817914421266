import React from 'react';
import PropTypes from 'prop-types';

import { Card, Slider, Divider, Col, Tooltip } from 'antd';
// import { CloseCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { CloseCircleOutlined } from '@ant-design/icons';
import ServiceTitle from 'components/ServiceTitle';

import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

// import styles from './ServicePackCreate.css';

class ServicePackCreate extends React.Component {
  static propTypes = {
    service: PropTypes.object.isRequired,
    instancesStore: PropTypes.array.isRequired,
    currency: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
  };

  state = {
    instanceStoreIndexSelected: 0,
    instancesCount: 0
  };


  componentDidMount() {
    const { service, instancesStore } = this.props;
    this.setState(
      {
        instanceStoreIndexSelected: instancesStore.findIndex(i => i.name === service.instance.default),
        instancesCount: service.instance.count.default
      },
      () => this.configurationUpdate()
    );
  }


  configurationUpdate() {
    const { instancesStore } = this.props;

    this.props.onChange({
      instancesCount: this.state.instancesCount,
      instanceStoreId: instancesStore[this.state.instanceStoreIndexSelected].id,
    });
  }


  renderInstanceRemove(index) {
    const { service } = this.props;

    if (index < service.instance.count.min || index >= this.state.instancesCount) {
      return null;
    }

    return (
      <CloseCircleOutlined
        style={{ position: 'absolute', top: -5, right: -5, cursor: 'pointer' }}
        onClick={() =>
          this.setState(
            ({ instancesCount }) => ({ instancesCount: instancesCount - 1 }),
            () => this.configurationUpdate()
          )
        }
      />
    );
  }


  renderPrice(instance) {
    if (!instance.prices) {
      return null;
    }

    const { currency } = this.props;
    const price = instance.prices.find(b => b.currency === currency);
    const amountPerMonth = price.amount;
    const priceFormatedPerMonth = new Intl
      .NumberFormat(
        undefined,
        {
          style: 'currency',
          currency,
          minimumFractionDigits: amountPerMonth === Math.round(amountPerMonth) ? 0 : 2
        }
      )
      .format(amountPerMonth);


    const amountPerHour = amountPerMonth / (30 * 24);
    const priceFormatedPerHour = new Intl
      .NumberFormat(
        undefined,
        {
          style: 'currency',
          currency,
          minimumFractionDigits: 4
        }
      )
      .format(amountPerHour);

    const diviverStyle = { margin: 12 };

    return (
      <>
        <Divider style={diviverStyle} />
        {priceFormatedPerMonth} per month
        <br />
        <span style={{ fontSize: 13 }}>(or {priceFormatedPerHour}/hour)</span>
      </>
    );
  }


  renderInstance(instance, index) {
    if (this.state.instancesCount < index) {
      return null;
    }

    const { t } = this.props;
    const { ram, hdd } = instance.configuration;
    const ramFormated = ram < 1024 ? `${ram} ${t('units.MB')}` : `${Math.round(ram / 1024)} ${t('units.GB')}`;
    const hddFormated = hdd < 1024 ? `${hdd} ${t('units.MB')}` : `${Math.round(hdd / 1024)} ${t('units.GB')}`;

    const diviverStyle = { margin: 12 };

    return (
      <Card
        title={`Instance ${index + 1}`}
        style={{ margin: 5, textAlign: 'center', cursor: 'default' }}
        type="inner"
        hoverable
      >
        {this.renderInstanceRemove(index)}

        Dedicated instance
        <Divider dashed style={diviverStyle} />

        {instance.configuration.vCpus > 1 ? `${instance.configuration.vCpus} vCPUs` : `${instance.configuration.vCpus} vCPU`}
        <Divider dashed style={diviverStyle} />

        {ramFormated} RAM
        <Divider dashed style={diviverStyle} />

        {hddFormated} SSD DISK
        <Divider dashed style={diviverStyle} />

        Dedicated IPv4
        <Divider dashed style={diviverStyle} />

        Backup every 24 hours

        {this.renderPrice(instance)}

        {/* {
          index >= this.state.instancesCount ?
            (
              <div
                onClick={() =>
                  this.setState(
                    ({ instancesCount }) => ({ instancesCount: instancesCount + 1 }),
                    () => this.configurationUpdate()
                  )
                }
                className={styles.coverAdd}
              >
                <div style={{ textAlign: 'center', fontSize: 16 }}>
                  <PlusCircleOutlined />
                  <br />
                  Add an instance
                </div>
              </div>
            )
          :
            null
        } */}
      </Card>
    );
  }


  instanceSelect(instanceStoreIndexSelected) {
    if (this.instanceCanBeSelected(instanceStoreIndexSelected)) {
      this.setState({ instanceStoreIndexSelected }, () => this.configurationUpdate());
    }
  }


  instanceCanBeSelected(index) {
    const { instancesStore, service } = this.props;
    const instance = instancesStore[index];

    if (service.requirements && service.requirements.instance) {
      for (const key in service.requirements.instance) {
        const value = service.requirements.instance[key];
        if (value > instance.configuration[key]) {
          return false;
        }
      }
    }

    return true;
  }


  renderConfiguration() {
    const { instancesStore, service } = this.props;

    const marks = instancesStore
      .map((instanceStore, index) => {
        if (this.instanceCanBeSelected(index)) {
          return { [index]: instanceStore.name };
        }
        else {
          return {
            [index]: (
              <Tooltip title={`This configuration is not powerful enough to support ${service.name}`}>
                <span style={{ textDecoration: 'line-through' }}>{instanceStore.name}</span>
              </Tooltip>
            )
          };
        }
      })
      .reduce((acc, value) => ({ ...acc, ...value }), {});

    return (
      <Slider
        marks={marks}
        included={false}
        value={this.state.instanceStoreIndexSelected}
        min={0}
        max={instancesStore.length - 1}
        tipFormatter={null}
        step={null}
        onChange={instanceStoreIndexSelected => this.instanceSelect(instanceStoreIndexSelected)}
        style={{ margin: '20px 30px 50px 30px' }}
      />
    );
  }


  renderCount() {
    const { instancesStore, service } = this.props;
    const instance = instancesStore[this.state.instanceStoreIndexSelected];
    return (
      <div style={{ overflow: 'hidden', margin: '20px 0px' }}> {/* Overflow to avoid a bug on display with billing sentences */}
        {Array(service.instance.count.max).fill(null).map((v, index) => (
          <Col key={index} md={24} lg={12} xl={6}>
            {this.renderInstance(instance, index)}
          </Col>
        ))}
      </div>
    );
  }


  render() {
    const { service } = this.props;

    return (
      <Card
        style={{ marginBottom: 16 }}
        title={( <ServiceTitle title={service.informations.title} name={service.name} /> )}
      >

        <h4>Instances configuration:</h4>
        {this.renderConfiguration()}

        <h4>Number of instances (between {service.instance.count.min} and {service.instance.count.max}):</h4>
        {this.renderCount()}
      </Card>
    );
  }
}

export default compose(
  withTranslation()
)(ServicePackCreate);