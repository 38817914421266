/*eslint no-unused-vars: ["error", { "varsIgnorePattern": "trash" }]*/

const initialState = {
	subscriptions: {},
	loadingCount: 0,
	error: {}
};

const api = (state = initialState, action) => {
	switch (action.type) {

	case 'API_SUBSCRIPTION_LOADING':
		return {
			...state,
			loadingCount: state.loadingCount + action.value
		};

	case 'API_SUBSCRIPTION_ADD':
		return {
			...state,

      // count is here to handle multiple subscriptions
      subscriptions: state.subscriptions[action.route]
        ? { ...state.subscriptions, [action.route]: { ...state.subscriptions[action.route], count: state.subscriptions[action.route].count + 1 } }
        : { ...state.subscriptions, [action.route]: { status: 'initialize', datas: null, count: 1 } }
		};

  case 'API_SUBSCRIPTION_UPDATE':
    return state.subscriptions[action.route]
      ? {
          ...state,
          subscriptions: {
            ...state.subscriptions,
            [action.route]: {
              ...state.subscriptions[action.route],
              status: action.status,
              datas: {
                ...state.subscriptions[action.route].datas,
                ...action.datas
              }
            }
          }
        }
      : state
    ;

	case 'API_SUBSCRIPTION_REPLACE': {
    const { etag } = action.response.headers;

    if (state.subscriptions[action.route] && state.subscriptions[action.route].etag === etag) {
      return state;
    }

		return {
			...state,
			subscriptions: {
				...state.subscriptions,
				[action.route]: {
          ...state.subscriptions[action.route],
          status: action.status,
          datas: action.response.data,
          etag
        }
			}
		};
	}

	case 'API_SUBSCRIPTION_REMOVE': {
    if (!state.subscriptions[action.route] || state.subscriptions[action.route].count === 1) {
      const { [action.route]:trash , ...subscriptions } = state.subscriptions;
      return {
        ...state,
        subscriptions
      };
    }
    else {
      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          [action.route]: {
            ...state.subscriptions[action.route],
            count: state.subscriptions[action.route].count - 1
          }
        },
      };
    }
	}

	case 'API_ERROR':
		return {
			...state,
			error: action.type ? { type: action.errorType, reason: action.reason, data: action.data } : false
		};

	default:
		return state;
	}
};


export default api;
