import logo from 'images/servicesLogos/mosquitto.svg';
import logoOpenGraph from 'images/servicesLogos/openGraphVersions/mosquitto.png';

import growth from 'images/landings/icons/growth.svg';
import startup from 'images/landings/icons/startup.svg';
import shield from 'images/landings/icons/shield.svg';
import key from 'images/landings/icons/key.svg';
import target from 'images/landings/icons/target.svg';
import strategy from 'images/landings/icons/strategy.svg';

import checked from 'images/landings/icons/checked.svg';

import imageMosquitto from 'images/landings/screenshots/mosquitto/mosquitto.png';

const backgroundColor = '#3C5280';
const button = {
  color: '#fff',
  backgroundColor: '#F3771C'
};

export default ({ t = () => [] }) => ({
  id: 'mosquitto',
  name: 'Mosquitto',
  nameUrl: 'Mosquitto',
  title: t('services/mosquitto:title'),
  logo,
  logoStyle: [
    {
      elementId: 'a',
      style: {
        fill: '#F3771C'
      }
    }
  ],
  logoOpenGraph,
  button,
  backgroundColor,

  header: {
    title: 'Mosquitto',
    description: t('services/mosquitto:description'),
    images: [
      { image: imageMosquitto }
    ]
  },


  explanation: {
    title: t('services/mosquitto:explanation.title'),
    content: t('services/mosquitto:explanation.content')
  },


  benefits: {
    items: [
      {
        icon: growth,
        ...t('services/mosquitto:benefits.business')
      },
      {
        icon: startup,
        ...t('services/mosquitto:benefits.performant')
      },
      {
        icon: shield,
        ...t('services/mosquitto:benefits.secured')
      },
      {
        icon: key,
        ...t('services/mosquitto:benefits.authentication')
      },
      {
        icon: target,
        ...t('services/mosquitto:benefits.acls')
      },
      {
        icon: strategy,
        ...t('services/mosquitto:benefits.updatable')
      }
    ]
  },

  also: {
    color: button.backgroundColor,
    items: t('services/mosquitto:also.items')
      .map(content => ({ icon: checked, ...content }))
  },

  pricing: {
    buttonBackgroundColor: button.backgroundColor,
    buttonColor: button.color,
    sentencesIconColor: button.backgroundColor,
    includes: t('services/mosquitto:pricing.includes')
      .map(description => ({ icon: checked, description })),
    fields: {
      vCpus: v => [ v, v > 1 ? t('mainPage.pricing.subLegends.vCpus') : t('mainPage.pricing.subLegends.vCpu') ],
      ram: ram => [ ram < 1024 * 1024 * 1024 ? `${Math.round(ram / (1024 * 1024))} ${t('units.MB')}` : `${Math.round(ram / (1024 * 1024 * 1024))} ${t('units.GB')}`, t('mainPage.pricing.subLegends.ram') ],
      maxConcurrentConnections: v => [ new Intl.NumberFormat(undefined).format(v), t('mainPage.pricing.subLegends.maxConcurrentConnections') ]
    }
  },

  recall: {
    description: t('services/mosquitto:recall')
  },

  faq: {
    items: t('services/mosquitto:faq.items')
  }
});