import React from 'react';
import PropTypes from 'prop-types';

class _default extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired
  };

  componentDidMount() {
    this.props.history.push('/notLogged/Login');
  }

  render() {
    return null;
  }
}

export default _default;
