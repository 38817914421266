import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { Steps as AntdSteps } from 'antd';

class Steps extends React.Component {
  static propTypes = {
    navigation: PropTypes.object.isRequired
  };

  render() {
    const { steps, stepSelected } = this.props.navigation;
    if (!steps.length) {
      return null;
    }

    return (
      <AntdSteps
        current={stepSelected}
        size="small"
        items={steps.map(({ title }) => ({ title }))}
      />
    );
  }
}

export default connect(
  state => ({ navigation: state.navigation })
)(Steps);
