import React from 'react';
import PropTypes from 'prop-types';

import MenuTop from 'components/MenuTop';
import Footer from 'components/layout/Footer';

import styles from './Layout.css';

class Layout extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    fullSize: PropTypes.bool,
    withCard: PropTypes.bool,
    style: PropTypes.object,
    menuType: PropTypes.string.isRequired,
    menuCentered: PropTypes.bool
  };

  render() {
    return (
      <div>
        <MenuTop
          type={this.props.menuType}
          centered={this.props.menuCentered}
          style={{ backgroundColor: '#4a75e2' }}
        />

        <div
          className={[
            styles.contentContainer,
            this.props.fullSize === true ? styles.contentContainerSizeFull : styles.contentContainerSizeLimited,
          ].join(' ')}
          style={this.props.style}
        >
          {
            this.props.withCard !== false
              ? (
                <div className={styles.contentCard}>
                  {this.props.children}
                </div>
              )
              : this.props.children
          }
        </div>

        <Footer backgroundColor="#4a75e2" />
      </div>
    );
  }
}

export default Layout;