import * as apiActions from 'redux/actions/api';
import Cookies from 'modules/Cookies';
import countries from 'contents/countries';

export function languageSet(language) {
  return {
    type: 'APP_LANGUAGE_SET',
    language
  };
}

export function _regionSet(region) {
  return {
    type: 'APP_REGION_SET',
    region
  };
}

export function regionSet(region = '', force = false) {
  return (dispatch, getState) => {
    // Do not set region if set yet except if forced
    if (getState().app.region !== undefined && !force) {
      return getState().app.region;
    }

    const regionFound = Object.keys(countries.regionsSupported).concat('').indexOf(region.toUpperCase()) !== -1;
    if (!regionFound) {
      return '';
    }
    dispatch(_regionSet(region.toLowerCase()));
    return region.toLowerCase();
  };
}

export function loggedSet(isLogged) {
  return {
    type: 'APP_LOGGED_SET',
    isLogged: isLogged ? true : false
  };
}

export function networkStatus(working) {
  return {
    type: 'APP_NETWORK_STATUS_SET',
    working
  };
}

export function intercomAvailabilitySet(availability) {
  return {
    type: 'APP_INTERCOM_AVAILABILITY_SET',
    availability
  };
}

export function apiError(error) {
  return {
    type: 'APP_API_ERROR_SET',
    error: error || false
  };
}

export function currencySet(currency) {
  // Set currency to cookies so the dashboard will be able to get the data too
  const cookies = new Cookies();
  cookies.set({
    name: 'currency',
    value: currency,
    maxAge: 10 * 12 * 30 * 24 * 60 * 60,
    global: true
  });

  return {
    type: 'APP_CURRENCY_SET',
    currency
  };
}

export function ipInformationsSet(ipInformations) {
  return {
    type: 'APP_IP_INFORMATIONS_SET',
    ipInformations
  };
}

export function pageVisibilitySet(visible) {
  return {
    type: 'APP_PAGE_VISIBILITY_SET',
    visible
  };
}

export function sessionSet(sessionAuth = {}) {
  if (document.location.hostname.split('.').length <= 2) {
    throw Error('Security flaw! "document.location.hostname" has to be "www.yyy.zzz" to avoid having cookies on "yyy.zzz" available for all subdomains!');
  }

  // Handle cookie
  const cookies = new Cookies();

  // Clear cookies first
  // Useful so we clear cookie even if global is not the same value
  cookies.delete('sessionAuth');
  cookies.delete('isLogged');

  if (!sessionAuth || !sessionAuth.username) {
    if (window.Intercom) {
      window.Intercom('shutdown');
    }
  }
  else {
    // Set cookie
    cookies.set({
      name: 'sessionAuth',
      value: sessionAuth,
      maxAge: sessionAuth.cookieMaxAge,
      global: sessionAuth.global // useful for login as through backoffice and sign up from showcase
    });

    // isLogged is useful for landing page (declared as "global")
    cookies.set({
      name: 'isLogged',
      value: true,
      maxAge: sessionAuth.cookieMaxAge,
      global: true
    });
  }

  return {
    type: 'APP_SESSION_SET',
    sessionAuth: sessionAuth
  };
}

export function menuLeftSet(content = []) {
  return {
    type: 'APP_MENU_LEFT_SET',
    content
  };
}


// Middlewares

export function languageInit({ language, forced }) {
  return (dispatch, getState) => {
    const cookies = new Cookies();

    // If a language has been passed and forced
    if (language && forced) {
      if (process.env.SUPPORTED_LANGUAGE.indexOf(language) !== -1) {
        cookies.set({
          name: 'language',
          value: language,
          maxAge: 10 * 12 * 30 * 24 * 60 * 60,
          global: true
        });

        dispatch(languageSet(language));
        return language;
      }
    }

    // Check if we have a language in cookies yet
    const { language: languageFromCookies } = cookies.getAll();
    language = languageFromCookies || language;

    if (process.env.SUPPORTED_LANGUAGE.indexOf(language) !== -1) {
      dispatch(languageSet(language));
      return language;
    }

    // Else, detect the language
    return dispatch(languageSetAuto());
  };
}

export function regionInit({ region, forced }) {
  return (dispatch, getState) => {
    const regionFound = Object.keys(countries.regionsSupported).concat('').indexOf(region.toUpperCase()) !== -1;
    const cookies = new Cookies();

    if (forced && regionFound) {
      cookies.set({
        name: 'region',
        value: region,
        maxAge: 10 * 12 * 30 * 24 * 60 * 60,
        global: true
      });

      dispatch(_regionSet(region));
      return region;
    }

    // Check if we have a region in cookies yet
    const { region: regionFromCookies } = cookies.getAll();
    if (regionFromCookies !== undefined) {
      const regionFromCookiesFound = Object.keys(countries.regionsSupported).concat('').indexOf(regionFromCookies.toUpperCase()) !== -1;
      if (regionFromCookiesFound) {
        dispatch(_regionSet(regionFromCookies));
        return regionFromCookies;
      }
      else {
        cookies.delete('region');
      }
    }

    // Check the passed region
    if (region && regionFound) {
      dispatch(_regionSet(region));
      return region;
    }

    // Else, detect the region
    const languages = navigator.languages || [ navigator.language ];
    const regionSupportedFound = languages
      .filter(entry => /^[a-z]{2}-[A-Z]{2}$/.test(entry))
      .map(entry => entry.replace(/^[a-z]{2}-/, ''))
      .map(regionFromBrowser => Object.keys(countries.regionsSupported)
        .find(regionGroup => countries.regionsSupported[regionGroup].indexOf(regionFromBrowser) !== -1)
      )
      .filter(regionGroup => regionGroup)
      .map(region => region.toLowerCase())
      .shift();

    if (regionSupportedFound) {
      dispatch(_regionSet(regionSupportedFound));
      return regionSupportedFound;
    }

    // In last case, we don't set the region
    dispatch(_regionSet(''));
    return '';
  };
}


export function initialize({ withSession = false }) {
  return (dispatch, getState) => {
    const { initialized } = getState().app;
    if (initialized) {
      return;
    }

    // Handle case where the tab is not visibled to stop loading requests for nothing
    document.addEventListener(
      'visibilitychange',
      () => dispatch(pageVisibilitySet(!document.hidden))
    );

    // Get client information
    dispatch(apiActions.get(
      {
        route: '/informations',
        routeArgs: {},
        spinner: false
      },
      (error, informations) => {
        if (error) {
          return;
        }

        const cookies = new Cookies();
        const { currency } = cookies.getAll();
        if (informations.currency && !currency) {
          dispatch(currencySet(informations.currency));
        }

        dispatch(ipInformationsSet(informations));
      }
    ));

    dispatch(languageInit({}));

    if (withSession) {
      dispatch(
        sessionGet(() => {
          dispatch({
            type: 'APP_INITIALIZE'
          });
        })
      );
    }
    else {
      dispatch({
        type: 'APP_INITIALIZE'
      });
    }
  };
}


export function sessionGet(cb) {
  return (dispatch, getState) => {
    const cookies = new Cookies();
    const { sessionAuth } = cookies.getAll();

    // If session cookie is set and is global (multi domains), we set it to not be global anymore.
    // This is useful when a user subscribes on showcase website. In that case, cookie is global to be available on dashboard too.
    // When he goes to dashboard, we arrive here and re create the cookie just for dashboard website and not for showcase.

    if (sessionAuth && sessionAuth.username && sessionAuth.global) {
      sessionAuth.global = false;
      dispatch(sessionSet({ ...sessionAuth, global: false }));
    }

    if (sessionAuth && sessionAuth.username) {
      dispatch(apiActions.get(
        {
          handleErrorAutomatically: false,
          route: '/tokens/this',
          routeArgs: {},
          options: {
            auth: {
              username: sessionAuth.username,
              password: sessionAuth.password
            }
          }
        },
        (error, result) => {
          if (error) {
            dispatch(sessionSet(false));
            cb();
            return;
          }

          dispatch(sessionSet(Object.assign({}, sessionAuth, result)));
          cb();
        }
      ));
    }
    else {
      dispatch(sessionSet(false));
      cb();
    }
  };
}

export function sessionSetDispatch(sessionAuth) {
  return (dispatch, getState) => {
    dispatch(sessionSet(sessionAuth));
  };
}


export function languageSetAuto() {
  return (dispatch, getState) => {
    // Note: navigator.languages do no exists on IE11, so we use navigator.language
    const languages = navigator.languages || [ navigator.language ];
    for (const languageString of languages) {
      const [ language ] = languageString.toLowerCase().split('-');
      if (process.env.SUPPORTED_LANGUAGE.indexOf(language) !== -1) {
        dispatch(languageSet(language));
        return language;
      }
    }

    dispatch(languageSet('en'));
    return 'en';
  };
}


export function onRouteChange() {
  return (dispatch, getState) => {
    const { currency, language, isLogged } = getState().app;

    // The goal here is to get data from cookies regularly (when route changes) to update the store.
    // Like when a user changed its currency on a tab, on the other it will be updated too when he will move to another page.
    const cookies = new Cookies();
    const cookiesData = cookies.getAll();

    if (cookiesData.currency && cookiesData.currency !== currency) {
      dispatch(currencySet(cookiesData.currency));
    }

    if (cookiesData.language && cookiesData.language !== language) {
      if (process.env.SUPPORTED_LANGUAGE.indexOf(cookiesData.language) !== -1) {
        dispatch(languageSet(cookiesData.language));
      }
    }

    if (cookiesData.isLogged !== isLogged) {
      dispatch(loggedSet(cookiesData.isLogged));
    }
  };
}