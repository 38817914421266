import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash.isequal';

import { Link } from 'react-router-dom';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as apiActions from 'redux/actions/api';

import { Card, Form, Button, Table, message } from 'antd';
import ModalChangeEmail from 'components/ModalChangeEmail';
import ModalChangePassword from 'components/ModalChangePassword';
import ModalTotp from 'components/ModalTotp';
import FormItems from 'components/FormItems';

import styles from './_default.css';

class _default extends React.Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    apiActions: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  state = {
    me: {},
    formIsValid: false,
    showErrors: false
  };

  componentDidMount() {
    this._mounted = true;

    const route = '/dashboard/account';
    const me = this.props.api.subscriptions[route].datas;

    // Go back to organizations if we don't have the permission to see this
    if (me.permissions.indexOf('userUpdate') === -1) {
      this.props.history.replace('/account/organizations/');
      return;
    }

    this.setState({ me });


    const routeTokens = '/dashboard/account/tokens';
    this.props.apiActions.subscribe({
      route: routeTokens,
      routeArgs: {},
      handleErrorAutomatically: false
    });
  }

  componentDidUpdate(prevProps) {
    const route = '/dashboard/account';
    const mePrevious = prevProps.api.subscriptions[route].datas;
    const meCurrent = this.props.api.subscriptions[route].datas;

    if (!isEqual(mePrevious, meCurrent)) {
      this.setState({ me: meCurrent });
    }
  }

  componentWillUnmount() {
    const routeTokens = '/dashboard/account/tokens';
    this.props.apiActions.unsubscribe({ route: routeTokens });
    this._mounted = false;
  }

  handleSubmit = e => {
    e.preventDefault();
    this.setState({ showErrors: true });
    if (!this.state.formIsValid) {
      return false;
    }

    const route = '/dashboard/account';
    const { name, sshKeyPublic, emailsInformationalSubscribed } = this.state.me;
    this.props.apiActions.patch(
      {
        route,
        routeArgs: { name, sshKeyPublic, emailsInformationalSubscribed }
      },
      (error, result) => !error && this._mounted && message.success('Your profile has been successfully updated') && this.props.history.push('/')
    );
  };


  render() {
    const entries = [
      {
        label: 'Fullname',
        name: 'name',
        required: true,
        input: {
          type: 'text',
          placeholder: 'John Doe',
          hasError: (name, value, form) => value.length < 1 || value.length > 128 ? 'Should be between 1 and 128 characters' : '',
          autoComplete: 'name'
        }
      },
      {
        label: 'Email',
        name: 'email',
        input: {
          type: 'element',
          entry: ( <ModalChangeEmail email={this.state.me.email} /> )
        }
      },
      {
        label: 'Password',
        name: 'password',
        input: {
          type: 'element',
          entry: ( <ModalChangePassword email={this.state.me.email} /> )
        }
      },
      {
        label: 'Two-factor authentication',
        name: '2fa',
        input: {
          type: 'element',
          entry: ( <ModalTotp email={this.state.me.email} emailValidated={this.state.me.emailValidated} totpEnabled={this.state.me.totpEnabled} /> )
        }
      },
      {
        label: 'SSH public key',
        name: 'sshKeyPublic',
        help: 'Useful to automatically add it to services that require SSH public keys.',
        input: {
          type: 'text',
          placeholder: 'ssh-rsa AAAA...',
          hasError: (name, value, form) => value.length > 4096 ? 'Should be less than 4096 characters' : '',
        }
      },
      {
        label: 'Informational emails',
        name: 'emailsInformationalSubscribed',
        help: 'Receive very occasional emails like coupons or new services information (no spams, promise!).',
        input: {
          type: 'switch',
          entries: [ 'No', 'Yes' ]
        }
      }
    ];

    const routeTokens = '/dashboard/account/tokens';
    const subscription = this.props.api.subscriptions[routeTokens];
    const display = subscription && subscription.status !== 'initialize';
    if (!display) return null;

    // Stop if we don't have the permission to see this
    const { permissions } = this.props.api.subscriptions['/dashboard/account'].datas;
    if (permissions.indexOf('userUpdate') === -1) {
      return null;
    }


    const tokens = this.props.api.subscriptions[routeTokens].datas;

    return (
      <div className={styles.container}>
        <Card key="account" title="Your Account">
          <Form>
            <FormItems
              entries={entries}
              onStatusChange={formIsValid => this.setState({ formIsValid })}
              datas={this.state.me}
              onUpdate={me => this.setState({ me })}
              showErrors={this.state.showErrors}
              sizes={{ xs: 24, sm: 5 }}
            />

            <Form.Item style={{ textAlign: 'right', marginBottom: 0 }}>
              <Link to={'/'}>
                <Button>Cancel</Button>
              </Link>

              <Button
                type="primary"
                htmlType="submit"
                onClick={this.handleSubmit}
                style={{ marginLeft: 8 }}
              >
                Save
              </Button>
            </Form.Item>
          </Form>
        </Card>

        {tokens && tokens.length
          ? (
            <Card key="tokens" style={{ marginTop: 16 }} title="Access tokens" styles={{ body: { overflow: 'scroll' }}}>
              <Table dataSource={tokens} rowKey="id">
                <Table.Column
                  title="Token"
                  dataIndex="token"
                />

                <Table.Column
                  title="Last used"
                  key="dateLastSeen"
                  render={({ dateLastSeen }) => dateLastSeen
                    ? new Intl.DateTimeFormat(
                        undefined,
                        { hour: 'numeric', minute: 'numeric', second: 'numeric', year: 'numeric', month: 'numeric', day: 'numeric' }
                      )
                      .format(new Date(dateLastSeen))
                    : null
                  }
                />


                <Table.Column
                  title="Valid until"
                  render={({ dateLastSeen, validityMiliSeconds }) =>
                    dateLastSeen && validityMiliSeconds
                      ? new Intl.DateTimeFormat(
                          undefined,
                          { hour: 'numeric', minute: 'numeric', second: 'numeric', year: 'numeric', month: 'numeric', day: 'numeric' }
                        )
                        .format(new Date(new Date(dateLastSeen).getTime() + validityMiliSeconds))
                      : null
                  }
                />

                <Table.Column
                  title="Last IP"
                  dataIndex="ipLastUsed"
                />
              </Table>
            </Card>
          )
          : null}
      </div>
    );
  }
}

export default connect(
  ({ api }) => ({ api }),
  dispatch => ({
    apiActions: bindActionCreators(apiActions, dispatch)
  })
)(_default);
