import React from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as appActions from 'redux/actions/app';

import Layout from 'components/layout/Layout';

class _container extends React.Component {
  static propTypes = {
    appActions: PropTypes.object.isRequired,
    children: PropTypes.object.isRequired
  };

  componentDidMount() {
    // Logout the current user (if connected).
    // Required else SingleUseTokenLogin will not work when user is still logged on another account (typically with Heroku when starting 2 add-ons and trying to login to one and then to the other one)
    this.props.appActions.sessionSetDispatch();
  }

  render() {
    return (
      <Layout menuType="others" menuCentered fullSize withCard={false}>
        <div style={{ width: 550, maxWidth: '100%', margin: '60px auto 30px auto', padding: '0 5px' }}>
          {this.props.children}
        </div>
      </Layout>
    );
  }
}

export default connect(
  () => ({}),
  dispatch => ({
    appActions: bindActionCreators(appActions, dispatch)
  })
)(_container);
