import logo from 'images/servicesLogos/directus.svg';
import logoOpenGraph from 'images/servicesLogos/openGraphVersions/directus.png';

import growth from 'images/landings/icons/growth.svg';
import startup from 'images/landings/icons/startup.svg';
import medal from 'images/landings/icons/medal.svg';
import packageIcon from 'images/landings/icons/package.svg';
import shield from 'images/landings/icons/shield.svg';
import strategy from 'images/landings/icons/strategy.svg';

import checked from 'images/landings/icons/checked.svg';

import imageDataModels from 'images/landings/screenshots/directus/dataModels.png';
import imageDataModelCreation from 'images/landings/screenshots/directus/dataModelCreation.png';

const backgroundColor = '#64f';
const button = {
  color: '#fff',
  backgroundColor: '#00C897'
};

export default ({ t = () => [] }) => ({
  id: 'directus',
  name: 'Directus',
  nameUrl: 'Directus',
  title: t('services/directus:title'),
  logo,
  logoOpenGraph,
  button,
  backgroundColor,

  header: {
    title: 'Directus',
    description: t('services/directus:description'),
    images: [
      { image: imageDataModels },
      { image: imageDataModelCreation }
    ]
  },

  benefits: {
    items: [ growth, startup, medal, packageIcon, shield, strategy ].map((icon, index) => ({
      icon,
      ...t('services/directus:benefits')[index]
    }))
  },

  also: {
    color: button.backgroundColor,
    items: t('services/directus:also.items')
      .map(content => ({ icon: checked, ...content }))
  },

  pricing: {
    buttonBackgroundColor: button.backgroundColor,
    buttonColor: button.color,
    sentencesIconColor: button.backgroundColor,
    includes: t('services/directus:pricing.includes')
      .map(description => ({ icon: checked, description })),
    fields: {
      vCpus: vCpus => [ vCpus, vCpus === 1 ? t('mainPage.pricing.subLegends.vCpu') : t('mainPage.pricing.subLegends.vCpus') ],
      ram: ram => [ ram < 1024 * 1024 * 1024 ? `${Math.round(ram / (1024 * 1024))} ${t('units.MB')}` : `${Math.round(ram / (1024 * 1024 * 1024))} ${t('units.GB')}`, t('mainPage.pricing.subLegends.ram') ],
      hdd: hdd => [ hdd < 1024 * 1024 * 1024 ? `${Math.round(hdd / (1024 * 1024))} ${t('units.MB')}` : `${Math.round(hdd / (1024 * 1024 * 1024))} ${t('units.GB')}`, t('mainPage.pricing.subLegends.disk') ],
      maxConcurrentConnections: v => [
        new Intl.NumberFormat(undefined).format(v),
        t('services/directus:pricing.subLegends.maxConcurrentConnections')
      ]
    }
  },


  recall: {
    description: t('services/directus:recall')
  },

  faq: {
    items: t('services/directus:faq.items')
  }
});
