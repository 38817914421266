import React from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as apiActions from 'redux/actions/api';
import * as navigationActions from 'redux/actions/navigation';

class _container extends React.Component {
  static propTypes = {
    apiActions: PropTypes.object.isRequired,
    api: PropTypes.object.isRequired,
    children: PropTypes.node,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    navigationActions: PropTypes.object.isRequired
  };

  componentDidMount() {
    this._mounted = true;
    const { serviceId } = this.props.match.params;
    const route = `/dashboard/services/${serviceId}`;
    this.props.apiActions.subscribe(
      {
        route,
        routeArgs: {},
        handleErrorAutomatically: false
      },
      err => err && this._mounted && this.props.history.replace('../')
    );

    const { url, path } = this.props.match;
    const urlCorrected = !/\/$/.test(url) && /\/$/.test(path) ? `${url}/` : url;
    this.props.navigationActions.breadcrumbAdd(serviceId, urlCorrected, urlCorrected);
  }

  componentDidUpdate(prevProps) {
    const { serviceId } = this.props.match.params;
    const route = `/dashboard/services/${serviceId}`;
    const subscription = this.props.api.subscriptions[route];
    if (!subscription || !subscription.datas) {
      return;
    }
    const { url, path } = this.props.match;
    const urlCorrected = !/\/$/.test(url) && /\/$/.test(path) ? `${url}/` : url;
    this.props.navigationActions.breadcrumbUpdate(subscription.datas.name, urlCorrected, urlCorrected);
  }

  componentWillUnmount() {
    const { url, path } = this.props.match;
    const urlCorrected = !/\/$/.test(url) && /\/$/.test(path) ? `${url}/` : url;
    this.props.navigationActions.breadcrumbRemove(urlCorrected);

    const { serviceId } = this.props.match.params;
    const route = `/dashboard/services/${serviceId}`;
    this.props.apiActions.unsubscribe({ route: route });
    this._mounted = false;
  }

  render() {
    const { serviceId } = this.props.match.params;
    const route = `/dashboard/services/${serviceId}`;
    const subscription = this.props.api.subscriptions[route];
    const display = subscription && subscription.status !== 'initialize';

    return display ? this.props.children : null;
  }
}

export default connect(
  ({ api }) => ({ api }),
  dispatch => ({
    navigationActions: bindActionCreators(navigationActions, dispatch),
    apiActions: bindActionCreators(apiActions, dispatch)
  })
)(_container);
